import { CssBaseline } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AuthProvider } from './context/AuthContext';
import { ClientsContextProvider } from './context/ClientsContext';
import { EventsContextProvider } from './context/EventsContext';
import { GpUpdatesContextProvider } from './context/GpUpdatesContext';
import { InvestmentsContextProvider } from './context/InvestmentsContext';
import { OpportunitiesContextProvider } from './context/OpportunitiesContext';
import { ProfessionalsContextProvider } from './context/ProfessionalContext';
import { RouterProviderEx } from './context/RouterProviderEx';
import { ThemeProviderEx } from './context/ThemeProviderEx';
import { UIContextProvider } from './context/UIContext';
import Zendesk from "react-zendesk";
import { NotificationsProvider } from './context/NotificationsContext';

const setting = {
  color: {
    theme: "#ff5c35",
    launcherText: "#fff",
    text: "#fff",
  },

  launcher: {
    chatLabel: {
      "en-US": "Need Help"
    }
  },
  contactForm: {
    fields: [
      // "Are you an Agent or Investor (optional)?" - sets 'Advisor' as default
      { id: "17736141491597", prefill: { "*": "advisor_webwidget" } }
    ]
  }
};

function App() {

  return (
    <ThemeProviderEx>
      <UIContextProvider>
        <AuthProvider>
          <ProfessionalsContextProvider>
            <OpportunitiesContextProvider>
              <ClientsContextProvider>
                <EventsContextProvider>
                  <InvestmentsContextProvider>
                    <GpUpdatesContextProvider>
                      <NotificationsProvider>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        {process.env.REACT_APP_ZENDESK_KEY && <Zendesk defer zendeskKey={process.env.REACT_APP_ZENDESK_KEY} {...setting} onLoaded={() => {}} />}
                        <CssBaseline />
                        <RouterProviderEx />
                      </LocalizationProvider>
                      </NotificationsProvider>
                    </GpUpdatesContextProvider>
                  </InvestmentsContextProvider>
                </EventsContextProvider>
              </ClientsContextProvider>
            </OpportunitiesContextProvider>
          </ProfessionalsContextProvider>
        </AuthProvider>
      </UIContextProvider>
    </ThemeProviderEx>
  );
}

export default App;

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Container, Toolbar, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ContentBackground } from "../../../components/ContentBackground/ContentBackground";
import { NewClientContextProvider } from "../../../context/NewClientContext";
import NewClientStepper from "./NewClientStepper";

export const NewClientPage: FC = (props) => {
  const navigate = useNavigate();

  return (
    <NewClientContextProvider>
      <Container sx={{ py: 2 }}>
        <ContentBackground>
          <Toolbar sx={{justifyContent: "space-between", py: 2}}>
            <Box sx={{display: 'flex', alignItems: 'center', }}>
              <ChevronLeftIcon onClick={() => navigate("/clients")} sx={{color: 'grey',fontSize: 30, cursor: 'pointer'}}/>
              <Typography variant="h5">Add New Client</Typography>
            </Box>
          </Toolbar>
          <Box sx={{py: 2, px: 3}}>
            <NewClientStepper />
          </Box>
        </ContentBackground>
      </Container>
    </NewClientContextProvider>
  )
}
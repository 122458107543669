import { Box, DialogActions, DialogContent, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { Field, Form, useFormikContext } from "formik";
import { Select } from "formik-mui";
import { useEffect, useMemo, useState } from "react";
import { apiClient } from "../../../../api/apiClient";
import { Custodian } from "../../../../api/payloads/customer.payload";
import { LoadingButton } from "../../../../components/Buttons";
import { FormikStepValidationHelper } from "../../../../components/FormikStepValidationHelper/FormikStepValidationHelper";
import { useClientDetails, useInit } from "../../../../hooks";
import { AccountType } from "../../../../utility/constants";
import { BaseRecord } from "../../../../utility/types";
import EntityAccountFields from "../../NewClientPage/Forms/EntityAccountFields";
import IRAAccountFields from "../../NewClientPage/Forms/IRAAccountFields";
import IndividualAccountFields from "../../NewClientPage/Forms/IndividualAccountFields";
import JointAccountFields from "../../NewClientPage/Forms/JointAccountFields";
import { AccountFormValues } from "../../NewClientPage/Forms/newClientFormSchema";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "100px",
    },
  },
};

type Props = {
  isLoading: boolean
}

export default function AddAccountFormFields({isLoading}:Props) {
  const {setFieldValue, values, isValid, validateForm, submitCount} = useFormikContext<AccountFormValues>();
  const {activeClient} = useClientDetails();
  const [custodians, setCustodians] = useState<Custodian[]>([]);
  const [accountTypeValues, setAccountTypeValues] = useState<BaseRecord[]>([])
  const [iraTypes, setIraTypes] = useState<BaseRecord[]>([]);
  const isIRA = values.accountType === AccountType.IRA;
  const showCustodiedQuestion = values.accountType && values.accountType !== AccountType.IRA;
  const {accountType, custodian, isCustodied } = values

  useInit(async () => {
    const resp = await apiClient.get("/customers/new-client-field-values");
    const accountTypesResp = await apiClient.get('/customers/accounts/types');
    setCustodians(resp.custodians)
    setAccountTypeValues(accountTypesResp.accountTypes);
    setIraTypes(accountTypesResp.iraTypes);
  }, null)

  useEffect(() => {
    setFieldValue("custodian", !isCustodied && accountType !== AccountType.IRA ? "" : custodian);
  },[accountType, custodian, isCustodied, setFieldValue, validateForm])

  const appropriateFields = useMemo(() => {
    switch(accountType as AccountType) {
      case AccountType.IRA:
        return <IRAAccountFields iraTypes={iraTypes} members={activeClient?.contacts ?? []} custodians={custodians}/>;
      case AccountType.Individual:
        return <IndividualAccountFields members={activeClient?.contacts ?? []} custodians={custodians}/>;
      case AccountType.Joint:
        return <JointAccountFields members={activeClient?.contacts ?? []} custodians={custodians}/>;
      case AccountType.Entity:
        return <EntityAccountFields defaultEntity={null} entities={activeClient?.entities ?? null} custodians={custodians}/>;
      default:
        return null
    }
  },[accountType, custodians, activeClient, iraTypes])

  return (
      <Form>
        <FormikStepValidationHelper activeStep={0}/>
        <DialogContent style={{ paddingTop: '0.5rem' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                formControl={{required: true, fullWidth: true}}
                component={Select}
                label="Account Type"
                MenuProps={MenuProps}
                name="accountType"
                onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
              >
                {accountTypeValues.map(a => <MenuItem disabled={a.id === AccountType.Entity && activeClient?.entities.length === 0} key={a.id} value={a.id}>{a.name}</MenuItem>)}
              </Field>
            </Grid>

            {isIRA && (
              <Grid item xs={6}>
                <Field
                  formControl={{ required: true, fullWidth: true }}
                  component={Select}
                  label="IRA Type"
                  MenuProps={MenuProps}
                  name="iraTypeId"
                  onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
                >
                  {iraTypes.map(a => <MenuItem key={a.id} value={a.id}>{a.name}</MenuItem>)}
                </Field>
              </Grid>
            )}
          </Grid>
          <Box sx={{py:1}}>
          </Box>
          {showCustodiedQuestion && (
          <Box sx={{ mb: 2 }}>
          <Typography>
            Would you like eligible investments with this account to be reported on the investor's Brokerage Statement*?
            <br />
            <Typography component="span" variant="body2" style={{ fontStyle: 'italic', fontSize: '0.875rem' }}>
              (*The Custodian may charge a fee for alternative investments. Please contact the Custodian for more information.)
            </Typography>
          </Typography>
          <Field component={RadioGroup} name="isCustodied" row>
            <FormControlLabel control={<Radio />} label="Yes" value="true" checked={values.isCustodied === true} onChange={() => setFieldValue("isCustodied", true)}/>
            <FormControlLabel control={<Radio />} label="No" value="false" checked={values.isCustodied === false} onChange={() => setFieldValue("isCustodied", false)}/>
          </Field>
        </Box>
          )}
          {values.accountType && (
          <Box sx={{pb:2}}>
            <Typography variant="h5" sx={{pb:2}}>Details</Typography>
            <Box>
              {appropriateFields}
            </Box>
          </Box>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton disabled={!isValid && submitCount > 0} color='info' type='submit' loading={isLoading}>Submit</LoadingButton>
        </DialogActions>
      </Form>
  );
}
import { AccountBox, Logout, Settings, Notifications } from "@mui/icons-material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { AppBar, Badge, Box, IconButton, Menu, MenuItem, Tabs, Toolbar, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import React, { useEffect, useMemo, useState } from "react";
import { useMatches, useNavigate } from "react-router-dom";
import { useAuth, useProfessionals } from "../../hooks";
import useBoolean from "../../hooks/useBoolean";
import { navBarRoutes } from "../../routes/router";
import { LOGO_SMALL } from "../../utility/constants";
import { LinkTab } from "../LinkTab/LinkTab";
import NotificationsCard from "../Notifications/NotificationsCard";
import AllNotificationsDrawer from "../Notifications/AllNotifications";
import { useNotifications } from "../../context/NotificationsContext";

 
export const NavBar = (_props: unknown) => {
  const [_, match] = useMatches();
  const { clearUser } = useAuth();
  const { professional } = useProfessionals();
  const navigate = useNavigate();
  const navBarRoutesSet = useMemo(() => new Set(navBarRoutes.map((route) => `/${route.path}`)),[])
  const [value, setValue] = useState(navBarRoutesSet.has(match.pathname) ? match.pathname : false);
  const { value: menuOpen, setValue: setMenuOpen, setTrue, setFalse: setMenuClosed, toggle: toggleMenu } = useBoolean(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [cardAnchorEl, setCardAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { notifications } = useNotifications();
  const unreadCount = notifications.filter((n) => !n.isRead).length;

  const handleCardOpen = (event: React.MouseEvent<HTMLElement>) => {
    setCardAnchorEl(event.currentTarget);
  };

  const handleCardClose = () => {
    setCardAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setCardAnchorEl(null);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    toggleMenu()
  };
  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    setMenuClosed();
    setAnchorEl(null);
  };

  useEffect(() => {
    setValue(navBarRoutesSet.has(match.pathname) ? match.pathname : false)
  },[match.pathname, navBarRoutesSet])

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleLogoutClick = async () => {
    try {
      await Auth.signOut()
      clearUser();
    } catch(e) {
    } finally {
      navigate("/login");
    }
  }

  const handleSettingsClick = () => {
    navigate("/settings")
  }
  // const triggerStonly = () => {
  //   console.log("Triggering Stonly");
  //   window.StonlyWidget && window.StonlyWidget('show');
  // };
  
  return (
    <AppBar position="static" color="default">
      <Toolbar sx={{justifyContent: 'space-around'}}>
        <Box display="flex">
          <img src={LOGO_SMALL} alt="logo" style={{width: '14rem'}}/>
        </Box>
        <Tabs value={value} onChange={handleChange} aria-label="nav tabs">
          {navBarRoutes.map(r => (
            <LinkTab label={r.label} to={r.path!} value={`/${r.path!}`} key={r.label}/>
          ))}
        </Tabs>
        <Box>
        <IconButton color="primary" aria-label="notifications" onClick={handleCardOpen}>
          <Badge badgeContent={unreadCount} color="error" variant="standard"></Badge>
          <Notifications/>
        </IconButton>
          <NotificationsCard open={Boolean(cardAnchorEl)} anchorEl={cardAnchorEl} onClose={handleCardClose} onViewAll={handleDrawerOpen}/>
        <AllNotificationsDrawer open={drawerOpen} onClose={handleDrawerClose}/>
        <IconButton color="primary" aria-label="settings" component="label" onClick={handleClick}>
          <Settings />
        </IconButton>
        <IconButton color="primary" aria-label="help" component="label" id='bip-capital-vision-stonly-kb-trigger'>
      <HelpOutlineIcon />
    </IconButton>
    </Box>
        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={menuOpen}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
       {professional != null && <MenuItem disabled>
          <Typography>{`${professional.first_name} ${professional.last_name}`}</Typography>
        </MenuItem>}
        <MenuItem>
          <IconButton color="primary" aria-label="settings" component="label" onClick={handleSettingsClick} sx={{borderRadius: 0}}>
              <AccountBox fontSize="small" sx={{marginRight: 2}} />
              <Typography>Settings</Typography>
          </IconButton>
        </MenuItem>
        <MenuItem>
        <IconButton color="primary" aria-label="settings" component="label" onClick={handleLogoutClick}  sx={{borderRadius: 0}}>
            <Logout fontSize="small" sx={{marginRight: 2}} />
            <Typography>Sign Out</Typography>
        </IconButton>
        </MenuItem>
      </Menu>
      </Toolbar>
    </AppBar>
  )
}
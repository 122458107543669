import { useFormikContext } from "formik";
import { FC, useEffect } from "react";
import { SubDocsFormValues } from "../../pages/Opportunities/Subdoc/Forms/subDocsFormSchema";
import { isDebug } from "../../utility/misc.util";

export const FormikStepValidationHelper: FC<{activeStep: number}> = ({activeStep}) => {
  const {validateForm, setTouched, errors, values} = useFormikContext<SubDocsFormValues>();

  useEffect(() => {
    setTouched({}, false);
    validateForm();
  }, [activeStep, validateForm, setTouched])

  useEffect(() => {
    if (isDebug && errors) {
      console.log(errors);
      console.log(values);
    }
  }, [errors, values])

  return null;
}
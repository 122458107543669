import { Card, Typography, Box, IconButton, useTheme } from "@mui/material";
import { FC } from "react";
import NorthEastIcon from "@mui/icons-material/NorthEast";

type UnlinkedWidgetProps = {
  title: string;
  subtitle: string;
  count: number;
  gradient: string;
  iconBgColor: string;
  iconColor: string;
  hoverBgColor: string;
  hoverIconColor: string;
  onClick?: () => void;
};

export const UnlinkedWidget: FC<UnlinkedWidgetProps> = ({ title, subtitle, count, gradient, iconBgColor, iconColor, hoverBgColor, hoverIconColor, onClick }) => {
  if (count === 0) return null;
  return (
    <Card sx={{ display:'flex', flexDirection: 'column', padding: '20px', background: gradient, boxShadow: 1, width: '24%'}}>
      <Typography variant="h6">{title} </Typography>
      <Typography sx={{ fontSize: 13}} variant="subtitle1">{subtitle}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
        <Typography variant="h3" color={'error.main'}>{count}</Typography>
        <IconButton
          onClick={onClick}
          sx={{
            color: iconColor,
            backgroundColor: iconBgColor,
            borderRadius: '50%',
            '&:hover': { backgroundColor: hoverBgColor, color: hoverIconColor }
          }}
        >
          <NorthEastIcon />
        </IconButton>
      </Box>
    </Card>
  );
};
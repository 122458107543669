import { Search } from "@mui/icons-material";
import { Container, Grid, InputAdornment, TextField, Toolbar, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { ContentBackground } from "../../components/ContentBackground/ContentBackground";
import EmptyState from "../../components/EmptyState/EmptyState";
import { useGpUpdates } from "../../hooks";
import { GpUpdateCard } from "./GpUpdateCard";

export const GpUpdatesPage: FC = (props) => {
  const { gpUpdates } = useGpUpdates();

  const [searchFilter, setSearchFilter] = useState("");
  const [filteredRows, setFilteredRows] = useState(gpUpdates);
  
  useEffect(() => {
    //TODO: Look into some kind of debouncing strategy with a hook or something to improve performance
    const filteredRows = gpUpdates.filter(r => (r.name.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase()) ||
                                                (r.plain_updates && r.plain_updates.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase())) ||
                                                (r.title && r.title.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase()))));
    setFilteredRows(filteredRows);
  }, [searchFilter, gpUpdates])
  

  return (
    <Container sx={{ py: 2 }}>
      <ContentBackground>
        <Toolbar sx={{justifyContent: "space-between", py: 2}}>
          <Typography variant="h5">GP Updates</Typography>
          <TextField
            value={searchFilter}
            onChange={(event) => setSearchFilter(event.target.value)}
            placeholder="Search Updates"
            size="small" 
            helperText="" //Empty string to remove the helper space padding, since we've set our theme's default helperText value as " ";
            InputProps={{
              startAdornment: <InputAdornment position="start"><Search/></InputAdornment>,
            }} />
        </Toolbar>
        {filteredRows.length > 0 ?
          <Grid container spacing={2} sx={{p: 2, minWidth: 1000}}>
            {filteredRows.map((gpUpdate, idx ) => (
            <Grid key={idx} item xs={3}>
              <GpUpdateCard gpUpdate={gpUpdate}/>
            </Grid>))}
          </Grid>
          :
            <EmptyState message="No updates"/>
        }
      </ContentBackground>
    </Container>
  )
}
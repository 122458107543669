import { ArrowForwardIos, Image } from "@mui/icons-material";
import { Box, Chip, Container, Grid, styled, Typography, Link as MuiLink  } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import { LimitedLineTypography } from "../../components/Typography/LimitedLineTypography";
import { GpUpdate } from "../../context/GpUpdatesContext";
import { formatDate } from "../../utility/date.util";
import { maskRouteId } from "../../utility/route-mask.util";

type GpUpdateProps = {
  gpUpdate: GpUpdate,
}

const Img = styled('img')({
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

export const GpUpdateCard: FC<GpUpdateProps> = ({gpUpdate}) => {
  return (
    <Box sx={{
      overflow: 'hidden', 
      height: 400, 
      borderRadius: 2, 
      position: 'relative', 
      backgroundColor: 'background.paper', 
      mt: 2,
      }}
      >
      <Img alt="article" src={gpUpdate.logo_url}/>
      <Box 
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'space-between',
          justifyContent: 'space-between',
          height: '100%',
          p: 1,
        }}
      >
        <Box sx={{backgroundColor: 'primary.main', display: 'flex', p: 1, borderRadius: 2, justifyContent: 'center', alignItems: 'center', width: 'fit-content' }}>
          <Typography color='background.paper' variant="subtitle1">
            {formatDate(gpUpdate.date)}
          </Typography>
        </Box>
        <Box sx={{p: 2, backgroundColor: 'background.paper', borderRadius: 2}}>
          <Box sx={{alignItems: 'center', display: 'flex', justifyContent: 'space-between'}}>
            <Chip label={gpUpdate.name} sx={{mb:1,fontWeight: 'bold'}}/>            
          </Box>
          <LimitedLineTypography gutterBottom variant="h5" numLines={4}>
            {gpUpdate.title}
          </LimitedLineTypography>
          <LimitedLineTypography numLines={3}>
            {gpUpdate.plain_updates}
          </LimitedLineTypography>
          <MuiLink component={Link} to={`/gp-updates/${maskRouteId(gpUpdate.id)}`} sx={{alignItems: 'center', display: 'flex', cursor: 'pointer', textDecoration: 'none'}}>
            <Typography color="primary.main" sx={{ cursor: 'pointer' }}>
              Read more
            </Typography>
            <ArrowForwardIos sx={{fontSize: 12, color: 'primary.main'}}/>
          </MuiLink>
        </Box>
      </Box>
    </Box>
  )
}
import { Box, Paper, TableContainer } from "@mui/material";
import { FC } from "react";
import { CapitalCall } from "../../../../../api/payloads";
import { ColumnDef, DataSourceTable } from "../../../../../components/DataSourceTable";
import { useInvestments, useMaskedParams } from "../../../../../hooks";
import { formatDate } from "../../../../../utility/date.util";
import { toCurrency } from "../../../../../utility/number.util";
import { CapitalCallDetailHeader } from "./CapitalCallDetailHeader";

const displayCurrency = (v: number) => toCurrency(v);

const columnDef: ColumnDef<CapitalCall['capitalCallInvestors'][number]> = [
  { id: 'customer_name', label: "Client" },
  { id: 'account_name', label: "Account" },
  { id: 'capital_called', label: "Capital Called", displayFn: displayCurrency, showTotal: true },
  { id: 'adjustment', label: "Adjustment", displayFn: displayCurrency, showTotal: true },
  { id: 'amount_due', label: "Amount Due", displayFn: displayCurrency, showTotal: true },
  { id: 'amount_paid', label: "Amount Paid", displayFn: displayCurrency, showTotal: true },
  { id: 'remaining', label: "Remaining", displayFn: v => v > 0 ? <Box component='span' color="error.main">{toCurrency(v)}</Box> : toCurrency(v), showTotal: true },
  { id: 'received_date', label: "Received Date", displayFn: v => formatDate(v) },
];

export const InvestmentCapitalCallDetail: FC = () => {
  const { capitalCallId } = useMaskedParams();
  const { capitalCallMap } = useInvestments();
  const capitalCall = capitalCallMap[+capitalCallId!] ?? {};
  const dataSource = capitalCall.capitalCallInvestors || [];

  return (
    <>
      <CapitalCallDetailHeader capitalCall={capitalCall} />
      <Box p={3}>
        <TableContainer component={Paper}>
          <DataSourceTable columnDef={columnDef} dataSource={dataSource} emptyMessage="No capital calls" showTotals />
        </TableContainer>
      </Box>
    </>
  )
}
import { Box, Button, ButtonProps, styled } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import ICalendarLink from "react-icalendar-link";
import { InvestmentEvent } from "../../api/payloads/events.payload";

/** A little bit of hacky retyping since ICalendarLink type defs are missing the children prop. */
const ICalendarLinkTypeFix = ICalendarLink as unknown as FC<PropsWithChildren<any>>;

type AddToCalendarButtonProps = ButtonProps & { event: InvestmentEvent }

export const AddToCalendarButton: FC<PropsWithChildren<AddToCalendarButtonProps>> = ({event, ...rest}) => {
  const calendarEvent = {
    title: event.name,
    description: event.description,
    startTime: event.start_time as string,
    endTime: event.end_time as string,
    location: event.location
  };

  return (
    <ICalendarLinkTypeFix filename="event.ics" event={calendarEvent}>
      <Box display="inline-flex" alignItems='center'>
        <Button {...rest}>Add to Calendar</Button>
      </Box>
    </ICalendarLinkTypeFix>
  )
}
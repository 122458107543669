import { Box, Checkbox, FormControlLabel, FormGroup, MenuItem, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { Select, TextField } from 'formik-mui';
import { DocumentFileUploadField } from '../../CustomFields/DocumentFileUploadField';
import { FC, useEffect } from 'react';
import { useClientDetails, useClients, useUI } from '../../../../../../hooks';
import { InstructionDetailFormValues } from '../newInstructionValidationSchema';


export const WireInstructionFields: FC = () => {
  const {addressStates} = useClients();
  const {activeClient} = useClientDetails();
  const {values, validateForm, setFieldTouched, setFieldValue} = useFormikContext<InstructionDetailFormValues>();
  const {setError} = useUI();

  useEffect(() => {
    if (values.wireInstructions?.existingAddressId != null) {
      setFieldTouched("wireInstructions.streetAddress1", true, true);
      setFieldTouched("wireInstructions.streetAddress2", true, true);
      setFieldTouched("wireInstructions.city", true, true);
      setFieldTouched("wireInstructions.stateId", true, true);
      setFieldTouched("wireInstructions.zip", true, true);
    }
  }, [values.wireInstructions?.existingAddressId, validateForm, setFieldTouched])

  const handleMailToClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && activeClient?.address) {
      setFieldValue("wireInstructions.existingAddressId", activeClient.address.id)
      setFieldValue("wireInstructions.streetAddress1", activeClient.address.street_address_1 ?? "")
      setFieldValue("wireInstructions.streetAddress2", activeClient.address.street_address_2 ?? "")
      setFieldValue("wireInstructions.city", activeClient.address.city ?? "")
      setFieldValue("wireInstructions.stateId", activeClient.address.state_id ?? "")
      setFieldValue("wireInstructions.zip", activeClient.address.zip_code ?? "")
    } else {
      setFieldValue("wireInstructions.existingAddressId", null)
      setFieldValue("wireInstructions.streetAddress1", "")
      setFieldValue("wireInstructions.streetAddress2", "")
      setFieldValue("wireInstructions.city", "")
      setFieldValue("wireInstructions.stateId", "")
      setFieldValue("wireInstructions.zip", "")
    }
  }

  return (
    <Box sx={{mt: 4, mb:2}}>
      <Box>
        <Field name="wireInstructions.instructionName" required label="Distribution Instruction Friendly Name" component={TextField} sx={{width: '50%', mr: 2, ml:2}}/>
        <Field name="wireInstructions.bankAccountName" required label="Name on Bank Account" component={TextField} sx={{width: '40%', ml:1}}/>
      </Box>
      <Box>
        <Field name="wireInstructions.aba" required label="Bank's Routing Number" component={TextField} sx={{width: '50%', mr: 2, ml: 2, mt:2}}/>
        <Field name="wireInstructions.accountNumber" required label="Bank Account Number" component={TextField} sx={{width: '40%', ml:1, mt:2}}/>
      </Box>
      <Box>
        <Field name="wireInstructions.financialInstitutionName" required label="Financial Institution Name" component={TextField} sx={{width: '92.5%', ml:2}}/>
      </Box>
      <Box>
        <Field name="wireInstructions.financialStreetAddress1" required label="Financial Institution Address" component={TextField} sx={{width: '50%', mr: 2, ml:2}}/>
        <Field name="wireInstructions.financialStreetAddress2" label="Street Address 2" component={TextField} sx={{width: '40%', ml:1}}/>
      </Box>
      <Box sx={{display: 'flex'}}>
        <Field name="wireInstructions.financialCity" required label="City" component={TextField} sx={{width: '30%', mr: 1, ml:2}}/>
        <Field 
          name="wireInstructions.financialStateId" 
          label="State" 
          component={Select} 
          formControl={{required: true}}
          sx={{ width: '200px'}}
          onClose={() => {}}
          >
            {addressStates != null && addressStates.map(state => (
              <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
            ))}
        </Field>
        <Field name="wireInstructions.financialZip" label="ZIP Code" required component={TextField} sx={{ml: 3.5,width: '30%'}}/>
      </Box>
      <Box>
        <Field name="wireInstructions.ffc" label="Reference (For Further Credit)" component={TextField} sx={{width: '50%', mr: 2, ml:2}}/>
        <Field name="wireInstructions.ffcAccountNumber" label="FFC to Account Number" component={TextField} sx={{width: '40%', ml:1}}/>
      </Box>
      <Box sx={{display: 'flex',justifyContent: 'flexStart', alignItems: 'center', mb: 2, ml:2}}>
        <Typography sx={{fontWeight: 'bold', mr: 2}}>Fetch Investor Address:</Typography>
        <FormGroup>
          <FormControlLabel control={<Checkbox onChange={handleMailToClick} disabled={!activeClient?.address} />} label="Same as Household Address" />
        </FormGroup>
      </Box>
      <Box>
        <Field name="wireInstructions.streetAddress1" disabled={values.wireInstructions?.existingAddressId != null} required label="Investor Primary Address" component={TextField} sx={{width: '50%', mr: 2, ml:2}}/>
        <Field name="wireInstructions.streetAddress2" disabled={values.wireInstructions?.existingAddressId != null} label="Street Address 2" component={TextField} sx={{width: '40%', ml:1}}/>
        <Box sx={{display: 'flex'}}>
          <Field name="wireInstructions.city" disabled={values.wireInstructions?.existingAddressId != null} required label="City" component={TextField} sx={{width: '30%', mr: 1, ml:2}} />
          <Field 
            name="wireInstructions.stateId" 
            label="State" 
            disabled={values.wireInstructions?.existingAddressId != null}
            component={Select} 
            formControl={{required: true}}
            sx={{ width: '200px'}}
            onClose={() => {}}
            >
              {addressStates != null && addressStates.map(state => (
                <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
              ))}
          </Field>
          <Field name="wireInstructions.zip" disabled={values.wireInstructions?.existingAddressId != null} label="ZIP Code" required component={TextField} sx={{ml: 3.5,width: '30%'}}/>
        </Box>
      </Box>
        <Box>
          <DocumentFileUploadField
            accept=".pdf"
            label="Verification Document"
            name="wireInstructions.verificationDocument"
            sx={{width: '30%', mr: 2, ml: 2, mt:2}}
          />
        </Box>
    </Box>
  );
}
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Select, TextField } from "formik-mui";
import { useEffect, useState } from "react";
import { InferType, number, object, string } from "yup";
import { apiClient } from "../../../../api/apiClient";
import { CornerCloseButton, LoadingButton } from "../../../../components/Buttons";
import { useClientDetails, useClients, useMaskedParams, useUI } from "../../../../hooks";
import { FormikSubmitFn } from "../../../../utility/types";

const addressFormSchema = object({
  streetAddress1: string().required("Street Address 1 is required"),
  streetAddress2: string(),
  city: string().required("City is required"),
  stateId: number().nullable(),
  zipCode: string().required("Zip Code is required"),
});

type AddressData = InferType<typeof addressFormSchema>;

type Props = {
  open: boolean,
  setClose: () => void,
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EditAddressDialog({setClose, open}: Props) {
  const { clientId } = useMaskedParams();
  const { loading, setLoading, setError, setSuccess } = useUI()
  const { activeClient, setActiveClient } = useClientDetails();
  const [selectedState, setSelectedState] = useState<number | null>(activeClient?.address?.state_id ?? null)
  const initialValues: AddressData = { 
    streetAddress1: activeClient?.address?.street_address_1 ?? "",
    streetAddress2: activeClient?.address?.street_address_2 ?? "",
    city: activeClient?.address?.city ?? "", 
    stateId: selectedState,
    zipCode: activeClient?.address?.zip_code ?? ""
  };

  useEffect(() => {
    setSelectedState(activeClient?.address?.state_id ?? null)
  },[activeClient, setSelectedState])

  const {addressStates} = useClients();
  const handleSubmit: FormikSubmitFn<AddressData> = async (values, { setErrors ,setSubmitting, setFieldError }) => {
    if (!values.stateId || selectedState == null){
      setFieldError("stateId","State must be selected");
      return
    }

    if (clientId == null || activeClient?.address?.id == null) {
      setError("Oops, something went wrong. Please try again later")
      return
    }

    setLoading(true);
    try {
      const resp = await apiClient.patch("/customers/:p0/address/:p1", {routeParams: [clientId!, activeClient?.address?.id], 
        data: { 
          street_address_1: values.streetAddress1, 
          street_address_2: values.streetAddress2 ?? "", 
          city: values.city, 
          state_id: selectedState,
          zip_code: values.zipCode
         }});
      setLoading(false);
      if (activeClient != null && resp != null) {
        setSuccess("Successfully updated address");
        activeClient.address = resp
        const newClient = {...activeClient}
        newClient.address = resp;
        setActiveClient(newClient);
        setClose();
      }
    } catch (e) {
      setError("Oops, something went wrong. Please try again later")
      setLoading(false)
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    if (typeof event.target.value === "number") {
      setSelectedState(event.target.value);
    }
  };

  return (
    <Dialog open={open} PaperProps={{ sx: { minWidth: '700px' } }} maxWidth="sm" onClose={() => setClose()}>
      <DialogTitle>
        Edit Address
        <CornerCloseButton onClick={() => setClose()}></CornerCloseButton>
      </DialogTitle>
        <Formik initialValues={initialValues} validationSchema={addressFormSchema} onSubmit={handleSubmit}>
          {({ isValid, dirty, errors }) => (
            <Form>
              <DialogContent style={{ paddingTop: '0.5rem',}}>
              <ErrorMessage  name="error"/>
              <Field name="streetAddress1" label="Street Address 1" component={TextField} fullWidth/>
              <Field name="streetAddress2" label="Street Address 2" component={TextField} fullWidth/>
              <Field name="city" label="City" component={TextField} fullWidth />
              <Field 
                as='select' 
                name="stateId" 
                label="State" 
                component={Select} 
                sx={{ width: '40.6rem'}}
                MenuProps={MenuProps}
                value={selectedState ?? ""}
                onChange={handleChange}
                >
                  {addressStates != null && addressStates.map(state => (
                    <MenuItem key={state.id} value={state.id}><option value={state.id}>{state.name}</option></MenuItem>
                ))}
              </Field>
              <Field name="zipCode" label="Zip Code" component={TextField} fullWidth sx={{mt: 3}}/>
                </DialogContent>
              <DialogActions>
                  <LoadingButton disabled={!isValid && dirty} color='info' type='submit' loading={loading}>Submit</LoadingButton>
                </DialogActions>
            </Form>
          )}
        </Formik>
    </Dialog>
  )
}
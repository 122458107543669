import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { FC } from "react"
import { CornerCloseButton } from "../../../components/Buttons"

export type BdcDisclaimerDialogProps = {
  investmentCompanyName: string,
  handleClose: (result?: boolean) => void,
}

export const BdcDisclaimerDialog: FC<BdcDisclaimerDialogProps> = (props) => {
  return (
    <Dialog open maxWidth='md'>
      <DialogTitle>
        BDC Disclaimer
        <CornerCloseButton onClick={() => props.handleClose()}></CornerCloseButton>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '0.5rem' }}>
        <Typography>
          Before you begin, a reminder that {props.investmentCompanyName} (the “BDC”) is not for everyone.
          The BDC intends to invest in early-stage, privately held companies that on average take 5-6 years
          to mature before the BDC will realize an exit. Based on our track record of success, BIP Capital
          strongly believes that patient capital is required to optimize the value of these investments,
          and therefore intends to work closely with them as their business model, go-to-market motions,
          and expected traction begins to take shape. Please consider the time horizon of a successful
          investment when recommending the BDC to individuals and clients.
        </Typography>
        <br />
        <Typography>
          In addition, while not necessary, it is helpful to have your client fully set up in Advisor Vision, including which account they plan to invest out of, before starting the investor documents process.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color='info' onClick={() => props.handleClose(true)}>Continue</Button>
      </DialogActions>
    </Dialog>
  )
}
import { apiClient } from '../api/apiClient';
import { PostGenerateCustomerDocumentUploadUrlReq } from '../api/payloads';

export async function createDocument(file: File, data: PostGenerateCustomerDocumentUploadUrlReq) {
  const signedUrlData = await apiClient.post('/documents/customers-documents/generate-signed-url', { data });
  await apiClient.putFile(signedUrlData.signedUrl, file);
  
  const {documentId} = await apiClient.post('/documents', {
    data: {
      documentTypeId: data.documentTypeId,
      fileName: signedUrlData.fileName,
      s3Key: signedUrlData.s3Key,
      mimeType: file.type,
      size: file.size,
    }
  });

  return documentId;
}
import { Box } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { Field, FieldArray, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import { useEffect, useState } from "react";
import { Contact, Custodian } from "../../../../api/payloads/customer.payload";
import { AccountFormValues } from "./newClientFormSchema";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "100px",
    },
  },
};

type Props = {
  members: Contact[],
  custodians: Custodian[]
}

export default function JointAccountFields({members, custodians}: Props) {
  const [selectedClientMembers, setSelectedClientMembers] = useState(new Set())
  const {values, setFieldValue, setFieldTouched, validateForm} = useFormikContext<AccountFormValues>();
  const {clientAccountMembers, ssnContactId, custodian, isCustodied} = values;

  useEffect(() => {
    const memberIds = clientAccountMembers.filter(id => id !== "") as number[];
    setSelectedClientMembers(new Set(memberIds))

    if (ssnContactId !== '' && !memberIds.some(v => v === ssnContactId)) {
      setFieldValue('ssnContactId', '');
    }
  }, [clientAccountMembers, ssnContactId, setFieldValue, validateForm])

  useEffect(() => {
    const currentCustodian = custodians.find(c => (c.id.toString() === custodian.toString()));
    const jointMembers = members.filter(member => clientAccountMembers.includes(member.id));
    let accountName = "";

    if (jointMembers && jointMembers.length > 0) {
      const allSameLastName = new Set(jointMembers.map(member => member.last_name)).size === 1;

      if (isCustodied && currentCustodian) {
        if (allSameLastName) {
          const firstNames = jointMembers.map(member => member.first_name).join(' and ');
          accountName = `${currentCustodian.name} FBO ${firstNames} ${jointMembers[0].last_name}`;
        } else {
          const fullNames = jointMembers.map(member => `${member.first_name} ${member.last_name}`).join(' and ');
          accountName = `${currentCustodian.name} FBO ${fullNames}`;
        }
      } else {
        if (allSameLastName) {
          const names = jointMembers.map(member => member.first_name).join(' and ') + ` ${jointMembers[0].last_name}`;
          accountName = names;
        } else {
          const names = jointMembers.map(member => `${member.first_name} ${member.last_name}`).join(' and ');
          accountName = names;
        }
      }
    }

    setFieldValue('accountName', accountName).then(() => validateForm());
  }, [members, clientAccountMembers, custodian, custodians, isCustodied, setFieldValue, setFieldTouched, validateForm]) 

  return (
     <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '16px', mb: 2 }}>
        {isCustodied && custodians && (
          <>
            <Box sx={{ width: 'calc(50% - 8px)' }}>
              <Field
                formControl={{ required: true, sx: {width: '100%', minWidth: 112, mb: 2}}}
                component={Select}
                label="Custodian Name"
                MenuProps={MenuProps}
                name="custodian"
                required
              >
                {custodians.filter(custodian => custodian.is_custodied !== false).map((custodian) => (
                  <MenuItem key={custodian.id} value={custodian.id}>{custodian.name}</MenuItem>
                ))}
              </Field>
            </Box>
            <Box sx={{ width: 'calc(50% - 8px)' }}>
              <Field
                name="accountNumber"
                required
                label="Account Number"
                component={TextField}
                sx={{width: '100%'}}
              />
            </Box>
          </>
        )}
        <FieldArray name="clientAccountMembers">
          {() => (
            <>
              {values.clientAccountMembers.map((_, index) => (
                <Box key={index} sx={{ width: 'calc(50% - 8px)' }}>
                  <Field
                    formControl={{required: true, sx:{width: '100%', minWidth: 112, mb: 2}}}
                    component={Select}
                    label="Account Owner"
                    MenuProps={MenuProps}
                    name={`clientAccountMembers[${index}]`}
                    onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
                  >
                    {members.map((member) => (
                      <MenuItem disabled={selectedClientMembers.has(member.id)} key={member.id} value={member.id}>
                        {`${member.last_name}, ${member.first_name}`}
                      </MenuItem>
                    ))}
                  </Field>
                </Box>
              ))}
            </>
          )}
        </FieldArray>
        <Box sx={{ width: 'calc(50% - 8px)' }}>
          <Field
            formControl={{ required: true, sx:{width: '100%', minWidth: 112, mb: 2}}}
            component={Select}
            label="SSN"
            MenuProps={MenuProps}
            name="ssnContactId"
            onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string 
          >
            {members.map((member) => {
              if (selectedClientMembers.has(member.id)) {
                return <MenuItem key={member.id} value={member.id}><option value={member.id}>{`${member.last_name}, ${member.first_name}`}</option></MenuItem>
              }
              return null
            })}
          </Field>
        </Box>
        <Box sx={{ width: 'calc(50% - 8px)' }}>
          <Field disabled name="accountName" required label="Account Name" component={TextField} sx={{width: '100%'}}/>
        </Box>
      </Box>
    </>
  );
}
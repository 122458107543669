import { Box, Breadcrumbs, Container, Link as MuiLink, Toolbar, Typography } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import { AddToCalendarButton, EventSignUpButton } from "../../components/Buttons";
import { ContentBackground } from "../../components/ContentBackground/ContentBackground";
import { useEvents, useMaskedParams } from "../../hooks";
import { formatDate } from "../../utility/date.util";

export const EventDetailPage: FC = () => {
  const { eventsMap } = useEvents();
  const { eventId } = useMaskedParams();
  const eventDetail = eventsMap[+eventId!] ?? {};
  const startTime = formatDate(eventDetail.start_time, { timeStyle: "short" });
  const endTime = formatDate(eventDetail.end_time, { hour: "numeric", minute: "numeric", timeZoneName: "short" });

  return (
    <Container sx={{ py: 2 }}>
      { !!eventDetail.id &&
        <ContentBackground>
          <Toolbar sx={{ flexDirection: 'column', alignItems: 'flex-start', pt: 2 }}>
            <Breadcrumbs sx={{ pb: 1 }}>
              <MuiLink component={Link} to="/events">Events</MuiLink>
              <Typography>{eventDetail.name ?? ""}</Typography>
            </Breadcrumbs>
            <Box display='flex' justifyContent='space-between' width='100%'>
              <Typography variant='h5'>{eventDetail.name}</Typography>
              <EventSignUpButton event={eventDetail} />
            </Box>
            <Typography>{formatDate(eventDetail.start_time, { dateStyle: "long" })}</Typography>
            <Typography>{`${startTime} - ${endTime}, ${eventDetail.location}`}</Typography>
            <AddToCalendarButton sx={{ p: 0 }} size="large" variant="text" event={eventDetail} />
          </Toolbar>
          <Box p={3}>
            <Typography variant='h5'>About</Typography>
            <Typography>{eventDetail.description}</Typography>
          </Box>
        </ContentBackground>
      }
    </Container>
  )
}
import { ErrorMessage, FieldArray } from "formik";
import { ProfessionalsTable } from "./ProfessionalsTable";

export default function ProfessionalsForm() {
  return (
      <>
        <ErrorMessage  name="error"/>
        <FieldArray name="professionals">
          {({ form, remove, push, }) => (
            <ProfessionalsTable push={push} remove={remove} />
          )}
        </FieldArray>
      </>
  );
}
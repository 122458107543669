import { MAX_FILE_SIZE } from "./constants";

export const downloadFile = (url: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
  }
  
export const downloadBlob = (data: Blob, fileName: string) => {
  const url = URL.createObjectURL(data);
  downloadFile(url, fileName);
  setTimeout(() => URL.revokeObjectURL(url));
}

/** Returns true is file is within file size limit, or if passing in data that represents an uploaded file */
export const largeFileTest = (value: File | {name: string}) => {
  if (!(value instanceof File)) {
    return true;
  
  }
  const isTooBig = value.size > MAX_FILE_SIZE;
  return !isTooBig;
};

/** Returns true if file data is not 0 bytes */
export const invalidFileDataTest = (value: File | {name: string}) => {
  if (!(value instanceof File)) {
    return true;
  
  }
  const isInvalidData = value.size === 0;
  return !isInvalidData;
};

export const largeFileTestMessage = `File size cannot be large than ${MAX_FILE_SIZE / 1024 / 1024} megabytes.`;
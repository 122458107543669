import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { paginate } from "../utility/array.util";

const calculatePageNumbers = <T=any>(dataSource: T[], rowsPerPage: number) => {
  return Math.ceil(dataSource.length / rowsPerPage);
};

const defaultRowsPerPage = 5;

export const usePaginator = <T=any>(dataSource: T[], initialRowsPerPage?: number) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(initialRowsPerPage ?? defaultRowsPerPage);
  const [paginatedRows, setPaginatedRows] = useState(paginate(dataSource, currentRowsPerPage, currentPage));
  const [numberOfPages, setNumberOfPages] = useState(0);

  useEffect(() => {
    setNumberOfPages(calculatePageNumbers(dataSource, currentRowsPerPage));
    setCurrentPage(1);
    const isShowAll = currentRowsPerPage === -1;
    setPaginatedRows(!isShowAll ? paginate(dataSource, currentRowsPerPage, 1) : dataSource);
  }, [dataSource, currentRowsPerPage])

  const handlePageChange = useCallback((_event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    setPaginatedRows(paginate(dataSource, currentRowsPerPage, value));
  }, [dataSource, currentRowsPerPage]);


  const values = useMemo(() => ({
    currentPage,
    currentRowsPerPage,
    setCurrentRowsPerPage,
    paginatedRows,
    numberOfPages,
    handlePageChange,
  }), [currentPage, paginatedRows, currentRowsPerPage, handlePageChange, numberOfPages]);

  return values;
}
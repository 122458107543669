import { Box, MenuItem } from '@mui/material';
import { Field } from 'formik';
import { Select, TextField } from 'formik-mui';
import { useClients } from '../../../../../hooks';
import { DocumentFileUploadField } from '../../../../Clients/NewClientPage/Forms/CustomFields/DocumentFileUploadField';

export default function WireInstructionFields() {
  const {addressStates} = useClients();

  return (
    <Box sx={{mt: 4, mb:2}}>
      <Box>
        <Field name="wireInstructions.instructionName" required label="Distribution Instruction Friendly Name" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
        <Field name="wireInstructions.bankAccountName" required label="Name on Bank Account" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
      </Box>
      <Box>
        <Field name="wireInstructions.aba" required label="Bank's Routing Number" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
        <Field name="wireInstructions.accountNumber" required label="Bank Account Number" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
      </Box>
      <Box>
        <Field name="wireInstructions.financialInstitutionName" required label="Financial Institution Name" component={TextField} fullWidth/>
      </Box>
      <Box>
        <Field name="wireInstructions.financialStreetAddress1" required label="Financial Institution Address" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
        <Field name="wireInstructions.financialStreetAddress2" label="Street Address 2" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Field name="wireInstructions.financialCity" required label="City" component={TextField} sx={{width: 'calc(42% - 8px)', mr: 2}} />
        <Field 
          name="wireInstructions.financialStateId" 
          label="State" 
          component={Select} 
          formControl={{required: true}}
          sx={{ width: '200px',}}
          onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
          >
            {addressStates != null && addressStates.map(state => (
              <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
            ))}
        </Field>
        <Field name="wireInstructions.financialZip" label="ZIP Code" required component={TextField} sx={{ml: 2,width: 'calc(42% - 8px)'}}/>
      </Box>
        <Box>
          <Field name="wireInstructions.ffc" label="Reference (For Further Credit)" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
          <Field name="wireInstructions.ffcAccountNumber" label="FFC to Account Number" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
          <DocumentFileUploadField
            accept=".pdf"
            label="Verification Document"
            name="wireInstructions.verificationDocument"
            sx={{width: 'calc(33% - 8px)', mr: 2}}
          />
        </Box>
    </Box>
  );
}
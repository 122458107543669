import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useSubDocs } from "../../../../hooks/ContextHooks/useSubDocs";
import { ApplicationSource, DistributionInstructionType, DocumentTypes } from "../../../../utility/constants";
import { DistributionInformationFormValues } from "./subDocsFormSchema";

export const DistributionInformationFormObserver: React.FC = () => {
  const {distributionInstructionsData} = useSubDocs();
  const { values, setFieldValue, validateForm, errors } = useFormikContext<DistributionInformationFormValues>();

  useEffect(() => {
    if (values.distributionInstructionId !== "") {
      const selectedDistributionInstruction = distributionInstructionsData?.distributionInstructions.find(instruction => instruction.id === values.distributionInstructionId)
      if (selectedDistributionInstruction != null) {
        setFieldValue("distributionInstructionType", selectedDistributionInstruction.instruction_type_id)
        if (selectedDistributionInstruction.instruction_type_id === DistributionInstructionType.ACH) {
          setFieldValue("achInstructions.instructionName", selectedDistributionInstruction.name ?? "")
          setFieldValue("achInstructions.bankAccountName",selectedDistributionInstruction.instruction_account_name ?? "")
          setFieldValue("achInstructions.aba", selectedDistributionInstruction.aba ?? "")
          setFieldValue("achInstructions.accountNumber", selectedDistributionInstruction.instruction_account_number ?? "")
          setFieldValue("achInstructions.accountType", selectedDistributionInstruction.account_type_id ?? "")
          setFieldValue("achInstructions.ffc", selectedDistributionInstruction.ffc_to ?? "")
          if (selectedDistributionInstruction.application_source_id === ApplicationSource.AdvisorVision) {
            const document = selectedDistributionInstruction.documents.find(document => document.document_type_id === DocumentTypes.VoidedCheck)
            setFieldValue("achInstructions.voidedCheck.documentId",document?.document_id ?? "")
            setFieldValue("achInstructions.voidedCheck.documentName",document?.document_name ?? "")
          }
        } else if (selectedDistributionInstruction.instruction_type_id === DistributionInstructionType.Check) {
          setFieldValue("checkInstructions.instructionName", selectedDistributionInstruction.name ?? "")
          setFieldValue("checkInstructions.payeeName",selectedDistributionInstruction.recipient_name ?? "")
          setFieldValue("checkInstructions.streetAddress1", selectedDistributionInstruction.street_address_1 ?? "")
          setFieldValue("checkInstructions.streetAddress2", selectedDistributionInstruction.street_address_2 ?? "")
          setFieldValue("checkInstructions.city", selectedDistributionInstruction.city ?? "")
          setFieldValue("checkInstructions.stateId", selectedDistributionInstruction.state_id ?? "")
          setFieldValue("checkInstructions.zip", selectedDistributionInstruction.zip_code ?? "")
        } else if (selectedDistributionInstruction.instruction_type_id === DistributionInstructionType.Wire) {
          setFieldValue("wireInstructions.instructionName", selectedDistributionInstruction.name ?? "")
          setFieldValue("wireInstructions.bankAccountName",selectedDistributionInstruction.instruction_account_name ?? "")
          setFieldValue("wireInstructions.aba", selectedDistributionInstruction.aba ?? "")
          setFieldValue("wireInstructions.accountNumber", selectedDistributionInstruction.instruction_account_number ?? "")
          setFieldValue("wireInstructions.financialInstitutionName", selectedDistributionInstruction.financial_institution_name ?? "")
          setFieldValue("wireInstructions.financialStreetAddress1", selectedDistributionInstruction.fi_street_address_1 ?? "")
          setFieldValue("wireInstructions.financialStreetAddress2", selectedDistributionInstruction.fi_street_address_2 ?? "")
          setFieldValue("wireInstructions.financialCity", selectedDistributionInstruction.fi_city ?? "")
          setFieldValue("wireInstructions.financialStateId", selectedDistributionInstruction.fi_state_id ?? "")
          setFieldValue("wireInstructions.financialZip", selectedDistributionInstruction.fi_zip_code ?? "")
          setFieldValue("wireInstructions.ffc", selectedDistributionInstruction.ffc_to ?? "")
          setFieldValue("wireInstructions.ffcAccountNumber", selectedDistributionInstruction.ffc_account_number ?? "")
          if (selectedDistributionInstruction.application_source_id === ApplicationSource.AdvisorVision) {
            const document = selectedDistributionInstruction.documents.find(document => document.document_type_id === DocumentTypes.WireVerificationDocument)
            setFieldValue("wireInstructions.verificationDocument.documentId",document?.document_id ?? "")
            setFieldValue("wireInstructions.verificationDocument.documentName",document?.document_name ?? "")
          }
        } else if (selectedDistributionInstruction.instruction_type_id === DistributionInstructionType.Brokerage) {
          setFieldValue("brokerageInstructions.instructionName", selectedDistributionInstruction.name ?? "")
          setFieldValue("brokerageInstructions.custodianId", selectedDistributionInstruction.custodian_id ?? "")
          setFieldValue("brokerageInstructions.bankAccountName",selectedDistributionInstruction.instruction_account_name ?? "")
          setFieldValue("brokerageInstructions.aba", selectedDistributionInstruction.aba ?? "")
          setFieldValue("brokerageInstructions.accountNumber", selectedDistributionInstruction.instruction_account_number ?? "")
          setFieldValue("brokerageInstructions.financialInstitutionName", selectedDistributionInstruction.financial_institution_name ?? "")
          setFieldValue("brokerageInstructions.financialCity", selectedDistributionInstruction.fi_city ?? "")
          setFieldValue("brokerageInstructions.financialStateId", selectedDistributionInstruction.fi_state_id ?? "")
          setFieldValue("brokerageInstructions.ffc", selectedDistributionInstruction.ffc_to ?? "")
          setFieldValue("brokerageInstructions.ffcAccountNumber", selectedDistributionInstruction.ffc_account_number ?? "")
          if (selectedDistributionInstruction.application_source_id === ApplicationSource.AdvisorVision) {
            const document = selectedDistributionInstruction.documents.find(document => document.document_type_id === DocumentTypes.WireVerificationDocument)
            setFieldValue("brokerageInstructions.verificationDocument.documentId",document?.document_id ?? "")
            setFieldValue("brokerageInstructions.verificationDocument.documentName",document?.document_name ?? "")
          }

        }
      }
    } else {
      const defaultDistributionInstruction = distributionInstructionsData?.distributionInstructions.find(instruction => instruction.is_default_account_instruction === true)
      if (defaultDistributionInstruction != null) {
        setFieldValue("distributionInstructionId", defaultDistributionInstruction.id)
        setFieldValue("distributionInstructionType", defaultDistributionInstruction.instruction_type_id);
      }
    }
    
  }, [setFieldValue,values.distributionInstructionId, distributionInstructionsData?.distributionInstructions, validateForm])

  return null;
};
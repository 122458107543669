import { boolean, number, object, ref } from "yup";
import { InterestedClient } from "../../../../api/payloads";
import { Account } from "../../../../api/payloads/customer.payload";
import { Client } from "../../../../context/ClientsContext";
import { Opportunity } from "../../../../context/OpportunitiesContext";
import { SubDocStatus } from "../../../../utility/constants";
import { toCurrency } from "../../../../utility/number.util";

export type InvestorFormValues = {
  investmentCompany: Opportunity,
  /** note: could just calc isBdc from the company data every time, so this is really just a shorthand since will read it a bunch*/
  isBdc: boolean,
  isSubsequentInvestment: boolean,
  existingEntryListEntry: InterestedClient | null,
  client: Client | null,
  account: Account | null,
  investmentAmount: number | "",
  parentId?: number,
}

export const addInvestorFormSchema = object({
  investmentCompany: object().shape({
    minimum_commitment: number().required(params => `Missing ${params.path} metadata field`),
  }).required(params => `Missing ${params.path} metadata field`),
  isBdc: boolean().required(params => `Missing ${params.path} metadata field`),
  isSubsequentInvestment: boolean().required(params => `Missing ${params.path} metadata field`),
  existingEntryListEntry: object().nullable(),
  client: object().nullable().required("Required"),
  account: object().nullable().required("Required")
    .when('isSubsequentInvestment', {
      is: true,
      then: schema => schema.when('isBdc', {
        is: true,
        then: schema => schema.test(
          'isExistingInterestListSubAgreementSigned',
          'Previous subscription documents must be signed before adding subsequent investment for this account',
          (_, context) => {
            const existingEntry = context.parent.existingEntryListEntry as InterestedClient | null;
            return !existingEntry || (!!existingEntry.sub_doc_submission_health && existingEntry.sub_doc_submission_health > SubDocStatus.AwaitingSignature);
          }
        ),
        otherwise: schema => schema
          .test(
            'isExistingInterestList',
            'Already on interest list',
            (_, context) => !context.parent.existingEntryListEntry
          )
      }),
    }),
  investmentAmount: number().required('Required')
    .when('isBdc', {
      is: false,
      then: (schema) => schema.min(
        ref('investmentCompany.minimum_commitment'),
        message => `Commitment must be greater than ${toCurrency(message.min)}`
      ),
      otherwise: schema => schema.when('isSubsequentInvestment', {
        is: true,
        then: schema.min(5000, message => `Subsequent Investment must be at least ${toCurrency(message.min)}`),
        otherwise: schema.min(10000, message => `Initial Investment must be at least ${toCurrency(message.min)}`)
      })
    })
});
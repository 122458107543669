import { Box, MenuItem, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import { FC, useCallback, useState } from "react";
import { apiClient } from "../../../../api/apiClient";
import { DatePickerField } from "../../../../components/FormFields/DatePickerField";
import { useClients, useInit } from "../../../../hooks";
import { RevocableStrings } from "../../../../utility/constants";
import { SubDocsRadioGroup } from "./CustomFields/SubDocsRadioGroup";
import { SubDocsFormValues } from "./subDocsFormSchema";
import { useSubDocs } from "../../../../hooks/ContextHooks/useSubDocs";

export const SupplementalData: FC = () => {
  const {addressStates, setAddressStates} = useClients();
  const { values } = useFormikContext<SubDocsFormValues>();
  const [showRevocableOptions, setShowRevocableOptions] = useState<boolean | null>(values.revocable === "" ? null :  values.revocable === RevocableStrings.Revocable);
  const { subDocsAccount } = useSubDocs();
  const init = useCallback(async () => {
    if (addressStates == null) {
      const states = await apiClient.get("/customers/states");
      setAddressStates(states);
    }
  }, [addressStates, setAddressStates])

  useInit(init);

  return (
    <Box sx={{ display: 'flex',flexDirection: 'column', py: 2,}}>
        <Typography variant="h6">Supplemental Data {subDocsAccount?.trust && `for Trusts`} </Typography>
        <Box sx={{display: 'flex',width: '100%', my: 2}}>
          <DatePickerField name="dateOfIncorporation" label="Date of Incorporation *" sx={{width: '100%',mr: 2}}/>
          <Field
            as='select' 
            name="stateOfIncorporation" 
            label="State of Incorporation" 
            component={Select} 
            formControl={{ fullWidth: true, required: true}} 
            onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
          >
            {addressStates != null && addressStates.map(state => (
              <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
            ))}
          </Field>
        </Box>
        <Box sx={{width: '100%', pl: 2, pb: 2}}>
          <SubDocsRadioGroup name='fundSpecificEntity' label='Was Entity formed specifically for the purpose of investing in the Company?' />
        </Box>
        {subDocsAccount?.trust === true && 
        <Field
          formControl={{ required: true,sx:{width: 'calc(50% - 8px)', mr: 2, mb: 2}}}
          component={Select}
          label="Type of Trust"
          name="revocable"
          onChange={(event: any) => {
            const value = event.target.value;
            if (value === RevocableStrings.Revocable) {
              setShowRevocableOptions(true)
            } else if (value === RevocableStrings.Other) {
              setShowRevocableOptions(false)
            } else {
              setShowRevocableOptions(null)
            }
          }}
        >
          {(Object.keys(RevocableStrings) as Array<keyof typeof RevocableStrings>).map(a => <MenuItem key={a} value={a}>{RevocableStrings[a]}</MenuItem>)}
        </Field>
       }
        {showRevocableOptions === true && subDocsAccount?.trust === true &&<Box>
          <Field name="grantor" label="Grantor(s)" required component={TextField} sx={{width: 'calc(50% - 8px)',mr: 2}}/>
        </Box>}
        {showRevocableOptions === false && subDocsAccount?.trust === true && <Box>
          <Field name="typeOfTrust" label="Type of 'Other' Trust" required component={TextField} sx={{width: 'calc(50% - 8px)',mr: 2}}/>
          <Field name="grantor" label="Grantor(s)" required component={TextField} sx={{width: 'calc(50% - 8px)',}}/>
          <Field name="beneficiary" label="Beneficiar(y)/(ies)" required component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
          <Field name="trustees" label="Trustee(s)" required component={TextField} sx={{width: 'calc(50% - 8px)',}}/>
        </Box>}
    </Box>
  )
}
import { AddCircle, Delete } from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Tooltip, Typography } from "@mui/material";
import { ErrorMessage, FieldArray } from "formik";
import { useNewClient } from "../../../../hooks/ContextHooks/useNewClient";
import MemberFormFields from "./MemberFormFields";
import { MemberValue, initialMemberValue } from "./newClientFormSchema";

export default function MemberForm() {
  const {accreditationValues, countryCodeValues, phoneTypeValues} = useNewClient();

  return (
      <>
        <ErrorMessage  name="error"/>
        <FieldArray name="members">
          {({ form, remove, push, }) => (
            <>
              {(form.values.members as MemberValue[]).map((member, index) => (
                  <Accordion key={index} defaultExpanded={index === 0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Box sx={{ display: 'flex',alignItems: 'center', justifyContent: 'space-between', width: '100%', height: 40, mr: 2}}>
                        <Typography sx={{fontSize: 20}}>{`Member ${index + 1}`}</Typography>
                      {index !== 0 &&
                      <Tooltip title="Delete" >
                        <IconButton aria-label='delete' onClick={() => remove(index)} color='error' ><Delete /></IconButton>
                      </Tooltip>}
                      </Box>
                    </AccordionSummary>
                  <AccordionDetails>
                    <MemberFormFields accreditationValues={accreditationValues} countryCodeValues={countryCodeValues} index={index} key={index} phoneTypeValues={phoneTypeValues} />
                  </AccordionDetails>
                </Accordion>
               ))}
              <Tooltip title="Add Member">
                <IconButton
                  sx={{ mt: 2 }}
                  color='primary'
                  aria-label='add member'
                  onClick={() => push(initialMemberValue)}
                >
                  <AddCircle fontSize="large" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </FieldArray>
      </>
  );
}
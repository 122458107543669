import { Navigate, RouteObject } from "react-router-dom";
import { InvestmentCapitalCallDetail, InvestmentCapitalCalls, InvestmentDistributions, InvestmentDistributionsDetail, InvestmentInvestors} from "../../pages/Investments/InvestmentDetailPage/Tabs";
import { NavBarRouteObject, isNavBarRoute } from "../router.util";
import { RegDInterestedList } from "../../pages/Opportunities/OpportunityDetailPage.tsx/RegDInterestedList";
import { InterestedList } from "../../pages/Opportunities/OpportunityDetailPage.tsx/InterestedList";
import { InvestedList } from "../../pages/Opportunities/OpportunityDetailPage.tsx/InvestedList";
import { DocumentsTab } from "../../pages/Opportunities/OpportunityDetailPage.tsx/DocumentsTab/DocumentsTab";

export const regDOpportunityDetailRoutes: (RouteObject | NavBarRouteObject)[] = [
  // { index: true, element: <Navigate to="interest-list" /> },
  { path: "interest-list", element: <RegDInterestedList />, handle: { tab: "interest-list" }, label: "Interest List" },
  { path: "investors", element: <InvestmentInvestors />, handle: { tab: "investors" }, label: "Investors" },
  { path: "capital-calls", element: <InvestmentCapitalCalls />, handle: { tab: "capital-calls" }, label: "Capital Calls", },
  { path: "capital-calls/:capitalCallId", element: <InvestmentCapitalCallDetail />, handle: { hideTabs: true }},
  { path: "distributions", element: <InvestmentDistributions />, handle: { tab: "distributions" }, label: "Distributions" },
  { path: "distributions/:distributionId", element: <InvestmentDistributionsDetail />, handle: { hideTabs: true }},
  { path: "documents", element: <DocumentsTab/>, handle: { tab: "documents" }, label: "Documents" },
];

export const bdcOpportunityDetailRoutes: (RouteObject | NavBarRouteObject)[] = [
  { index: true, element: <Navigate to="../" /> },
  { path: "bdc-interest-list", element: <InterestedList />, handle: { tab: "bdc-interest-list" }, label: "Interest List" },
  { path: "positions", element: <InvestedList />, handle: { tab: "positions" }, label: "Investors" },
  { path: "documents", element: <DocumentsTab />, handle: { tab: "documents" }, label: "Documents" },
];

export const regDOpportunityDetailNavLinks = regDOpportunityDetailRoutes.filter(isNavBarRoute);
export const bdcOpportunityDetailNavLinks = bdcOpportunityDetailRoutes.filter(isNavBarRoute);
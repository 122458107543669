import { useCallback, useMemo, useState } from "react";

/**
 * Utility for getting/setting a local storage value and loading it into app state
 * @returns [storedValue, setValue, clearValue]
 * @todo Work with conditional types or overload to narrow down the return type if you passed in a default
 * Right now will just rely on caller using non-null assertion operator if they provided default 
 */
export const useLocalStorage = <T=any>(key: string, defaultValue?: T) => {
  const [storedValue, setStoredValue] = useState<T | null>(() => {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : defaultValue ?? null;
  });

  const setValue = useCallback((newValue: T) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(newValue));
    } catch (err) {
      //TODO: Redirect to error page
    }
    setStoredValue(newValue);
  }, [key]);

  const clearValue = useCallback(() => {
    window.localStorage.removeItem(key);
    setStoredValue(null);
  }, [key])

  const values = useMemo(() => [
    storedValue,
    setValue,
    clearValue
  ] as const, [storedValue, setValue, clearValue])

  return values;
};
import { Box, Grid, MenuItem } from '@mui/material';
import { ErrorMessage, Field, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import { Accreditation, Country, PhoneNumberType } from '../../../../api/payloads/customer.payload';
import { DatePickerField } from '../../../../components/FormFields/DatePickerField';
import  {SSNInput } from '../../../../utility/MaskComponent';
import { IDFileUploadField } from './CustomFields/IDFileUploadField';
import { MemberFormValues } from "../../NewClientPage/Forms/newClientFormSchema";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "100px",
    },
  },
};

type Props = {
  index: number
  accreditationValues: Accreditation[]
  countryCodeValues: Country[]
  phoneTypeValues: PhoneNumberType[]
}

export default function MemberFormFields({accreditationValues, countryCodeValues, phoneTypeValues ,index}:Props) {
  const form = useFormikContext<MemberFormValues>();

  return (
      <Box>
        <ErrorMessage  name="error"/>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Field name={`members[${index}].firstName`} required label="First Name" component={TextField} sx={{width: 'calc(33% - 8px)', mr: 2}}/>
          <Field name={`members[${index}].nickname`} required label="Preferred Name" component={TextField} sx={{width: 'calc(33% - 8px)', mr: 2}}/>
          <Field name={`members[${index}].middleName`} label="Middle Name" component={TextField} sx={{width: 'calc(33% - 8px)', mr: 2}}/>
          <Field name={`members[${index}].lastName`} required label="Last Name" component={TextField} sx={{width: 'calc(33% - 8px)'}}/>
        </Box>
        <Field name={`members[${index}].email`} required label="Email" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2, mb:2}}/>
        <DatePickerField required={true} name={`members[${index}].dateOfBirth`} label="Date of Birth" sx={{width: "calc(50% - 8px)"}}/>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field name={`members[${index}].SSN`} label="SSN" required component={SSNInput} fullWidth sx={{ mb: 2 }}/>
          </Grid>
          <Grid item xs={2}>
            <Field required label="Phone Number" component={TextField} name={`members[${index}].phoneNumber`} fullWidth/>
          </Grid>
          <Grid item xs={2}>
            <Field
              formControl={{ required: true,sx: { minWidth: '100px', width: '100%' } }}
              required
              component={Select}
              label="Type"
              MenuProps={MenuProps}
              name={`members[${index}].contactNumberType`}
            >
              {phoneTypeValues.map(pt => <MenuItem key={pt.id} value={pt.id}>{pt.name}</MenuItem>)}
            </Field>
          </Grid>
          <Grid item xs={2}>
            <Field
              formControl={{ required: true,sx: { width: '100%' } }}
              component={Select}
              label="Country Code"
              MenuProps={MenuProps}
              name={`members[${index}].countryCode`}
            >
              {countryCodeValues.map(c => <MenuItem key={c.id} value={`${c.dial_code};${c.name}`}>{c.name}</MenuItem>)}
            </Field>
          </Grid>
        </Grid>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Field
              formControl={{required: true, sx:{width: 'calc(33% - 8px)', mr: 2}}}
              component={Select}
              label="Accreditation"
              MenuProps={MenuProps}
              name={`members[${index}].accreditation`}
            >
              {accreditationValues.map(a => <MenuItem key={a.id} value={`${a.id}`}>{a.name}</MenuItem>)}
            </Field>
          <IDFileUploadField accept="image/*,application/pdf" name={`members[${index}].idImageFile`} label="Upload Photo ID" index={index} sx={{width: 'calc(33% - 8px)', mr: 2}}/>
          <DatePickerField required={!!form.values.members[index].idImageFile} name={`members[${index}].expirationDate`} label="ID Expiration Date" sx={{width: 'calc(33% - 8px)' }}/>
        </Box>
      </Box>
  );
}
import React, { createContext, FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { GetFundRaisingBDCs } from "../api/payloads/investments.payload";
import { createObjectMap } from "../utility/array.util";

export type Opportunity = GetFundRaisingBDCs[number];

export type OpportunitiesContextValue = {
  opportunities: Opportunity[],
  opportunitiesMap: Record<Opportunity['id'], Opportunity>,
  setOpportunities: React.Dispatch<React.SetStateAction<Opportunity[]>>,
}

export const OpportunitiesContext = createContext(undefined as unknown as OpportunitiesContextValue);

export const OpportunitiesContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [opportunities, setOpportunities] = useState<OpportunitiesContextValue['opportunities']>([]);
  const [opportunitiesMap, setOpportunitiesMap] = useState<OpportunitiesContextValue['opportunitiesMap']>({});
 
  useEffect(() => {
    const map = createObjectMap(opportunities, 'id');
    setOpportunitiesMap(map);
  }, [opportunities])

  const value = useMemo(() => ({
    opportunities,
    setOpportunities,
    opportunitiesMap,
  }), [opportunities, setOpportunities, opportunitiesMap]);

  return (
    <OpportunitiesContext.Provider value={value}>
      {children}
    </OpportunitiesContext.Provider>
  )
}
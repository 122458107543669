import { Dialog, DialogContent, DialogProps, DialogTitle, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { WiringInstructions } from "../../../api/payloads";
import { CornerCloseButton } from "../../../components/Buttons/CornerCloseButton";
import { Opportunity } from "../../../context/OpportunitiesContext";

export type WiringDialogProps = Omit<DialogProps, 'onClose'> & {
  handleClose: (result?: boolean) => void
  wiringInstructions: WiringInstructions
  opportunity: Opportunity
};

type InfoCardProps = {
  title: string,
  value: string,
}

const InfoCard = ({title, value}:InfoCardProps) => {
  return (
    <>
      <Grid item xs={6} sx={{display: 'flex', height: 40}}>
        <Typography sx={{fontSize: 16,fontWeight: 'bold', mr: 4}}>{`${title}:`}</Typography>
      </Grid>
      <Grid item xs={6} sx={{display: 'flex', height: 40}}>
        <Typography sx={{color: 'info.contrastText'}}>{value}</Typography>
      </Grid>
    </>
  )
}

export const WiringDialog: FC<WiringDialogProps> = (props) => {
  let {  maxWidth, fullWidth, handleClose, wiringInstructions, opportunity,...rest } = props;

  return (
    
    <Dialog PaperProps={{ sx: { minWidth: '700px' } }} maxWidth={maxWidth} onClose={() => handleClose()} {...rest}>
      <DialogTitle>
        {`Wiring Instructions for ${opportunity.name}`}
        <CornerCloseButton onClick={() => handleClose()}></CornerCloseButton>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '0.5rem', }}>
        <Grid container sx={{flex: 1}}>
          <Typography sx={{my: 2}}>Please wire funds to the below wiring instructions</Typography>
          <InfoCard title="Bank Name" value={wiringInstructions.bank_name ?? "No wiring instructions set at this time"} />
          <InfoCard title="Routing Number" value={wiringInstructions?.routing_number ?? "No wiring instructions set at this time"} />
          <InfoCard title="Account Number" value={wiringInstructions.account_number ?? "No wiring instructions set at this time"} />
          <InfoCard title="Account Name" value={wiringInstructions.account_name ?? "No wiring instructions set at this time"} />
          <InfoCard title="Reference" value={wiringInstructions.reference ?? "No wiring instructions set at this time"} />
          <InfoCard title="Make Checks Payable To" value={wiringInstructions.check_payable_to ?? "No wiring instructions set at this time"} />
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
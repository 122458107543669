import { CheckCircleOutline } from "@mui/icons-material";
import { ButtonProps, Typography } from "@mui/material";
import { FC, useState } from "react";
import { InvestmentEvent } from "../../api/payloads/events.payload";
import { useAuth, useUI } from "../../hooks";
import { LoadingButton } from "./LoadingButton";

type EventSignUpButtonProps = ButtonProps & {
  event: InvestmentEvent
}
const shareholdersEventUrl = "https://bipcapital-5706307.hs-sites.com/shareholder2024";

export const EventSignUpButton: FC<EventSignUpButtonProps> = ({ event, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    // STOP-GAP JFM-2498: Open hyperlink to hubspot form url for 2024 shareholders meeting
    if (event.id === +process.env.REACT_APP_2024_SHAREHOLDERS_EVENT_ID!) {
      return window.open(shareholdersEventUrl, "_blank", 'noopener,noreferrer');
    }
    else {
      window.open(event.registration_url, "_blank", 'noopener,noreferrer');
    }
  }

  return (
    <LoadingButton {...rest} onClick={handleClick} loading={isLoading}>
      <span style={{fontWeight: 'bold'}}>Sign Up</span>
    </LoadingButton>
  )
}
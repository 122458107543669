import { Box, Container, Paper, styled, Typography } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { LOGO_VERTICAL_LOCKUP } from "../../utility/constants";

const Background = styled(Paper)(
  ({ theme }) => {
    const isDarkMode = theme.palette.mode === "dark";
    const backgroundImage = "url(/images/landing-background.png) no-repeat center center fixed";
    const darkenedBackgroundImage = `linear-gradient(135deg, #000000a6, #000000c4), ${backgroundImage}`;

    return {
      minHeight: '100vh',
      background: `${isDarkMode ? darkenedBackgroundImage : backgroundImage}`,
      backgroundSize: 'cover',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }
  });

type Props = {
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
};

export const UnauthenticatedAppShell: FC<Props> = ({maxWidth}: Props) => {
  return (
    <Background square>
      <Container 
        component="main" 
        maxWidth={maxWidth ?? "xs"}
        style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 0 }}>
          <img src={LOGO_VERTICAL_LOCKUP} alt="Vision Logo" style={{width: '40vw', maxWidth: '13rem'}}/>
        </Box>
        <Outlet/>
      </Container>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Typography component="footer" variant="caption">© All rights reserved</Typography>
      </Box>
    </Background>
  );
}
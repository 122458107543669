import { Search } from "@mui/icons-material";
import {
  Box,
  Container,
  InputAdornment,
  Link as MuiLink,
  Paper,
  TableContainer,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContentBackground } from "../../components/ContentBackground/ContentBackground";
import { ColumnDef, DataSourceTable } from "../../components/DataSourceTable";
import { TablePaginator } from "../../components/TablePaginator/TablePaginator";
import { Investment as TInvestment } from "../../context/InvestmentsContext";
import { useInvestments, usePaginator } from "../../hooks";
import { sumElements } from "../../utility/array.util";
import { formatDate } from "../../utility/date.util";
import { toCurrency, toPercent } from "../../utility/number.util";
import { maskRouteId } from "../../utility/route-mask.util";

const displayCapitalFn = (v: number, row: TInvestment) => {
  return (
    <Box>
      {`${toCurrency(v)}`}
      <Box component="span" color="primary.main">
        {` (${toPercent(v / row["total_commitment"])})`}
      </Box>
    </Box>
  );
};
const totalCommitmentFn = (dataSource: TInvestment[]): string =>
  dataSource.reduce((total, row) => total + row.total_commitment, 0).toString();

const totalCapitalFn = (dataSource: TInvestment[]): [number, number] => {
  const capital = sumElements(dataSource, "called_amount");
  const commitment = sumElements(dataSource, "total_commitment");
  return [capital, commitment];
};

const totalCapitalDisplayFn = (totals: [number, number]): ReactElement => {
  const [capital, commitment] = totals;
  return (
    <Box>
      {`${toCurrency(capital)} `}
      {commitment !== 0 && (
        <Box component="span" color="primary.main">
          {`(${toPercent(capital / commitment)})`}
        </Box>
      )}
    </Box>
  );
};

const columnDef: ColumnDef<TInvestment> = [
  {
    id: "name",
    label: "Name",
    displayFn: (v, row) => (
      <MuiLink component={Link} to={`./${maskRouteId(row.id)}/investors`}>
        {v}
      </MuiLink>
    ),
    sortable: true,
    sortValueFn: (v: string | null) => v?.toLocaleLowerCase() ?? ""
  },
  {
    id: "close_date",
    label: "Close Date",
    displayFn: (v: string) => formatDate(v),
    sortable: true,
    sortValueFn: (v: string | null) => v ?? ""
  },
  {
    id: "investors",
    label: "Client Investors",
    displayFn: (v: number) => (v ? v.toString() : ""),
    sortable: true,
  },
  {
    id: "entity_type",
    label: "Entity Type",
    sortable: true,
  },
  {
    id: "total_commitment",
    label: "Total Client Commitment",
    displayFn: (v) => <Box component="span">{toCurrency(v)}</Box>,
    showTotal: true,
    totalFn: totalCommitmentFn,
    sortable: true,
  },
  {
    id: "called_amount",
    label: "Capital Called",
    displayFn: displayCapitalFn,
    showTotal: true,
    totalFn: totalCapitalFn,
    totalDisplayFn: totalCapitalDisplayFn,
    sortable: true,
  },
];

export const InvestmentsPage: FC = (props) => {
  const { investments } = useInvestments();
  const [searchFilter, setSearchFilter] = useState("");
  const [filteredRows, setFilteredRows] = useState(investments);

  useEffect(() => {
    //TODO: Look into some kind of debouncing strategy with a hook or something to improve performance
    const filteredRows = investments.filter((r) =>
      r.name.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase())
    );
    setFilteredRows(filteredRows);
  }, [searchFilter, investments]);

  const {
    currentPage,
    currentRowsPerPage,
    setCurrentRowsPerPage,
    paginatedRows,
    numberOfPages,
    handlePageChange,
  } = usePaginator(filteredRows, -1);

  return (
    <Container sx={{ py: 2 }}>
      <ContentBackground>
        <Toolbar sx={{ justifyContent: "space-between", py: 2 }}>
          <Box>
            <Typography variant="h5" sx={{ paddingBottom: 3 }}>
              Investments
            </Typography>
            <TextField
              value={searchFilter}
              onChange={(event) => setSearchFilter(event.target.value)}
              placeholder="Search Investments"
              size="small"
              helperText="" //Empty string to remove the helper space padding, since we've set our theme's default helperText value as " ";
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Toolbar>
        <Box p={3}>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <DataSourceTable
              dataSource={paginatedRows}
              columnDef={columnDef}
              emptyMessage="No investments"
              showTotals={true}
              initialOrderBy="name"
            />
          </TableContainer>
          <TablePaginator
            page={currentPage}
            count={numberOfPages}
            currentRowsPerPage={currentRowsPerPage}
            setCurrentRowsPerPage={setCurrentRowsPerPage}
            onChange={handlePageChange}
          />
        </Box>
      </ContentBackground>
    </Container>
  );
};

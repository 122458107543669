import MenuItem from '@mui/material/MenuItem';
import { Field, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import { useEffect } from 'react';
import { Custodian, EntityResp, PostCustomerEntityResp } from '../../../../api/payloads/customer.payload';
import { AccountFormValues } from './newClientFormSchema';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "100px",
    },
  },
};

type Props = {
  entities: EntityResp[] | null
  defaultEntity: PostCustomerEntityResp | null
  custodians: Custodian[]
}
export default function EntityAccountFields({entities, defaultEntity, custodians}: Props) {
  const {setFieldValue, values, validateForm} = useFormikContext<AccountFormValues>();
  const {isCustodied, custodian, entityAccount} = values;

  useEffect(() => {
    defaultEntity && setFieldValue("entityAccount", defaultEntity?.entityId)
  },[defaultEntity, setFieldValue])

  useEffect(() => {
    //TODO: Update PostCustomerEntityResp and EntityResp to have common interface so can use simpler logic
    const currentCustodian = custodians.find(c => (c.id.toString() === custodian.toString()));
    const currentEntity = entities?.find(entity => entity.id === entityAccount);
    const accountName = `${isCustodied && currentCustodian ? currentCustodian.name + " FBO " : ""}${currentEntity?.name ?? defaultEntity?.entityName ?? ""}`;
    setFieldValue('accountName', accountName).then(() => validateForm());
  }, [custodian, custodians, defaultEntity, entities, entityAccount, isCustodied, setFieldValue, validateForm,])

  return (
     <>
     {isCustodied && custodians && (
        <>
          <Field
            formControl={{ required: true, sx: {width: 'calc(50% - 8px)', minWidth: 112, mb: 2, mr: 2}}}
            component={Select}
            label="Custodian Name"
            MenuProps={MenuProps}
            name="custodian"
            required
          >
            {custodians.filter(custodian => custodian.is_custodied !== false).map((custodian) => (
              <MenuItem key={custodian.id} value={custodian.id}>{custodian.name}</MenuItem>
            ))}
          </Field>
          <Field
            name="accountNumber"
            required
            label="Account Number"
            component={TextField}
            sx={{width: 'calc(50% - 8px)'}}
          />
        </>
      )}
      {/* TODO: Seems odd to have these alternate Select Entity controls as two separate elements. See if makes more sense to consolidate */}
      {entities == null ?
      <Field
        formControl={{ required: true, sx:{width: 'calc(50% - 8px)',minWidth: 112, mb:2, mr:2}}}
        component={Select}
        label="Select Entity"
        MenuProps={MenuProps}
        value={defaultEntity?.entityId}
        disabled
        name="entityAccount"
        onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
      >
       {[defaultEntity].map((entity, index) => (<MenuItem key={index} value={entity?.entityId}>{entity?.entityName ?? ""}</MenuItem>))}
      </Field>
      :
      <Field
        formControl={{ sx:{width: 'calc(50% - 8px)',minWidth: 112, mb:2, mr:2}}}
        component={Select}
        required
        label="Select Entity"
        MenuProps={MenuProps}
        name="entityAccount"
        onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
      >
       {entities.map((entity, index) => (<MenuItem key={index} value={entity?.id}>{entity?.name ?? ""}</MenuItem>))}
      </Field>
      }
      <Field disabled name="accountName" label="Account Name*" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
    </>
  );
}
import { FC, PropsWithChildren, useMemo } from "react";
import { RouterProvider } from "react-router-dom";
import { useAuth } from "../hooks";
import { getRouter } from "../routes/router";

export const RouterProviderEx: FC<PropsWithChildren> = ({children}) => {
  const {clearUser, user} = useAuth();
  
  const router = useMemo(() => {
    return getRouter(user, clearUser);
  }, [user, clearUser])

  return (
    <RouterProvider router={router}/>
  )
}
import { FC, PropsWithChildren } from "react";
import { ThemeProvider } from '@mui/material/styles';
import { useLocalStorage, useKeyDown } from "../hooks";
import { lightTheme, darkTheme } from "../themes";

export const ThemeProviderEx: FC<PropsWithChildren> = ({children}) => {
  const [darkMode, setDarkMode] = useLocalStorage('darkMode', false);
  
  useKeyDown({code: "KeyD", altKey: true, ctrlKey: true}, 
    () => setDarkMode(!darkMode),
    [darkMode]
  );
  
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>{children}</ThemeProvider>
  )
}
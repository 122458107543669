import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { Box, Button, FormHelperText, SxProps, Typography } from "@mui/material";
import { Theme } from "@mui/system";
import { FieldInputProps, useField, useFormikContext } from "formik";
import { SimpleFileUpload } from "formik-mui";
import { useEffect, useState } from 'react';
import { LimitedLineTypography } from "../../../../../components/Typography/LimitedLineTypography";
import { MAX_FILE_SIZE } from '../../../../../utility/constants';

type Props = {
  accept: string
  label: string
  name: string
  sx?: SxProps<Theme>
  fileValue?: FieldInputProps<any>
}

export const DocumentFileUploadField = (props: Props) => {
  const form = useFormikContext<any>();
  const [field, meta] = useField(props.name);
  const validationError = meta.error;
  const fileValue = props.fileValue ?? field.value;

  const title = fileValue ? fileValue.name : ""
  const [error, setError] = useState<string>("");

  useEffect(() => {
    //TODO: This may be dead code due to the validation in the form schema. Investigate and remove if possible. 
    if (field?.value != null && field.value.size != null && field.value.size > MAX_FILE_SIZE ) {
      setError("File Size is too large")
    } else {
      setError("")
    }
  }, [ field, setError, validationError])

  return (
    <Box>
      <Typography>{props.label}</Typography>
      <Button endIcon={<DriveFolderUploadIcon />} variant={fileValue ? "contained":"outlined"} component="label" sx={{p: 1.5, ...props.sx,}} >
        <LimitedLineTypography numLines={1}>{title}</LimitedLineTypography>
        <Box sx={{display: 'none'}} hidden={true}>
          <SimpleFileUpload 
            meta={{touched: true, value: fileValue, error: "null", initialValue: "", initialTouched: false }} 
            form={form} 
            field={field} 
            accept={props.accept} 
            label=""
          />
        </Box>
      </Button>
      <FormHelperText error>{meta.error ?? validationError ?? error}</FormHelperText>
    </Box>
  );
};
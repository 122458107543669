import { Navigate, RouteObject } from "react-router-dom";
import { ClientAccounts, ClientDocuments, ClientInstructions, ClientMembers, ClientOverview, ClientProfessionals, K1Checklist } from "../../pages/Clients/ClientDetailPage/Tabs";
import { isNavBarRoute, NavBarRouteObject, Placeholder } from "../router.util";

//TODO: Lots of repetition here with path, label, and handle values. 
// Consider DRying it up by building this with by calling Array.prototype.map with a simplified array.
export const clientDetailRoutes: (RouteObject | NavBarRouteObject)[] = [
  { index: true, element: <Navigate to="overview"/> },
  { path: "overview", element: <ClientOverview/>, handle: {tab: "overview"}, label: "Overview"},
  { path: "members", element: <ClientMembers/>, handle: {tab: "members"}, label: "Members"},
  { path: "accounts", element: <ClientAccounts/>, handle: {tab: "accounts"}, label: "Accounts"},
  { path: "professionals", element: <ClientProfessionals/>, handle: {tab: "professionals"}, label: "Professionals" },
  { path: "documents", element: <ClientDocuments/>, handle: {tab: "documents"}, label: "Investment Documents",},
  { path: "k1checklist", element: <K1Checklist/>, handle: {tab: "k1checklist"}, label: "K1 Checklist"},
  { path: "instructions", element: <ClientInstructions/>, handle: {tab: "instructions"}, label: "Distribution Instructions"},

];

export const clientDetailNavLinks = clientDetailRoutes.filter(isNavBarRoute); 
import { Auth } from "aws-amplify";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InferType, object, string } from "yup";
import { LoadingButton } from "../../../components/Buttons";
import { useUI } from "../../../hooks";
import { passwordRequirements } from "../../../utility/constants";
import { isError } from "../../../utility/type-guards";
import { FormikSubmitFn } from "../../../utility/types";

const setPasswordFormSchema = object({
  smsCode: string().required("Authentication code is required"),
  newPassword: string()
    .required("New Password is required")
    .matches(passwordRequirements.regex, passwordRequirements.message),
});

type SetPasswordData = InferType<typeof setPasswordFormSchema>;

const initialValues: SetPasswordData = { smsCode: "", newPassword: "" };

/** 
 * Form for handling the Forced Password Update case of logging in 
 */
export const SetPasswordForm: FC<{email: string}> = (props) => {
  const [loading, setLoading] = useState(false);
  const ui = useUI();
  const navigate = useNavigate();

  const handleSubmit: FormikSubmitFn<SetPasswordData> = async (values, { setSubmitting }) => {
    try {
      setLoading(true);
      await Auth.forgotPasswordSubmit(props.email, values.smsCode, values.newPassword);
      ui.setError("Password updated!") //TODO: Either rename `setError` or create an error vs success snackbar
      navigate('/login');
    } catch (e) {
      isError(e) && ui.setError(e.message);
      setSubmitting(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={setPasswordFormSchema} onSubmit={(handleSubmit)}>
      <Form >
        <Field name="smsCode" label="SMS Code" component={TextField} fullWidth />
        <Field name="newPassword" label="New Password" component={TextField} fullWidth type="password"/>
        <LoadingButton type="submit" size="large" fullWidth loading={loading}>
          Submit
        </LoadingButton>
      </Form>
    </Formik>
  );
}
import { East } from "@mui/icons-material";
import { Box, Button, styled, Typography } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import { InvestmentEvent } from "../../api/payloads/events.payload";
import { AddToCalendarButton, EventSignUpButton } from '../../components/Buttons';
import { LimitedLineTypography } from "../../components/Typography/LimitedLineTypography";
import { formatDate } from "../../utility/date.util";
import { maskRouteId } from "../../utility/route-mask.util";

const EventCardWrapper = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  '&.MuiBox-root:last-child': {
    borderBottom: 'unset',
  }
}))

type EventPageCardProps = { event: InvestmentEvent };

export const EventPageCard: FC<EventPageCardProps> = ({ event }) => {
  const startTime = formatDate(event.start_time, { timeStyle: "short" });
  const endTime = formatDate(event.end_time, { hour: "numeric", minute: "numeric", timeZoneName: "short" });

  return (
    <EventCardWrapper>
      <Box display="flex" justifyContent={"space-between"} sx={{mb: 1}}>
        <Typography>{formatDate(event.start_time, { dateStyle: 'long' })}</Typography>
        <Typography>{event.event_type_name}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h6">{event.name}</Typography>
        <EventSignUpButton size='small' event={event} sx={{ display: 'block' }} />
      </Box>
      <Box display='flex' justifyContent="space-between" alignItems="center" mb={1}>
        <Typography color="success.main">{`${startTime} - ${endTime}, ${event.location}`}</Typography>
        <AddToCalendarButton size='small' event={event} variant='outlined' />
      </Box>
      <Box sx={{ overflowY: "hidden", maxHeight: '3rem', mb: 1 }}>
        <LimitedLineTypography numLines={2}>{event.description}</LimitedLineTypography>
      </Box>
      <Button variant="outlined" endIcon={<East />} component={Link} to={`${maskRouteId(event.id)}`}>
        View All
      </Button>
    </EventCardWrapper>
  )
}
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Field, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import { FC, useEffect, useState } from "react";
import { useClients, useUI } from "../../../../hooks";
import { useSubDocs } from "../../../../hooks/ContextHooks/useSubDocs";
import { AccountType, ContactNumberTypes, CustomerEntityTypes, RevocableStrings } from "../../../../utility/constants";
import { AuthorizedRepresentative } from "./AuthorizedRepresentative";
import { BeneficialOwner } from "./BeneficialOwner";
import { SubDocsRadioGroup } from "./CustomFields/SubDocsRadioGroup";
import { InvestorQuestionnaireFormValues } from "./subDocsFormSchema";

type InfoCardProps = {
  title: string,
  value: string | number | React.ReactNode,
  subCopy?: string | null,
}

type OwnerInfo = {
  name: string;
  date_of_birth: string;
  ssn: string;
  email: string;
  phone_numbers: { number: string; contact_number_type_id: number }[];
};

type OwnerInfoListProps = {
  ownerInfoArray: OwnerInfo[];
};

export const InfoCard = ({title,subCopy,value}:InfoCardProps) => {
  return (
    <Grid item xs={6} sx={{display: 'flex', height: 60, mb:2}}>
      <Typography sx={{fontSize: 16, fontWeight: 'bold', mr: 4}}>{`${title}:`}</Typography>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Typography sx={{color: 'info.contrastText'}}>{value}</Typography>
        <Typography sx={{fontSize: '.8rem',color: grey[600]}}>{subCopy}</Typography>
      </Box>
      <Box>
      </Box>
    </Grid>
  )
}

 const InfoCardThirdWidth = ({title,subCopy,value}:InfoCardProps) => {
  return (
    <Grid item xs={4} sx={{display: 'flex', height: 60, mb:2}}>
      <Typography sx={{fontSize: 16, fontWeight: 'bold', mr: 4}}>{`${title}:`}</Typography>
      <Box sx={{display: 'flex', flexDirection: 'column'}} >
        <Typography sx={{color: 'info.contrastText'}}>{value}</Typography>
        <Typography sx={{fontSize: '.8rem',color: grey[600]}}>{subCopy}</Typography>
      </Box>
      <Box>
      </Box>
    </Grid>
  )
}

 const InfoListCard = ({ title, value, subCopy }: InfoCardProps) => {
  return (
    <Grid item xs={6} sx={{display: 'flex', height: 60, mb:2}}>
      <Typography sx={{fontSize: 16, fontWeight: 'bold', mr: 4}}>{`${title}:`}</Typography>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Typography sx={{color: 'info.contrastText'}}>{value}</Typography>
        <Typography sx={{fontSize: '.8rem',color: grey[600]}}>{subCopy}</Typography>
      </Box>
      <Box>
      </Box>
    </Grid>
  )
}

export const InvestorQuestionnaire: FC = () => {
  const { subDocsClient, subDocsAccount} = useSubDocs();
  const {setError} = useUI();
  const { values, setFieldValue, setTouched, touched  } = useFormikContext<InvestorQuestionnaireFormValues>();
  const { addressStates } = useClients();
  const address = subDocsClient!.address;
  //A few state variables aren't being read...is this dead code?
  const [ownerEmails, setOwnerEmails] = useState<string[]>([])
  const [ownerPhoneNumbers, setOwnerPhoneNumbers] = useState<string[]>([])
  const [formOfSubscriber, setFormOfSubscriber] = useState<string>("")
  const [formOfSubscriberSubCopy, setFormOfSubscriberSubCopy] = useState<string>("")
  const [owners, setOwners] = useState<any[]>([]);
  
  useEffect(() => {
    const emailString: string[] = []
    const phoneString: string[] = []
    setFormOfSubscriber(subDocsAccount?.account_type ?? "")

    if (subDocsAccount?.account_type_id === AccountType.Entity) {
      setFormOfSubscriber(subDocsAccount.customer_entity_type ?? "")
      if (subDocsAccount.customer_entity_type_id && subDocsAccount.customer_entity_type_id === CustomerEntityTypes.STrust) {
        setFormOfSubscriberSubCopy(subDocsAccount?.customer_entity_is_revocable === true ? RevocableStrings.Revocable : RevocableStrings.Other);
      } else if (subDocsAccount.customer_entity_type_id && (subDocsAccount.customer_entity_type_id === CustomerEntityTypes.OtherDescribe || subDocsAccount.customer_entity_type_id === CustomerEntityTypes.OtherEmployeeBenefitPlan)) {
        setFormOfSubscriberSubCopy(subDocsAccount?.customer_entity_other_info ?? "");
      }
      setFieldValue("entityAccount", true)
    } else {
      setFieldValue("entityAccount", false)
    }

    subDocsAccount?.contact_owners.forEach((owner) => {
      emailString.push(owner.email) 
      owner.phone_numbers.forEach(phoneNumber => {
        if (phoneNumber.contact_number_type_id === ContactNumberTypes.Mobile) {
          phoneString.push(phoneNumber.number)
        }
      })
    })
    setOwnerEmails(emailString)
    setOwnerPhoneNumbers(phoneString)
    setOwners(subDocsAccount?.contact_owners || []);
    setFieldValue('owners', subDocsAccount?.contact_owners || []);
  }, [subDocsAccount,setFieldValue, setOwnerEmails, setOwnerPhoneNumbers])
  
  const handlePermanentAddressClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && subDocsClient?.address) {
      setFieldValue("mailingSameAsPermanentAddress", true)
      setFieldValue("mailingAddress.street1", subDocsClient.address.street_address_1 ?? "")
      setFieldValue("mailingAddress.street2", subDocsClient.address.street_address_2 ?? "")
      setFieldValue("mailingAddress.city", subDocsClient.address.city ?? "")
      setFieldValue("mailingAddress.stateId", subDocsClient.address.state_id ?? "")
      setFieldValue("mailingAddress.zip", subDocsClient.address.zip_code ?? "")
      setTimeout(() => {
        setTouched({
          ...touched,
          mailingAddress: {
            street1: true,
            street2: true,
            city: true,
            stateId: true,
            zip: true,
          },
          mailingSameAsPermanentAddress: true,
        });
      })
    } else {
      setFieldValue("mailingSameAsPermanentAddress", false)
      setFieldValue("mailingAddress.street1", "")
      setFieldValue("mailingAddress.street2", "")
      setFieldValue("mailingAddress.city", "")
      setFieldValue("mailingAddress.stateId", "")
      setFieldValue("mailingAddress.zip", "")
      setTimeout(() => {
        setTouched({
          ...touched,
          mailingAddress: {
            street1: false,
            street2: false,
            city: false,
            stateId: false,
            zip: false,
          },
          mailingSameAsPermanentAddress: false,
        });
      })
    }
  }
  
  const checkMissing = (value: string) => {
    if (!value) {
      return <Typography component='span' sx={{ color: 'red' }}>Missing</Typography>;
    }
    return <Typography component='span'>{value}</Typography>;
  };
  const OwnerInfoList = ({ ownerInfoArray }: OwnerInfoListProps) => {
    return (
      <Grid container>
        <Grid container item xs={12} sx={{ marginBottom: 1 }}>
          <Grid item xs={2}><Typography sx={{ fontWeight: 'bold' }}>Name</Typography></Grid>
          <Grid item xs={2}><Typography sx={{ fontWeight: 'bold' }}>Date of Birth</Typography></Grid>
          <Grid item xs={2}><Typography sx={{ fontWeight: 'bold' }}>SSN</Typography></Grid>
          <Grid item xs={3}><Typography sx={{ fontWeight: 'bold' }}>Email</Typography></Grid>
          <Grid item xs={3}><Typography sx={{ fontWeight: 'bold' }}>Mobile Number</Typography></Grid>
        </Grid>
        {ownerInfoArray.map((owner, index) => (
          <Grid container item xs={12} key={index} sx={{ marginBottom: 1 }}>
            <Grid item xs={2}>{checkMissing(owner.name)}</Grid>
            <Grid item xs={2}>{checkMissing(owner.date_of_birth)}</Grid>
            <Grid item xs={2}>{checkMissing(owner.ssn)}</Grid>
            <Grid item xs={3}>{checkMissing(owner.email)}</Grid>
            <Grid item xs={3}>{checkMissing(owner.phone_numbers[0]?.number ?? '')}</Grid>
          </Grid>
        ))}
      </Grid>
    );
  };
  
  const isEntityAccount = subDocsAccount?.account_type_id === AccountType.Entity;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', }}>
      <Box sx={{ display: 'flex', p: 2,}}>
        <Box sx={{display: 'flex',width: '100%', minWidth: 500}}>
          <Grid container sx={{flex: 1}}>
            {isEntityAccount ? (
              <>
                <InfoCardThirdWidth title="Name" value={subDocsAccount?.account_name ?? ""} />
                <InfoCardThirdWidth title="Form of Subscriber" value={formOfSubscriber} />
                <InfoCardThirdWidth title="Entity EIN" value={checkMissing(subDocsAccount?.ein ?? '')} />
              </>
            ) : (
              <>
                <InfoCard title="Name" value={subDocsAccount?.account_name ?? ""} />
                <InfoCard title="Form of Subscriber" value={formOfSubscriber} />
              </>
            )}
          </Grid>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', pr: 2, pl: 2, }}>
        <Typography sx={{fontSize: 16, fontWeight: 'bold' , mb: 2, mt: -3}}>Owner:</Typography>
      </Box>
      <Box sx={{ display: 'flex', pr: 5, pl: 4, pb: 3}}>
        <Box sx={{display: 'flex',width: '100%', minWidth: 500}}>
          <Grid container sx={{flex: 1}}>
            <OwnerInfoList ownerInfoArray={owners} />
          </Grid>
        </Box>
      </Box> 
      <Box sx={{ display: 'flex', flexDirection: 'column', }}></Box>
        <Box sx={{ display: 'flex', p: 2,}}>
          <Box sx={{display: 'flex',width: '60%', minWidth: 500 , mt: 2}}>
            <Grid container sx={{flex: 1}}>
              <InfoListCard title="Street Address" value={checkMissing(address?.street_address_1 ?? "")} />
              <InfoListCard title="Street Address 2" value={address?.street_address_2 ?? ""} />
              <InfoListCard title="City" value={checkMissing(address?.city ?? "")} />
              <InfoListCard title="State" value={checkMissing(address?.state ?? "")} />
              <InfoListCard title="Zip" value={checkMissing(address?.zip_code ?? "")} />
            </Grid>
          </Box>
          <Box sx={{display: 'flex',flexDirection: 'column',width: '40%', pl: 2, minWidth: 500, mt: 2}}>
            <Typography sx={{fontSize: 16, fontWeight: 'bold'}}>Mailing Address</Typography>
            <Box sx={{mb: 2}}>
              <FormGroup>
                <FormControlLabel checked={values.mailingSameAsPermanentAddress} control={<Checkbox onChange={handlePermanentAddressClick} disabled={!subDocsClient?.address} />} label="Same as Permanent Address" />
              </FormGroup>
            </Box>
            <Field name="mailingAddress.street1" disabled={values.mailingSameAsPermanentAddress} required label="Street Address" component={TextField} />
            <Field name="mailingAddress.street2" disabled={values.mailingSameAsPermanentAddress} label="Street Address 2" component={TextField} />
            <Field name="mailingAddress.city" disabled={values.mailingSameAsPermanentAddress} required label="City" component={TextField}/>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Field
                name="mailingAddress.stateId" 
                label="State" 
                disabled={values.mailingSameAsPermanentAddress}
                component={Select} 
                formControl={{required: true}}
                sx={{width: 'calc(50% - 8px)', minWidth: 250, mr: 2}}
                onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
                >
                {addressStates != null && addressStates.map(state => (
                  <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
                ))}
              </Field>
              <Field name="mailingAddress.zip" disabled={values.mailingSameAsPermanentAddress} label="ZIP Code" required component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
            </Box>
          </Box>
        </Box>
        {subDocsAccount?.account_type_id === AccountType.Entity && 
        <>
          <SubDocsRadioGroup name='fundSubscriber' label='Is the Subscriber a "Funds of Funds"' />
          <AuthorizedRepresentative />
          <BeneficialOwner />
        </>
       }
    </Box>
  )
}
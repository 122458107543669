import { Typography, TypographyProps } from "@mui/material";

type LimitedLineTypographyProps = {
  numLines: number
}

export const LimitedLineTypography = ({ children, numLines, ...props }: TypographyProps & LimitedLineTypographyProps) => (
  <Typography {...props} 
    sx={{
      ...props.sx,              
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: numLines,
    }}
  >
    {children}
  </Typography>
);
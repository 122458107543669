import { SxProps, TextField, Typography } from "@mui/material";
import { Theme } from "@mui/system";
import { DesktopDatePicker, DesktopDatePickerProps } from "@mui/x-date-pickers";
import { ErrorMessage, useField, useFormikContext } from "formik";
import { Moment } from "moment";

type Props = {
  name: string
  label: string
  sx?: SxProps<Theme>
  required?: boolean
  size?: 'small' | 'medium',
} & Partial<DesktopDatePickerProps<any, any>>

export const DatePickerField = ({ name, label, required, sx, size,...datePickerProps }: Props) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta, helpers] = useField(name);

  return (
      <DesktopDatePicker
        label={label}
        value={field.value}
        onChange={(startDate: Moment | null) => {
          setFieldTouched(field.name)
          setFieldValue(field.name, startDate)}}
        renderInput={(params) => (
          <TextField
            required={required === true ?? false}
            size={size ?? "medium"}
            {...params}
            onBlur={() => helpers.setTouched(true)}
            helperText={meta.error ? meta.error : ""}
            error={!!meta.touched && !!meta.error}
            sx={sx}
          />
        )}
        {...datePickerProps}
      />
    );
};
import { useTheme } from "@mui/material";
import { FC, useState } from "react";
import { useNavigate } from "react-router";
import { UnlinkedWidget } from "../../../../components/Widget/UnlinkedWidget";
import { useClientDetails } from "../../../../hooks";
import { LinkAccountInstructionsDialog } from "../Dialogs/LinkAccountInstructionsDialog";

export const UnlinkedAccountsWidget: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { unlinkedAccounts } = useClientDetails();
  const [openLinkAccountInstructionsDialog, setOpenLinkAccountInstructionsDialog] = useState(false);

  return (
    <>
      <UnlinkedWidget
        title="Unlinked Accounts"
        subtitle="without linked Instructions"
        count={unlinkedAccounts.length}
        gradient={`linear-gradient(90deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`}
        iconBgColor={theme.palette.error.main}
        iconColor={theme.palette.background.paper}
        hoverBgColor={theme.palette.primary.main}
        hoverIconColor={theme.palette.error.main}
        onClick={() => setOpenLinkAccountInstructionsDialog(true)}
      />
      {openLinkAccountInstructionsDialog &&
        <LinkAccountInstructionsDialog
          open={openLinkAccountInstructionsDialog}
          handleClose={() => setOpenLinkAccountInstructionsDialog(false)}
          handleNewInstructionClick={() => {
            setOpenLinkAccountInstructionsDialog(false);
            navigate("../instructions", { state: { showNewInstructionForm: true } });
          }}
        />
      }
    </>
  );
};

import { AddCircle, Delete } from "@mui/icons-material";
import { Box, Checkbox, FormControlLabel, FormGroup, FormHelperText, IconButton, Tooltip, Typography } from "@mui/material";
import { Field, FieldArray, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { FC } from "react";
import { DatePickerField } from "../../../../components/FormFields/DatePickerField";
import  {SSNInput } from '../../../../utility/MaskComponent';
import { initialBeneficialOwner, InvestorQuestionnaireFormValues } from "./subDocsFormSchema";

export const BeneficialOwner: FC = () => {
  const { values, handleChange } = useFormikContext<InvestorQuestionnaireFormValues>();
  return (
    <Box sx={{ display: 'flex',flexDirection: 'column', py: 2,}}>
      <Typography variant="h6" >Beneficial Ownership Certification</Typography>
      <Typography sx={{my: 2}}>Please complete the below information for (a) every person who is directly or indirectly through any contract, arrangement, understanding, relationship or otherwise, owns 25% or more of the equity interests of the Investor. If the intermediary’s shareholders or partners are not individuals, continue up the chain of ownership listing their 25% or more equity interest holders until individuals are listed; and (b) all directors, general partners, or members, as applicable. If there are no 25% beneficial owners of the Investor, please select “None”.</Typography>
      <FormHelperText sx={{pb: 1}}>Can only add up to 3 beneficial owners</FormHelperText>
      <FieldArray name="beneficialOwners">
        {({ form, remove, push, replace }) => (
          <Box sx={{position: 'relative', display:'flex', flexDirection: 'column', width: '100%'}}>
          {values.beneficialOwners.map((owner, index) => (
            <Box  key={index} sx={{display: 'flex', width: '100%'}}>
              <Field disabled={values.optsOutBeneficialOwners === true || !!owner.contactId} name={`beneficialOwners[${index}].ownerName`} label="Name" component={TextField} sx={{width: '35%', mr: 2}} 
              />
              <DatePickerField disabled={values.optsOutBeneficialOwners === true || !!owner.contactId} name={`beneficialOwners[${index}].dob`} label="Date of Birth*" sx={{width: '20%',mr: 2}}/>
              <Field disabled={values.optsOutBeneficialOwners === true} name={`beneficialOwners[${index}].ownerAddress`} label="Address (Residential or Business)" component={TextField} sx={{width: '35%', mr: 2}} 
              />
              <Field disabled={values.optsOutBeneficialOwners === true || !!owner.contactId} name={`beneficialOwners[${index}].ssn`} label="SSN" component={SSNInput} sx={{width: '20%', mr: 2}}/>
              <Box sx={{width: 60, pt: 1}}>
                {index !== 0 && <Tooltip title="Delete" >
                  <IconButton disabled={values.optsOutBeneficialOwners === true} aria-label='delete' onClick={() => remove(index)} color='error'><Delete /></IconButton>
                </Tooltip>}
              </Box>
            </Box>
          )
          )}
          {values.beneficialOwners.length < 3 &&
          <Box>
            <IconButton
              color='primary'
              onClick={() => push(initialBeneficialOwner)}
              sx={{mb: 1}}
              disabled={values.optsOutBeneficialOwners === true}
            >
              <Tooltip title="Add Beneficial Owner">
                <AddCircle fontSize="large" />
              </Tooltip>
            </IconButton>
          </Box>}
          <Box sx={{mt: 1,pl: "1rem", display: 'flex'}}>
            <FormGroup>
              <FormControlLabel checked={values.optsOutBeneficialOwners} name="optsOutBeneficialOwners" control={<Checkbox onChange={handleChange} />} label="None" />
            </FormGroup>
          </Box>
        </Box>
        )}
      </FieldArray>
    </Box>
  )
}
import { Auth } from "aws-amplify";
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { FC } from 'react';
import { InferType, object, string } from 'yup';
import { LoadingButton } from '../../../components/Buttons';
import { useAuth } from '../../../hooks';
import { AmplifyAuthResp, FormikSubmitFn } from '../../../utility/types';
import { LoginPageFormProps } from "../LoginPage"

const mfaFormSchema = object({
  mfaCode: string().required("Authentication code is required"),
});

type MfaData = InferType<typeof mfaFormSchema>;

const initialValues: MfaData = { mfaCode: "" };

/** 
 * Form for handling Multifactor authentication
 */
export const MFAForm: FC<LoginPageFormProps> = ({ handleLoginStageSubmit, loading }) => {
  const auth = useAuth();

  const handleSubmit: FormikSubmitFn<MfaData> = async ({ mfaCode }, { setSubmitting }) => {
    const authFn = () => Auth.confirmSignIn(auth.cognitoUser, mfaCode) as Promise<AmplifyAuthResp>;
    handleLoginStageSubmit(authFn, setSubmitting);
  }

  return (
    <Formik initialValues={initialValues} validationSchema={mfaFormSchema} onSubmit={(handleSubmit)}>
      <Form>
        <Field name="mfaCode" label="Authentication Code" component={TextField} fullWidth />
        <LoadingButton type="submit" size="large" fullWidth loading={loading}>
          Submit
        </LoadingButton>
      </Form>
    </Formik>
  );
}
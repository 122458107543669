import { Search } from "@mui/icons-material";
import { Box, InputAdornment, Link as MuiLink, Paper, TableContainer, TextField, Typography } from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Investor } from "../../../../api/payloads";
import { ColumnDef, DataSourceTable } from "../../../../components/DataSourceTable";
import { useInvestments } from "../../../../hooks";
import { sumElements } from "../../../../utility/array.util";
import { toCurrency, toPercent } from "../../../../utility/number.util";
import { maskRouteId } from "../../../../utility/route-mask.util";
import { InstructionDetailsModal } from "../../../../components/CustomModal/DistributionInstructionDetailModal";
import { AccountType } from "../../../../utility/constants";
import { deepClone } from "../../../../utility/object.util";

const displayCurrency = (v: number | string | null) => v !== null ? toCurrency(v) : "---";
const displayContributionFn = (v: number, row: Investor) => {
  return (
    <Box>
      {`${toCurrency(v)}`}
      <Box component="span" color="primary.main" >
        {` (${toPercent(v / row['commitment'])})`}
      </Box>
    </Box>)
};

const totalCommitmentFn = (dataSource: Investor[]): [number, number] => {
  const called_amount = sumElements(dataSource, 'total_called_amount');
  const commitment = sumElements(dataSource, 'commitment');
  return [called_amount, commitment];
};
const totalCommitmentDisplayFn = (totals: [number, number]): ReactElement => {
  const [called_amount, commitment] = totals
  return (
  <Box>
    {`${toCurrency(called_amount)} `}
    {commitment !== 0 && <Box component="span" color="primary.main" >
      {` (${toPercent(called_amount / commitment)})`}
    </Box>}
  </Box>)
}


export const InvestmentInvestors: FC = () => {
  const { investors } = useInvestments();
  const [searchFilter, setSearchFilter] = useState("");
  const [filteredRows, setFilteredRows] = useState(investors);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDistributionInstruction, setSelectedDistributionInstruction] = useState<Investor | null>(null);

  const investmentsColumnDef: ColumnDef<Investor> = [
    { id: "customer_name", label: "Client", displayFn: (v, row) => <MuiLink component={Link} to={`/clients/${maskRouteId(row.customer_id)}/overview`}>{v}</MuiLink>, },
    { id: "account_name", label: "Account Name", displayFn: (v, row) => (
      <div style={{ minWidth: '150px', maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {v}
      </div>
    )},
    { id: "details", label: "Distribution Instruction",
      displayFn: (v, row) => {
        if (row.details.distribution_instruction_name) {
          return (
            <MuiLink 
              component="span" 
              style={{ cursor: 'pointer' }} 
              onClick={() => {
                let instruction = row
                if(row.account_type_id === AccountType.IRA){
                  instruction = deepClone(instruction);
                  instruction.details.ffc_account_number = row.account_number ?? '';
                  instruction.details.ffc_to =  '';
                }
                handleOpenModal(instruction)}}
            >
              {row.details.distribution_instruction_name}
            </MuiLink>
          );
        }
        return 'Instruction Not Set';
      },
    },
    { id: "commitment", label: "Commitment", displayFn: displayCurrency, showTotal: true },
    {
      id: "total_called_amount",
      label: "Capital Called",
      displayFn: (v, row) => (
        <div style={{ minWidth: '150px', maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {displayContributionFn(v, row)}
        </div>
      ),
      showTotal: true,
      totalFn: totalCommitmentFn,
      totalDisplayFn: totalCommitmentDisplayFn,
    },
    { id: "total_distributed", label: "Distributions", displayFn: displayCurrency, },
    { id: "fair_value", label: "Fair Value", displayFn: v => (v !== null) ? displayCurrency(v) : "Pending" },
  ];
  
  const handleOpenModal = (instruction: Investor) => {
    setSelectedDistributionInstruction(instruction)
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedDistributionInstruction(null);
  };

  useEffect(() => {
    //TODO: Look into some kind of debouncing strategy with a hook or something to improve performance -- lodash has one
    const newRows = investors.filter(r => r.account_name.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase()));

    setFilteredRows(newRows);
  }, [investors, searchFilter, setFilteredRows])

  return (
    <Box component="section">
      <Box sx={{ justifyContent: "space-between", display: 'flex', mb: 1 }}>
        <Typography variant="h5">Investor List</Typography>
        <TextField
          value={searchFilter}
          onChange={(event) => setSearchFilter(event.target.value)}
          placeholder="Search Investors"
          size="small"
          helperText="" //Empty string to remove the helper space padding, since we've set our theme's default helperText value as " ";
          InputProps={{
            startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
          }} />
      </Box>
      <TableContainer component={Paper}>
        <DataSourceTable columnDef={investmentsColumnDef} dataSource={filteredRows} emptyMessage="No investors" showTotals />
      </TableContainer>
      {selectedDistributionInstruction && (
        <InstructionDetailsModal 
          open={modalOpen} 
          onClose={handleCloseModal} 
          instruction={selectedDistributionInstruction}
          isCustodied={false}
        />
      )}
    </Box>
  )
}
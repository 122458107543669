import { Box, Container } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { Outlet, useMatches } from "react-router-dom";
import { apiClient } from "../../../api/apiClient";
import { ContentBackground } from "../../../components/ContentBackground/ContentBackground";
import { useInit, useInvestments, useMaskedParams } from "../../../hooks";
import { MatchWithHandle } from "../../../routes/router.util";
import { InvestmentDetailHeader } from "./InvestmentDetailHeader";

export const InvestmentDetailPage: FC = () => {
  const { investmentsMap, setInvestors, setDistributions, setCapitalCalls } = useInvestments();
  const { investmentId } = useMaskedParams();
  const investment = useMemo(() => investmentsMap[+investmentId!] ?? {}, [investmentId, investmentsMap]);
  const matches = useMatches() as MatchWithHandle[];
  const [hideTabsRoute] = matches.filter((m: MatchWithHandle) => !!m.handle?.hideTabs);
  const hideTabs = !!hideTabsRoute;

  const init = useCallback(async () => {
    const resp = await apiClient.get('/investors/investment-companies/:p0', {routeParams: [investmentId!]});
    const { investors, capitalCalls, distributions } = resp;
    setCapitalCalls(capitalCalls);
    setInvestors(investors);
    setDistributions(distributions);    
  }, [investmentId, setCapitalCalls, setDistributions, setInvestors]);

  useInit(init);
  
  return (
    <Container sx={{ py: 2 }}>
      <ContentBackground>
        { !hideTabs ? 
        <>
          <InvestmentDetailHeader investment={investment} />
          <Box p={3}>
            <Outlet />
          </Box>
        </>
         : <Outlet/>
        }
      </ContentBackground>
    </Container>
  )
}
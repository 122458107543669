import { RouteObject, useMatches } from "react-router-dom";
import { maskRouteId } from "../utility/route-mask.util";
import { NotificationActionType } from "../utility/constants";

/** Simple stub for testing successful routing and such */
export const Placeholder = (props: { name: string }) => (<p style={{ padding: '1rem' }}>{props.name} placeholder</p>);

export type NavBarRouteObject = RouteObject & { label: string, }

/** Simple type guard for narrowing down route type to those we can use to build a Navbar */
export const isNavBarRoute = (r: RouteObject | NavBarRouteObject): r is NavBarRouteObject => (
  !!(r as NavBarRouteObject).label
);

export type MatchWithHandle = ReturnType<typeof useMatches>[number] & { handle?: { tab: string, hideTabs?: boolean }};

export const getPathByLabel = (routes: (RouteObject | NavBarRouteObject)[], label: string): string | null => {
  const route = routes.find(route => 'label' in route && route.label === label);
  return route?.path || null;
};

export type LinkBuilder = (id: number) => string;
const actionToNotificationLinkMap: { [key in NotificationActionType]?: LinkBuilder } = {
  [NotificationActionType.IndicatedInterestInOpportunity]: (id) => `/opportunities/${maskRouteId(id)}/interest-list`,
  [NotificationActionType.IndicatedInterestInBDCOpportunity]: (id) => `/opportunities/${maskRouteId(id)}/bdc-interest-list`,
};

export const getNotificationLink = (actionType: NotificationActionType, linkId?: number): string | undefined => {
  const linkBuilder = actionToNotificationLinkMap[actionType];
  return linkBuilder && linkId ? linkBuilder(linkId) : undefined;
};
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { Box, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Collapse, Button, Grid, Modal, Tooltip } from "@mui/material";
import React from "react";
import { DistributionInstruction } from "../../../api/payloads/customer.payload";
import lightBlue from '@mui/material/colors/lightBlue';
import gray from '@mui/material/colors/grey';

 interface InstructionsTableProps {
  instructionsDataSource: DistributionInstruction[];
  open: { [key: number]: boolean };
  handleToggle: (id: number) => void;
}

export const InstructionsTable: React.FC<InstructionsTableProps & { openModal: (id: number) => void }> = ({ instructionsDataSource, open, handleToggle, openModal }) => {

  const handleOpenModal = (instruction: DistributionInstruction) => {
    openModal(instruction.id);
  };

  const handleIconButtonClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, id: number) => {
      event.stopPropagation();
      handleToggle(id);
    },
    [handleToggle]
  );

  if (instructionsDataSource.length === 0) {
    return (
      <Box component="section">
        <Typography variant="h5" mb={1}>Instructions on File</Typography>
        <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "5%" }}/>
              <TableCell style={{ width: "80%" }}>Distribution Instruction Name</TableCell>
              <TableCell style={{ width: "20%" }}>Instruction Type</TableCell>
            </TableRow>
          </TableHead>
          <TableCell style={{ textAlign: "center" }} colSpan={4}>No Distribution Instructions on file</TableCell>
        </Table>
        </TableContainer>
      </Box>
    );
  }

  if (instructionsDataSource.length === 0) {
    return (
      <Box component="section">
        <Typography variant="h5" mb={1} mt={4}>Instructions on File</Typography>
        <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "5%" }}/>
              <TableCell style={{ width: "80%" }}>Distribution Instruction Name</TableCell>
              <TableCell style={{ width: "20%" }}>Instruction Type</TableCell>
            </TableRow>
          </TableHead>
          <TableCell style={{ textAlign: "center" }} colSpan={4}>No Distribution Instructions on file</TableCell>
        </Table>
        </TableContainer>
      </Box>
    );
  }

  return (
    <Box component="section">
      <Typography variant="h5" mb={1}>Instructions on File</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "5%" }}/>
              <TableCell style={{ width: "80%" }}>Distribution Instruction Name</TableCell>
              <TableCell style={{ width: "20%" }}>Instruction Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {instructionsDataSource.map((instruction) => (
              <React.Fragment key={instruction.id}>
                <TableRow>
                  <TableCell sx={{backgroundColor: open[instruction.id] ? gray[200] : 'background.paper'}}>
                    <IconButton size="small" onClick={(event) =>handleIconButtonClick(event, instruction.id)}>
                    {open[instruction.id] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </TableCell>
                  <TableCell sx={{ width: "80%", backgroundColor: open[instruction.id] ? gray[200] : 'background.paper' }}>
										<Typography sx={{ color: lightBlue[700], cursor: 'pointer', display: 'inline', '&:hover': { textDecoration: 'underline'}}} component="span" onClick={() => handleOpenModal(instruction)}>{instruction.name}</Typography>
									</TableCell>
                  <TableCell sx={{ width: "20%", backgroundColor: open[instruction.id] ? gray[200] : 'background.paper' }}>{instruction.instruction_type}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={3}>
                    <Collapse in={open[instruction.id]} timeout="auto" unmountOnExit>
                      <Box>
                        <Table size="small" style={{ width: "100%" }}>
                          <TableHead sx={{backgroundColor: 'background.paper'}}>
                          <TableRow>
                            <TableCell style={{textAlign: "center", width: "50%"}}>
                              <Tooltip title="Accounts linked to Distribution Instruction" placement="top">
                                <span style={{ marginLeft: '100px' }}>Accounts</span>
                              </Tooltip>
                            </TableCell>
                            <TableCell style={{textAlign: "center", width: "50%" }}>
                              <Tooltip title="Investments linked to Distribution Instruction" placement="top">
                                <span style={{ marginRight: '100px' }}>Investments</span>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                          </TableHead>
                          <TableBody sx={{backgroundColor: 'background.paper'}}>
                            {instruction.accounts.length === 0 ? (
                              <TableRow>
                                <TableCell style={{ textAlign: "center" }} colSpan={2}>No Accounts or Investments</TableCell>
                              </TableRow>
															) : (
                              instruction.accounts.map((account) => (
                                <TableRow key={account.id}>
                                  <TableCell style={{ textAlign: "center", width: "50%" }}>
                                    <span style={{ marginLeft: '100px' }}>{account.account_name}</span>
                                  </TableCell>
                                  <TableCell style={{textAlign: "center",width: "50%",}}>
                                  <div style={{ marginRight: '100px' }}>
                                    {account.investments &&
                                    account.investments.length > 0
                                      ? account.investments.map(
                                          (investment, index) => (
                                            <div key={index}>
                                              {investment.investment_name}
                                            </div>
                                          )
                                        )
                                      : "--"}
                                  </div>
                                  </TableCell>
                                </TableRow>
                              ))
                            )}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Container, Link as MuiLink, Toolbar, Typography } from "@mui/material";
import moment from 'moment';
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiClient } from '../../../api/apiClient';
import { ContentBackground } from "../../../components/ContentBackground/ContentBackground";
import { useClients, useInit, useMaskedParams, useOpportunities, useProfessionals } from '../../../hooks';
import { useSubDocs } from '../../../hooks/ContextHooks/useSubDocs';
import { AccountType, DocumentTypes } from '../../../utility/constants';
import { isNullish } from '../../../utility/misc.util';
import { deepClone } from '../../../utility/object.util';
import { maskRouteId } from '../../../utility/route-mask.util';
import { SubDocsFormValues, initialAuthorizedRepresentativeValue, parseSavedData, subDocsInitialValues } from './Forms/subDocsFormSchema';
import { SubDocsStepper } from './SubdocsStepper';
import { bdcOpportunityDetailNavLinks } from '../../../routes/child-routes/opportunity-detail.routes';
import { getPathByLabel } from '../../../routes/router.util';

export const SubDocsPage: FC = (props) => {
  const navigate = useNavigate();
  const {subDocId, investmentId} = useMaskedParams();
  const {opportunitiesMap} = useOpportunities();
  const {setSubDocsAccount, setIsInitialSubscription, setSubDocsClient, subDocsClient, setAccreditedInvestorQuestions,setDistributionInstructionsData, setSubDocsAccountId, setCustodians, custodians, setSubDocsClientId, setSubDocsTransactionId, subDocsAccount, setTransactionAccreditationCompleted, setUserIsApprovedSigner, setSubscriptionAmount} = useSubDocs();
  const {advisory} = useProfessionals();
  const {clientsMap, addressStates, setAddressStates, countries, setCountries, phoneTypes, setPhoneTypes} = useClients()
  const opportunity = investmentId ? opportunitiesMap[Number(investmentId)] : null
  const [initialValues, setInitialValues] = useState<SubDocsFormValues>()
  const [isInitialized, setIsInitialized] = useState(false);
  const [initialStep, setInitialStep] = useState(0);
  const firstTabPath = getPathByLabel(bdcOpportunityDetailNavLinks, "Interest List") || bdcOpportunityDetailNavLinks[0].path;

  useInit(async () => {
    if (!subDocId || advisory == null ) {
      return;
    }
    
    const {savedData, attachments, ...rest} = await apiClient.get("/investors/sub-doc/:p0/in-progress", {routeParams: [+subDocId]})

    if (!rest?.accountId || !rest?.clientId || !savedData?.transaction_id) {
      return;
    }
    setUserIsApprovedSigner(rest.isUserApprovedSigner)
    setTransactionAccreditationCompleted(!isNullish(rest.transactionAccreditationId))
    setIsInitialSubscription(rest.isInitialSubscription)
    setSubDocsAccountId(rest.accountId)
    setSubDocsClientId(rest.clientId)
    setSubDocsTransactionId(savedData.transaction_id)
    setSubscriptionAmount(rest.subscriptionAmount)
    
    const resp = await apiClient.get("/customers/:p0", {routeParams: [+rest.clientId]});
    const client = clientsMap[+rest.clientId]; //TODO: I suspect clientId doesn't need to be cast to number, as it would be a number already. Investigate
    const account = resp.accounts.find(account => account.id === +rest.accountId)
    setSubDocsAccount(account ?? null);

    const subDocsClientData = {...resp, id: client.id, name: client.name};
    setSubDocsClient(subDocsClientData);
    
    const accreditedInvestorQuestions = await apiClient.get('/documents/subscription-documents/accredited-investor-questions');
    setAccreditedInvestorQuestions(accreditedInvestorQuestions);
    
    if (addressStates === null) {
      const states = await apiClient.get("/customers/states");
      setAddressStates(states);
    }

    if (custodians.length === 0) {
      const custodians = await apiClient.get("/customers/custodians");
      setCustodians(custodians);
    }
    
    
    if (!countries.length || !phoneTypes.length) {
      const resp = await apiClient.get("/customers/phone-options");
      setCountries(resp.countries);
      setPhoneTypes(resp.phoneNumberTypes);
    }
    
    setInitialStep(savedData?.current_step ?? 0);
    const values = savedData ? parseSavedData(savedData) : deepClone(subDocsInitialValues);
    values.firm = advisory.id.toString();
    
    const distributionInstructionsResp = await apiClient.get('/customers/accounts/:p0/distribution-instructions', { routeParams: [rest.accountId]});
    setDistributionInstructionsData(distributionInstructionsResp);
    
    if (account?.account_type_id === AccountType.Entity && account?.customer_entity_id) {
      values.entityAccount = true;
      values.ein = account?.ein ?? "";
      attachments.forEach((document) => {
        if (document.document_type_id === DocumentTypes.FormationDocuments) {
          values.formationDocuments = {
            documentId: document.id,
            documentName: document.file_name
          }
        } else if (document.document_type_id === DocumentTypes.GoverningDocuments) {
          values.governingDocuments = {
            documentId: document.id,
            documentName: document.file_name
          }
        } else if (document.document_type_id === DocumentTypes.TrustDocuments) {
          values.trustDocuments = {
            documentId: document.id,
            documentName: document.file_name
          }
        } else if (document.document_type_id === DocumentTypes.Other) {
          values.supportingDocuments = [...values.supportingDocuments, {documentId: document.id, documentName: document.file_name, supportingDocumentFile: null}]
        }
      })
    }
    
    let owner;
    owner = account && account.contact_owners.length >= 1 ? account.contact_owners[0]?.name : null
    
    if (account?.account_type_id === AccountType.Entity) {
      owner = account?.account_name ?? null
    }
    
    values.residenceAccountOwner = owner ?? ""

    if (!savedData) { // Potentially dead code 
      values.advisoryAddress = {
        street1: advisory.street_1 ?? "",
        street2: advisory.street_2 ?? "",
        city: advisory.city_name ?? "",
        stateId: advisory.state_id ?? "",
        zip: advisory.zip_code ?? ""
      } 
    }
    
    values.ownerIdDocuments = account?.contact_owners?.map(owner => ({
      contactId: owner.contact_id,
      name: owner.name, 
      expirationDate: (owner.photo_id_expiration_date != null ? moment(owner.photo_id_expiration_date) : null), 
      idImageFile: owner.photo_id_file_name ? { name: owner.photo_id_file_name } : null,
      imageId: owner.photo_id_document_id  ?? null
    })) ?? []

    const hasSelectedAuthorizedSigner = values.authorizedRepresentatives?.length > 0;
    const isEntityAccount = account?.account_type_id === AccountType.Entity;
    
    // Make sure to include all authorized representatives's photo IDs for AML Verification for any reps that are not also account owners
    if (isEntityAccount && !hasSelectedAuthorizedSigner) {
      values.authorizedRepresentatives = [initialAuthorizedRepresentativeValue]
    } else if (isEntityAccount && hasSelectedAuthorizedSigner) {
      for (const rep of values.authorizedRepresentatives) {
        const repContactRecord = subDocsClientData.contacts.find(contact => contact.id === rep.representativeContactId);
        
        if (!repContactRecord) { 
          continue;
        }
        
        const isRepOwner = account?.contact_owners.some(owner => owner.contact_id === rep.representativeContactId);  
        
        if (!isRepOwner) {
          values.ownerIdDocuments.push({
            contactId: repContactRecord.id,
            name: `${repContactRecord.first_name} ${repContactRecord.last_name}`,
            expirationDate: repContactRecord.doc_expiry_date ? moment(repContactRecord.doc_expiry_date) : null,
            idImageFile: repContactRecord.photo_id_file_name ? { name: repContactRecord.photo_id_file_name } : null,
            imageId: repContactRecord.document_id,
          })
        }
      }
    }
    
    setInitialValues(values);
    setIsInitialized(true);
  }, null, false)

  if (!isInitialized) {
    return null;
  } 
  return (
    <Container sx={{ py: 2}} maxWidth="xl">
      <ContentBackground>
        <Toolbar sx={{ justifyContent: "space-between", py: 2 }}>
          <Box >
            <Box sx={{display: 'flex', alignItems: 'center', }}>
              <ChevronLeftIcon onClick={() => navigate(`/opportunities/${maskRouteId(investmentId)}/${firstTabPath}`)} sx={{color: 'grey',fontSize: 30, cursor: 'pointer'}}/>
              <Typography variant="h5">{`Subscription Document For ${opportunity!.name}`}</Typography>
            </Box>
            <Box sx={{display: 'flex', mt: 2}}>
              <Typography>Investor Name</Typography>
              <MuiLink component={Link} to={`/clients/${maskRouteId(subDocsClient!.id)}/overview`} sx={{ml: 4}}>{subDocsClient!.name}</MuiLink>
            </Box>
            <Box sx={{display: 'flex', mt: 2}}>
              <Typography>Account Name</Typography>
              <Typography  sx={{ml: 4}}>{subDocsAccount?.account_name}</Typography>
            </Box>
          </Box>
        </Toolbar>
        <Box sx={{py: 2, px: 3, minWidth: 1200}}>
          { !!initialValues && 
            <SubDocsStepper {...{initialStep, initialValues}}/>
          }
        </Box>
      </ContentBackground>
    </Container>
  )
}
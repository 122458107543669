import { ArrowForwardIos, Schedule } from "@mui/icons-material";
import { Box, Button, Container, Grid, Typography, styled } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ContentBackground } from "../../components/ContentBackground/ContentBackground";
import { Opportunity } from "../../context/OpportunitiesContext";
import { formatDate } from "../../utility/date.util";
import { toCurrency } from "../../utility/number.util";
import { maskRouteId } from "../../utility/route-mask.util";
import { bdcOpportunityDetailNavLinks, regDOpportunityDetailNavLinks } from "../../routes/child-routes/opportunity-detail.routes";
import { InvestingEntityType } from "../../utility/constants";
import { getPathByLabel } from "../../routes/router.util";

type OpportunityProps = {
  opportunity: Opportunity,
}

const Img = styled('img')(({theme})=> ({
  margin: 'auto',
  display: 'block',
  width: 150,
  height: 150,
  borderRadius: 10,
  objectFit: 'contain',
  backgroundColor: theme.palette.background.secondary
}))

export const OpportunityCard: FC<OpportunityProps> = ({opportunity}) => {
  const isRegD = ![InvestingEntityType.BDCEquity, InvestingEntityType.BDCDebt].includes(opportunity.investing_entity_type_id);
  const navigate = useNavigate();
  const handleClick = () => {
    const path = isRegD ? 
    getPathByLabel(regDOpportunityDetailNavLinks, "Interest List") || regDOpportunityDetailNavLinks[0].path 
    : 
    getPathByLabel(bdcOpportunityDetailNavLinks, "Interest List") || bdcOpportunityDetailNavLinks[0].path;
    navigate(`./${maskRouteId(opportunity.id)}/${path}`);
  }
  return (
    <Container sx={{ py: 2 }} id={`investment-company-${opportunity.id}`}>
      <ContentBackground sx={{backgroundColor: 'background.paper'}}>
        <Box sx={{alignItems: "end",justifyContent: 'space-between', display: 'flex', p: 2}}>
          <Grid container spacing={3}>
            <Grid item>
              <Img alt="article" src={opportunity.logo_url} sx={{backgroundColor: 'inherit'}}/>
            </Grid>
            <Grid item xs={8}>
              <Box sx={{alignItems: 'start', display: 'box',flexGrow: 1, justifyContent: 'flex-start'}}>
                <Typography variant="h5" >{opportunity.name}</Typography>
                <Box sx={{alignItems: 'flex-start', display: 'flex'}}>
                  {isRegD ? 'Minimum Commitment:' : 'Initial Investment:'}
                  <Typography color="primary.main" sx={{fontWeight: 'bold'}}>{`${toCurrency(opportunity.minimum_commitment)} minimum`}</Typography>
                </Box>
                {!isRegD && (
                <Box sx={{alignItems: 'flex-start', display: 'flex'}}>
                  <Typography sx={{marginRight: 1}}>Subsequent Investment: </Typography>
                  <Typography color="primary.main" sx={{fontWeight: 'bold'}}>{`${toCurrency(opportunity.subsequent_investment)} minimum`}</Typography>
                </Box>
                )}
                <Box sx={{ alignItems: 'center', display: 'flex' }}>
                  <Schedule fontSize="small" sx={{ color: "primary.main", marginRight: ".25rem" }} />
                  <Typography>
                    {isRegD 
                      ? `Expected Close Date: ${opportunity.expected_close_date ? (formatDate(opportunity.expected_close_date)): 'N/A'}`
                      : opportunity.subscription_deadline 
                        ? `Subscription Deadline: ${formatDate(opportunity.subscription_deadline)}`
                        : null}
                  </Typography>
                </Box>
                <Box sx={{alignItems: 'center', display: 'flex'}}>
                  <Typography sx={{marginRight: 1}}>Investor Type: </Typography>
                  <Typography>{opportunity.investor_type}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sx={{flexGrow: 1, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
              <Button color='info' onClick={handleClick} endIcon={<Box><ArrowForwardIos sx={{fontSize: 14}} /></Box>}>Learn More</Button>
            </Grid>
          </Grid>
        </Box>
      </ContentBackground>
    </Container>
  )
}
import { Box, Button, ButtonProps, CircularProgress } from '@mui/material';
import { FC, forwardRef, PropsWithChildren } from 'react';

type LoadingButtonProps = ButtonProps & { loading: boolean };

/** 
 * A quick and dirty estimate of Spinner sizes that attempt to fit the button's expected height and 
 * minimize noticeable button height change between states.
 * Derived from the Mui button's 'size' prop. Might not take into account custom text sizes, icons, adornments, etc  
 */
const sizeMap: Record<Exclude<ButtonProps['size'], undefined>, number> = {
  large: 26,
  medium: 24,
  small: 22,
}

/**
 * Extended MUI button with a loading state. 
 * @example <LoadingButton variant="outlined" loading={isLoading}>Submit</LoadingButton>
 * @note I threw this together quickly so might not cover some common use cases. If enhancing 
 * is too much trouble, may want to consider the official LoadingButton component from the MUI Lab: 
 * https://mui.com/material-ui/api/loading-button/
 */
export const LoadingButton: FC<PropsWithChildren<LoadingButtonProps>> = forwardRef((props, ref) => {
  const { loading, disabled, children, ...rest } = props
  return (
    <Button ref={ref} {...rest} disabled={loading || disabled}>
      {loading ?
        <Box display='flex' justifyContent='center' alignItems='center'>
          <CircularProgress size={sizeMap[rest.size ?? 'medium']} />
        </Box>
        : children
      }
    </Button>
  );
})
import { createTheme } from '@mui/material/styles';
import { baseThemeOptions } from './_base.theme';

export const darkTheme = createTheme({
  typography: baseThemeOptions.typography,
  components: baseThemeOptions.components,
  palette: {
    ...baseThemeOptions.palette,
    mode: 'dark',
    background: { paper: '#1a1a1a', secondary: '#171717'}
  },
});
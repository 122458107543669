import { Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Link, Tooltip, Typography } from "@mui/material";
import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { apiClient } from "../../../api/apiClient";
import { Contact, ResetPassword } from "../../../api/payloads/customer.payload";
import { ColumnDef, DataSourceTable } from "../../../components/DataSourceTable";
import { ErrorContactEmail } from "../../../components/ErrorContactEmail/ErrorContactEmail";
import { useClientDetails, useMaskedParams, useUI } from "../../../hooks";
import { EditMemberDialog } from "./Dialogs/EditMemberDialog/EditMemberDialog";
import EditVisionUser from "./Dialogs/EditVisionUser";
import RegisterVisionUser from "./Dialogs/RegisterVisionUser";

type Member = Contact & {
  nickname: string;
  name: string;
  phone_number_types: string[];
  edit: ReactNode;
};

export const MembersTable: FC<{
  dataSource: Contact[];
  emptyMessage?: string;
}> = ({ dataSource, emptyMessage }) => {
  const [selectedMember, setSelectedMember] = useState<Contact | null>(null);
  const { setLoading, setError, setSuccess } = useUI();
  const { setActiveClient } = useClientDetails();
  const { clientId } = useMaskedParams();
  const [showEditMemberDialog, setShowEditMemberDialog] = useState(false);
  const [showVisionRegisterDialog, setShowVisionRegisterDialog] = useState(false);
  const [showEditVisionUserDialog, setShowEditVisionUserDialog] = useState(false);

  const refreshClientData = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await apiClient.get("/customers/:p0", {
        routeParams: [clientId!],
      });
      setActiveClient(resp);
    } catch (error) {
      setError(
        <div>
          <Typography variant="h6">Something went wrong:</Typography>
          <Typography variant="body1">
            We were unable to load your data after updating the household
            member. Please contact <ErrorContactEmail /> for assistance.
          </Typography>
        </div>
      );
    } finally {
      setLoading(false);
    }
  }, [clientId, setActiveClient, setError, setLoading]);

  const handleClose = useCallback(
    (result?: boolean) => {
      if (result) {
        refreshClientData();
        setSuccess("Member updated successfully");
      }

      setSelectedMember(null);
      setShowEditMemberDialog(false);
    },
    [refreshClientData, setSuccess]
  );

  const handleCloseVisionRegisterDialog = useCallback(() => {
    setShowVisionRegisterDialog(false);
    setShowEditVisionUserDialog(false);

    refreshClientData();
  }, [refreshClientData]);

  const handleResetPassword = useCallback(async(row: any)=>{
    try {
      setLoading(true);
      const payload:ResetPassword = {
        first_name: row?.first_name,
        email: row?.email,
        exchange_user: row?.exchange_user
      }
      const response = await apiClient.post('/customers/investor-vision/reset-password', {data : payload});
      if(response){
        setSuccess("Reset Password has been sent")
      }
    } catch (error) {
      setError(
        <div>
          <Typography variant="h6">Something went wrong:</Typography>
          <Typography variant="body1">
            We were unable to process your request to reset password. Please contact <ErrorContactEmail /> for assistance.
          </Typography>
        </div>
      );
    } finally {
      setLoading(false);
    }
  },[setLoading,setError,setSuccess])

  const memberColumnDef = useMemo(() => {
    const def: ColumnDef<Member> = [
      { id: 'nickname', label: 'Preferred Name'},
      { id: "name", label: "Name" },
      {
        id: "email",
        label: "Email",
        displayFn: (v) => <Link href={`mailto:${v}`}>{v}</Link>,
      },
      {
        id: "phone_numbers",
        label: "Phone",
        displayFn: (numbers: Contact["phone_numbers"]) =>
          numbers.map((n) => <div key={n.id}>{n.number}</div>),
      },
      {
        id: "phone_number_types",
        label: "Contact Number Type",
        displayFn: (phoneTypes: string[]) =>
          phoneTypes.map((pt, i) => (
            <Box key={i} component="div">
              {pt}
            </Box>
          )),
      },
      {
        id: "exchange_user",
        label: "Vision",
        displayFn: (record: Member["exchange_user"], row) => {
          const isRegistered = !!record;

          return (
            <Button
              variant={isRegistered ? "outlined" : "contained"}
              color={isRegistered ? "error" : "info"}
              onClick={() => {
                setSelectedMember(row);
                isRegistered
                  ? handleResetPassword(row)
                  : setShowVisionRegisterDialog(true);
              }}
            >
              {isRegistered ? "Reset Password" : "Register"}
            </Button>
          );
        },
      },
    ];

    def.push(
      { id: "edit", label: "Edit Member" }
    );

    return def;
  }, [handleResetPassword]);

  const membersDataSource: Member[] = useMemo(() => {
    const ds: Member[] = dataSource.map((c) => {
      return {
        ...c,
        nickname: c.nickname ? c.nickname : "",
        name: `${c.first_name} ${c.middle_name ? c.middle_name : ""} ${c.last_name}`,
        phone_number_types: c.phone_numbers.map((pn) => pn.contact_number_type),
        edit: (
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              onClick={() => {
                setSelectedMember(c);
                setShowEditMemberDialog(true);
              }}
              color="primary"
            >
              <Edit />
            </IconButton>
          </Tooltip>
        ),
      };
    });

    return ds;
  }, [dataSource]);

  return (
    <>
      <EditMemberDialog
        handleClose={handleClose}
        open={showEditMemberDialog && !!selectedMember}
        contact={selectedMember}
      />
      <DataSourceTable
        dataSource={membersDataSource}
        columnDef={memberColumnDef}
        emptyMessage={emptyMessage}
      />
      {showVisionRegisterDialog && !!selectedMember && (
        <RegisterVisionUser
          setClose={handleCloseVisionRegisterDialog}
          member={selectedMember}
        />
      )}
      {showEditVisionUserDialog && !!selectedMember && (
        <EditVisionUser
          setClose={handleCloseVisionRegisterDialog}
          member={selectedMember}
        />
      )}
    </>
  );
};

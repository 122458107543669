import { PeopleAlt } from "@mui/icons-material";
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import PasswordIcon from '@mui/icons-material/Password';
import GroupsIcon from '@mui/icons-material/Groups';
import { Box, Container, Grid, Typography } from "@mui/material";
import { ReactNode, useCallback, useState } from "react";
import { SettingsCard } from "./SettingsCard";
import NonAdminAddProfDialog from "./Dialogs/NonAdminAddProfDialog";

type TSettingCard = {
  description: string,
  icon: ReactNode,
  path: string,
  title: string
}

const settingsList: TSettingCard[] = [
  {
    description: "Update your Password",
    icon: <PasswordIcon sx={{color: "primary.main"}} fontSize="large"/>,
    path: "/settings/password",
    title: "Update Password",
  },
  {
    description: "Review members of your firm",
    icon: <PeopleAlt sx={{color: "primary.main"}} fontSize="large"/>,
    path: "/settings/firm",
    title: "Firm",
  },
  {
    description: "Review terms and disclosures",
    icon: <ContentPasteSearchIcon sx={{color: "primary.main"}} fontSize="large"/>,
    path: "/settings/terms-and-conditions",
    title: "Terms and Disclosures",
  }
]

  settingsList.push({
    description: "Access Management",
    icon: <GroupsIcon sx={{ color: "primary.main" }} fontSize="large" />,
    path: "/settings/access-management",
    title: "Access Management"
  });
  
const modalPaths = new Set([
  '/settings/access-management'
]);

export const SettingsPage = () => {
  const [modalState, setModalState] = useState({showAddProfessionalModal: false});

  const handleCardClick = useCallback((path: string) => {
    if (modalPaths.has(path)) {
      setModalState(prevState => ({
        ...prevState,
        showAddProfessionalModal: true
      }));
    } else {
      window.location.href = path;
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      showAddProfessionalModal: false
    }));
  }, []);

  return (
      <Container sx={{py: 2}}>
        <Box sx={{p: 4, pt: 2}}>
          <Typography variant="h4"><b>Settings</b></Typography>
        </Box>
        <Grid container spacing={3}>
          {settingsList.map((card, idx) => (
            <Grid key={idx} item xs={4}>
              <SettingsCard 
                description={card.description}
                icon={card.icon}
                path={card.path}
                title={card.title}
                onClick={modalPaths.has(card.path) ? () => handleCardClick(card.path) : undefined}
              />
            </Grid>
          ))}
        </Grid>
        <NonAdminAddProfDialog open={modalState.showAddProfessionalModal} onClose={handleCloseModal} />
      </Container>
  )
}
import { LocationOn } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import {
  Breadcrumbs,
  IconButton,
  Link as MuiLink,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { Link, useMatches } from "react-router-dom";
import { LinkTab } from "../../../components/LinkTab/LinkTab";
import EditAddressDialog from "./Dialogs/EditAddressDialog";
import { AddressLine } from "../../../components/Typography/AddressLine";
import useBoolean from "../../../hooks/useBoolean";
import { clientDetailNavLinks } from "../../../routes/child-routes/client-detail.routes";
import { MatchWithHandle } from "../../../routes/router.util";

type ClientDetailHeaderProps = { client: { name: string; address: string } };

export const ClientDetailHeader: FC<ClientDetailHeaderProps> = ({ client }) => {
  const matches = useMatches() as MatchWithHandle[];
  const [routeTabValue] = matches.filter((m: MatchWithHandle) => m.handle?.tab);
  const activeTab = routeTabValue?.handle?.tab ?? "overview";

  const { value: open, setFalse: setClose, setTrue: setOpen } = useBoolean();

  return (
    <Toolbar sx={{ flexDirection: "column", alignItems: "flex-start", pt: 2 }}>
      <Breadcrumbs sx={{ pb: 1 }}>
        <MuiLink component={Link} to="/clients">
          Clients
        </MuiLink>
        <Typography>{client.name}</Typography>
      </Breadcrumbs>
      <Typography variant="h4">{client.name}</Typography>
      {!!client.address && (
        <AddressLine display="flex" alignItems="center" mb={1}>
          <LocationOn fontSize="small" sx={{ mr: 1 }} />
          <Typography>{client.address}</Typography>
          <IconButton
              color="primary"
              aria-label="settings"
              component="label"
              onClick={setOpen}
              sx={{ borderRadius: 10, marginLeft: 2 }}
            >
              <EditIcon fontSize="small" />
          </IconButton>
        </AddressLine>
      )}
      <EditAddressDialog open={open} setClose={setClose} />
      <Tabs value={activeTab} aria-label="nav tabs" variant="scrollable" sx={{width: '100%'}}>
        {clientDetailNavLinks.map((r) => (
          <LinkTab label={r.label} to={r.path!} value={r.path!} key={r.label} />
        ))}
      </Tabs>
    </Toolbar>
  );
};

import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { Field, useFormikContext } from "formik";
import { Select } from "formik-mui";
import { useEffect, useMemo } from "react";
import { useNewClient } from "../../../../hooks/ContextHooks/useNewClient";
import { AccountType } from "../../../../utility/constants";
import EntityAccountFields from "./EntityAccountFields";
import IRAAccountFields from "./IRAAccountFields";
import IndividualAccountFields from "./IndividualAccountFields";
import JointAccountFields from "./JointAccountFields";
import { AccountFormValues } from "./newClientFormSchema";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "100px",
    },
  },
};

export default function AccountForm() {
  const {values, setFieldValue, validateForm} = useFormikContext<AccountFormValues>();
  const {accountTypeValues, iraTypes, custodians, newMembers, newEntity} = useNewClient();
  const {accountType, custodian, isCustodied} = values
  const isIRA = values.accountType === AccountType.IRA;
  const showCustodiedQuestion = values.accountType && values.accountType !== AccountType.IRA;

  useEffect(() => {
    setFieldValue("custodian", !isCustodied && accountType !== AccountType.IRA ? "" : custodian);
  },[accountType, custodian, isCustodied, setFieldValue, validateForm])

  const appropriateFields = useMemo(() => {
    switch(accountType as AccountType) {
      case AccountType.IRA:
        return <IRAAccountFields iraTypes={iraTypes} custodians={custodians} members={newMembers} />;
      case AccountType.Individual:
        return <IndividualAccountFields members={newMembers} custodians={custodians}/>;
      case AccountType.Joint:
        return <JointAccountFields members={newMembers} custodians={custodians} />;
      case AccountType.Entity:
        return <EntityAccountFields entities={null} defaultEntity={newEntity} custodians={custodians}/>;
      default:
        return null
    }
  },[accountType, custodians, newEntity, newMembers, iraTypes])

  return (
      <>
        <Box sx={{pb:2}}>
          <Box sx={{pb:2}}>
            <Typography variant="h6">Add New Account</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                formControl={{ required: true, fullWidth: true }}
                component={Select}
                label="Account Type"
                MenuProps={MenuProps}
                name="accountType"
                onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
              >
                {accountTypeValues.map(a => <MenuItem disabled={a.id === AccountType.Entity && newEntity == null} key={a.id} value={a.id}>{a.name}</MenuItem>)}
              </Field>
            </Grid>

            {isIRA && (
              <Grid item xs={6}>
                <Field
                  formControl={{ required: true, fullWidth: true }}
                  component={Select}
                  label="IRA Type"
                  MenuProps={MenuProps}
                  name="iraTypeId"
                  onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
                >
                  {iraTypes.map(a => <MenuItem key={a.id} value={a.id}>{a.name}</MenuItem>)}
                </Field>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box sx={{py:1}}></Box>
        {showCustodiedQuestion && (
          <Box sx={{ mb: 2 }}>
          <Typography>
            Would you like eligible investments with this account to be reported on the investor's Brokerage Statement*?
            <br />
            <Typography component="span" variant="body2" style={{ fontStyle: 'italic', fontSize: '0.875rem' }}>
              (*The Custodian may charge a fee for alternative investments. Please contact the Custodian for more information.)
            </Typography>
          </Typography>
          <Field component={RadioGroup} name="isCustodied" row>
            <FormControlLabel control={<Radio />} label="Yes" value="true" checked={values.isCustodied === true} onChange={() => setFieldValue("isCustodied", true)}/>
            <FormControlLabel control={<Radio />} label="No" value="false" checked={values.isCustodied === false} onChange={() => setFieldValue("isCustodied", false)}/>
          </Field>
        </Box>
          )}
        {values.accountType && (
          <Box sx={{pb:2}}>
            <Typography variant="h6" sx={{pb:2}}>Details</Typography>
            <Box>
              {appropriateFields}
            </Box>
          </Box>
        )}
      </>
  );
}
import React, { createContext, FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { InvestmentEvent, InvestmentEventType } from "../api/payloads/events.payload";
import { createObjectMap } from "../utility/array.util";

export type EventsContextValue = {
  events: InvestmentEvent[],
  setEvents: React.Dispatch<React.SetStateAction<InvestmentEvent[]>>,
  eventsMap: Record<InvestmentEvent['id'], InvestmentEvent>
  eventTypes: InvestmentEventType[],
  setEventTypes: React.Dispatch<React.SetStateAction<InvestmentEventType[]>>,
}

export const EventsContext = createContext(undefined as unknown as EventsContextValue);

export const EventsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [events, setEvents] = useState<EventsContextValue['events']>([]);
  const [eventsMap, setEventsMap] = useState<EventsContextValue['eventsMap']>({});
  const [eventTypes, setEventTypes] = useState<EventsContextValue['eventTypes']>([])

  const value = useMemo(() => ({ 
    events,
    setEvents,
    eventsMap,
    eventTypes,
    setEventTypes
  }), [events, setEvents, eventsMap, eventTypes, setEventTypes]);

  useEffect(() => {
    const map = createObjectMap(events, 'id');
    setEventsMap(map);
  }, [events])

  return (
    <EventsContext.Provider value={value}>
      {children}
    </EventsContext.Provider>
  )
}
import { createContext, FC, PropsWithChildren, useMemo, useState } from "react";
import { useLocalStorage } from "../hooks";
import { AmplifyAuthResp } from '../utility/types';

export type User = {
  /** aka professional_id */
  id: number
  cognitoId: string,
  token: string,
};

export type AuthContextValue = {
  user: User | null, 
  setUser: (data: User) => void,
  clearUser: () => void,
  cognitoUser?: AmplifyAuthResp,
  setCognitoUser: React.Dispatch<React.SetStateAction<AmplifyAuthResp | undefined>>,
}

// Default value won't be used since we'll always be getting the context from a provider:
// Casting to the expected value since we need not worry about a null check 
export const AuthContext = createContext<AuthContextValue>(undefined as unknown as AuthContextValue);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser, clearUser] = useLocalStorage<User>("user");
  const [cognitoUser, setCognitoUser] = useState<AmplifyAuthResp>();

  const value = useMemo<AuthContextValue>(() => ({
    user, 
    setUser,
    clearUser,
    cognitoUser,
    setCognitoUser,
  }),
    [user, cognitoUser, clearUser, setUser]
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}
import { Box, Container } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useMatches, useNavigate } from "react-router-dom";
import { ContentBackground } from "../../../components/ContentBackground/ContentBackground";
import { useAuth, useInvestments, useMaskedParams, useOpportunities, useUI } from "../../../hooks";
import useBoolean from "../../../hooks/useBoolean";
import { isBdc } from "../../../utility/misc.util";
import { InvestorDialog } from "./InvestorDialog";
import { BdcDisclaimerDialog } from "./BdcDisclaimerDialog";
import { WiringDialog } from "./WiringDialog";
import { OpportunityDetailHeader } from "./OpportunityDetailHeader";
import { MatchWithHandle } from "../../../routes/router.util";
import { apiClient } from "../../../api/apiClient";
import { maskRouteId } from "../../../utility/route-mask.util";

export const OpportunityDetailPage: FC = () => {
  const { opportunitiesMap } = useOpportunities();
  const { investors, wiringInstructions, regDInterestedList, investedList, interestedList } = useInvestments();
  const { investmentId } = useMaskedParams();
  const opportunity = useMemo(() => opportunitiesMap[+investmentId!] ?? {}, [investmentId, opportunitiesMap]);
  const [showBdcDisclaimer, setShowBdcDisclaimer] = useState(false);
  const {value: openAddInvestor, setFalse: setAddInvestorClose, setTrue: setAddInvestorOpen } = useBoolean();
  const {value: openWiring, setFalse: setWiringClose, setTrue: setWiringOpen } = useBoolean();
  const {state} = useLocation();
  const {setSuccess} = useUI();
  const isRegD = !isBdc(opportunity.investing_entity_type_id);
  const matches = useMatches() as MatchWithHandle[];
  const [hideTabsRoute] = matches.filter((m: MatchWithHandle) => !!m.handle?.hideTabs);
  const hideTabs = !!hideTabsRoute;
  const { setError, setLoading } = useUI();
  const { setInvestors, setDistributions, setCapitalCalls, setInterestedList,setProspectsList, setInvestedList, setRegDInterestedList, setWiringInstructions, setInvestmentCompanyDocuments } = useInvestments();
  const { value: refreshData, setFalse: setRefreshDataFalse, setTrue: setRefreshDataTrue } = useBoolean(true);
  const defaultErrorMessage = "Oops, something went wrong. Please try again later.";
  const { user } = useAuth();

  useEffect(() => {
    async function fetchData() {
      if (!user?.id || !investmentId || !refreshData) return;
  
      const routeParams = { routeParams: [investmentId] }; 
      setLoading(true);
      
      try {
        const [wiringInstructions, documents] = await Promise.all([
          apiClient.get(`/investors/investment-companies/:p0/wiring-instructions`, routeParams),
          apiClient.get(`/investors/investment-companies/:p0/documents`, routeParams)
        ]);
  
        setWiringInstructions(wiringInstructions);
        setInvestmentCompanyDocuments(documents);
  
        if (isRegD) {
          const [regDResponse, companyDetails] = await Promise.all([
            apiClient.get(`/investors/investment-companies/:p0/investors`, routeParams),
            apiClient.get(`/investors/investment-companies/:p0`, routeParams)
          ]);
  
          const { investors, capitalCalls, distributions } = companyDetails;
          setCapitalCalls(capitalCalls);
          setInvestors(investors);
          setDistributions(distributions);
          setRegDInterestedList(regDResponse.regDInterestedList);
        } else {
          const transactions = await apiClient.get(`/investors/investment-companies/:p0/transactions`, routeParams);
          setInterestedList(transactions.interestedList);
          setProspectsList(transactions.prospectsList);
          setInvestedList(transactions.investedList);
        }
      } catch (error) {
        setError(defaultErrorMessage);
      } finally {
        setLoading(false);
        setRefreshDataFalse();
      }
    }
    fetchData();
  }, [
    investmentId, refreshData, user, isRegD, 
    setError, setLoading, setRefreshDataFalse, 
    setInvestors, setDistributions, setCapitalCalls, 
    setInterestedList, setInvestedList, setRegDInterestedList, 
    setWiringInstructions, setInvestmentCompanyDocuments, setProspectsList
  ]);

  useEffect(() => {
    if (state && state.openAddInvestor === true) {
      setAddInvestorOpen();
    }
  },[setAddInvestorOpen, state])


  const handleDialogClose = (result?: boolean) => {
    setAddInvestorClose();
    if (result) {
      setSuccess("Investor added successfully");
      setRefreshDataTrue();
    }
  };

  const handleWiringClose = () => {
    setWiringClose();
  }

  const handleWiringClick = () => {
    setWiringOpen();
  }
  
  return (
    <Container sx={{ py: 2 }}>
      <ContentBackground>
        {!hideTabs ?
          (
            <>
              <OpportunityDetailHeader
                opportunity={opportunity}
                handleWiringClick={handleWiringClick}
                handleAddInvestorClick={isRegD ? setAddInvestorOpen : () => setShowBdcDisclaimer(true)}
              />
              <Box sx={{ p: 3 }}>
                <Outlet />
              </Box>
            </>
          )
          : <Outlet />
        }
        {showBdcDisclaimer && (
          <BdcDisclaimerDialog
            investmentCompanyName={opportunity.name}
            handleClose={result => {
              setShowBdcDisclaimer(false);
              result && setAddInvestorOpen();
            } }
          />
        )}
        {openAddInvestor && (
          <InvestorDialog 
            mode='add'
            open={openAddInvestor} 
            handleClose={handleDialogClose} 
            setRefreshDataTrue={setRefreshDataTrue} 
            interestedList={isRegD ? regDInterestedList : interestedList} 
            investedList={isRegD ? investors : investedList}
          />
        )}
        {openWiring && wiringInstructions && <WiringDialog open={openWiring} handleClose={handleWiringClose} wiringInstructions={wiringInstructions}
        opportunity={opportunity}
        />}
      </ContentBackground>
    </Container>
  )
}
import { Search } from "@mui/icons-material";
import { Box, Button, InputAdornment, Paper, TableContainer, TextField, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InvestedClient } from "../../../api/payloads";
import { ColumnDef, DataSourceTable } from "../../../components/DataSourceTable";
import { toCurrency } from "../../../utility/number.util";
import { maskRouteId } from "../../../utility/route-mask.util";
import { useMaskedParams } from "../../../hooks/useMaskedParams";
import { useInvestments, useOpportunities } from "../../../hooks";


export const InvestedList: FC = () => {
  const navigate = useNavigate()
  const { investmentId } = useMaskedParams();
  const { opportunitiesMap } = useOpportunities();
  const { investedList } = useInvestments();
  const opportunity = opportunitiesMap[+investmentId];
  const [searchFilter, setSearchFilter] = useState("");
  const [filteredRows, setFilteredRows] = useState<InvestedClient[]>([]);
  const displayCurrency = (v: number | string | null) => v !== null ? toCurrency(v, true) : "---";
  const displayTransactionHistory = (v: string | null) => <Button color='info' onClick={() => { navigate(`./${maskRouteId(v)}` , {state:{"value": opportunity}} )}} sx={{ p: 1, pt: 0, pb: 0 }}>View History</Button>
  const displayShares = (v: number | null) => v ?? "";

  const distributionsColumnDef: ColumnDef<InvestedClient> = [
    { id: 'client_name', label: "Client Name" },
    { id: 'account_name', label: "Account Name" },
    { id: 'total_subscriptions', label: "Subscription Amount", displayFn: displayCurrency, showTotal: true, },
    { id: 'shares', label: "Total Shares", displayFn: displayShares },
    { id: 'nav', label: "NAV", displayFn: displayCurrency, },
    { id: 'id', label: "Transaction History", displayFn: displayTransactionHistory },
  ];

  useEffect(() => {
    const filteredRows = investedList ? investedList.filter(r => r.client_name.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase())) : [];
    setFilteredRows(filteredRows);
  }, [searchFilter, investedList])
  
  return (
    <Box component="section" mb={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h5">Investors List</Typography>
        <TextField
          value={searchFilter}
          onChange={(event) => setSearchFilter(event.target.value)}
          placeholder="Search Investors List"
          size="small"
          helperText=""
          InputProps={{
            startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
          }} />
      </Box>
      <TableContainer component={Paper}>
        <DataSourceTable columnDef={distributionsColumnDef} dataSource={filteredRows} emptyMessage="No clients" showTotals />
      </TableContainer>
    </Box>
  )
}
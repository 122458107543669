import { Box, FormControlLabel, Grid, Radio, Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText 
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import { RadioGroup } from "formik-mui";
import { ChangeEvent, FC, MouseEvent, PropsWithChildren, useState } from "react";
import { ConfirmationDialog } from "../../../../components/ConfirmationDialog/ConfirmationDialog";
import { SubDocsFormValues } from "./subDocsFormSchema";
import { CornerCloseButton } from '../../../../components/Buttons';

const Strong: FC<PropsWithChildren> = ({children}) => {
  return <Typography component='strong' fontWeight='500'>{children}</Typography>
}

export const ElectronicDelivery: FC = (props) => {
  const { setFieldValue } = useFormikContext<SubDocsFormValues>();
  const [warningDialogOpen, setWarningDialogOpen] = useState<boolean>(false);
  const [open, setOpen] =  useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const closeWarningDialog = (confirmed?: boolean) => {
    if (!confirmed) {
      setFieldValue("isElectronicDeliveryConfirmed", 1)
    } else {
      setFieldValue("isElectronicDeliveryConfirmed", 0)
    }
    setWarningDialogOpen(false)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h5' mb={2} fontWeight='500' >
          Notice and Consent For Electronic Delivery of Company Materials and Regulatory Documents
        </Typography>
        <Typography variant='body1' sx={{mb : 3}}>
           The Company is seeking written consent from each Shareholder to use Electronic Delivery to deliver 
           the Company Materials and Regulatory Documents to Shareholders.  Accordingly, the Company seeks 
           consent from you. <Box component='span' onClick={handleClickOpen} sx={{cursor:"pointer", color:"secondary.main", display:"inline", fontWeight:"bold"}}>Learn More</Box>
        </Typography>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { minWidth: "800px" } }}
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant='h5' mb={2} fontWeight='500'>
            Notice and Consent For Electronic Delivery of Company Materials and Regulatory Documents
          </Typography>
          <CornerCloseButton onClick={handleClose}></CornerCloseButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Typography variant='body1' sx={{ textIndent: '3rem' }} mb={3}>
            The Company intends to electronically deliver documents or information pertaining to a Shareholder's investment in the Company, including, but not limited to, reports, statements, notices (including capital call notices), Form K-1s and other information (the    <Strong>"Company Materials"</Strong>) required or permitted to be provided to the Company's Shareholders pursuant to any federal, state or local law or regulation or pursuant to the Agreement and Declaration of Trust of the Company, as amended and/or restated from time to time. In addition, the Company will use the electronic delivery methods to deliver regulatory communications to Shareholders, including Form ADV Part 2 and privacy notices, among others (this information, together with any other information required under the 1933 Act, the Securities Exchange Act of 1934, the 1940 Act and the Investment Advisers Act of 1940, the <Strong>"Regulatory Documents"</Strong>). <Strong>"Electronic Delivery"</Strong> means making Company Materials and Regulatory Documents available electronically to a Shareholder by delivery of information by email, by posting documents to the Company's internet-based investor portal and/or by any other electronic means. The Investment Adviser may elect which method of delivery it uses with respect to Electronic Delivery of any and all such materials and documents.
          </Typography>
          <Typography variant='body1' sx={{ textIndent: '3rem' }}>
            The Company is seeking written consent from each Shareholder to use Electronic Delivery to deliver the Company Materials and Regulatory Documents to Shareholders. Accordingly, the Company seeks consent from you, as follows:
          </Typography>
          <Box component={'ol'} sx={{'& > li': {pb: '1rem'}}}>
            <li>
              The Investor hereby acknowledges that the Investor has read and understands this Notice and 
              Consent for Electronic Delivery of Company Materials and Regulatory Documents (this 
              <Strong> "Consent" </Strong>) and hereby consents to Electronic Delivery of all Company Materials 
              and Regulatory Documents.
            </li>
            <li>
              The Investor understands that the Investor may incur costs in connection with Electronic Delivery of Company Materials and Regulatory Documents, including costs associated with online time and printing documents.
            </li>
            <li>
              It is the Investor's affirmative obligation to notify the Company in writing if the Investor's email address provided to the Company changes. The Investor may revoke or restrict its consent to this Electronic Delivery of Company Materials and Regulatory Documents upon delivery of 30 days' prior written notice to the Company of such revocation or restriction.
            </li>
            <li>
              The Investor agrees to notify the Company as promptly as possible if the Investor is unable to access a document link in an Electronic Delivery communication or does not receive an expected communication via Electronic Delivery.
            </li>
          </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>        
      <Typography>Click "Yes" to give consent or "No" to reject.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Field row
          component={RadioGroup}
          name="isElectronicDeliveryConfirmed"
          onChange={(event: ChangeEvent, value: number | string) => {
            if (+value === 1) {
              setFieldValue('isElectronicDeliveryConfirmed', +value)
            }
          }}
        >
          <FormControlLabel
            value={1}
            control={<Radio />}
            label="Yes"

          />
          <FormControlLabel
            value={0}
            control={<Radio />}
            label="No"
            onClick={(event: MouseEvent<HTMLLabelElement>) => {
              event.stopPropagation();
              setWarningDialogOpen(true)
            }}
          />
        </Field>
        <ConfirmationDialog open={warningDialogOpen} title="Please Confirm" message="By selecting NO, you understand that your receipt of ALL communications (including notice of distributions), invitations, and other information pertaining to your investment will be delayed relative to electronic delivery." yesButtonLabel="Confirm" noButtonLabel="Cancel" handleClose={closeWarningDialog} />
      </Grid>
    </Grid>
  )
}
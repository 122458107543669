import { Auth } from "aws-amplify";
import { createBrowserRouter, Navigate, redirect, RouteObject } from "react-router-dom";
import { AppShell } from "../components/AppShell/AppShell";
import { UnauthenticatedAppShell } from "../components/UnathenticatedAppShell/UnauthenticatedAppShell";
import { AuthContextValue } from "../context/AuthContext";
import { SubDocsContextProvider } from "../context/SubDocsContext";
import { ClientDetailPage } from "../pages/Clients/ClientDetailPage/ClientDetailPage";
import { ClientsPage } from "../pages/Clients/ClientsPage";
import { NewClientPage } from "../pages/Clients/NewClientPage/NewClientPage";
import { EventDetailPage } from "../pages/Events/EventDetailPage";
import { EventsPage } from "../pages/Events/EventsPage";
import { GpUpdateDetailPage } from "../pages/GpUpdates/GpUpdateDetailPage/GpUpdateDetailPage";
import { GpUpdatesPage } from "../pages/GpUpdates/GpUpdatesPage";
import { InvestmentDetailPage } from "../pages/Investments/InvestmentDetailPage/InvestmentDetailPage";
import { InvestmentsPage } from "../pages/Investments/InvestmentsPage";
import { LoginPage } from "../pages/Login/LoginPage";
import { OpportunitiesPage } from "../pages/Opportunities/OpportunitiesPage";
import { AccreditedStatus } from "../pages/Opportunities/OpportunityDetailPage.tsx/AccreditedStatus";
import { OpportunityDetailPage } from "../pages/Opportunities/OpportunityDetailPage.tsx/OpportunityDetailPage";
import { TransactionHistory } from "../pages/Opportunities/OpportunityDetailPage.tsx/TransactionHistory";
import { SubDocsPage } from "../pages/Opportunities/Subdoc/SubdocsPage";
import { ResetPassword } from "../pages/Reset Password/ResetPassword";
import { SettingsPage } from "../pages/Settings/SettingsPage";
import { TermsAndConditionsPage } from "../pages/Terms/TermsAndConditionsPage";
import { clientDetailRoutes } from "./child-routes/client-detail.routes";
import { investmentDetailRoutes } from "./child-routes/investment-detail.routes";
import { settingChildRoutes } from "./child-routes/settings-child.routes";
import { isNavBarRoute, NavBarRouteObject } from "./router.util";
import { ClientDetailsContextProvider } from "../context/ClientDetailsContext";
import { bdcOpportunityDetailRoutes, regDOpportunityDetailRoutes } from "./child-routes/opportunity-detail.routes";
import { InvestmentsContextProvider } from "../context/InvestmentsContext";

const opportunitiesRoute: NavBarRouteObject = {
  path: "opportunities",
  label: "Opportunities",
  children: [
    { element: <OpportunitiesPage />, index: true },
    { path: ":investmentId", element:<InvestmentsContextProvider><OpportunityDetailPage/></InvestmentsContextProvider>, children: [ ...bdcOpportunityDetailRoutes, ...regDOpportunityDetailRoutes]},
    { path: ":investmentId/sub-doc/:subDocId", element: <SubDocsContextProvider><SubDocsPage /></SubDocsContextProvider> },
    { path: ":investmentId/positions/:positionId", element: <TransactionHistory/> },
    { path: ":investmentId/client/:clientId/accredited/:accountId/transactions/:transactionId", element: <AccreditedStatus/> }
  ]
};

const authenticatedRoutes: (RouteObject | NavBarRouteObject)[] = [
  { index: true, loader: () => redirect("/opportunities") },
  opportunitiesRoute,
  {
    path: "clients",
    label: "Clients",
    children: [
      { element: <ClientsPage />, index: true },
      { path: "new-client", element: <NewClientPage /> },
      { path: "new-client/:householdId",element: <NewClientPage /> },
      { path: ":clientId", element: <ClientDetailsContextProvider><ClientDetailPage /></ClientDetailsContextProvider>, children: clientDetailRoutes }
    ]
  },
  {
    path: "investments",
    label: "Investments",
    children: [
      { element: <InvestmentsPage />, index: true, },
      { path: ":investmentId", element: <InvestmentDetailPage />, children: investmentDetailRoutes },
    ],
  },
  {
    path: "gp-updates",
    label: "GP Updates",
    children: [
      { element: <GpUpdatesPage />, index: true },
      { path: ":updateId", element: <GpUpdateDetailPage />, index: true }
    ]
  },
  {
    path: "events",
    label: "Events",
    children: [
      { element: <EventsPage />, index: true },
      { path: ":eventId", element: <EventDetailPage /> }
    ]
  },
  {
    path: 'settings',
    children: [
      { element: <SettingsPage />, index: true },
      ...settingChildRoutes
    ]
  },
];


export const navBarRoutes = authenticatedRoutes.filter(isNavBarRoute);

export const getRouter = (user: AuthContextValue['user'], clearUser: AuthContextValue['clearUser']) => {
  const handleLogout = () => {
    clearUser();
    return redirect('/login');
  }

  /** 
   * TODO: A bug in this version of React Router causes a cached version of the loader to be use when using back button in Chrome
   * This has led us to move some of the auto-logo handling to AppShell. This may deem this loader unnecessary if we move the remaining logic
   * there, but keeping for now as would like to see if this is fixed in a newer version of react-router-dom (currently using 6.4.2)
   */
  const authenticatedLoader = async () => {
    try {
      const session = await Auth.currentSession();

      if (!session.isValid()) {
        return handleLogout();
      }
      /* 
        Commenting this out for 1.0.47 since a React router issue causes
        the router to use a cached version of the loader when using browser back button after
        logging in. Refreshing the page after logging in solves the problem, and it doesn't occur
        if your visiting the site after having already logged in
       */
      // else if (!user || !user.id) {
      //   // Very rare case, only observed once in staging. Just need to handle the scenario where a local storage item got cleared/corrupted but
      //   // the amplify session is still valid.
      //   await Auth.signOut();
      //   return handleLogout();
      // }
    } catch (e) {
      // Session expired
      return handleLogout();
    }
  }

  const unauthenticatedLoader = async () => {
    try {
      const session = await Auth.currentSession();
      if (session.isValid()) {
        return redirect('/');
      }
    } catch (e) {
      return;
    }
  }

  return createBrowserRouter([
    {
      path: 'terms-and-conditions',
      element: <UnauthenticatedAppShell maxWidth={"xl"}/>,
      children: [
        { index: true, element: <TermsAndConditionsPage /> },
      ],
    },
    {
      path: '/',
      element: <AppShell />,
      children: authenticatedRoutes,
      loader: authenticatedLoader
    },
    {
      path: 'login',
      element: <UnauthenticatedAppShell />,
      children: [
        { index: true, element: <LoginPage /> },
        { path: 'reset', element: <ResetPassword /> },
      ],
      loader: unauthenticatedLoader,
    },
  ]);
}

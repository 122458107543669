import { Modal, Box, Typography, Grid, Button } from "@mui/material";
import React from "react";
import { DistributionInstruction } from "../../api/payloads/customer.payload";
import { Distribution, Investor } from "../../api/payloads/investments.payload";

export type InstructionContent = {
  [key: string]: any; 
};

export interface InstructionModalProps {
  open: boolean;
  onClose: () => void;
  instruction: DistributionInstruction | Investor | Distribution['distributionInvestors'][number];
  isCustodied?: boolean;
}

export const InstructionDetailsModal = ({open, onClose, instruction, isCustodied}: InstructionModalProps) => {
  const isACHorCheck = instruction.details?.instruction_type === 'ACH' || instruction.details?.instruction_type === 'Check';
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    maxWidth: isACHorCheck ? 500 : 'auto',
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: "16px",
    p: 2,
  };
  
  const InstructionContent = (): { label: any; value: any }[] => {
    const content: InstructionContent = {
      Brokerage: [
        { label: "Name", value: instruction.details?.distribution_instruction_name ?? "--" },
        { label: "Type", value: instruction.details?.instruction_type ?? "--" },
        { 
          label: isCustodied ? "Name on Bank Account" : "Custodian Name", 
          value: isCustodied ? instruction.details?.instruction_account_name ?? "--" : instruction.details?.custodian_name ?? "--",
        },
        {
          label: "Financial Institution Name",
          value: instruction.details?.financial_institution_name ?? "--",
        },
        {
          label: "Financial Institution City",
          value: instruction.details?.financial_institution_city ?? "--",
        },
        {
          label: "Financial Institution State",
          value: instruction.details?.financial_institution_state ?? "--",
        },
        {
          label: "Bank Routing Number",
          value: instruction.details?.instruction_routing_number ?? "--",
        },
        {
          label: "Bank Account Number",
          value: instruction.details?.instruction_account_number ?? "--",
        },
        {
          label: "Reference (For Further Credit)",
          value: instruction.details?.ffc_to ?? "--",
        },
        {
          label: "FFC to Account Number",
          value: instruction.details?.ffc_account_number ?? "--",
        },
        {
          label: "Investor Primary Address",
          value: [
            instruction.details?.instruction_address_1,
            instruction.details?.instruction_address_2,
            instruction.details?.instruction_city,
            instruction.details?.instruction_state,
            instruction.details?.instruction_zip
          ].filter(Boolean).join(', ').trim() ?? "--"
        },        
      ],
      ACH: [
        { label: "Name", value: instruction.details?.distribution_instruction_name ?? "--" },
        { label: "Type", value: instruction.details?.instruction_type ?? "--" },
        {
          label: "Name on Bank Account",
          value: instruction.details?.instruction_account_name ?? "--",
        },
        {
          label: "Bank’s Routing Number",
          value: instruction.details?.instruction_routing_number ?? "--",
        },
        {
          label: "Bank Account Number",
          value: instruction.details?.instruction_account_number ?? "--",
        },
        { label: "Account Type", value: instruction.details?.instruction_account_type_name ?? "--" },
        {
          label: "For Further Credit To",
          value: instruction.details?.ffc_to ?? "--",
        },
      ],
      Check: [
        { label: "Name", value: instruction.details?.distribution_instruction_name ?? "--" },
        { label: "Type", value: instruction.details?.instruction_type ?? "--" },
        { label: "Payee", value: instruction.details?.recipient_name ?? "--" },
        {
          label: "Mail To",
					value: 
						`${instruction.details?.instruction_address_1 ?? ""} ` +
						`${instruction.details?.instruction_address_2 ?? ""} ` +
						`${instruction.details?.instruction_city ?? ""}, ` +
						`${instruction.details?.instruction_state ?? ""}, ` +
						`${instruction.details?.instruction_zip ?? ""}`.trim() || "--"
					},
      ],
      Wire: [
        { label: "Name", value: instruction.details?.distribution_instruction_name ?? "--" },
        { label: "Type", value: instruction.details?.instruction_type ?? "--" },
        {
          label: "Name on Bank Account",
          value: instruction.details?.instruction_account_name ?? "--",
        },
        {
          label: "Financial Institution Name",
          value: instruction.details?.financial_institution_name ?? "--",
        },
        {
          label: "Financial Institution Street Address",
          value:
            instruction.details?.financial_institution_street_address_1 ?? "--",
        },
        {
          label: "Address Line 2",
          value:
            instruction.details?.financial_institution_street_address_2 ?? "--",
        },
        {
          label: "Financial Institution City",
          value: instruction.details?.financial_institution_city ?? "--",
        },
        {
          label: "Financial Institution State",
          value: instruction.details?.financial_institution_state ?? "--",
        },
        {
          label: "Zip Code",
          value: instruction.details?.financial_institution_zip ?? "--",
        },
        {
					label: instruction.details?.wire_type_id === 2 ? "SWIFT Code" : "Bank's Routing Number",
					value: instruction.details?.wire_type_id === 2
						? instruction.details?.instruction_swift ?? "--"
						: instruction.details?.instruction_routing_number ?? "--",
				},
        {
          label: "Bank Account Number",
          value: instruction.details?.instruction_account_number ?? "--",
        },
        {
          label: "Reference (For Further Credit)",
          value: instruction.details?.ffc_to ?? "--",
        },
        {
          label: "FFC to Account Number",
          value: instruction.details?.ffc_account_number ?? "--",
        },
        {
          label: "Investor Primary Address",
          value: [
            instruction.details?.instruction_address_1,
            instruction.details?.instruction_address_2,
            instruction.details?.instruction_city,
            instruction.details?.instruction_state,
            instruction.details?.instruction_zip
          ].filter(Boolean).join(', ').trim() || "--"
        },        
      ],
    };
    return content[instruction.details?.instruction_type] ?? [];
  };

  const content = InstructionContent();
  return (
		<Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box sx={style}>
        <Typography variant="h5" mb={1}>{instruction.details.distribution_instruction_name}</Typography>
        <Typography id="modal-title" variant="h6" component="h2">Distribution Instruction Details</Typography>
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            {content.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item xs={instruction.details.instruction_type === 'Check' ? 4 : 6}>
                  <Typography>{item.label}</Typography>
                </Grid>
                <Grid item xs={instruction.details.instruction_type === 'Check' ? 8 : 6}>
                <Typography>{item.value}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button onClick={onClose}>Close</Button>
        </Box>
      </Box>
    </Modal>
  );
};
import { Box, Checkbox, FormControlLabel, FormGroup, MenuItem, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { Select, TextField } from 'formik-mui';
import { useEffect } from 'react';
import { useClients, useUI } from '../../../../../hooks';
import { useSubDocs } from '../../../../../hooks/ContextHooks/useSubDocs';
import { InstructionFormValues } from './NewInstructionDialog';

export default function CheckInstructionFields() {
  const {setFieldValue, values, validateForm, setFieldTouched,} = useFormikContext<InstructionFormValues>();
  const {addressStates} = useClients();
  const {subDocsClient} = useSubDocs();
  const {setError} = useUI();
  useEffect(() => {
    // TODO: find a better way to trigger validation/set touched
    if (values.checkInstructions?.existingAddressId != null) {
      setFieldTouched("checkInstructions.streetAddress1", true, true);
      setFieldTouched("checkInstructions.streetAddress2", true, true);
      setFieldTouched("checkInstructions.city", true, true);
      setFieldTouched("checkInstructions.stateId", true, true);
      setFieldTouched("checkInstructions.zip", true, true);
    }
  }, [values.checkInstructions?.existingAddressId, validateForm, setFieldTouched])

  const handleMailToClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && subDocsClient?.address) {
      setFieldValue("checkInstructions.existingAddressId", subDocsClient.address.id)
      setFieldValue("checkInstructions.streetAddress1", subDocsClient.address.street_address_1 ?? "")
      setFieldValue("checkInstructions.streetAddress2", subDocsClient.address.street_address_2 ?? "")
      setFieldValue("checkInstructions.city", subDocsClient.address.city ?? "")
      setFieldValue("checkInstructions.stateId", subDocsClient.address.state_id ?? "")
      setFieldValue("checkInstructions.zip", subDocsClient.address.zip_code ?? "")
    } else {
      setFieldValue("checkInstructions.existingAddressId", null)
      setFieldValue("checkInstructions.streetAddress1", "")
      setFieldValue("checkInstructions.streetAddress2", "")
      setFieldValue("checkInstructions.city", "")
      setFieldValue("checkInstructions.stateId", "")
      setFieldValue("checkInstructions.zip", "")
    }
  }

  return (
    <Box sx={{mt: 4, mb:2}}>
      <Box>
        <Field name="checkInstructions.instructionName" required label="Distribution Instruction Friendly Name" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
        <Field name="checkInstructions.payeeName" required label="Payee Name" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
      </Box>
      <Box sx={{display: 'flex',justifyContent: 'flexStart', alignItems: 'center', mb: 2}}>
        <Typography sx={{fontWeight: 'bold', mr: 2}}>Mail to: </Typography>
        <FormGroup>
          <FormControlLabel control={<Checkbox onChange={handleMailToClick} disabled={!subDocsClient?.address} />} label="Same as Household Address" />
        </FormGroup>
      </Box>
      <Box>
        <Field name="checkInstructions.streetAddress1" disabled={values.checkInstructions?.existingAddressId != null} required label="Street Address" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
        <Field name="checkInstructions.streetAddress2" disabled={values.checkInstructions?.existingAddressId != null} label="Street Address 2" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Field name="checkInstructions.city" disabled={values.checkInstructions?.existingAddressId != null} required label="City" component={TextField} sx={{width: 'calc(42% - 8px)', mr: 2}} />
        <Field 
          name="checkInstructions.stateId" 
          label="State" 
          disabled={values.checkInstructions?.existingAddressId != null}
          component={Select} 
          formControl={{required: true}}
          sx={{ width: '200px',}}
          onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
          >
            {addressStates != null && addressStates.map(state => (
              <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
            ))}
        </Field>
        <Field name="checkInstructions.zip" disabled={values.checkInstructions?.existingAddressId != null} label="ZIP Code" required component={TextField} sx={{ml: 2,width: 'calc(42% - 8px)'}}/>
        </Box>
    </Box>
  );
}
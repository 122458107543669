import { Search } from "@mui/icons-material";
import { Box, InputAdornment, Link as MuiLink, Paper, TableContainer, TextField, Typography } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { CapitalCall } from "../../../../../api/payloads";
import { ColumnDef, DataSourceTable } from "../../../../../components/DataSourceTable";
import { useInvestments } from "../../../../../hooks";
import { formatDate } from "../../../../../utility/date.util";
import { toCurrency } from "../../../../../utility/number.util";
import { maskRouteId } from "../../../../../utility/route-mask.util";

export const InvestmentCapitalCalls: FC = () => {
  const { capitalCalls } = useInvestments();
  const [searchFilter, setSearchFilter] = useState("");
  const [filteredRows, setFilteredRows] = useState(capitalCalls);

  const capitalCallColumnDef = useMemo(() => {
    const def: ColumnDef<CapitalCall> = [
      { id: 'date', label: "Date", displayFn: v => formatDate(v) },
      { id: 'title', label: "Title", displayFn: (v, r) =>  <MuiLink component={Link} to={`./${maskRouteId(r.id)}`}>{v}</MuiLink> },
      { id: 'totalCalled', label: "Called Amount", displayFn: v => toCurrency(v), showTotal: true },
      { id: 'totalPaid', label: "Amount Paid", displayFn: v => toCurrency(v), showTotal: true },
      { id: 'status', label: "Status", displayFn: v => <Box component="span" color={v ? 'success.main' : "error.main"}>{v ? 'Open' : 'Closed'}</Box>},
    ];

    return def;
  }, []);

  useEffect(() => {
    const filteredRows = capitalCalls.filter(r => r.title.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase()));
    setFilteredRows(filteredRows);
  }, [searchFilter, capitalCalls])

  return (
    <Box component="section" mb={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h5">Capital Calls List</Typography>
        <TextField
          value={searchFilter}
          onChange={(event) => setSearchFilter(event.target.value)}
          placeholder="Search by title"
          size="small"
          helperText=""
          InputProps={{
            startAdornment: <InputAdornment position="start"><Search/></InputAdornment>,
          }} />
      </Box>
      <TableContainer component={Paper}>
        <DataSourceTable columnDef={capitalCallColumnDef} dataSource={filteredRows} emptyMessage="No capital calls" showTotals/>
      </TableContainer>
    </Box>
  )
}


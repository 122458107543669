import { Auth } from "aws-amplify";
import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorContactEmail } from "../components/ErrorContactEmail/ErrorContactEmail";
import { TOKEN_EXPIRED } from "../utility/misc.util";
import { useAuth, useUI } from "./ContextHooks";

export const defaultErrorMessage = (
  <span>
    Our apologies, we encountered an error while retrieving your data. Please contact <ErrorContactEmail/> for assistance.
  </span>
);

/**
 * Simple useEffect hook with no empty dependency array that executes 
 * a given function and updates app pending and error states
 */
export function useInit(initFn: () => Promise<any>, errorMessage?: ReactNode, signOutOnError = true) {
  const { clearUser } = useAuth();
  const { setLoading, setError } = useUI();
  const navigate = useNavigate();
  errorMessage = errorMessage ?? defaultErrorMessage;

  useEffect(() => {
    const executeInitFn = async () => {
      try {
        setLoading(true)
        await initFn();
      } catch (e: unknown) {
        const isTokenExpiredError = e instanceof Error && e.name === TOKEN_EXPIRED;
        setError(isTokenExpiredError ? e.message : errorMessage!);
       
        if (isTokenExpiredError || signOutOnError) {
          try {
            await Auth.signOut();
            clearUser();
          } catch (e) {}
          finally {
            navigate('/login');
          }
        }
      }
      finally {
        setLoading(false);
      }
    }
    executeInitFn()
  },
    // Disabling Exhaustive deps rule since we're certain we only want to run this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
}

import { useState, useCallback } from 'react';

type AsyncFunction = (...args: any[]) => Promise<void>;

/** 
 * Hook for wrapping an async event handler with a loading state.
 */
export function useLoadingWrapper(asyncFunction: AsyncFunction) {
  const [isLoading, setIsLoading] = useState(false);

  const wrappedFunction = useCallback(async (...args: any[]) => {
    setIsLoading(true);
    await asyncFunction(...args);
    setIsLoading(false);
  }, [asyncFunction]);

  return [wrappedFunction, isLoading] as const;
}
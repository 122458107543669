import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import { FC, ReactNode } from "react";

type ConfirmationDialogProps = Omit<DialogProps, 'onClose'> & 
  { 
    handleClose: (success?: boolean) => void, 
    titleNode?: ReactNode, 
    yesButtonLabel?: string, 
    noButtonLabel?: string,
    message?: string,
    textAlign?: string,
  }

//TODO: Consider adding an optional "Cancel" button for cases where "No" and "Cancel" 
// should have different behaviors

export const ConfirmationDialog: FC<ConfirmationDialogProps> = (props) => {
  let {titleNode, yesButtonLabel, noButtonLabel, handleClose, message, textAlign, ...rest} = props;
  titleNode = titleNode ?? "Are you sure?"
  yesButtonLabel = yesButtonLabel ?? "Yes";
  noButtonLabel = noButtonLabel ?? "No";
  textAlign = textAlign ?? "center";

  return (
    <Dialog onClose={() => handleClose} {...rest}>
      <DialogTitle textAlign='center'>{titleNode}</DialogTitle>
      {!!message && <DialogContent sx={{textAlign}}>{message}</DialogContent>}
      <DialogActions sx={{display: 'flex', justifyContent: 'center'}}>
        <Button onClick={() => handleClose(false)} size="large" variant="outlined">{noButtonLabel}</Button>
        <Button onClick={() => handleClose(true)} size="large">{yesButtonLabel}</Button>
      </DialogActions>
    </Dialog>
  )
}
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@mui/material";
import { ErrorMessage } from "formik";
import { FC } from "react";
import { useSubDocs } from "../../../../hooks/ContextHooks/useSubDocs";
import { AccountType } from '../../../../utility/constants';
import { AMLVerificationReview } from "./Review/AMLVerificationReview";
import { AccreditedInvestorReview } from "./Review/AccreditedInvestorReview";
import { AdvisorAcknowledgementReview } from './Review/AdvisorAcknowledgementReview';
import { DistributionInformationReview } from "./Review/DistributionInformationReview";
import { ElectronicDeliveryReview } from "./Review/ElectronicDeliveryReview";
import { FinancialAdvisorContactReview } from "./Review/FinancialAdvisorContactReview";
import { InvestorQuestionnaireReview } from "./Review/InvestorQuestionnaireReview";
import { PurchaseFundingInstructionReview } from './Review/PurchaseFundingInstructionReview';
import { RepresentationOfResidenceReview } from "./Review/RepresentationOfResidenceReview";
import { SupplementalDataReview } from "./Review/SupplementalDataReview";

type InfoCardProps = {
  title: string,
  value: string,
  span?: number,
}


type InfoListCardProps = {
  title: string,
  values: string[],
  span?: number,
}

export const SubDocsReviewInfoListCard = ({span, title, values}:InfoListCardProps) => {
  return (
    <Grid item xs={span ?? 2}>
      <Typography sx={{fontSize: 16, fontWeight: 'bold', mr: 0}}>{`${title}:`}</Typography>
      {values.map((value, index) => <Typography key={index} sx={{color: 'info.contrastText'}}>{value}</Typography>)}
    </Grid>
  )
}

export const SubDocsReviewInfoCard = ({span, title, value}:InfoCardProps) => {
  return (
    <Grid item xs={span ?? 2}>
      <Typography 
      sx={{fontSize: 16, fontWeight: 'bold'}}
      >{`${title}:`}</Typography>
      <Typography sx={{color: 'info.contrastText'}}>{value}</Typography>
    </Grid>
  )
}

export const SubDocsReview: FC = () => {
  const { subDocsAccount } = useSubDocs();
  const isEntity = subDocsAccount?.account_type_id === AccountType.Entity;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', }}>
      <Box sx={{mb: 2}}>
        <ErrorMessage  name="error"/> 
        <Accordion defaultExpanded={true} sx={{mb: 2}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            Investor Questionnaire
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <InvestorQuestionnaireReview />
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true} sx={{mb: 2}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            Accredited Investor
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <AccreditedInvestorReview />
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true} sx={{mb: 2}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            Financial Advisor Contact
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <FinancialAdvisorContactReview />
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true} sx={{mb: 2}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            Representation of Residence
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <RepresentationOfResidenceReview />
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true} sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Distribution Information
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <DistributionInformationReview />
            </Grid>
          </AccordionDetails>
        </Accordion>
        {isEntity && <Accordion defaultExpanded={true} sx={{mb: 2}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            Supplemental Data
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <SupplementalDataReview />
            </Grid>
          </AccordionDetails>
        </Accordion>}
        <Accordion defaultExpanded={true} sx={{mb: 2}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            Electronic Delivery
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <ElectronicDeliveryReview />
            </Grid>
          </AccordionDetails>
        </Accordion>
        {subDocsAccount?.account_type_id === AccountType.IRA &&<Accordion defaultExpanded={true} sx={{mb: 2}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            IRA Purchase Instructions
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <PurchaseFundingInstructionReview />
            </Grid>
          </AccordionDetails>
        </Accordion>}
        <Accordion defaultExpanded={true} sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            AML Verification
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <AMLVerificationReview />
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={true} defaultExpanded={true} sx={{ mb: 2 }}>
          <AccordionSummary disabled sx={{ opacity: '1!important' }}>
            Advisor Acknowledgment
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <AdvisorAcknowledgementReview />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  )
}
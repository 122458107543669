import { Box, Container, FormControl, InputLabel, MenuItem, Paper, Select, styled, TextField, Toolbar, Typography } from "@mui/material";
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { FC, useEffect, useState } from "react";
import { ContentBackground } from "../../components/ContentBackground/ContentBackground";
import EmptyState from "../../components/EmptyState/EmptyState";
import { useEvents } from "../../hooks";
import { EventPageCard } from "./EventPageCard";

const FilterControlContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: theme.spacing(1),
  '& .MuiFormControl-root': {
    minWidth: '10rem',
    backgroundColor: theme.palette.background.secondary,
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    }
  }
}))

export const EventsPage: FC = () => {
  const { events, eventTypes } = useEvents();
  const [filters, setFilters] = useState<{ eventTypeId: number, startDate: Moment | null, endDate: Moment | null }>({
    eventTypeId: 0,
    startDate: null,
    endDate: null,
  });
  
  const [filterList, setFilteredList] = useState(events);

  useEffect(() => {
    const { eventTypeId, startDate, endDate } = filters;
    const [hasTypeFilter, hasStartDateFilter, hasEndDateFilter] = [!!eventTypeId, !!startDate, !!endDate];
    const hasFilter = hasTypeFilter || hasStartDateFilter || hasEndDateFilter;
    
    const list = !hasFilter ? events : events.filter(e => {
      const isEventType = !hasTypeFilter || e.event_type === filters.eventTypeId;
      const isAfterStateDate = !hasStartDateFilter || moment(e.start_time).isAfter(startDate);
      const isBeforeEndDate = !hasEndDateFilter || moment(e.start_time).isBefore(endDate);
      return isEventType && isAfterStateDate && isBeforeEndDate;
    })
    
    setFilteredList(list);
  }, [filters, events])

  return (
    <Container sx={{ py: 2 }}>
      <ContentBackground>
        <Toolbar sx={{ mb: 3, justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5">Events</Typography>
          <FilterControlContainer>
            <FormControl size="small">
              <InputLabel>Event Types</InputLabel>
              <Select
                value={filters.eventTypeId}
                onChange={e => setFilters(f => ({ ...f, eventTypeId: +e.target.value }))}
                label="Event Types"
              >
                <MenuItem value={0}>All</MenuItem>
                {eventTypes.map(et => <MenuItem key={et.id} value={et.id}>{et.name}</MenuItem>)}
              </Select>
            </FormControl>
            <DesktopDatePicker
              label="Start Date"
              value={filters.startDate}
              onChange={(startDate: Moment | null) => setFilters(f => ({ ...f, startDate }))}
              renderInput={(params) => <TextField helperText="" size="small" {...params} />}
            />
            <DesktopDatePicker
              label="End Date"
              value={filters.endDate}
              onChange={(endDate: Moment | null) => setFilters(f => ({ ...f, endDate }))}
              renderInput={(params) => <TextField helperText="" size="small" {...params} />}
            />
          </FilterControlContainer>
        </Toolbar>
        <Paper sx={{ mx: 4, mb: 3 }}>
          { filterList.length > 0 ?
            filterList.map(e => <EventPageCard key={e.id} event={e} />)
          :
            <EmptyState message={"No events"}/>
          }
        </Paper>
      </ContentBackground>
    </Container>
  )
}
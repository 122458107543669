import { Box, MenuItem } from '@mui/material';
import { ErrorMessage, Field, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import { useCallback } from "react";
import { apiClient } from '../../../../api/apiClient';
import { useClients, useInit } from '../../../../hooks';
import { NewClientFormValues } from './newClientFormSchema';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function HouseholdFormFields() {
  const {values, validateForm} = useFormikContext<NewClientFormValues>();

  const {addressStates, setAddressStates} = useClients();

  const init = useCallback(async () => {
    validateForm();
    if (addressStates == null) {
      const states = await apiClient.get("/customers/states");
      setAddressStates(states);
    }
  }, [addressStates, validateForm, setAddressStates])

  useInit(init);

  return (
      <>
        <ErrorMessage  name="error"/>
        <Field name="householdName" required label="Household Name" component={TextField} fullWidth/>
        <Field name="streetAddress1" required label="Street Address 1" component={TextField} sx={{width: 'calc(50% - 8px)', mr:2}}/>
        <Field name="streetAddress2" label="Street Address 2" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
        <Box sx={{display: 'flex', justifyContent: 'space-around'}}>
          <Field name="city" required label="City" component={TextField} sx={{width: 'calc(40% - 8px)', mr: 2}} />
            <Field 
              formControl={{ required: true }}
              as='select' 
              name="stateId" 
              label="State" 
              component={Select} 
              sx={{ width: '200px',}}
              MenuProps={MenuProps}
              value={values.stateId ?? ""}
              >
                {addressStates != null && addressStates.map(state => (
                  <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
                  ))}
            </Field>
            <Field name="zipCode" required label="Zip Code" component={TextField} sx={{ml: 2,width: 'calc(40% - 8px)'}}/>
        </Box>
      </>
  );
}
import React, { createContext, FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { CapitalCall, Distribution, GetInvestmentCompanyDocumentsResp, GetInvestmentResp, InterestedClient, InvestedClient, Investor, ProspectsInterestedClient, RegDInterestedClient, WiringInstructions } from "../api/payloads/investments.payload";
import { createObjectMap } from "../utility/array.util";

export type Investment = GetInvestmentResp[number];

export type InvestmentsContextValue = {
	distributions: Distribution[],
	distributionsMap: Record<Distribution['id'], Distribution>,
	investments: Investment[],
	investors: Investor[],
  investmentCompanyDocuments: GetInvestmentCompanyDocumentsResp | undefined,
  wiringInstructions: WiringInstructions | undefined,
  interestedList: InterestedClient[],
  prospectsList: ProspectsInterestedClient[],
  regDInterestedList: RegDInterestedClient[],
  investedList: InvestedClient[],
  investmentsMap: Record<Investment['id'], Investment>,
  capitalCalls: CapitalCall[],
  capitalCallMap: Record<Investment['id'], CapitalCall>,
	setDistributions: React.Dispatch<React.SetStateAction<Distribution[]>>,
  setInvestments: React.Dispatch<React.SetStateAction<Investment[]>>,
  setInvestors: React.Dispatch<React.SetStateAction<Investor[]>>,
  setInvestedList: React.Dispatch<React.SetStateAction<InvestedClient[]>>,
  setInterestedList: React.Dispatch<React.SetStateAction<InterestedClient[]>>,
  setProspectsList: React.Dispatch<React.SetStateAction<ProspectsInterestedClient[]>>,
  setRegDInterestedList: React.Dispatch<React.SetStateAction<RegDInterestedClient[]>>,
  setCapitalCalls: React.Dispatch<React.SetStateAction<CapitalCall[]>>,
  setWiringInstructions: React.Dispatch<React.SetStateAction<WiringInstructions | undefined>>,
  setInvestmentCompanyDocuments: React.Dispatch<React.SetStateAction<GetInvestmentCompanyDocumentsResp | undefined>>,
}

export const InvestmentsContext = createContext(undefined as unknown as InvestmentsContextValue);

export const InvestmentsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [distributions, setDistributions] = useState<InvestmentsContextValue['distributions']>([]);
  const [distributionsMap, setDistributionsMap] = useState<InvestmentsContextValue['distributionsMap']>({});
  const [investments, setInvestments] = useState<InvestmentsContextValue['investments']>([]);
  const [investmentsMap, setInvestmentsMap] = useState<InvestmentsContextValue['investmentsMap']>({});
  const [investors, setInvestors] = useState<InvestmentsContextValue['investors']>([]);
  const [interestedList, setInterestedList] = useState<InvestmentsContextValue['interestedList']>([]);
  const [prospectsList, setProspectsList] = useState<InvestmentsContextValue['prospectsList']>([]);
  const [regDInterestedList, setRegDInterestedList] = useState<InvestmentsContextValue['regDInterestedList']>([]);
  const [investedList, setInvestedList] = useState<InvestmentsContextValue['investedList']>([]); 
  const [capitalCalls, setCapitalCalls] = useState<InvestmentsContextValue['capitalCalls']>([]);
  const [capitalCallMap, setCapitalCallMap] = useState<InvestmentsContextValue['capitalCallMap']>({});
  const [wiringInstructions, setWiringInstructions] = useState<InvestmentsContextValue['wiringInstructions']>();
  const [investmentCompanyDocuments, setInvestmentCompanyDocuments] = useState<InvestmentsContextValue['investmentCompanyDocuments']>();
 
  useEffect(() => {
    const map = createObjectMap(distributions, 'id');
    setDistributionsMap(map);
  }, [distributions])

  useEffect(() => {
    const map = createObjectMap(investments, 'id');
    setInvestmentsMap(map);
  }, [investments])
  
  useEffect(() => {
    const map = createObjectMap(capitalCalls, 'id');
    setCapitalCallMap(map);
  }, [capitalCalls])

  const value = useMemo(() => ({
    distributions,
    distributionsMap,
    investments,
    investors,
    setDistributions,
    setInvestments,
    setInvestors,
    investmentsMap,
    capitalCalls,
    setCapitalCalls,
    capitalCallMap,
    interestedList,
    setInterestedList,
    regDInterestedList,
    setRegDInterestedList,
    prospectsList,
    setProspectsList,
    investmentCompanyDocuments,
    investedList,
    setInvestedList,
    wiringInstructions,
    setWiringInstructions,
    setInvestmentCompanyDocuments
  }), [distributions, distributionsMap, investments, investors, investmentsMap, capitalCalls, setCapitalCalls, capitalCallMap, interestedList, setInterestedList, regDInterestedList, setRegDInterestedList, investmentCompanyDocuments, investedList , setInvestedList, wiringInstructions, setWiringInstructions, prospectsList, setProspectsList]);

  return (
    <InvestmentsContext.Provider value={value}>
      {children}
    </InvestmentsContext.Provider>
  )
}
import { Tab } from "@mui/material";
import { Link } from "react-router-dom";

type LinkTabProps = {
  label: string;
  to: string;
  value?: string
} & {[k: string]: any} //TODO: Replace this hacky intersection with actual MuiTab prop type 

export const LinkTab = ({to, ...rest}: LinkTabProps) => {
  
  return (
    <Tab
      component={Link}
      to={to}
      {...rest}
    />
  );
}
import { Box, DialogTitle, Typography } from "@mui/material"
import { Formik } from "formik"
import { FC, useMemo, useState } from "react"
import { apiClient } from "../../../../api/apiClient"
import { PostCustomerAccountReq } from "../../../../api/payloads/customer.payload"
import { CornerCloseButton } from "../../../../components/Buttons"
import { ErrorToast } from "../../../../components/ErrorToast"
import { useUI } from "../../../../hooks"
import { AccountType } from "../../../../utility/constants"
import { isAxiosError } from "../../../../utility/type-guards/";
import { FormikSubmitFn } from "../../../../utility/types"
import { AccountFormValues, accountFormSchema, initialAccountValues } from "../../NewClientPage/Forms/newClientFormSchema"
import AddAccountFormFields from "./AddAccountFormFields"

type AddAccountDialogProps = {
  onClose: (result?: boolean) => void,
  clientId: number,
}

export const AddAccountDialog: FC<AddAccountDialogProps> = ({onClose, clientId}) => {
  const { setError, setSuccess } = useUI();
  const [isLoading, setLoading] = useState(false);

  const accountInitialValues = useMemo(() => ({...initialAccountValues}), [])

  const handleAccountSubmit: FormikSubmitFn<AccountFormValues> = async (values) => {
    if (clientId == null)  {
      return;
    }
    setLoading(true);
    try {
      const {clientAccountMembers, accountType, ...rest} = values

      const createAccount: PostCustomerAccountReq  = {
        accountData: {
          account_name: rest.accountName,
          account_type_id: accountType,
          customer_id: clientId
        },
        owners: [],
      }

      if (values.isCustodied) {
        createAccount.accountData.account_number = rest.accountNumber;
        createAccount.accountData.custodian_id = Number(rest.custodian);

        switch (accountType) {
          case AccountType.Individual:
            createAccount.accountData.ssn_contact_id = Number(rest.clientAccountMember);
            createAccount.owners?.push({ family_member_id: Number(rest.clientAccountMember), ownership: 100 });
            break;
          case AccountType.Joint:
            clientAccountMembers.forEach(m => {
              createAccount.owners?.push({ family_member_id: Number(m), ownership: 50 });
              });
            createAccount.accountData.ssn_contact_id = Number(rest.ssnContactId);
            break;
          case AccountType.Entity:
            createAccount.accountData.customer_entity_id = Number(rest.entityAccount);
            break;
        }
      } 
      else {     
        switch (accountType) {
          case AccountType.IRA:
            createAccount.accountData.custodian_id = Number(rest.custodian);
            createAccount.accountData.account_number = rest.accountNumber;
            createAccount.accountData.ira_type_id = rest.iraTypeId as number;
            createAccount.owners?.push({family_member_id: Number(rest.beneficiary), ownership: 100})
            break;
          case AccountType.Individual:
            createAccount.owners?.push({family_member_id: Number(rest.clientAccountMember), ownership: 100})
            createAccount.accountData.ssn_contact_id = Number(rest.clientAccountMember)
            break;
          case AccountType.Joint:
            clientAccountMembers.forEach(m => createAccount.owners?.push({ family_member_id: Number(m), ownership: 50 }));
            createAccount.accountData.ssn_contact_id = Number(rest.ssnContactId);
            break;
          case AccountType.Entity:
            createAccount.accountData.customer_entity_id = Number(rest.entityAccount);
            break;
          }
       } 

      const newAccountResp = await apiClient.post("/customers/accounts", {data: createAccount});

      setLoading(false);
      setSuccess("Successfully added account");
      onClose(true);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        const isInternalError = error.response?.status === 500;
        const message = error.response?.data?.message ?? error.message;
        setError(<ErrorToast showContactEmail={isInternalError}>{message}</ErrorToast>)
      } else {
        console.log(error);
        setError(<ErrorToast showContactEmail>An unexpected error occurred.</ErrorToast>)
      }
      setLoading(false)
    }
  };
  
  return (
    <>
      <DialogTitle sx={{pt: 5}}>
        <Box display="flex" justifyContent={"space-between"} alignItems="center">
          <Typography variant="h5">Add New Account</Typography>
        </Box>
        <CornerCloseButton onClick={() => onClose()}></CornerCloseButton>
      </DialogTitle>
      <Formik 
        initialValues={accountInitialValues} 
        validationSchema={accountFormSchema} 
        onSubmit={handleAccountSubmit}
      >
        <AddAccountFormFields isLoading={isLoading}/>
      </Formik>
    </>
  )
}
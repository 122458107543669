import React from 'react';
import { Drawer, Typography, Box, Divider, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsList from './NotificationsList';
import { useNotifications } from '../../context/NotificationsContext';
import { useUI } from '../../hooks';

type AllNotificationsDrawerProps = {
  open: boolean;
  onClose: () => void;
};

const AllNotificationsDrawer: React.FC<AllNotificationsDrawerProps> = ({ open, onClose }) => {
  const { notifications, clearNotifications } = useNotifications();
  const {setError} = useUI();

  const handleClearAllNotifications = async () => {
    const result = await clearNotifications();
    if (result instanceof Error) {
      console.error(result.message);
      setError(result.message);
    } 
  };


  return (
    <Drawer anchor="right" open={open} onClose={onClose} PaperProps={{ sx: { width: 400, padding: 2 } }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 2 }}>
        <Typography variant="h6" fontWeight="bold">
          All Notifications
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Divider sx={{ marginBottom: 2 }} />

      <NotificationsList notificationsData={notifications} onNotificationClick={onClose} />

      <Divider sx={{ marginY: 2 }} />

      {notifications.length > 0 && (
        <Button
          variant="outlined"
          color="error"
          fullWidth
          onClick={handleClearAllNotifications}
          sx={{ textTransform: 'none' }}
        >
          Clear all notifications
        </Button>
      )}
    </Drawer>
  );
};

export default AllNotificationsDrawer;

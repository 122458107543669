import { Grid, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { FC } from "react";
import { useSubDocs } from "../../../../../hooks/ContextHooks/useSubDocs";
import { AccountType } from "../../../../../utility/constants";
import { SubDocsReviewInfoCard } from "../SubDocsReview";
import { AMLVerificationFormValues } from "../subDocsFormSchema";

export const AMLVerificationReview: FC = () => {
  const { subDocsAccount} = useSubDocs();
  const { values } = 
  useFormikContext<AMLVerificationFormValues>();
  return (
    <>
      { subDocsAccount?.account_type_id === AccountType.Entity &&
      <>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{mb: 2}}>Documents</Typography>
        </Grid>
        {values.formationDocuments && <SubDocsReviewInfoCard span={4} title="Formation Document" value={values.formationDocuments?.documentName ?? ""} />}
        {values.governingDocuments && <SubDocsReviewInfoCard span={4} title="Governing Document" value={values.governingDocuments?.documentName ?? ""} />}
        {subDocsAccount?.trust === true && values.trustDocuments && <SubDocsReviewInfoCard span={4} title="Trust Documents" value={values.trustDocuments?.documentName ?? ""} />}
      </>
      }
      { values.supportingDocuments.length > 0 &&
      <>
        {
         values.supportingDocuments.map((document, index) => {
            if (document.documentName) {
              return (<SubDocsReviewInfoCard key={index} span={4} title={`Supporting Document ${index + 1}`} value={document.documentName ?? ""} />)
            }
            return null
          })
        }
      </>
      }
      <Grid item xs={12}>
        <Typography variant="h6" sx={{mb: 2}}>Identification</Typography>
      </Grid>
      {values.ownerIdDocuments.map((ownerData, index) => {
        return (
          <Grid key={index} item xs={12}>
            <Grid container spacing={2}>
              <SubDocsReviewInfoCard span={4} title="Owner" value={ownerData.name} />
              <SubDocsReviewInfoCard span={4} title="Identification File Name" value={ownerData.idImageFile?.name ?? ""} />
              <SubDocsReviewInfoCard span={4} title="Expiration Date" value={ownerData.expirationDate?.format('MMMM Do, YYYY') ?? ""} />
            </Grid>
          </Grid>
        )
      })}

    </>
  )
}
import { Grid, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import { useClients } from "../../../../../hooks";
import { useSubDocs } from "../../../../../hooks/ContextHooks/useSubDocs";
import { AccountType, ContactNumberTypes } from "../../../../../utility/constants";
import { SubDocsReviewInfoCard, SubDocsReviewInfoListCard } from "../SubDocsReview";
import { InvestorQuestionnaireFormValues } from "../subDocsFormSchema";

export const InvestorQuestionnaireReview: FC = () => {
  const { subDocsClient, subDocsAccount} = useSubDocs();
  const { setFieldValue, values } = useFormikContext<InvestorQuestionnaireFormValues>();
  const address = subDocsClient!.address;
  const [ownerEmails, setOwnerEmails] = useState<string[]>([])
  const [ownerPhoneNumbers, setOwnerPhoneNumbers] = useState<string[]>([])
  const [formOfSubscriber, setFormOfSubscriber] = useState<string>("")
  const {addressStates, } = useClients();

  const getStateName = useCallback((stateId: number | "") => {
    const currentState = addressStates?.find(state => state.id ===  stateId) ?? null;
    return currentState?.name ?? ""
  },[addressStates,])

  useEffect(() => {
    const emailString: string[] = []
    const phoneString: string[] = []

    setFormOfSubscriber(subDocsAccount?.account_type ?? "")

    if (subDocsAccount?.account_type_id === AccountType.Entity) {
      setFormOfSubscriber(subDocsAccount.customer_entity_type ?? "")
      setFieldValue("entityAccount", true)
    } else {
      setFieldValue("entityAccount", false)
    }

    subDocsAccount?.contact_owners.forEach((owner) => {
      emailString.push(owner.email) 
      owner.phone_numbers.forEach(phoneNumber => {
        if (phoneNumber.contact_number_type_id === ContactNumberTypes.Mobile) {
          phoneString.push(phoneNumber.number)
        }
      })
    })
    setOwnerEmails(emailString)
    setOwnerPhoneNumbers(phoneString)
  }, [subDocsAccount,setFieldValue, setOwnerEmails, setOwnerPhoneNumbers])

  return (
    <>
      <SubDocsReviewInfoCard span={4} title="Name" value={subDocsAccount?.account_name ?? ""} />
      <SubDocsReviewInfoCard title="Form of Subscriber" value={formOfSubscriber} />
      <SubDocsReviewInfoListCard span={4} title="Email" values={ownerEmails}/> 
      <SubDocsReviewInfoListCard title="Phone Number" values={ownerPhoneNumbers} />
      <SubDocsReviewInfoCard span={6} title="Street Address" value={address?.street_address_1 ?? ""} />
      <SubDocsReviewInfoCard span={6} title="Street Address 2" value={address?.street_address_2 ?? ""} />
      <SubDocsReviewInfoCard span={4} title="City" value={address?.city ?? ""} />
      <SubDocsReviewInfoCard span={2} title="State" value={address?.state ?? ""} />
      <SubDocsReviewInfoCard span={4} title="Zip" value={address?.zip_code ?? ""} />
      <Grid item xs={12}>
        <Typography variant="h6" >Mailing Address:</Typography>
      </Grid>
      <SubDocsReviewInfoCard span={6} title="Street Address" value={values.mailingAddress.street1 ?? ""} />
      <SubDocsReviewInfoCard span={6} title="Street Address 2" value={values.mailingAddress.street2 ?? ""} />
      <SubDocsReviewInfoCard span={4} title="City" value={values.mailingAddress.city ?? ""} />
      <SubDocsReviewInfoCard span={2} title="State" value={getStateName(values.mailingAddress.stateId) ?? ""} />
      <SubDocsReviewInfoCard span={4} title="Zip" value={values.mailingAddress.zip ?? ""} />
      {subDocsAccount?.account_type_id === AccountType.Entity &&
        <SubDocsReviewInfoCard span={12} title='Is the Subscriber a "Funds of Funds"' value={values.fundSubscriber === 1 ? 'yes' : 'no' } />
      }
      {subDocsAccount?.account_type_id === AccountType.Entity &&
      <Grid item xs={12}>
        <Typography variant="h6" sx={{mb: 2}}>Authorized Representatives:</Typography>
        {
          values.authorizedRepresentatives.map((authorizedRep, index) => {
            const client = subDocsClient?.contacts.find((contact) => +contact.id === +authorizedRep.representativeContactId)
            return (
            <Grid key={index} container spacing={2}>
              <SubDocsReviewInfoCard span={3} title='Name' value={client ? `${client.first_name} ${client.last_name}` : ""} />
              <SubDocsReviewInfoCard span={3} title='Address' value={authorizedRep.representativeAddress} />
              <SubDocsReviewInfoCard span={3} title='SSN' value={authorizedRep.ssn} />
              <SubDocsReviewInfoCard span={3} title='DOB' value={authorizedRep?.dob?.format('MMMM Do, YYYY') ?? ""} />
            </Grid>
          )})
        }
      </Grid>
      }
      {subDocsAccount?.account_type_id === AccountType.Entity &&
      <Grid item xs={12}>
        <Typography variant="h6"  sx={{mb: 2}}>Beneficial Owners:</Typography>
        {
          values.optsOutBeneficialOwners === true ? 
          <Typography>
            None
          </Typography>
          :
          values.beneficialOwners.map((beneficialOwner, index) =>  (
            <Grid key={index} container spacing={2}>
              <SubDocsReviewInfoCard span={3} title='Name' value={beneficialOwner.ownerName} />
              <SubDocsReviewInfoCard span={3}  title='Address' value={beneficialOwner.ownerAddress} />
              <SubDocsReviewInfoCard span={3}  title='SSN' value={beneficialOwner.ssn} />
              <SubDocsReviewInfoCard span={3}  title='DOB' value={beneficialOwner?.dob?.format('MMMM Do, YYYY') ?? ""} />
            </Grid>
          ))
        }
      </Grid>
      }
    </>
  )
}
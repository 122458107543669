import React, { FC, useEffect, useState } from 'react';
import { Checkbox, FormGroup, FormControlLabel, Box, Typography, Accordion, AccordionDetails, AccordionSummary, Paper, IconButton, useTheme} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import gray from '@mui/material/colors/grey';
import lightBlue from '@mui/material/colors/lightBlue';
import { useClientDetails} from '../../../../../../hooks';
import { ErrorMessage, useFormikContext } from 'formik';
import { BdcPosition, Investment } from '../../../../../../api/payloads/customer.payload';
import { InvestmentsByAccountDataSource, SelectionState, getInvestmentKey } from '../ClientInstructionsStepper';
import { NewInstructionDisclaimer } from '../NewInstructionDisclaimer';

type AssociatedAccountsAndInvestmentsProps = {
  linkableInvestmentsByAccount: InvestmentsByAccountDataSource
  selected: SelectionState;
  setSelected: (selected: SelectionState) => void;
  activeStep: number;
};

export const AssociatedAccountsAndInvestments: FC<AssociatedAccountsAndInvestmentsProps> = ({ linkableInvestmentsByAccount, selected, setSelected }) => {
  const { accountsMap } = useClientDetails();
  const {setFieldValue} = useFormikContext();
  const [expandedPanels, setExpandedPanels] = useState<SelectionState>({});
  const theme = useTheme();
  const hasNonCustodianAccounts = Object.values(accountsMap).some(account => account.custodian_id === null);

  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const newExpanded: SelectionState = {};
    const noAccounts = Object.keys(linkableInvestmentsByAccount).length === 0;

    Object.keys(linkableInvestmentsByAccount).forEach(accountId => {
      newExpanded[accountId] = true;
    });

    setExpandedPanels(newExpanded);
    setSelectAll(false)
    if (noAccounts) {
      setSelected({});
      setFieldValue('selected', {});
    }
  }, [linkableInvestmentsByAccount, setExpandedPanels, setSelected, setFieldValue]);
  
  const handleAccountSelect = (event: React.ChangeEvent<HTMLInputElement>, accountId: string) => {
    const isChecked = event.target.checked;
    const updatedSelected = { ...selected, [accountId]: isChecked };
    const investments = linkableInvestmentsByAccount[accountId];
  
    investments.forEach(investment => {
      const uniqueKey = getInvestmentKey(investment);
      updatedSelected[uniqueKey] = isChecked;
    });
  
    //TODO: Decide between using either formik state or our self-managed component states. Using both is confusing.
    setSelected(updatedSelected);
    setFieldValue('selected', updatedSelected);
  };

  const handleSelectAll = () => {
    const newSelected: SelectionState = {};

    Object.entries(linkableInvestmentsByAccount).forEach(([accountId, investments]) => {
      newSelected[accountId] = !selectAll;
      investments.forEach(investment => {
        const uniqueKey = getInvestmentKey(investment);
        newSelected[uniqueKey] = !selectAll;
      });
    });

    setSelected(newSelected);
    setFieldValue('selected', newSelected);
    setSelectAll(!selectAll);
  };
  
  const handleInvestmentSelect = (event: React.ChangeEvent<HTMLInputElement>, investment: BdcPosition | Investment) => {
    const isChecked = event.target.checked;
    const uniqueKey = getInvestmentKey(investment);
    const updatedSelected = { ...selected, [uniqueKey]: isChecked };
    const accountInvestments = linkableInvestmentsByAccount[investment.account_id];

    const areAllInvestmentsChecked = accountInvestments.every(
      inv => updatedSelected[getInvestmentKey(inv)]
    );
    
    const areAnyInvestmentsChecked = areAllInvestmentsChecked || accountInvestments.some(
      inv => updatedSelected[getInvestmentKey(inv)]
    );
  
    if (areAllInvestmentsChecked) {
      updatedSelected[investment.account_id] = true;
    } else {
      updatedSelected[investment.account_id] = areAnyInvestmentsChecked;
    }
    setSelected(updatedSelected);
    setFieldValue('selected', updatedSelected);
  };

  const handleAccordionToggle = (accountId: string) => {
    const isExpanded = expandedPanels[accountId];
    const newExpanded = { ...expandedPanels, [accountId]: !isExpanded };
    setExpandedPanels(newExpanded);
  };

  return (
    <Paper sx={{ p: 3, mt: 3, mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        Select Associated Accounts and Investments
      </Typography>
      <Typography variant="body2" gutterBottom sx={{mt:-1, color: gray[700]}}>
        Choose the account(s) and investment(s) you would like to link to your new distribution instructions.
      </Typography>
      <NewInstructionDisclaimer/>
      <Box sx={{ display: 'flex', alignItems: 'center', borderRadius: '2px', pl: 2, pr: 2, backgroundColor: gray[200] }}>
      <FormControlLabel
        control={<Checkbox checked={selectAll}
        onChange={handleSelectAll}
        disabled={!hasNonCustodianAccounts}
        />}
        label={selectAll ? "Unselect All" : "Select All"}
        sx={{ color: lightBlue[700] }}
      />
        <Typography variant="subtitle2" component="span" sx={{ flexGrow: 1, textAlign: 'left', ml: 5 }}>
          Accounts
        </Typography>
      </Box>
      <FormGroup>
        {hasNonCustodianAccounts ? (
          Object.entries(linkableInvestmentsByAccount).filter(([accountId]) => {
            const accountDetails = accountsMap[+accountId];
            return accountDetails && accountDetails.custodian_id === null;
          })
          .map(([account, investments]) => (
            <Accordion
              key={account}
              expanded={expandedPanels[account] || false}
              square={true}
              sx={{
                '&:before': {
                  display: 'none',
                },
                '&.Mui-expanded': {
                  margin: 0,
                },
                '&:not(:last-child)': {
                  borderBottom: 0,
                },
                '&:not(:first-of-type)': {
                  marginTop: 0, 
                },
                '& .MuiAccordionSummary-root.MuiButtonBase-root': {
                  cursor: 'default',
                  '&:hover': {
                    cursor: 'default',
                  }
                },
                boxShadow: 'none',
              }}
            >
              <AccordionSummary
                expandIcon={<IconButton onClick={e => handleAccordionToggle(account)}><ExpandMoreIcon /></IconButton>}
                aria-controls={`panel-${account}-content`}
                id={`panel-${account}-header`}
                sx={{ alignItems: 'center',
                  '& .MuiAccordionSummary-content': {
                    margin: 0,
                    alignItems: 'center',
                  },
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    order: -1,
                    marginRight: 13.5,
                  },
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selected[account] || false}
                      onChange={(e) => handleAccountSelect(e, account)}
                    />
                  }
                  label={
                    <Typography variant="body2" sx={{ color: 'black' }}>
                      {accountsMap[+account].account_name}
                    </Typography>
                  }
                  sx={{ marginLeft: '8px', marginRight: 0, alignItems: 'center'}}
                />
              </AccordionSummary>
              <AccordionDetails sx={{ flexDirection: 'column', p:0 }}>
                {investments.length > 0 ? (
                  <Typography variant="subtitle2" gutterBottom component="div" sx={{ pl: 30, pb:0.5, pt:0.5, fontSize: 15, backgroundColor: gray[200]}}>
                    Investments
                  </Typography>
                ) : (
                  <Typography variant="body2" sx={{ pl: 30, pb: 0.5, pt: 0.5, fontStyle: 'normal', backgroundColor: gray[200] }}>
                    No investments
                  </Typography>
                )}
                {investments.map((investment) => (
                  <Box key={getInvestmentKey(investment)} sx={{pl: 30, pb: 0.5, '&:not(:last-child)': {borderBottom: '1px solid #e0e0e0'} }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selected[getInvestmentKey(investment)] || false}
                          onChange={(e) => handleInvestmentSelect(e, investment)}
                        />
                      }
                      label={investment.investment_name}
                    />
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Accordion expanded={true} square={true}>
            <AccordionSummary>
              <Typography sx={{ textAlign: 'center', width: '100%', color: theme.palette.text.primary}}>No Accounts on File</Typography>
            </AccordionSummary>
          </Accordion>
        )}
      </FormGroup>
      <ErrorMessage name="selected" render={msg => (<Box dangerouslySetInnerHTML={{ __html: msg.replace(/\n/g, '<br/>') }} sx={{ color: "error.main" }}/>)}/>
    </Paper>
  );
};
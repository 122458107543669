import { Box, CircularProgress, Paper, TableContainer, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { InvestmentCompanyDocument } from "../../../../api/payloads";
import { ColumnDef, DataSourceTable } from "../../../../components/DataSourceTable";
import { DocumentTypes } from "../../../../utility/constants";
import { camelCaseToSpaceSeparated } from "../../../../utility/string.util";
import { DocumentDownloadButton } from "./DocumentDownloadButton";
import { useInvestments } from "../../../../hooks/ContextHooks/useInvestments";

function getMissingDocumentRow(documentType: DocumentTypes): InvestmentCompanyDocument {
  const docTypeEnumName = DocumentTypes[documentType];
  return {
    id: `missing-${docTypeEnumName}` as any, // just need a unique id that's an invalid document id,
    document_type_id: documentType,
    document_type_name: camelCaseToSpaceSeparated(DocumentTypes[documentType]),
    file_name: "",
    description: "Not Available",
  }
}

const documentTableColumnDef: ColumnDef<InvestmentCompanyDocument & { action: never }> = [
  { 
    id: "document_type_name", 
    label: "Document Type" 
  },
  { 
    id: "description", 
    label: "Document Title", 
    displayFn: (v, r) => v || r.file_name, 
  },
  { 
    id: "action", 
    label: "Action", 
    displayFn: (_, r) => isNaN(r.id) ? "" : (
      <DocumentDownloadButton documentId={r.id} color='info' size='small' sx={{minWidth: 80}}>
        Download
      </DocumentDownloadButton> 
    )
  },
];

export const DocumentsTab: FC = () => {
  const { investmentCompanyDocuments: documents = null } = useInvestments();
  const dataSource = useMemo(() => {
    return [
      documents?.privatePlacementMemorandum ?? getMissingDocumentRow(DocumentTypes.PrivatePlacementMemorandum),
      documents?.governingDocuments ?? getMissingDocumentRow(DocumentTypes.GoverningDocuments),
      documents?.subscriptionAgreements ?? getMissingDocumentRow(DocumentTypes.SubscriptionAgreements),
    ];
  }, [documents])

  // TODO: Set up routing for the Opportunity tab components, and then 
  // look into using React 'suspense' with React Router loader. May lead to a more generalized solution
  if (!documents) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center'>
        <CircularProgress size={60} />
      </Box>
    )
  }

  return (
    <Box component="section" mb={3}>
      <Box sx={{ display: "flex", justifyContent: "space-between", pd: 2 }}>
        <Typography variant="h5" mb={1}>Documents</Typography>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          mb: 1,
          '& ': {
            'th, td': {
              width: '33%',
            },
            '.col-header-action, & .col-action': {
              width: '10%'
            }
          }
        }}
      >
        <DataSourceTable columnDef={documentTableColumnDef} dataSource={dataSource} />
      </TableContainer>
    </Box>
  );
};

import { ButtonProps } from "@mui/material";
import { ReactNode, useCallback } from "react";
import { apiClient } from "../../../../api/apiClient";
import { DocumentDownloadButton } from "./DocumentDownloadButton";
import { ApplicationSource, SubDocActions } from "../../../../utility/constants";


interface SubDocDocumentHandlerProps extends ButtonProps {
  documentId: number;
  subDocId: number;
  isAccreditationCompleted: boolean;
  icon?: ReactNode;
}

const SubDocDownloadHandler: React.FC<SubDocDocumentHandlerProps> = ({ 
  documentId, 
  subDocId, 
  isAccreditationCompleted, 
  icon, 
  ...props 
}) => {
  const handleDownloadLog = useCallback(async () => {
    try {
      const logData = { 
        sub_doc_id: subDocId, 
        action: SubDocActions.SubscriptionDocumentDownloaded, 
        source_id: ApplicationSource.AdvisorVision,
      };
      await apiClient.post('/investors/sub-doc/:p0/log', { routeParams: [subDocId],data: logData });
    } catch (error) {
      console.error("Failed to log document download action:", error);
    }
  }, [subDocId]);

  return (
    <DocumentDownloadButton
      documentId={documentId}
      onDownload={handleDownloadLog}
      icon={icon}
      disabled={!isAccreditationCompleted}
      {...props}
    />
  );
};

export default SubDocDownloadHandler;

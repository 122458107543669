import { AddCircle, Delete } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, Tooltip, Typography } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { ErrorMessage, Field, FieldArray, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import { useEffect, useState } from "react";
import { Accreditation, Contact, EntityType } from "../../../../api/payloads/customer.payload";
import { CustomerEntityTypes, GrantorStrings, RevocableStrings } from "../../../../utility/constants";
import DocumentFields from "./DocumentFields";
import { EntityDocumentFormValues, initialEntityOwnerValue } from "./newClientFormSchema";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "100px",
    },
  },
};

type EntityFormProps = {
  accreditationValues: Accreditation[],
  entityTypeValues: EntityType[],
  newMembers: Contact[],
}

export default function EntityForm({accreditationValues, entityTypeValues, newMembers}: EntityFormProps) {
  const {values} = useFormikContext<EntityDocumentFormValues>();
  const {entityOwners} = values
  const [percentageError, setPercentageError] = useState<string>("")
  const [selectedOwners, setSelectedOwners] = useState(new Set())

  const members = newMembers
 
  useEffect(() => {
    const entityOwnersMap = entityOwners.map((owner) => owner.id.toString()).filter((id) => id !== "")
    setSelectedOwners(new Set(entityOwnersMap))

    const sum = entityOwners.reduce((sum, entityOwners) => {
      if (entityOwners.percentage != null) return sum + Number(entityOwners.percentage)
      return sum
    },0)

    if (sum !== 100) {
      setPercentageError("Percentage needs to total to 100%")
    } else {
      setPercentageError("")
    }
  },[members, entityOwners])

  return (
      <>
        <ErrorMessage  name="error"/>
        <Box>
          <ErrorMessage  name="error"/>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Field name="entityName" label="Entity Name" required component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
            <Box sx={{width: 'calc(50% - 8px)',alignItems: 'flex-start',display: 'flex', flexDirection: 'column'}}>
              <FieldArray name="entityOwners">
                {({ form, remove, push, replace }) => (
                  <Box sx={{position: 'relative', display:'flex', flexDirection: 'column', width: '100%'}}>
                  {percentageError !== "" && <Typography sx={{color: "red", fontSize: 12, position: 'absolute', top: -22}}>{percentageError}</Typography>}
                  {values.entityOwners.map((entityOwner, index) => (
                    <Box  key={index} sx={{display: 'flex', width: '100%'}}>
                      <Field
                        formControl={{  required: true, sx:{width: 'calc(65% - 8px)',minWidth: 112, mr: 2,}}}
                        component={Select}
                        label="Owner"
                        MenuProps={MenuProps}
                        renderValue={(memberID: number) => {
                          const currentMember = newMembers.find((member) => (member.id.toString() === memberID.toString()))
                          return <Typography>{currentMember ? `${currentMember.first_name} ${currentMember.last_name}` : ""}</Typography>
                        }}
                        name={`entityOwners[${index}].id`}
                      >
                        {members.map((member) => <MenuItem disabled={selectedOwners.has(member.id.toString())} key={member.id} value={member.id}><option value={member.id}>{`${member.last_name}, ${member.first_name}`}</option></MenuItem>)}
                      </Field>
                      <Field name={`entityOwners[${index}].percentage`} label="Percent" required component={TextField} sx={{width: 'calc(35% - 8px)', mr: 2}} 
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" style={{ outline: "none" }}>
                              %
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box sx={{width: 60, pt: 1}}>
                        {index !== 0 &&
                          <Tooltip title="Delete" >
                            <IconButton aria-label='delete' onClick={() => remove(index)} color='error'><Delete /></IconButton>
                          </Tooltip>}
                      </Box>
                    </Box>
                  )
                  )}
                {selectedOwners.size < members.length && selectedOwners.size === entityOwners.length &&
                  <Box>
                    <Tooltip title="Add Entity Owner">
                      <IconButton
                        color='primary'
                        aria-label='add entity owner'
                        onClick={() => push(initialEntityOwnerValue)}
                        sx={{mb: 2}}
                        >
                        <AddCircle fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  </Box>}
                  </Box>
                )}
              </FieldArray>
            </Box>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Field name="EIN" required label="EIN" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
            <Field
                formControl={{ required: true, sx:{width: 'calc(50% - 8px)'}}}
                component={Select}
                label="Type"
                MenuProps={MenuProps}
                name="entityType"
                renderValue={(entityID: number) => {
                  const currentEntityType = entityTypeValues.find((entityType) => (entityType.id.toString() === entityID.toString()))
                  return <Typography>{currentEntityType ? currentEntityType.name : ""}</Typography>
                }}
              >
                {entityTypeValues.map((a, index )=> <MenuItem key={`entity-type-${index}`} value={a.id}>{a.name}</MenuItem>)}
              </Field>
          </Box>

          {Number(values.entityType) !== 5 ? // S Trust ID
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Field
                formControl={{ required: true, sx:{width: 'calc(50% - 8px)', required: true}}}
                component={Select}
                label="Qualification"
                MenuProps={MenuProps}
                name="qualification"
              >
                {accreditationValues.map(a => <MenuItem key={a.id} value={`${a.id}`}>{a.name}</MenuItem>)}
              </Field>
            {((Number(values.entityType) === CustomerEntityTypes.OtherEmployeeBenefitPlan) || (Number(values.entityType) === CustomerEntityTypes.OtherDescribe)) && (
              <Field
                sx={{width: 'calc(50% - 8px)'}}
                component={TextField}
                label={Number(values.entityType) === CustomerEntityTypes.OtherEmployeeBenefitPlan? 'Name' : 'Description'}
                name="other_info"
                required
              />
            )}
          </Box>
        :
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Field
                formControl={{ required: true, sx:{width: 'calc(33% - 8px)', mr: 2}}}
                component={Select}
                label="Revocable"
                MenuProps={MenuProps}
                name="revocable"
              >
                {(Object.keys(RevocableStrings) as Array<keyof typeof RevocableStrings>).map(a => <MenuItem key={a} value={a}>{RevocableStrings[a]}</MenuItem>)}
              </Field>
            <Field
                formControl={{ required: true, sx:{width: 'calc(33% - 8px)', mr: 2}}}
                component={Select}
                label="Grantor"
                MenuProps={MenuProps}
                name="grantor"
              >
                {(Object.keys(GrantorStrings) as Array<keyof typeof GrantorStrings>).map(a => <MenuItem key={a} value={a}>{GrantorStrings[a]}</MenuItem>)}
              </Field>
            <Field
                formControl={{required: true, sx:{width: 'calc(33% - 8px)'}}}
                component={Select}
                label="Qualification"
                MenuProps={MenuProps}
                name="qualification"
              >
                {accreditationValues.map(a => <MenuItem key={a.id} value={`${a.id}`}>{a.name}</MenuItem>)}
              </Field>
          </Box>
        }
        </Box>
        <Box sx={{pt:2}}>
          <Typography variant="h6">Upload Document</Typography>
          <DocumentFields isTrustSelected={Number(values.entityType) === 5}/>
        </Box>
      </>
  );
}
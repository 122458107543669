import { Card, CardContent, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { UnauthenticatedAppShell } from "../../components/UnathenticatedAppShell/UnauthenticatedAppShell";
import { ResetPasswordRequestForm } from "./forms/ResetPasswordRequestForm";
import { SetPasswordForm } from "./forms/SetPasswordForm";

export const enum ResetPasswordStage {
  ResetRequest = 1,
  SetPassword = 2,
}

export const ResetPassword: FC = (props: unknown) => {
  const [resetStage, setResetStage] = useState(ResetPasswordStage.ResetRequest);
  const [email, setEmail] = useState("");

  return (
    <Card sx={{ p: 4 }} variant="elevation" elevation={1}>
      <CardContent sx={{ '& .MuiTextField-root': { mb: 1 } }}>
        <Typography variant="h5" mb={3}><b>Reset Password</b></Typography>
        {resetStage === ResetPasswordStage.ResetRequest && <ResetPasswordRequestForm {...{ setResetStage, setEmail }} />}
        {resetStage === ResetPasswordStage.SetPassword && <SetPasswordForm {...{ email }} />}
      </CardContent>
    </Card>
  )
}
import React, { createContext, FC, PropsWithChildren, useMemo, useState } from "react";
import { GetAccreditedInvestorQuestionsResp } from "../api/payloads";
import { Account, Custodian, DistributionInstruction, GetCustomerResp, GetDistributionInstructionsByAccountResp } from "../api/payloads/customer.payload";

type SubDocsClient = GetCustomerResp & {id: number, name: string};

export type SubDocsContextValue = {
  custodians: Custodian[],
  setSubDocsAccount: React.Dispatch<React.SetStateAction<Account | null>>,
  subDocsAccount: Account | null,
  subDocsAccountId: number | null,
  subDocsClientId: number | null,
  isInitialSubscription: boolean,
  subDocsTransactionId: number | null,
  subDocsClient: SubDocsClient | null,
  attachmentsToBeDetached: number[],
  distributionInstructionsData: GetDistributionInstructionsByAccountResp | null,
  setIsInitialSubscription: React.Dispatch<React.SetStateAction<boolean>>,
  setSubDocsClient: React.Dispatch<React.SetStateAction<SubDocsClient | null>>,
  accreditedInvestorQuestions: GetAccreditedInvestorQuestionsResp,
  setCustodians: React.Dispatch<React.SetStateAction<Custodian[]>>,
  setSubDocsAccountId: React.Dispatch<React.SetStateAction<number | null>>,
  setSubDocsClientId: React.Dispatch<React.SetStateAction<number | null>>,
  setSubDocsTransactionId: React.Dispatch<React.SetStateAction<number | null>>,
  setAccreditedInvestorQuestions: React.Dispatch<React.SetStateAction<GetAccreditedInvestorQuestionsResp>>
  setAttachmentsToBeDetached: React.Dispatch<React.SetStateAction<number[]>>,
  setDistributionInstructionsData: React.Dispatch<React.SetStateAction<GetDistributionInstructionsByAccountResp | null>>,
  setTransactionAccreditationCompleted: React.Dispatch<React.SetStateAction<boolean>>,
  setUserIsApprovedSigner: React.Dispatch<React.SetStateAction<boolean>>,
  transactionAccreditationCompleted: boolean,
  userIsApprovedSigner: boolean,
  subscriptionAmount: number,
  setSubscriptionAmount: React.Dispatch<React.SetStateAction<number>>,
}

export const SubDocsContext = createContext(undefined as unknown as SubDocsContextValue);

export const SubDocsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  //TODO: Feels like a lot of state variables; consider grouping some of these together, either just here or in response payload as well (e.g. account and client ids, subscription amount, etc)
  // Could also consider simplifying what the 'in-progress' endpoint returns and instead look up the data from other contexts
  const [subDocsAccount, setSubDocsAccount] = useState<SubDocsContextValue['subDocsAccount']>(null);
  const [custodians, setCustodians] = useState<SubDocsContextValue['custodians']>([]);
  const [subDocsAccountId, setSubDocsAccountId] = useState<SubDocsContextValue['subDocsAccountId']>(null);
  const [subDocsTransactionId, setSubDocsTransactionId] = useState<SubDocsContextValue['subDocsTransactionId']>(null);
  const [subDocsClient, setSubDocsClient] = useState<SubDocsContextValue['subDocsClient']>(null);
  const [subDocsClientId, setSubDocsClientId] = useState<SubDocsContextValue['subDocsClientId']>(null);
  const [accreditedInvestorQuestions, setAccreditedInvestorQuestions] = useState<SubDocsContextValue['accreditedInvestorQuestions']>([]);
  const [distributionInstructionsData, setDistributionInstructionsData] = useState<SubDocsContextValue['distributionInstructionsData']>(null);
  const [attachmentsToBeDetached, setAttachmentsToBeDetached] = useState<number[]>([])
  const [transactionAccreditationCompleted, setTransactionAccreditationCompleted] = useState<SubDocsContextValue['transactionAccreditationCompleted']>(false);
  const [userIsApprovedSigner, setUserIsApprovedSigner] = useState<boolean>(false);
  const [isInitialSubscription, setIsInitialSubscription] = useState<boolean>(false);
  const [subscriptionAmount, setSubscriptionAmount] = useState<number>(0);

  const value = useMemo(() => ({
    attachmentsToBeDetached,
    isInitialSubscription,
    setSubDocsAccount,
    setSubDocsClient,
    subDocsAccount,
    custodians,
    subDocsClient,
    subDocsAccountId,
    subDocsClientId,
    subDocsTransactionId,
    distributionInstructionsData,
    accreditedInvestorQuestions,
    setAccreditedInvestorQuestions,
    setAttachmentsToBeDetached,
    setDistributionInstructionsData,
    setIsInitialSubscription,
    setSubDocsAccountId,
    setCustodians,
    setSubDocsClientId,
    setSubDocsTransactionId,
    setTransactionAccreditationCompleted,
    setUserIsApprovedSigner,
    transactionAccreditationCompleted,
    userIsApprovedSigner,
    subscriptionAmount,
    setSubscriptionAmount,
  }), [
      attachmentsToBeDetached,
      isInitialSubscription,
      subDocsAccountId,
      subDocsClientId,
      subDocsTransactionId,
      subDocsAccount,
      custodians,
      subDocsClient,
      accreditedInvestorQuestions,
      distributionInstructionsData,
      transactionAccreditationCompleted,
      userIsApprovedSigner,
      subscriptionAmount,
    ]);

  return (
    <SubDocsContext.Provider value={value}>
      {children}
    </SubDocsContext.Provider>
  )
}
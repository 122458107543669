import { Grid, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { FC } from "react";
import { useSubDocs } from "../../../../../hooks/ContextHooks/useSubDocs";
import { AccreditedInvestorFormValues } from "../subDocsFormSchema";

export const AccreditedInvestorReview: FC = () => {
  const { accreditedInvestorQuestions } = useSubDocs();
  const { values } = useFormikContext<AccreditedInvestorFormValues>();

  return (
    <>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: 16, fontWeight: 'bold', mr: 0}}>In order to invest in this fund you have to be an Accredited investor. An “accredited investor” is any person who comes within any of the following categories, or who the Investment Adviser reasonably believes comes within any of the following categories, at the time of the sale of the Shares to that person.  Please indicate which of the categories are applicable to you by checking the boxes beside the applicable item or items (you may qualify under more than one category).
        </Typography>      
      </Grid>
      <ul>
        {values.accreditedAnswers.sort((a, b) => a - b ).map((answer, index) => {
          const question= accreditedInvestorQuestions.find(question => question.envelope_tab_index === answer)?.question ?? ""
          return (
            <li key={index}>
              {question}
            </li>
          )
        })}
      </ul>
    </>
  )
}
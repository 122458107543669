import { DependencyList, useCallback, useEffect } from "react";

type KeyDownHookOptions = {
  /** Not to be confused with the deprecated KeyCode event property */
  code: KeyboardEvent['code'],
  altKey?: KeyboardEvent['altKey'],
  ctrlKey?: KeyboardEvent['ctrlKey'],
  metaKey?: KeyboardEvent['metaKey'],
  repeat?: KeyboardEvent['repeat'],
}

/** 
 * Hook for setting up a keydown listener on the document node
 * @note I'm writing this more as a training exercise on using custom hooks 
 * and without a business critical use case in mind. It's possible that we may 
 * need to further generalize/enhance this hook if it actually becomes useful
 */
export const useKeyDown = (key: KeyDownHookOptions, callback: (...args: any[]) => void, deps: DependencyList = []) => {
  const onKeyDown = useCallback((event: KeyboardEvent) => {
    const isKeyboardShortcut = 
      event.code === key.code &&
      (!key.altKey || event.altKey) &&
      (!key.ctrlKey || event.ctrlKey) &&
      (!key.metaKey || event.metaKey) &&
      (!event.repeat || key.repeat);

    if (isKeyboardShortcut) {
      event.preventDefault();
      callback();
    }
  //TODO: See if better way to include dependency array in a custom hook, so that I don't need to disable esLint for this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    }
  }, [onKeyDown])
  
}
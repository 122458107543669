import { Auth } from "aws-amplify";
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { FC } from 'react';
import { InferType, object, string } from 'yup';
import { LoadingButton } from '../../../components/Buttons';
import { useAuth } from '../../../hooks';
import { AmplifyAuthResp, FormikSubmitFn } from '../../../utility/types';
import { LoginPageFormProps } from "../LoginPage"

const updatePasswordFormSchema = object({
  password: string().required("New password is required"),
});

type UpdatePasswordData = InferType<typeof updatePasswordFormSchema>;

const initialValues: UpdatePasswordData = { password: '' };

/** 
 * Form for handling the Forced Password Update case of logging in 
 * @todo Consider generalizing components like this; especially if you found yourself duplicating it as a reference
 */
export const UpdatePasswordForm: FC<LoginPageFormProps> = ({ handleLoginStageSubmit, loading }) => {
  const auth = useAuth();

  const handleSubmit: FormikSubmitFn<UpdatePasswordData> = async ({ password }, { setSubmitting }) => {
    const authFn = () => Auth.completeNewPassword(auth.cognitoUser, password) as Promise<AmplifyAuthResp>; 
    handleLoginStageSubmit(authFn, setSubmitting);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={updatePasswordFormSchema} onSubmit={(handleSubmit)}>
      <Form >
        <Field name="password" label="Password" type="password" component={TextField} fullWidth/>
        <LoadingButton type="submit" size="large" fullWidth loading={loading}>
          Update Password
        </LoadingButton>
      </Form>
    </Formik>
  );
}
import { Link, Typography } from "@mui/material";
import { FC } from "react";

type Props = {
  route: string;
};

export const Disclosure: FC<Props> = ({route}: Props) => {
  return (
    <Typography variant='caption' fontSize='10px'>
      <span>Your use of this portal is subject to our </span>
      <Link href={route}>Terms of Use and Disclosures.</Link>
    </Typography>
  )
}
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { SimpleFileUpload } from 'formik-mui';
import { MouseEvent, useCallback, useMemo } from 'react';
import { apiClient } from '../../../../../api/apiClient';
import { LimitedLineTypography } from '../../../../../components/Typography/LimitedLineTypography';
import { useUI } from '../../../../../hooks';
import { useSubDocs } from '../../../../../hooks/ContextHooks/useSubDocs';
import { DistributionInstructionType, DocumentTypes } from '../../../../../utility/constants';
import { isNullish } from '../../../../../utility/misc.util';
import { SubDocsFormValues } from '../subDocsFormSchema';

type Props = {
  label: string
  name: string
  entityDocumentType: DocumentTypes,
  disableDelete?: boolean
}

export const DownloadableDocumentField = ({ label, name , entityDocumentType, disableDelete }: Props) => {
  const form = useFormikContext<SubDocsFormValues>();
  const {attachmentsToBeDetached, setAttachmentsToBeDetached} = useSubDocs();
  const [field] = useField(name);
  const {values, setFieldValue} = form 
  const { setLoading, setError } = useUI();
  const documentValue = useMemo(() => {
    if (entityDocumentType === DocumentTypes.FormationDocuments) {
      return values.formationDocuments
    } else if (entityDocumentType === DocumentTypes.GoverningDocuments) {
      return values.governingDocuments
    } else if (entityDocumentType === DocumentTypes.TrustDocuments) {
      return values.trustDocuments
    } else if (entityDocumentType === DocumentTypes.VoidedCheck) {
      return values.achInstructions.voidedCheck
    } else if (entityDocumentType === DocumentTypes.WireVerificationDocument) {
      if (values.distributionInstructionType === DistributionInstructionType.Brokerage) {
        return values.brokerageInstructions.verificationDocument
      } else if (values.distributionInstructionType === DistributionInstructionType.Wire) {
        return values.wireInstructions.verificationDocument
      }
    }
  },[entityDocumentType, values])
  
  const handleFileClick = useCallback(async (e: MouseEvent<any>) => {
    try {
      if (documentValue == null || documentValue.documentId == null) {
        return
      }
      e.preventDefault();
      setLoading(true);
      const resp = await apiClient.get("/documents/:p0", { routeParams: [documentValue.documentId] });
      window.open(resp.url)
    } catch (error) {
      setError("Unable to download file");
    } finally {
      setLoading(false);
    }
  }, [setLoading, documentValue, setError]);

  const handleFileDelete = () => {
    if (!isNullish(documentValue) && !isNullish(documentValue.documentId)) {
      setAttachmentsToBeDetached([...attachmentsToBeDetached, documentValue.documentId])
    }
    setFieldValue(name, null);
    setFieldValue(name, null);
  }

  return (
    <Box>
        <Typography>{label}</Typography>
        <Button endIcon={documentValue?.documentId == null ? <DriveFolderUploadIcon /> : <CloudDownloadIcon />} variant={documentValue ? "contained":"outlined"} component="label"sx={{width: 'calc(33% - 8px)', mr: 2, height: 57}} onClick={(e: MouseEvent) => handleFileClick(e)}>
          <LimitedLineTypography numLines={1}>{documentValue?.documentName}</LimitedLineTypography>
          <Box sx={{display: 'none'}} hidden={true}>
            <SimpleFileUpload 
              meta={{touched: true, value: "test", error: "null", initialValue: 
              "", initialTouched: false }}
              form={form} 
              field={field}
              accept=".pdf" label="Upload Photo ID"
            />
          </Box>
        </Button>
        {documentValue?.documentId != null && disableDelete !== true &&
        <IconButton onClick={(_e) => handleFileDelete()} sx={{mr: 2}}>
          <DeleteIcon />
        </IconButton>}
    </Box>
  )
};
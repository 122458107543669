import { Schedule } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Link as MuiLink,Tabs,Toolbar,Typography} from "@mui/material";
import { toCurrency } from "../../../utility/number.util";
import { Opportunity } from "../../../context/OpportunitiesContext";
import { Link, useMatches } from "react-router-dom";
import { FC } from "react";
import { MatchWithHandle } from "../../../routes/router.util";
import { isBdc } from "../../../utility/misc.util";
import { Add as AddIcon } from "@mui/icons-material";
import { LinkTab } from "../../../components/LinkTab/LinkTab";
import { bdcOpportunityDetailNavLinks, regDOpportunityDetailNavLinks} from "../../../routes/child-routes/opportunity-detail.routes";
import { formatDate } from "../../../utility/date.util";

type OpportunityDetailHeaderProps = {
  opportunity: Opportunity;
  handleWiringClick: () => void;
  handleAddInvestorClick: () => void;
};

export const OpportunityDetailHeader: FC<OpportunityDetailHeaderProps> = ({opportunity, handleWiringClick, handleAddInvestorClick}) => {
  const isRegD = !isBdc(opportunity.investing_entity_type_id);
  const matches = useMatches() as MatchWithHandle[];
  const [routeTabValue] = matches.filter((m: MatchWithHandle) => m.handle?.tab);
  
  const activeTab = routeTabValue?.handle?.tab ?? (isRegD ? "interest-list" : "bdc-interest-list");
  const navLinks = isRegD ? regDOpportunityDetailNavLinks : bdcOpportunityDetailNavLinks;


  return (
    <Toolbar sx={{ flexDirection: "column", alignItems: "flex-start", pt: 2 }}>
      <Breadcrumbs sx={{ pb: 1 }}>
        <MuiLink component={Link} to="/opportunities">
          Opportunities
        </MuiLink>
        <Typography>{opportunity.name ?? ""}</Typography>
      </Breadcrumbs>
      <Box sx={{ alignItems: "end", justifyContent: "space-between", display: "flex", pt: 2, width: "100%" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ alignItems: "start", display: "box", flexGrow: 1, justifyContent: "flex-start" }}>
            <Typography variant="h5">{opportunity.name}</Typography>
            <Box sx={{ alignItems: "flex-start", display: "flex" }}>
              <Typography sx={{ marginRight: 1 }}>{isRegD ? "Minimum Commitment:" : "Initial Investment:"}</Typography>
              <Typography color="primary.main" sx={{ fontWeight: "bold" }}>{toCurrency(opportunity.minimum_commitment, true)}</Typography>
            </Box>
            {!isRegD && (
              <Box sx={{ alignItems: "flex-start", display: "flex" }}>
                <Typography sx={{ marginRight: 1 }}>Subsequent Investment:</Typography>
                <Typography color="primary.main" sx={{ fontWeight: "bold" }}>{`${toCurrency(opportunity.subsequent_investment)} minimum`}</Typography>
              </Box>
            )}
            <Box sx={{ alignItems: "center", display: "flex" }}>
              <Schedule fontSize="small" sx={{ color: "primary.main", marginRight: ".25rem" }} />
              <Typography>
                {isRegD ? `Expected Close Date: ${opportunity.expected_close_date ? formatDate(String(opportunity.expected_close_date)) : "N/A"}` : 
                opportunity.subscription_deadline ? `Subscription Deadline: ${formatDate(String(opportunity.subscription_deadline))}` : null}
              </Typography>
            </Box>
            <Box sx={{ alignItems: "center", display: "flex" }}>
              <Typography sx={{ marginRight: 1 }}>Investor Type:</Typography>
              <Typography>{opportunity.investor_type}</Typography>
            </Box>
            <MuiLink component="button" sx={{ color: "primary.main" }} onClick={handleWiringClick}>
              <Typography>View Wiring Instructions</Typography>
            </MuiLink>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", pt: 2, width: "100%" }}>
            <Typography>{opportunity.description}</Typography>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <Button color="info" onClick={handleAddInvestorClick} startIcon={<Box><AddIcon sx={{ fontSize: 14 }} /></Box>} sx={{ marginLeft: 2, minWidth: 150 }}>
                Add Investor
              </Button>
            </Box>
          </Box>
          <Tabs value={activeTab} aria-label="nav tabs">
            {navLinks.map((r) => (
              <LinkTab label={r.label} to={r.path!} value={r.path!} key={r.label} />
            ))}
          </Tabs>
        </Box>
      </Box>
    </Toolbar>
  );
};
/**
 * The encrypt utility is only intended for masking data that will still be exposed the application code.
 * Do not use this utility for encrypting sensitive data. 
 * Do not re-use any keys or salts that are used in this utility for encrypting sensitive data.
 * 
 * Thank you,
 * Jeff
 */

import { AES, enc } from 'crypto-js';
const routeMaskKey = process.env.REACT_APP_ROUTE_MASK_KEY!;

export function maskRouteId(id?: string | number | null): string {
  if (!id) { 
    return 'undefined'
  }

  id = id.toString();
  
  const base64 = AES.encrypt(id, routeMaskKey).toString()
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export function unmaskRouteId(maskedId: string): string {
  if (!maskRouteId) {
    return ''
  }
  
  let base64 = maskedId.replace(/-/g, '+').replace(/_/g, '/');

  while (base64.length % 4) {
    base64 += '=';
  }

  const bytes = AES.decrypt(base64, routeMaskKey);
  return bytes.toString(enc.Utf8);
} 

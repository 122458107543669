import { Box, Checkbox, FormControlLabel, FormGroup, MenuItem, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { Select, TextField } from 'formik-mui';
import { FC, useEffect } from 'react';
import { useClientDetails, useClients, useUI } from '../../../../../../hooks';
import { InstructionDetailFormValues } from '../newInstructionValidationSchema';


export const CheckInstructionFields: FC = () => {
  const {values, validateForm, setFieldTouched, setFieldValue} = useFormikContext<InstructionDetailFormValues>();
  const { addressStates} = useClients();
  const {activeClient} = useClientDetails();
  const {setError} = useUI();
  useEffect(() => {
    if (values.checkInstructions?.existingAddressId != null) {
      setFieldTouched("checkInstructions.streetAddress1", true, true);
      setFieldTouched("checkInstructions.streetAddress2", true, true);
      setFieldTouched("checkInstructions.city", true, true);
      setFieldTouched("checkInstructions.stateId", true, true);
      setFieldTouched("checkInstructions.zip", true, true);
    }
  }, [values.checkInstructions?.existingAddressId, validateForm, setFieldTouched])

  const handleMailToClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && activeClient?.address) {
      setFieldValue("checkInstructions.existingAddressId", activeClient.address.id)
      setFieldValue("checkInstructions.streetAddress1", activeClient.address.street_address_1 ?? "")
      setFieldValue("checkInstructions.streetAddress2", activeClient.address.street_address_2 ?? "")
      setFieldValue("checkInstructions.city", activeClient.address.city ?? "")
      setFieldValue("checkInstructions.stateId", activeClient.address.state_id ?? "")
      setFieldValue("checkInstructions.zip", activeClient.address.zip_code ?? "")
    } else {
      setFieldValue("checkInstructions.existingAddressId", null)
      setFieldValue("checkInstructions.streetAddress1", "")
      setFieldValue("checkInstructions.streetAddress2", "")
      setFieldValue("checkInstructions.city", "")
      setFieldValue("checkInstructions.stateId", "")
      setFieldValue("checkInstructions.zip", "")
    }
  }

  return (
    <Box sx={{mt: 4, mb:2}}>
      <Box>
        <Field name="checkInstructions.instructionName" required label="Distribution Instruction Friendly Name" component={TextField} sx={{width: '50%', mr: 2, ml:2}}/>
        <Field name="checkInstructions.payeeName" required label="Payee Name" component={TextField} sx={{width: '40%', ml:1}}/>
      </Box>
      <Box sx={{display: 'flex',justifyContent: 'flexStart', alignItems: 'center', mb: 2, ml:2}}>
        <Typography sx={{fontWeight: 'bold', mr: 2}}>Mail to:</Typography>
        <FormGroup>
          <FormControlLabel control={<Checkbox onChange={handleMailToClick} disabled={!activeClient?.address}  />} label="Same as Household Address" />
        </FormGroup>
      </Box>
      <Box>
        <Field name="checkInstructions.streetAddress1" disabled={values.checkInstructions?.existingAddressId != null} required label="Street Address" component={TextField} sx={{width: '50%', mr: 2, ml:2}}/>
        <Field name="checkInstructions.streetAddress2" disabled={values.checkInstructions?.existingAddressId != null} label="Street Address 2" component={TextField} sx={{width: '40%', ml:1}}/>
      </Box>
      <Box sx={{display: 'flex'}}>
        <Field name="checkInstructions.city" disabled={values.checkInstructions?.existingAddressId != null} required label="City" component={TextField} sx={{width: '30%', mr: 1, ml:2}} />
        <Field 
          name="checkInstructions.stateId" 
          label="State" 
          disabled={values.checkInstructions?.existingAddressId != null}
          component={Select} 
          formControl={{required: true}}
          sx={{ width: '200px'}}
          onClose={() => {}}
          >
            {addressStates != null && addressStates.map(state => (
              <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
            ))}
        </Field>
        <Field name="checkInstructions.zip" disabled={values.checkInstructions?.existingAddressId != null} label="ZIP Code" required component={TextField} sx={{ml: 3.5,width: '30%'}}/>
        </Box>
    </Box>
  );
}

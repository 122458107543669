import { ValidationError } from "yup";
import { debounce } from "lodash";
import { apiClient } from "../api/apiClient";

export const debouncedFunctions: Map<string, any> = new Map();
export const pendingRequests: Map<string, Promise<boolean | ValidationError>> = new Map();

export const checkDuplicate = (type: 'email' | 'ssn') => {

    const debouncedCheck = debounce(async (value: string, resolve: (value: boolean | ValidationError) => void, context: any) => {
        const key = `${type}-${value}`;
        if (pendingRequests.has(key)) {
            const existingRequest = pendingRequests.get(key);
            if (existingRequest) {
                const result = await existingRequest;
                if (result instanceof ValidationError) {
                    return resolve(context.createError({ message: result.message }));
                }
                return resolve(result);
            }
        }
        const param = type === 'email' ? { email: value } : { ssn: value };
        const request = (async () => {
            try {
                const response = await apiClient.get(`/customers/contacts/duplicate`, { params: param });
                if (response && response.message) {
                    return context.createError({ message: response.message });
                }
                return true;
            } catch (error) {
                if (error instanceof ValidationError) {
                    return context.createError({ message: error.message });
                } else {
                    console.error(error);
                    return false;
                }
            }
        })();
        pendingRequests.set(key, request);
        const result = await request;
        resolve(result);
        pendingRequests.delete(key);
    }, 300);

    const callableFunction = (value: string, context: any) => {
        return new Promise<boolean | ValidationError>((resolve) => {
            debouncedCheck(value, resolve, context);
        });
    };

    return callableFunction;
};

import { Typography } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { Field, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import { Contact, Custodian } from "../../../../api/payloads/customer.payload";
import { useEffect } from "react";
import { AccountFormValues } from "./newClientFormSchema";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "100px",
    },
  },
};

type Props = {
  members: Contact[],
  custodians: Custodian[],
}

export default function IndividualAccountFields({ members, custodians }: Props) {
  const {values, setFieldValue, setFieldTouched, validateForm} = useFormikContext<AccountFormValues>();
  const {clientAccountMember, custodian, isCustodied} = values;

  useEffect(() => {
    const currentCustodian = custodians.find(c => (c.id.toString() === custodian.toString()));
    const currentMember = members?.find(member =>member.id === clientAccountMember);
    let accountName = "";
    
    if (isCustodied && currentCustodian) {
      accountName = currentMember && currentCustodian ? `${currentCustodian.name} FBO ${currentMember.first_name} ${currentMember.last_name}` : '';
    }
    else if (currentMember) {
      accountName = `${currentMember.first_name} ${currentMember.last_name}`;
    }

    setFieldValue('accountName', accountName).then(() => validateForm());
  }, [members, clientAccountMember, custodian, custodians, isCustodied, setFieldValue, setFieldTouched, validateForm]) 

  return (
     <>
     {isCustodied && custodians && (
        <>
          <Field
            formControl={{ required: true, sx: {width: 'calc(50% - 8px)', minWidth: 112, mb: 2, mr: 2}}}
            component={Select}
            label="Custodian Name"
            MenuProps={MenuProps}
            name="custodian"
            required
          >
            {custodians.filter(custodian => custodian.is_custodied !== false).map((custodian) => (
              <MenuItem key={custodian.id} value={custodian.id}>{custodian.name}</MenuItem>
            ))}
          </Field>
          <Field
            name="accountNumber"
            required
            label="Account Number"
            component={TextField}
            sx={{width: 'calc(50% - 8px)'}}
          />
        </>
      )}
      <Field
        formControl={{ sx:{width: 'calc(50% - 8px)',minWidth: 112, mb:2, mr:2}}}
        component={Select}
        required
        label="Account Owner"
        MenuProps={MenuProps}
        renderValue={(memberID: number) => {
          const currentMember = members.find((member) => (member.id.toString() === memberID.toString()))
          return <Typography>{currentMember ? `${currentMember.first_name} ${currentMember.last_name}` : ""}</Typography>
        }}
        name="clientAccountMember"
        onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
      >
        {members.map((member) => <MenuItem key={member.id} value={member.id}><option value={member.id}>{`${member.last_name}, ${member.first_name}`}</option></MenuItem>)}
      </Field>
      <Field disabled name="accountName" required label="Account Name" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
    </>
  );
}
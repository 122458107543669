import { Box, Card, CardContent, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../api/apiClient";
import { Disclosure } from "../../components/Disclosure/Disclosure";
import { User } from "../../context/AuthContext";
import { useAuth, useUI } from "../../hooks";
import { CognitoChallenge } from "../../utility/constants";
import { isError } from "../../utility/type-guards";
import { AmplifyAuthResp } from '../../utility/types';
import { LoginForm } from "./forms/LoginForm";
import { MFAForm } from "./forms/MFAForm";
import { UpdatePasswordForm } from "./forms/UpdatePasswordForm";

export type LoginPageFormProps = {
  loading: boolean
  handleLoginStageSubmit: (authFn: () => Promise<AmplifyAuthResp>, setSubmitting: (v: boolean) => void) => void,
}

const enum LoginStage {
  Login = 1,
  MFA = 2,
  SetPassword = 3,
}

const loginChallengeStageMap: Record<CognitoChallenge, LoginStage> = {
  'NEW_PASSWORD_REQUIRED': LoginStage.SetPassword,
  'SMS_MFA': LoginStage.MFA
}

const loginStageFormMap: Record<LoginStage, { title: string, component: FC<LoginPageFormProps> }> = {
  [LoginStage.Login]: { title: "Login to Continue", component: LoginForm },
  [LoginStage.MFA]: { title: "Multi-Factor Authentication", component: MFAForm },
  [LoginStage.SetPassword]: { title: "Update Password", component: UpdatePasswordForm }
}

export const LoginPage = (props: unknown) => {
  const auth = useAuth();
  const ui = useUI();
  const navigate = useNavigate();
  const [loginStage, setLoginStage] = useState(LoginStage.Login);
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const currentFormTitle = loginStageFormMap[loginStage].title;
  const CurrentFormComponent = loginStageFormMap[loginStage].component; // Using PascalCase because JSX w/ TS redlines with camelCase.

  useEffect(() => {
    if (auth.user && loggedIn) {
      navigate("/");
    }
  }, [auth.user, loggedIn, navigate])

  const handleLoginStageSubmit: LoginPageFormProps['handleLoginStageSubmit'] = async (authFn, setSubmitting) => {
    try {
      setLoading(true);
      const resp = await authFn();
      auth.setCognitoUser(resp); // Might not need to track this ourselves...Auth class might cache this
      const username = resp.getUsername();
      const jwt = resp.getSignInUserSession()?.getAccessToken().getJwtToken();
      const isSignedIn = !!jwt && !!username;
      const nextLoginStage = loginChallengeStageMap[resp.challengeName as CognitoChallenge]

      if (!isSignedIn && nextLoginStage) {
        setLoginStage(nextLoginStage);
        return;
      }

      isSignedIn ? signInUser(jwt, username) : ui.setError("Encountered unexpected login error. Please contact support");
    } catch (e) {
      isError(e) && ui.setError(e.message);
      setSubmitting(false);
    } finally {
      setLoading(false);
    }
  }

  const signInUser = async (jwt: string, username: string) => {
    const userResp = await apiClient.get("/advisor-vision/users/:p0", { routeParams: [username] });

    const user: User = {
      id: userResp.professional_id,
      cognitoId: userResp.cognito_id,
      token: jwt,
    }
    auth.setUser(user)
    setLoggedIn(true);
  };

  return (
    <Card sx={{ p: 4, backgroundColor: 'background.default' }} variant="elevation" elevation={1}>
      <CardContent sx={{
        '& .MuiTextField-root': { mb: 1 },
        '& button[type="submit"].MuiButton-root': { fontWeight: 'bold' }
      }}>
        <Typography variant="h5" mb={3}><b>{currentFormTitle}</b></Typography>
        <Box mb={1}>
          <CurrentFormComponent {...{ handleLoginStageSubmit, loading }} />
        </Box>
        <Box display='flex' justifyContent='center'>
          <Disclosure route="/terms-and-conditions"/> 
        </Box>
      </CardContent>
    </Card>
  )
}
// If this file becomes overstuffed or messy, please consider breaking it up and reorganizing.

export const LOGO_SMALL = "/images/BIPCAV-Horizontal-GunMetal.png";
export const LOGO_VERTICAL_LOCKUP = "/images/BIPCAV-GunMetal-LRG.png";

export const enum CognitoChallenge {
  NewPassword = "NEW_PASSWORD_REQUIRED",
  MfaText = "SMS_MFA",
}

export const passwordRequirements = {
  regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/,
  message: "Must contain minimum of one uppercase letter, lowercase letter, and special character",
}

export const enum ProfessionalRelationship {
  Advisor = 1,
  Lawyer = 2,
  Other = 3,
}

export enum AccountType {
  /** @deprecated */
  IndividualJointEntity = 1,
  IRA = 2,
  Individual = 3,
  Joint = 4,
  Entity = 5,
}

export const IraType = {
  Traditional: 1,
  Roth: 2,
  Rollover: 3,
  SEP: 4,
  Beneficiary: 5,
  Simple: 6,
  '401k': 7,
  Inherited: 8,
} as const;

export enum RevocableStrings {
  Other = "Other",
  Revocable = "Revocable"
}

export enum GrantorStrings {
  NonGrantor = "Non Grantor",
  Grantor = "Grantor"
}

export enum DocumentTypes {
  InvestorStatements = 1,
  K1 = 2,
  Form1099 = 3,
  SubscriptionAgreements = 4,
  GoverningDocuments = 5,
  Other = 6,
  PrivatePlacementMemorandum = 7,
  FormationDocuments = 8,
  EINSS4 = 9,
  WiringInstructions = 10,
  W9 = 11,
  PortfolioCompanyUpdates = 12,
  TrustDocuments = 13,
  LOA = 14,
  DistributionInstruction = 15,
  Identification = 16,
  TransferAgreements = 17,
  AttestationDocument = 18,
  VoidedCheck = 19,
  WireVerificationDocument = 20,
}

export enum AccountTypeOptions {
  IRA = "IRA",
  Individual = "Individual",
  Joint = "Joint",
  Entity = "Entity",
}

export enum ContactNumberTypes {
  Mobile = 1,
  Home = 2,
  Office = 3,
  Other = 4,
}

export enum InvestmentCompanyStages {
  GatheringInterest = 1,
  ClosedToNewInvestors = 2,
  InvestmentClosed = 3
}

export enum Custodians {
  InspiraFinancial = 1,
  TDAmeritrade = 2,
  NA = 3,
  BIPCapital = 4,
}

export const PROFESSIONAL_ID_HEADER = 'PROFESSIONAL_ID';
export const APPLICATION_SOURCE_ID_HEADER = 'APPLICATION_SOURCE_ID';
export const APP_USERNAME_HEADER = 'APP_USERNAME';

export enum CustodiansAccountType {
  IRA = 1,
  BROKERAGE = 2 
}

/** aka "default_fundings" table */
export const enum DistributionInstructionType {
  Wire = 1,
  Check = 2,
  ACH = 3,
  Brokerage = 4,
}

export const enum CustomerEntityTypes {
  IndividualSingleMemberLLC = 1,
  Partnership = 2,
  CCorp = 3,
  SCorp = 4,
  STrust = 5,
  LimitedLiabilityCompany = 6,
  Estate = 7,
  OtherEmployeeBenefitPlan  = 8,
  ExemptOrganization = 9,
  OtherDescribe = 10,
}

/** 
 * Note that this is intentionally not an enum for iterative purposes. 
 * May consider avoiding Typescript enums in the future due to their imperfections
 */
export const DistributionInstructionAccountType = {
  Checking: 1,
  Savings: 2,
} as const

export enum SubDocStatus {
  NotStarted = 0,
  InProgress = 1,
  AwaitingSignature = 2,
  Signed = 3,
  AwaitingApproval = 4,
  Approved = 5
}

export const SubDocActions = {
  QuestionnaireCreated: 1,
  QuestionnaireCompleted: 2,
  DocusignEnvelopeCreated: 3,
  DocusignEnvelopeCompleted: 4,
  SubscriptionDocumentSubmitted: 5,
  SubscriptionDocumentDownloaded: 6,
} as const;

export type SubDocActionType = typeof SubDocActions[keyof typeof SubDocActions];

export enum DistributionInstructionDocusignStatus {
  AwaitingSignature = 1,
  Signed = 2,
}

export enum FundingStatusStrings {
  alreadyFunded = "Account has been funded for this transaction",
  toBeFunded = "Account to be funded for this transaction"
}


export const IRAFundingType = {
  Wire: {
    value: 'Wire', //magic string
    description: 'Wire Transfer'
  },
  Custodian: {
    value: 'Custodian',
    description: 'Transfer account from another custodian'
  }
};

export enum InvestorDescriptionStrings {
  notAnOfficer = "I represent that I am not an officer or director of the offering entity or any affiliates thereof, nor am I related to any officer or director of the offering entity or any affiliates thereof. I also represent that my ownership of this entity (combined with any family member or disqualified person) will be less than 50%.",
  isAnOfficer = "I represent and disclose that I am an officer or director of the offering entity or its affiliate, or that I am related to any officer or director of the offering entity or its affiliate."
}

export enum FundStatus {
  NotSubmitted = 1,
  Submitted = 2,
  Pending = 3,
  Approved = 4,
  Rejected = 5,
  Rolled = 6,
  Withdrawn = 7
}

export enum DistributionInstructionStatus {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
}

export const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100 MB

export const ApplicationSource = {
  JFM: 1,
  Vision: 2,
  AdvisorVision: 3,
  JFMExternalApi: 4,
} as const;

export const enum InvestingEntityType {
  FundEquity = 1,
  FundDebt = 2,
  SPVEquity = 3,
  SPVDebt = 4,
  RealEstate = 5,
  BDCEquity = 6, 
  BDCDebt = 7,
}

export const enum EntityStage {
  FundRaising = 1,
  Active = 2,
  Inactive = 3,
}

export const ProfessionalTypes = {
  RIA_MFOS: 1,
  Accountant: 2,
  Lawyer: 3,
  Consultant: 4,
  OutSourcedCIO: 5,
  FundOfFunds: 6,
  Solicitor: 7,
  SingleFamilyOffice: 8,
} as const;

export type ProfessionalType = typeof ProfessionalTypes[keyof typeof ProfessionalTypes];

export const ProfessionalTypeNames: { [key in ProfessionalType]: string } = {
  [ProfessionalTypes.RIA_MFOS]: 'RIA/MFOS',
  [ProfessionalTypes.Accountant]: 'Accountant',
  [ProfessionalTypes.Lawyer]: 'Lawyer',
  [ProfessionalTypes.Consultant]: 'Consultant',
  [ProfessionalTypes.OutSourcedCIO]: 'Out Sourced CIO',
  [ProfessionalTypes.FundOfFunds]: 'Fund Of Funds',
  [ProfessionalTypes.Solicitor]: 'Solicitor',
  [ProfessionalTypes.SingleFamilyOffice]: 'Single Family Office',
};

export const enum InvestorStatus {
  Interested = 1,
  Committed = 2,
  NotInterested = 3,
  Contacting = 4
}

export enum NotificationActionType {
  IndicatedInterestInOpportunity = 'Indicated Interest in Opportunity',
  IndicatedInterestInBDCOpportunity = 'Indicated Interest in Bdc Opportunity',
}

import { Box, Checkbox, Radio, styled, TableContainer, Typography } from "@mui/material"
import { useFormikContext } from "formik"
import { FC, useCallback, useMemo, useState } from "react"
import { Professional } from "../../../../api/payloads"
import { ColumnDef, DataSourceTable } from "../../../../components/DataSourceTable"
import { useAuth, useProfessionals } from "../../../../hooks"
import { ProfessionalFormValues } from "./newClientFormSchema"
import { ProfessionalTypeNames, ProfessionalType } from "../../../../utility/constants"

const TableCheckBox = styled(Checkbox)({padding: 0})

type AddProfessionalData = Professional & {disabled: boolean, relationship: string, isAddChecked: boolean, isReceivesChecked: boolean }

type AddProfessionalDialogProps = {
  push:  (obj: any) => void,
  remove: <T>(index: number) => T | undefined
}

export const ProfessionalsTable: FC<AddProfessionalDialogProps> = ({push, remove}) => {
  const {values, setFieldValue, setFieldTouched, errors} = useFormikContext<ProfessionalFormValues>();
  const { professionalsList } = useProfessionals();
  const {user} = useAuth();
  const {professionals} = values
  const [dataSource] = useState<AddProfessionalData[]>(professionalsList.map(p => {
    const selectedProfessional = professionals.find(pv => pv.professionalId === p.id)
    return ({
      ...p,
      relationship: "Advisor",
      isAddChecked: selectedProfessional != null,
      disabled: user?.id.toString() === p.id.toString(),
      isReceivesChecked: selectedProfessional?.receivesComms ?? false,
    })
  }));
  
  const handleCheckAdd = useCallback((row: AddProfessionalData, isChecked: boolean, isSelectedPrimary: boolean) => {
    if (isChecked) {
      row.isAddChecked = true
      push({professionalId: row.id, receivesComms: false})
    } else {
      row.isAddChecked = false
      row.isReceivesChecked = false
      const index = professionals.findIndex(professional => professional.professionalId === row.id)
      remove(index)
      if (isSelectedPrimary) {
        setFieldValue("primaryProfessionalId", undefined)
      }
    }
  }, [push, remove, professionals, setFieldValue]);

  const handleCheckReceivesComms = useCallback((row: AddProfessionalData, isChecked: boolean) => {
    const index = professionals.findIndex(professional => professional.professionalId === row.id)
    if (isChecked) {
      row.isReceivesChecked = true
      setFieldValue(`professionals[${index}].receivesComms`, true)
    } else {
      row.isReceivesChecked = false
      setFieldValue(`professionals[${index}].receivesComms`, false)
    }
  }, [professionals,setFieldValue])
  
  const columnDef = useMemo(() => {
    const def: ColumnDef<AddProfessionalData & {primaryContactColumn: never}> = [
      { id: "first_name", label: "Name", sortable: true, displayFn: (_, r) => `${r.first_name} ${r.last_name}`},
      { id: "relationship", label: "Relationship", sortable: true,},
      { id: "phone", label: "Phone", sortable: true,},
      { id: "email", label: "Email", sortable: true,},
      { id: "professional_type_id", label: "Type", sortable: true, displayFn: (_, r) => getProfessionalTypeName(r.professional_type_id as ProfessionalType)},
      { 
        id: "isAddChecked", 
        label: "Add", 
        displayFn: (_, r) => (
          <TableCheckBox
            disabled={  r.disabled}
            checked={r.isAddChecked} 
            onChange={e => handleCheckAdd(r, e.target.checked, values.primaryProfessionalId === r.id)}
          />
        )
      },
      {
        id: "primaryContactColumn",
        label: "Primary Contact",
        displayFn: (_, r) => (
        <Radio
          sx={{padding: 0}}
          disabled={!professionals.some(p => p.professionalId === r.id)}
          checked={values.primaryProfessionalId === r.id}
          onChange={async (_e, checked) => {
            if (!checked) return
            await setFieldValue("primaryProfessionalId", r.id)
            await setFieldTouched("primaryProfessionalId", true)
          }}
        />)
      },
      { 
        id: "isReceivesChecked", 
        label: "Receives Communications", 
        displayFn: (_, r) => (
          <TableCheckBox 
            disabled={!professionals.some(p => p.professionalId === r.id)} 
            checked={r.isReceivesChecked} 
            onChange={e => handleCheckReceivesComms(r, e.target.checked)} 
          />
        )
      },
    ];
    
    return def;
  }, [handleCheckAdd, handleCheckReceivesComms, professionals, setFieldValue, values.primaryProfessionalId, setFieldTouched])
  
  return (
    <Box sx={{py: 4}}>
        <TableContainer>
          <DataSourceTable columnDef={columnDef} dataSource={dataSource} initialOrderBy="first_name"/>
        </TableContainer>
        {!!errors.primaryProfessionalId && (
          <Typography color="error" variant="caption">{errors.primaryProfessionalId}</Typography>
        ) }
    </Box>
  )
}

export function getProfessionalTypeName(id: ProfessionalType): string {
  return ProfessionalTypeNames[id];
}
import { Navigate, RouteObject } from "react-router-dom";
import { ChangePasswordPage } from "../../pages/Settings/ChildRoutes/ChangePasswordPage";
import { FirmPage } from "../../pages/Settings/ChildRoutes/FirmPage";
import { isNavBarRoute, NavBarRouteObject, Placeholder } from "../router.util";
import { TermsAndConditionsPage } from "../../pages/Terms/TermsAndConditionsPage";

//TODO: Lots of repetition here with path, label, and handle values. 
// Consider DRying it up by building this with by calling Array.prototype.map with a simplified array.
export const settingChildRoutes: (RouteObject | NavBarRouteObject)[] = [
  { index: true, element: <Navigate to="settings" /> },
  { path: "password", element: <ChangePasswordPage />, label: "Password" },
  { path: "firm", element: <FirmPage />, label: "Firm", },
  { path: 'terms-and-conditions', element: <TermsAndConditionsPage />, label: "Terms and Disclosures" },
];

export const settingChildNavLinks = settingChildRoutes.filter(isNavBarRoute); 
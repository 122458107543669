import { Close } from "@mui/icons-material";
import { Alert, IconButton, Snackbar, Typography } from "@mui/material";
import React, { createContext, FC, PropsWithChildren, ReactNode, useMemo, useState } from "react";
import { GlobalLoader } from "../components/GlobalLoader/GlobalLoader";

export type UIContextValue = {
  error: ReactNode,
  setError: React.Dispatch<React.SetStateAction<ReactNode>>,
  loading: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  success: string | ReactNode,
  setSuccess: React.Dispatch<React.SetStateAction<string | ReactNode>>,
};

export const UIContext = createContext<UIContextValue>(undefined as unknown as UIContextValue);

/** WIP context for global UI states such as errors and loaders */
export const UIContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [error, setError] = useState<ReactNode>("");
  const [success, setSuccess] = useState<string | ReactNode>("");
  const [loading, setLoading] = useState(false);

  const value = useMemo(() => (
    {
      error,
      setError,
      loading,
      setLoading,
      success,
      setSuccess,
    }), [error, loading, success]);

  return (
    <UIContext.Provider value={value}>
      <GlobalLoader />
      <Snackbar
        open={!!error}
        autoHideDuration={10000}
        onClose={() => setError('')}
        message={error}
      />
      <Snackbar
        open={!!success}
        color="green"
        autoHideDuration={12000}
        onClose={() => setSuccess('')}
      >
        <Alert severity="success"
          action={(
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSuccess('')}
            >
              <Close fontSize="small" />
            </IconButton>
          )}>
          {typeof success === 'string' ? (<Typography variant="body1">{success}</Typography>) : (success)}
        </Alert>
      </Snackbar>
      {children}
    </UIContext.Provider>
  )
}
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { Select } from "formik-mui";
import { FC, useEffect } from "react";
import { useSubDocs } from "../../../../hooks/ContextHooks/useSubDocs";
import useBoolean from "../../../../hooks/useBoolean";
import { AccountType, DistributionInstructionType } from "../../../../utility/constants";
import DistributionInformationFields, { CustodialAccountInformation } from "./DistributionInformationFields";
import { NewInstructionDialog } from "./NewInstructionDialog/NewInstructionDialog";
import { SubDocsFormValues } from "./subDocsFormSchema";
import { useClients } from '../../../../hooks/ContextHooks/useClients';


export const DistributionInformation: FC = (props) => {
  const { isInitialSubscription,subDocsAccount, distributionInstructionsData } = useSubDocs();
  const {addressStates} = useClients();
  const { values, setFieldValue, validateForm } = useFormikContext<SubDocsFormValues>();
  const {value: openAddInstruction, setFalse: setAddInstructionClose, setTrue: setAddInstructionOpen } = useBoolean();

  const instructionType = values.distributionInstructionType;
  const isCustodiedNonIRA = subDocsAccount?.account_type_id !== AccountType.IRA && subDocsAccount?.custodian_id !== null;
  const isCustodiedIRA = subDocsAccount?.account_type_id === AccountType.IRA && subDocsAccount?.custodian_id !== null;
  const instructions = instructionType === DistributionInstructionType.Brokerage ? values.brokerageInstructions : values.wireInstructions;
  const fundingMethod = instructionType === DistributionInstructionType.Brokerage ? "Brokerage" : "Wire";
  const state = addressStates?.find(state => state.id === instructions?.financialStateId);

  // Needed to resolve issue where setting the instruction ID results in several instruction fields updating,
  // but in at least on case the validation runs before the internal state finished updating
  useEffect(() => {
    validateForm()
  }, [values, validateForm])

  const isFollowOnPurchase = !isInitialSubscription;

  const handleDialogClose = (distributionInstructionId?: number) => {
    setAddInstructionClose();
    if (!isFollowOnPurchase && distributionInstructionId) {
      setFieldValue("distributionInstructionId", distributionInstructionId);
    }
  };
  
  return (
    <Grid container spacing={2}>
      {isCustodiedNonIRA &&
        <CustodialAccountInformation
          fundingMethod={fundingMethod} 
          instructionFields={[
            { label: "Funding Method", value: fundingMethod },
            { label: "Name", value: instructions?.instructionName },
            { label: "Account Number", value: instructions?.accountNumber },
            { label: "ABA Routing Number", value: instructions?.aba },
            { label: "Bank Account Name", value: instructions?.bankAccountName },
            { label: "Financial Institution Name", value: instructions?.financialInstitutionName },
            { label: "City", value: instructions?.financialCity },
            { label: "State", value: state?.name },
            ...(fundingMethod === "Wire" ? [{ label: "Zip Code", value: values.wireInstructions?.financialZip }] : []),
            { label: "Reference (For Further Credit To)", value: instructions?.ffc },
            { label: "FFC To Account Number", value: instructions?.ffcAccountNumber },
          ]}
        />
      }
      {isCustodiedIRA &&
        <CustodialAccountInformation
          fundingMethod="Wire"
          instructionFields={[
            { label: "Funding Method", value: "Wire" },
            { label: "Name", value: values.wireInstructions?.instructionName },
            { label: "Account Number", value: values.wireInstructions?.accountNumber },
            { label: "ABA Routing Number", value: values.wireInstructions?.aba },
            { label: "Bank Account Name", value: values.wireInstructions?.bankAccountName },
            { label: "For Further Credit To", value: values.wireInstructions?.ffc },
            { label: "FFC To Account Number", value: values.wireInstructions?.ffcAccountNumber },
          ]}
        />
      }
     {subDocsAccount?.account_type_id !== AccountType.IRA && subDocsAccount?.custodian_id === null && 
     <>
     {distributionInstructionsData && distributionInstructionsData?.distributionInstructions.length > 0 && <Grid item xs={12}>
         <Typography fontWeight="bold">
          Please review the Distribution information below or add a new one.
        </Typography>
      </Grid>}
      <Grid item xs={12}>
        <Box sx={{display: 'flex', justifyContent: 'space-between',mt: 2}}>
        {distributionInstructionsData && distributionInstructionsData?.distributionInstructions.length > 0 ? <Field
          formControl={{required: true, sx:{width: 'calc(50% - 8px)'}}}
          component={Select}
          disabled={!isInitialSubscription && values.distributionInstructionId !== ""}
          label="Instruction Name"
          name="distributionInstructionId"
          onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
          >
          {distributionInstructionsData?.distributionInstructions.map(instruction => <MenuItem key={instruction.id} value={instruction.id}>{`${instruction.name} - (${instruction.instruction_type}) ${instruction.is_default_account_instruction ? " - default": ""}`}</MenuItem>)}
        </Field>
        :
        <Typography>
          No Distribution Instructions are available at this time. Please complete the Distribution information by clicking the Add New button.
        </Typography>
        }
          {<Button id="distributionInstructionButton" color='info' onClick={() => {setAddInstructionOpen()}} disabled={isFollowOnPurchase} startIcon={<Box><AddIcon sx={{fontSize: 14}} /></Box>}>Add New Instruction</Button>}
        </Box>
        <NewInstructionDialog id="distributionDialog" handleClose={handleDialogClose} open={openAddInstruction} maxWidth="xl" />
      </Grid>
      <Grid item xs={12}>
        {values.distributionInstructionId !== "" && <DistributionInformationFields />}
      </Grid>
      </>
      }
    </Grid>
  )
}
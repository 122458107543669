import { FC } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Typography} from "@mui/material";
import { ErrorMessage, useFormikContext } from "formik";
import { DistributionInstructionType } from "../../../../../../utility/constants";
import { CheckInstructionFields } from "../InstructionTypesFields/CheckInstructionFields";
import { ACHInstructionFields } from "../InstructionTypesFields/ACHInstructionFields";
import { WireInstructionFields } from "../InstructionTypesFields/WireInstructionFields";
import { BrokerageInstructionFields } from "../InstructionTypesFields/BrokerageInstructionFields";
import { NewInstructionFormValues } from "../newInstructionValidationSchema";

export const DistributionInstructionDetailsForm: FC<{ instructionType: DistributionInstructionType | '', setInstructionType: Function }> = ({ instructionType, setInstructionType }) => {
  const { resetForm, initialValues } = useFormikContext<NewInstructionFormValues>();

  const handleInstructionTypeChange = (event: SelectChangeEvent<DistributionInstructionType>) => {
    const value = event.target.value as DistributionInstructionType;
    setInstructionType(value);
    resetForm({
      values: {
        ...initialValues,
        instructionType: value,
      },
    });
  };

  return (
    <Paper sx={{ p: 3, mt: 3, mb: 3 }}>
      <ErrorMessage name="error" />
      <Typography variant="inherit" sx={{ mb: 2, fontWeight: "bold" }}>
        Distribution Instruction Details
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <FormControl fullWidth sx={{ ml: 2, mt: 1 }}>
            <InputLabel id="instruction-type-label">
              Instruction Type
            </InputLabel>
            <Select
              labelId="instruction-type-label"
              id="instructionType"
              name="instructionType"
              label="Instruction Type"
              value={instructionType}
              onChange={handleInstructionTypeChange}
            >
              <MenuItem value="" disabled>Select Instruction Type</MenuItem>
              <MenuItem value={DistributionInstructionType.ACH}>ACH</MenuItem>
              <MenuItem value={DistributionInstructionType.Wire}>Wire</MenuItem>
              <MenuItem value={DistributionInstructionType.Brokerage}>Brokerage</MenuItem>
              <MenuItem value={DistributionInstructionType.Check}>Check</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {instructionType === DistributionInstructionType.Check && (
            <CheckInstructionFields />
          )}
          {instructionType === DistributionInstructionType.ACH && (
            <ACHInstructionFields />
          )}
          {instructionType === DistributionInstructionType.Wire && (
            <WireInstructionFields />
          )}
          {instructionType === DistributionInstructionType.Brokerage && (
            <BrokerageInstructionFields />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
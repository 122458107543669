import { CreateSubDocQuestionnaireDto, PatchSubDocQuestionnaireReq, PostSubDocQuestionnaireReq } from "../../../../api/payloads";
import { RevocableStrings, SubDocStatus } from "../../../../utility/constants";
import { isNullish } from "../../../../utility/misc.util";
import { SubDocsFormValues } from "./subDocsFormSchema";

export function getSubDocDto(values: SubDocsFormValues, currentStep: number) {
  const data: CreateSubDocQuestionnaireDto = {
    accredited_investor_answers: values.accreditedAnswers,
    advisor_email: values.advisorEmail,
    advisor_phone: values.advisorPhoneNumber,
    advisory_address: {
      street1: values.advisoryAddress.street1,
      street2: values.advisoryAddress.street2,
      city: values.advisoryAddress.city,
      stateId: values.advisoryAddress.stateId || null,
      zip: values.advisoryAddress.zip,
    },
    current_step: currentStep,
    distribution_instruction_id: values.distributionInstructionId || null,
    is_electronic_consent_granted: !isNullish(values.isElectronicDeliveryConfirmed) ? !!values.isElectronicDeliveryConfirmed : null,
    mailing_same_as_permanent_address: values.mailingSameAsPermanentAddress,
    mailing_address: {
      street1: values.mailingAddress.street1,
      street2: values.mailingAddress.street2,
      city: values.mailingAddress.city,
      stateId: values.mailingAddress.stateId || null,
      zip: values.mailingAddress.zip 
    },
    is_fund_subscriber: !isNullish(values.fundSubscriber) ? !!values.fundSubscriber : null,
    professional_id: values.advisorId ? values.advisorId : null,
    representation_of_residence_state_id: values.residenceStateId || null,
    status: SubDocStatus.InProgress,
    trust_state_id: values.stateOfIncorporation || null,
    authorized_representatives: values.authorizedRepresentatives.map(r => ({
      representativeContactId: r.representativeContactId || null, 
      representativeAddress: r.representativeAddress,
    })),
    beneficial_owners: values.beneficialOwners.map(o => ({
      ...o,
      dob: o.dob?.toISOString() ?? null,
    })),
    opts_out_beneficial_owners: values.optsOutBeneficialOwners ?? false,
    is_trust_fund_specific: !isNullish(values.fundSpecificEntity) ? !!values.fundSpecificEntity : null,
    is_trust_revocable: values.revocable ? values.revocable === RevocableStrings.Revocable : null,
    trust_beneficiaries: values.beneficiary,
    trust_date_of_incorporation: values.dateOfIncorporation ? values.dateOfIncorporation.toISOString() : null,
    trust_grantors: values.grantor,
    trust_type: values.typeOfTrust,
    trustees: values.trustees,
    is_funded: !isNullish(values.isFunded) ? !!values.isFunded : null,
    funding_date: values.fundingDate ? values.fundingDate.toISOString() : null,
    funding_type: values.fundingType,
    is_officer: !isNullish(values.isOfficer) ? !!values.isOfficer : null,
    investor_nature_of_relationship: values.investorNatureOfRelationship,
    investor_percent_owned: values.investorPercentOwned !== ""? values.investorPercentOwned: null,
    source_of_funds: values.sourceOfFunds,
  }

  return data;
}
import AttachmentIcon from "@mui/icons-material/Attachment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Link as MuiLink,
  Paper,
  styled,
  Toolbar,
  Typography
} from "@mui/material";
import { FC, MouseEvent, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { apiClient } from "../../../api/apiClient";
import { ContentBackground } from "../../../components/ContentBackground/ContentBackground";
import { LimitedLineTypography } from "../../../components/Typography/LimitedLineTypography";
import { useGpUpdates, useUI, useMaskedParams } from "../../../hooks";
import { formatDate } from "../../../utility/date.util";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  width: 150,
  height: 150,
  borderRadius: 10,
  objectFit: "cover",
});

export const GpUpdateDetailPage: FC = () => {
  const { gpUpdatesMap } = useGpUpdates();
  const { updateId } = useMaskedParams();
  const { setLoading, setError } = useUI();
  const update = useMemo(
    () => gpUpdatesMap[+updateId!] ?? {},
    [updateId, gpUpdatesMap]
  );

  const handleFileClick = useCallback(
    async (e: MouseEvent<any>, docId: number) => {
      try {
        e.preventDefault();
        setLoading(true);
        const resp = await apiClient.get("/documents/:p0", {
          routeParams: [docId],
        });
        window.open(resp.url);
      } catch (error) {
        setError("Unable to download file");
      } finally {
        setLoading(false);
      }
    },
    [setError, setLoading]
  );

  return (
    <Container sx={{ py: 2 }}>
      <ContentBackground>
        <Toolbar
          sx={{ flexDirection: "column", alignItems: "flex-start", pt: 2 }}
        >
          <Breadcrumbs sx={{ pb: 1 }}>
            <MuiLink component={Link} to="/gp-updates">
              GP Updates
            </MuiLink>
            <Typography>{update.title ?? ""}</Typography>
          </Breadcrumbs>
          <Typography variant="h5">GP Updates</Typography>
        </Toolbar>
        <ContentBackground
          sx={{ m: 2, mx: 4, backgroundColor: "background.paper" }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ alignItems: "center", display: "flex", mb: 1 }}>
                <Chip
                  label={update.name}
                  sx={{ mb: 0, mr: 2, fontWeight: "bold" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CalendarMonthIcon sx={{ mr: 1 }} />
                  <Typography variant="subtitle1">
                    {formatDate(update.date)}
                  </Typography>
                </Box>
              </Box>
              <Typography gutterBottom variant="h5">
                {update.title}
              </Typography>
            </Box>
            {update.attachments && update.attachments.length > 0 && (
              <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                {update.attachments.map((attachment) => (
                  <li key={attachment.id}>
                    <Paper
                      sx={{
                        p: 0,
                        width: 225,
                        my: 1,
                        backgroundColor: "background.secondary",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="text"
                        onClick={(e) => handleFileClick(e, attachment.id)}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <LimitedLineTypography
                          numLines={1}
                          sx={{ color: "primary.main" }}
                        >
                          {attachment.file_name}
                        </LimitedLineTypography>
                        <AttachmentIcon sx={{ color: "primary.main" }} />
                      </Button>
                    </Paper>
                  </li>
                ))}
              </ul>
            )}
          </Box>
          <Box sx={{ p: 2, pt: 0 }}>
            <Typography>{update.plain_updates}</Typography>
          </Box>
        </ContentBackground>
      </ContentBackground>
    </Container>
  );
};

// TODO: Ensure that the updated mailto: link is correct for new brand email
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CircleIcon from '@mui/icons-material/Circle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Dialog, DialogContent, DialogTitle,
    FormControlLabel,
    Grid,
    Link as MuiLink,
    Toolbar,
    Typography
} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import moment, { Moment } from 'moment';
import { FC, useCallback, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { InferType, object, string } from "yup";
import { apiClient } from "../../../api/apiClient";
import { AccreditedTransactionType, AccreditedVerification } from "../../../api/payloads";
import { CornerCloseButton, LoadingButton } from '../../../components/Buttons';
import { ContentBackground } from "../../../components/ContentBackground/ContentBackground";
import { ErrorContactEmail } from "../../../components/ErrorContactEmail/ErrorContactEmail";
import { useAuth, useClients, useInit, useMaskedParams, useOpportunities, useUI } from "../../../hooks";
import { AccountType } from "../../../utility/constants";
import { maskRouteId } from '../../../utility/route-mask.util';
import { FormikSubmitFn } from "../../../utility/types";
import { GetCustomerResp } from '../../../api/payloads/customer.payload';

type IndividualIraAndJoint = {
    investorLegalName: string,
    state: string,
    id: string,
}

type Question = {
    id: number;
    question: string;
};

const setLegalNameSchema = object({
    legalName: string().required("Investor Legal Name is required"),
});

const initialValues = { legalName: "" };

type SetLegalName = InferType<typeof setLegalNameSchema>;

type Entity = IndividualIraAndJoint & { customer_entity_id: number }

interface ContactOwner {
    customer_entity_id: number,
    state: string,
    contact_id: number;
    name: string;
}

export const AccreditedStatus: FC = () => {
    const { accountId, investmentId, clientId, transactionId } = useMaskedParams();
    const {investmentId: maskedInvestmentId} = useParams();
    const navigate = useNavigate()
    const [customer, setCustomer] = useState<GetCustomerResp>()
    const [accreditedAccount, setAccreditedAcccount] = useState([]) as any
    const { setLoading, setError } = useUI();
    const { clientsMap } = useClients()
    const { user } = useAuth()
    const { opportunitiesMap } = useOpportunities();
    const opportunity = useMemo(() => opportunitiesMap[+investmentId!] ?? {}, [investmentId, opportunitiesMap]);
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [verificationQuestions, setVerificationQuestions] = useState<AccreditedVerification[]>([])
    const [notAnAccreditedChoosen, setNotAnAccreditedChoosen] = useState(false);
    const [entityNotAnAccreditedChoosen, setEntityNotAnAccreditedChoosen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [disable, setDisable] = useState(false); 
    const [entityDisable, setEntityDisable] = useState(false) 
    const [verificationEntityQuestions, setVerificationEntityQuestions] = useState<AccreditedVerification[]>([])
    const [selectedInvestorQuestions, setSelectedInvestorQuestions] = useState<Record<string, number[]>>({});
    const [selectedEntityQuestions, setSelectedEntityQuestions] = useState<Record<string, number[]>>({});
    const [allOwnersAnswered, setAllOwnersAnswered] = useState(false);
    const [entityAnswered, setEntityAnswered] = useState(false)
    
    const ownervalues = accreditedAccount?.contact_owners?.map((owner: ContactOwner) => ({
        id: owner.contact_id,
        investorLegalName: owner.name,
        customer_entity_id: accreditedAccount?.customer_entity_id ?? null,
        state: customer?.address?.state
    }));

    const handleInvestorAnswerChange = (id: number, ownerId: string) => {
        const newSelectedAnswers = { ...selectedInvestorQuestions };
        newSelectedAnswers[ownerId] = [id];
      
        let disableState = false;
        let allOwnersAnswered = true;
        let notAnAccredited = false;
        ownervalues?.forEach((owner: IndividualIraAndJoint) => {
          if (newSelectedAnswers[owner?.id]?.includes(3)) {
            disableState = true;
            notAnAccredited = true;
          }
          if (!newSelectedAnswers[owner?.id]?.length) {
            allOwnersAnswered = false;
           }
        });
      
        setSelectedInvestorQuestions(newSelectedAnswers);
        setDisable(disableState);
        setAllOwnersAnswered(allOwnersAnswered);
        setNotAnAccreditedChoosen(notAnAccredited)
        
        if (id === 3) {
          setDialogOpen(true);
          setNotAnAccreditedChoosen(true);
        }
    };


    const handleEntityAnswerChange = (id: number, customer_entity_type_id: string) => {
       const newSelectedAnswers = {...selectedEntityQuestions};
        if (id === 2) {
          setDialogOpen(true);
          setEntityDisable(true);
          setEntityNotAnAccreditedChoosen(true);
          newSelectedAnswers[customer_entity_type_id] = [id];
        } else {
          setEntityDisable(false);
          setEntityNotAnAccreditedChoosen(false)
          newSelectedAnswers[customer_entity_type_id] = [id];
        }
        let entityAnswer = true;
        if (!newSelectedAnswers[customer_entity_type_id]?.length) {
            entityAnswer = false;
        }
        setEntityAnswered(entityAnswer)
        setSelectedEntityQuestions(newSelectedAnswers);
    };
   

    const init = useCallback(async () => {
        const customer = await apiClient.get("/customers/:p0", { routeParams: [clientId!] });
        const verificationQuesReponse = await apiClient.get("/investors/accredited-verification-questions");
        setVerificationQuestions(verificationQuesReponse)
        const account = customer.accounts.find(account => account.id === +accountId!)
        const entity = customer.entities.find(entity => entity.id === account?.customer_entity_id)
        const client = clientsMap[+clientId!];
        setAccreditedAcccount({ ...account, name: client.name, entity_name: entity?.name })
        setCustomer(customer)
        const entityQuestions = await apiClient.get('/investors/accredited-entity-questions');  
        setVerificationEntityQuestions(entityQuestions)     
    }, [clientId, setCustomer, accountId, clientsMap, setVerificationQuestions, setVerificationEntityQuestions])

    useInit(init)



    const handleSubmit: FormikSubmitFn<SetLegalName> = async (values, { setSubmitting }) => {
        try {
          if (!transactionId) {
            setError("Oops, something went wrong. Please try again later")
            return;
          }
            setLoading(true);
            setIsLoading(true)
            const now: Moment = moment();
            const payload: AccreditedTransactionType = {
                legalname: values?.legalName,
                timestamp: now.format('YYYY-MM-DD HH:mm:ss'),
                transaction_id: transactionId,
                user_id: user?.id,
                is_checked : isChecked,
                accredited_verification: selectedInvestorQuestions,
                entity_verification: accreditedAccount?.account_type_id === AccountType.Entity ? selectedEntityQuestions : null
            }
            const response = await apiClient.post('/investors/transactions/:p0/accreditations', { data: payload, routeParams: [+transactionId] })
            if (response) {
                navigate(`../${maskedInvestmentId}/bdc-interest-list`)
            }
        } catch (e) {
            setError(
                <div>
                    <Typography variant="h6">Something went wrong:</Typography>
                    <Typography variant="body1">
                        We were unable to proceed.
                        Please contact <ErrorContactEmail /> for assistance.
                    </Typography>
                </div>
            )
            setSubmitting(false);
        } finally {
            setLoading(false);
            setIsLoading(false)
        }
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => setIsChecked(event.target.checked);
    const isEntity = useMemo(() => accreditedAccount?.account_type_id === AccountType.Entity, [accreditedAccount])

    return (
        <Container sx={{ py: 2 }}>
            <ContentBackground>
                <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                    <DialogTitle>
                        Additional Information Needed 
                        <CornerCloseButton onClick={() => setDialogOpen(false)}></CornerCloseButton>
                    </DialogTitle>
                    <DialogContent> 
                        <Typography sx={{mb:2}}>Government Regulation requires Accredited Investor status to participate in the BDC. Based on your answer, additional information is required.</Typography>  
                        <Typography>Please contact the BIP Capital Investor Relations team at <a href="mailto:investorcommunications@bipcapital.com">investorcommunications@bipcapital.com</a> or 404-410-6508</Typography>
                    </DialogContent>     
                </Dialog>
                <Toolbar sx={{ alignItems: 'flex-start', pt: 2, pb: 2 }}>
                    <Box sx={{ display: 'flex', }}>
                        <ChevronLeftIcon 
                            onClick={() => navigate(`../${maskedInvestmentId}/bdc-interest-list`)}
                            sx={{ color: 'grey', fontSize: 30, cursor: 'pointer' }} 
                        />
                        <Box sx={{ display: 'flex', mt: 0.5 }}>
                            <Typography sx={{ fontWeight: 'bold' }}>Client</Typography>
                            <MuiLink component={Link} to={`/clients/${maskRouteId(clientId)}/overview`} sx={{ ml: 4 }}>{accreditedAccount?.name}</MuiLink>
                        </Box>
                        <Box sx={{ display: 'flex', mt: 0.5, ml: 5 }}>
                            <Typography sx={{ fontWeight: 'bold' }}> Account -</Typography>
                            <Typography sx={{ ml: 2 }}>{accreditedAccount?.account_name}</Typography>
                        </Box>
                    </Box>
                </Toolbar>
                {accreditedAccount?.account_type_id === AccountType.Entity && 
                <Box p={3}>
                    <Box sx={{backgroundColor:"#f1f2f6", w:1, borderTopLeftRadius: 5, borderTopRightRadius: 5}}>
                        <Typography sx={{p:1.5}}>Entity Name</Typography>
                    </Box>
                    <Accordion defaultExpanded={true}  sx={{ "&.Mui-expanded": { marginTop: 0 }} }>
                       <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{borderTop: 0}}
                       >
                      <Typography sx={{fontWeight:"bold", color:"#000"}}>{accreditedAccount?.entity_name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="h6">Accredited Investor Verification for Entity</Typography>
                        <Box p={3}>
                            <Typography pb={2}>
                            Proposed  Investor  is  subscribing  for  common  shares  of  beneficial  interest  (“Shares”)  in  {opportunity.name}, a Delaware statutory trust (the “BDC”).   The Shares are being sold through an offering (the “Offering”) made pursuant to Rule 506(c) of Regulation D under the Securities Act of 1933, as amended (the “Securities Act”), which imposes a requirement that the BDC take reasonable steps to verify that Proposed Investor is an “accredited investor” as defined in Rule 501(a) of Regulation D of the Securities Act (“Accredited Investor”).
                            </Typography>
                            <Typography pb={2}>
                                <strong>1. Accredited Investor Status of Proposed Investor. </strong> Verifying RIA confirms that, within three months of the date hereof, Verifying RIA has confirmed that Proposed Investor is (please select at least one of the following):
                            </Typography>
                            {verificationEntityQuestions?.map((data:Question, index) => (
                                <Box key={`verification-entity-question${index}`} pb={2}>
                                    <FormControlLabel
                                      control={
                                        <Radio
                                        checked={selectedEntityQuestions[accreditedAccount?.customer_entity_type_id]?.includes(data.id) ?? false}
                                        onChange={() => handleEntityAnswerChange(data.id, accreditedAccount?.customer_entity_type_id)}
                                        name={`question-${accreditedAccount?.customer_entity_type_id}`}
                                        value={data.id}
                                        color="primary"
                                     />
                                    }
                                    label={data.question}
                                    />                                  
                                </Box>        
                           ))}
                           <Typography>
                            <strong>2. Reliance by BDC. </strong>   Verifying RIA understands the meaning and legal consequences of the written confirmations made  by  Verifying  RIA  herein,  and  acknowledges  that  the  BDC  is  relying  on  the  verification:  (i)  in  making  a determination to accept or reject the proposed investment in Shares through the Offering by Proposed Investor, and (ii) for purposes of complying with Rule 506(c) of under Regulation D of the Securities Act.  Verifying RIA hereby represents to the BDC that Verifying RIA has taken reasonable steps to determine Proposed Investor’s Accredited Investor status in accordance with Rule 506(c) and that the information contained herein is complete and accurate to the best of Verifying RIA’s knowledge.
                           </Typography>
                        </Box> 
                       </AccordionDetails>
                    </Accordion>
                </Box>
                }
                <Box p={3}>
                    { isEntity &&
                        <Typography fontWeight='bold' mb={1}>
                            Please complete the below-accredited investor verification form for each of the entity's equity owners.
                        </Typography>
                    }
                    <Box sx={{backgroundColor:"#f1f2f6", w:1, borderTopLeftRadius: 5, borderTopRightRadius: 5}}>
                        <Typography sx={{p:1.5}}>Investor Legal Name</Typography>
                    </Box>
                    {ownervalues?.map((owner : IndividualIraAndJoint, index: number)=>(
                       <Accordion  key={`owner-values${index}`} defaultExpanded={true}  sx={{ "&.Mui-expanded": { marginTop: 0 } }}>
                       <AccordionSummary
                         expandIcon={<ExpandMoreIcon />}
                         aria-controls="panel1a-content"
                         id="panel1a-header"                         
                       >
                       <Typography sx={{fontWeight:"bold", color:"#000"}}>{owner.investorLegalName}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="h6">Accredited Investor Verification</Typography>
                        <Box p={3}>
                            <Typography pb={2}>
                            Proposed  Investor  is  subscribing  for  common  shares  of  beneficial  interest  (“Shares”)  in  {opportunity.name}, a Delaware statutory trust (the “BDC”).   The Shares are being sold through an offering (the “Offering”) made pursuant to Rule 506(c) of Regulation D under the Securities Act of 1933, as amended (the “Securities Act”), which imposes a requirement that the BDC take reasonable steps to verify that Proposed Investor is an “accredited investor” as defined in Rule 501(a) of Regulation D of the Securities Act (“Accredited Investor”).
                            </Typography>
                            <Typography pb={2}> <strong>1. Status of Verifying RIA.</strong>   Verifying RIA certifies that, as of the date hereof, Verifying RIA is an investment adviser registered with the U.S. Securities and Exchange Commission.</Typography>

                            <Typography pb={2}> <strong> 2.  Accredited Investor Status of Proposed Investor.</strong>  Verifying RIA confirms that, within three months of the date hereof, Verifying RIA has confirmed that Proposed Investor is (please select one of the following):</Typography>
                            {verificationQuestions?.map((data:Question, index: number) => {
                               return (
                                <Box  key={`verification-question${index}`} pb={2}>
                                    <FormControlLabel
                                      control={
                                        <Radio
                                           checked={selectedInvestorQuestions[owner.id]?.includes(data.id) ?? false}
                                           onChange={() => handleInvestorAnswerChange(data.id, owner.id)}
                                           name={`question-${owner.id}`}
                                           value={data.id}
                                           color="primary"
                                        />
                                        }
                                        label={data.question}
                                    />                                  
                                </Box>        
                           )})}
                           <Typography><strong>3. Reliance by BDC.</strong>   Verifying RIA understands the meaning and legal consequences of the written confirmations made  by  Verifying  RIA  herein,  and  acknowledges  that  the  BDC  is  relying  on  the  verification:  (i)  in  making  a determination to accept or reject the proposed investment in Shares through the Offering by Proposed Investor, and (ii) for purposes of complying with Rule 506(c) of under Regulation D of the Securities Act.  Verifying RIA hereby represents to the BDC that Verifying RIA has taken reasonable steps to determine Proposed Investor’s Accredited Investor status in accordance with Rule 506(c) and that the information contained herein is complete and accurate to the best of Verifying RIA’s knowledge.</Typography>
                        </Box>
                       </AccordionDetails>
                      </Accordion>
                    ))}               
                </Box>
                <>
                 {notAnAccreditedChoosen || entityNotAnAccreditedChoosen ? 
                 <Box sx={{ p: 3, backgroundColor:"#fff" }}>
                    <Box sx={{ml:5}}>
                      <Typography sx={{mb:2}} variant="h6"> Additional Information Needed </Typography>
                      <Typography sx={{mb:2}}>Government Regulation requires Accredited Investor status to participate in the BDC. Based on your answer, additional information is required.</Typography>  
                      <Typography>Please contact the BIP Capital Investor Relations team at <a href="mailto:investorcommunications@bipcapital.com">investorcommunications@bipcapital.com</a> or 404-410-6508</Typography>
                    </Box>  
                    <Box sx={{display:"flex", justifyContent:"flex-end"}}>
                      <Button  onClick={() => navigate(`../${maskedInvestmentId}/bdc-interest-list`)}>Exit</Button>                            
                    </Box>   
                 </Box>
                  :
                  <>
                   <Box sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', mb: 5, ml:2 }}>
                        <Box >
                            <CircleIcon sx={{ fontSize: "10px", color: '#1c252c' }} />
                        </Box>
                        <Typography sx={{ ml: 2 }}>
                            This written confirmation of Investor’s status as an “accredited investor” may be relied upon by any issuer and any of its partners, agents, affiliates, or participating platforms in connection with any transaction it may conduct pursuant to Rule 506 under the Securities Act. It may not be used or relied upon by an issuer or any other party for any other purpose.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Box >
                            <Checkbox                                
                                disabled={
                                    accreditedAccount?.account_type_id === AccountType.Entity
                                      ? !allOwnersAnswered || disable || entityDisable || !entityAnswered
                                      : !allOwnersAnswered || disable 
                                }
                                checked={isChecked} 
                                onChange={handleCheckboxChange}
                                required
                                sx={{
                                    color: 'primary.main',
                                    '&.Mui-checked': {
                                        color: 'primary.main',
                                    },
                                }}
                            />
                        </Box>
                        <Typography sx={{ ml: 1 }}>
                            By checking the box below, I agree that the electronic digitized signatures I apply on the following document are representations of my signature and are legally valid and binding as if I had signed the document with ink on paper in accordance with the Uniform Electronic Transactions Act (UETA) and the Electronic Signatures in Global and National Commerce Act (E-SIGN) of 2000.
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ p: 3, backgroundColor: 'background.paper', mt: 5 }}>
                    <Formik initialValues={initialValues} validationSchema={setLegalNameSchema} onSubmit={(handleSubmit)}>
                        {({ isValid, dirty, errors }) => (
                            <Form>
                                <Grid sx={{ mb: 1 }}>
                                    <Field                                     
                                        disabled={
                                           accreditedAccount?.account_type_id === AccountType.Entity
                                            ? !allOwnersAnswered || disable || entityDisable || !entityAnswered
                                            : !allOwnersAnswered || disable 
                                        }
                                        name="legalName" label="Legal Name*" component={TextField} sx={{ width: 0.25 }} 
                                    />
                                </Grid>
                                <LoadingButton disabled={!isChecked || disable || entityDisable} loading={isLoading} type="submit">Complete</LoadingButton>
                            </Form>
                        )}
                    </Formik>
                </Box>
                </>
                }
                </>
            </ContentBackground>
        </Container >
    )
}

import { Breadcrumbs, Card, CardContent, Container, Link as MuiLink, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { FC } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { InferType, object, string } from 'yup';
import { LoadingButton } from '../../../components/Buttons';
import { useUI } from '../../../hooks';
import { isError } from "../../../utility/type-guards";
import { FormikSubmitFn } from '../../../utility/types';

const updatePasswordFormSchema = object({
  currentPassword: string().required("Current password is required"),
  confirmPassword: string().required("Confirm New Password is required"),
  newPassword: string().required("New Password is required")
});

type UpdatePasswordData = InferType<typeof updatePasswordFormSchema>;

const initialValues: UpdatePasswordData = { confirmPassword: '', currentPassword: '', newPassword: '', };

/** 
 * Form for handling the Forced Password Update case of logging in 
 * @todo Consider generalizing components like this; especially if you found yourself duplicating it as a reference
 */
 export const ChangePasswordPage: FC = () => {
  const ui = useUI();
  const navigate = useNavigate();
  const location = useLocation();
  const handleSubmit: FormikSubmitFn<UpdatePasswordData> = async ({ currentPassword, confirmPassword, newPassword }, { setSubmitting }) => {
    if (newPassword === confirmPassword) {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(cognitoUser, currentPassword, newPassword);
        ui.setSuccess("Successfully updated password");
        const origin = location.state?.from?.pathname ?? "/";
        navigate(origin);
      } catch (e) {
        isError(e) && ui.setError(e.message);
      }
    } else {
      ui.setError("New password does not match confirm Password");
    }
  };
  return (
      <Container component="main" maxWidth="xs" sx={{pt: 8}}>
          <Card sx={{ p: 4, pt: 2 }} variant="elevation" elevation={1}>
            <CardContent sx={{
              '& .MuiTextField-root': { mb: 1 },
              '& button[type="submit"].MuiButton-root': { fontWeight: 'bold' }
            }}>
              <Breadcrumbs sx={{ pb: 1 }}>
                <MuiLink component={Link} to="/settings">Settings</MuiLink>
                <Typography>Update Password</Typography>
              </Breadcrumbs>
            <Typography variant="h5" mb={3}><b>Update Password</b></Typography>
            <Formik initialValues={initialValues} validationSchema={updatePasswordFormSchema} onSubmit={(handleSubmit)}>
            <Form >
              <Field name="currentPassword" label="Current Password" type="password" component={TextField} fullWidth/>
              <Field name="newPassword" label="New Password" type="password" component={TextField} fullWidth/>
              <Field name="confirmPassword" label="Confirm New Password" type="password" component={TextField} fullWidth/>
              <LoadingButton type="submit" size="large" fullWidth loading={false}>
                Update Password
              </LoadingButton>
            </Form>
          </Formik>
          </CardContent>
        </Card>
      </Container>
  );
}
import { Typography } from "@mui/material"
import { ErrorContactEmail } from "../ErrorContactEmail/ErrorContactEmail"
import { FC, PropsWithChildren } from "react"

type ErrorToastProps = {
  header?: string,
  showContactEmail?: boolean,
}
export const ErrorToast: FC<PropsWithChildren<ErrorToastProps>> = (props) => {
  return (
    <div>
      <Typography variant="h6">{props.header ?? "Something went wrong:"}</Typography>
      <Typography variant="body1">{props.children}</Typography>
      { !!props.showContactEmail &&
        <Typography variant="body1">Please contact <ErrorContactEmail /> for assistance.</Typography>
      }
    </div>
  )
}
import { CloudUpload } from "@mui/icons-material"
import { Button, ButtonGroup, Tooltip } from "@mui/material"
import { FC, useCallback, useRef } from "react"
import { apiClient } from "../../api/apiClient"
import { useLoadingWrapper } from "../../hooks"
import { LoadingButton } from "./LoadingButton"

type DownloadUploadButtonProps = {
  documentId: number,
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>,
  uploadDisabled?: boolean,
  file?: File,
  accept?: string
}

export const DownloadUploadSplitButton: FC<DownloadUploadButtonProps> = (props) => {
  const { documentId, handleFileChange, file, accept, uploadDisabled } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [handleFileChangeWithLoadingWrapper, isLoading] = useLoadingWrapper(handleFileChange)

  const handleDownload = useCallback(() => {
    apiClient.get('/documents/:p0', { routeParams: [documentId] }).then((r) => window.open(r.url));
  }, [documentId]);

  return (
    <ButtonGroup size='small' color={documentId ? 'success' : 'info'} variant="contained" aria-label="Button group with upload and download" disableElevation>
      <Button disabled={!documentId} onClick={handleDownload}>
        Download
      </Button>
      <LoadingButton
        disabled={uploadDisabled ?? false}
        loading={isLoading}
        onClick={() => fileInputRef.current?.click()}
        aria-label="Upload"
      >
        <Tooltip title='Upload'><CloudUpload /></Tooltip>
      </LoadingButton>
      <input
        type="file"
        value={file ? file.name : ''}
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChangeWithLoadingWrapper}
        accept={accept}
      />
    </ButtonGroup>
  )
}
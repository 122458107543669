import { CancelOutlined, LocationOn, Search } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Container, Dialog, IconButton, InputAdornment, Link as MuiLink, Paper, TableContainer, TextField, Toolbar, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Professional } from "../../../api/payloads";
import { ContentBackground } from "../../../components/ContentBackground/ContentBackground";
import { DataSourceTable } from "../../../components/DataSourceTable";
import { AddressLine } from "../../../components/Typography/AddressLine";
import { useAuth, useInit, useProfessionals, useUI } from "../../../hooks";
import { apiClient } from "../../../api/apiClient";
import { ErrorContactEmail } from "../../../components/ErrorContactEmail/ErrorContactEmail";
import AdminAddProfDialog from "../Dialogs/AdminAddProfDialog";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog/ConfirmationDialog";
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

type OpenDeleteDialogFn = (professionalId: number) => void;

export const getColumnDef = ( isAdmin: boolean, handleOpenDeleteDialog: OpenDeleteDialogFn) => [
  {
    id: "first_name",
    label: "Name",
    sortable: true,
    displayFn: (_v: any, row: Professional) => (<Typography>{`${row.first_name ?? ""} ${row.last_name ?? ""}`}</Typography>),
  },
  {
    id: "phone",
    label: "Phone",
    sortable: true,
  },
  {
    id: "email",
    label: "Email",
    sortable: true,
    displayFn: (v: string) => <MuiLink href={`mailto:${v}`}>{v}</MuiLink>,
  },
  {
    id: "delete",
    label: "",
    displayFn: (_v: any, row: Professional) =>
      isAdmin ? (
        < IconButton
          color="error"
          sx={{ fontFamily: "inherit" }}
          size="small"
          onClick={() => handleOpenDeleteDialog(row.id)}
        >
         <ClearIcon/>
        </IconButton>
      ) : null,
  },
];

export const FirmPage: FC = () => {
  const { advisory, professionalsList, setProfessionalsList, countries, setCountries, phoneTypes, setPhoneTypes} = useProfessionals();
  const { setSuccess, setLoading, setError } = useUI();
  const [showAddProfessionalModal, setShowAddProfessionalModal] = useState(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [professionalToDelete, setProfessionalToDelete] = useState<number | null>(null);
  const { user } = useAuth();
  const isAdmin = !!user && professionalsList.some((professional) => professional.id === user.id && professional.admin);

  const [searchFilter, setSearchFilter] = useState("");

  useInit(async () => {
    if (countries.length && phoneTypes.length) {
      return;
    }
    const resp = await apiClient.get("/customers/phone-options");
    setCountries(resp.countries);
    setPhoneTypes(resp.phoneNumberTypes);
  }, null, false);

  const filteredRows = useMemo(() => {
    return professionalsList
      .filter(
        (r) =>
          r.first_name?.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase()) ||
          r.last_name?.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase()) ||
          r.email?.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase())
      )
      .sort((a, b) => a.first_name?.localeCompare(b.first_name));
  }, [professionalsList, searchFilter]);

  const address = advisory
    ? `${advisory.street_1 ?? ""} ${advisory.street_2 ?? ""} ${
        advisory.city_name ?? ""
      }${advisory.city_name ? "," : ""} ${advisory.state_name ?? ""} ${
        advisory.zip_code ?? ""
      }`
    : "";

  const refreshProfessionalData = useCallback(async () => {
    try {
      setLoading(true);
      const resp = advisory &&
        (await apiClient.get("/professionals/advisories/:p0/professionals", {
          routeParams: [advisory.id],
        }));
      resp && setProfessionalsList(resp.professionalsList);
    } catch (error) {
      setError(
        <div>
          <Typography variant="h6">Something went wrong:</Typography>
          <Typography variant="body1">
            We were unable to load your data after updating the professionals.
            Please contact <ErrorContactEmail /> for assistance.
          </Typography>
        </div>
      );
    } finally {
      setLoading(false);
    }
  }, [advisory, setProfessionalsList, setLoading, setError]);

  const handleAddProfessional = useCallback(() => {
    setShowAddProfessionalModal(true);
  }, []);

  const closeAddProfessionalModal = async (success?: boolean) => {
    setShowAddProfessionalModal(false);
    if (!success) return;
    refreshProfessionalData();
  };

  const handleOpenDeleteDialog = useCallback((professionalId: number) => {
      setProfessionalToDelete(professionalId);
      setShowDeleteConfirmDialog(true);
    },[setProfessionalToDelete, setShowDeleteConfirmDialog]
  );

  const handleCloseDeleteDialog = (success?: boolean) => {
    if (success) handleConfirmDelete();
    setProfessionalToDelete(null);
    setShowDeleteConfirmDialog(false);
  };

  const handleConfirmDelete = useCallback(async () => {
    if (professionalToDelete !== null) {
      try {
        setLoading(true);
        const resp = await apiClient.patch(`/professionals/:p0`, {
          routeParams: [professionalToDelete],
          data: { deleted: true },
        });
        setSuccess(resp.message);
        refreshProfessionalData();
      } catch (error) {
        setError(
          <div>
            <Typography variant="h6">Deletion Error:</Typography>
            <Typography variant="body1">
              We were unable to delete the professional. Please contact{" "}
              <ErrorContactEmail /> for assistance.
            </Typography>
          </div>
        );
      } finally {
        setLoading(false);
      }
    }
  }, [professionalToDelete, setLoading, setError, refreshProfessionalData, setSuccess]);
  
  const columnDef = useMemo(() => 
    getColumnDef(isAdmin, handleOpenDeleteDialog),
    [isAdmin, handleOpenDeleteDialog]
  );

  return (
    <Container sx={{ py: 2 }}>
      <ContentBackground >
        <Toolbar sx={{ flexDirection: 'column', alignItems: 'flex-start', pt: 2, pb: 2 }}>
          <Breadcrumbs sx={{ pb: 1 }}>
            <MuiLink component={Link} to="/settings">Settings</MuiLink>
            <Typography>Firm</Typography>
          </Breadcrumbs>
          <Typography variant="h5" >{advisory?.name ?? ""}</Typography>
          <AddressLine display="flex" alignItems='center' mb={1}>
            <LocationOn fontSize="small" sx={{ mr: 1 }} />
            <Typography color={grey[600]}>{`Corporate Address: ${address}`}</Typography>
          </AddressLine>
        </Toolbar>
        <Box p={3}>
          <Box component="section" mb={1}>
            <Box sx={{ justifyContent: "space-between", display: 'flex', mb: 1 }}>
              <Typography variant="h5" mb={1}>Professionals</Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {isAdmin && (
                  <Button color="info" onClick={handleAddProfessional} startIcon={<AddIcon />} sx={{ mr: 2 }}>
                    Add Professional
                  </Button>
                )}
                <TextField
                  value={searchFilter}
                  onChange={(event) => setSearchFilter(event.target.value)}
                  placeholder="Search Professionals"
                  size="small"
                  helperText="" //Empty string to remove the helper space padding, since we've set our theme's default helperText value as " ";
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            <TableContainer component={Paper}>
              <DataSourceTable columnDef={columnDef} dataSource={filteredRows} emptyMessage="No professionals" />
            </TableContainer>
          </Box>
        </Box>
        <Dialog
          open={showAddProfessionalModal}
          onClose={() => closeAddProfessionalModal()}
          fullWidth
          maxWidth="sm"
        >
          <AdminAddProfDialog onClose={closeAddProfessionalModal} />
        </Dialog>
        <ConfirmationDialog
          maxWidth="xs"
          open={showDeleteConfirmDialog}
          handleClose={handleCloseDeleteDialog}
          yesButtonLabel="Delete"
          noButtonLabel="Cancel"
          message={`You are about to remove ${
            professionalsList.find((p) => p.id === professionalToDelete)?.first_name
          } ${
            professionalsList.find((p) => p.id === professionalToDelete)?.last_name
          } from this firm's professionals list.`}
          titleNode={
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <CancelOutlined color="error" sx={{ fontSize: "4rem", mb: 3 }} />
              <Typography variant="h4" fontWeight="bold">
                Are you sure?
              </Typography>
            </Box>
          }
          sx={{
            "& .MuiButton-root.MuiButton-containedPrimary": {
              backgroundColor: "error.main",
              "&:hover": {
                backgroundColor: "error.dark",
              },
            },
          }}
        />
      </ContentBackground>
    </Container>
  );
};
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { IconButton, styled, TableCell, TableRow, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import { useState } from "react";
import { DataSourceTableProps } from "../DataSourceTable.types";
import { DataRow } from "./DataRow";

const StyledRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.background.secondary,
}))

type ExpandableRowProps = DataSourceTableProps & { name: string, secondary: string | null};

export const ExpandableRow = ({ columnDef, dataSource, openInitial = false, name, secondary }: ExpandableRowProps) => {
  const [open, setOpen] = useState(openInitial);
  return (
    <>
      <StyledRow>
        <TableCell colSpan={columnDef.length} sx={{ py: 1 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
          <Box sx={{display: 'inline-flex'}}>
          <Typography>{name}</Typography>
          {secondary && <Typography sx={{ml: 2, color: grey[500], fontSize: "1rem"}}>{secondary}</Typography>}
          </Box>
        </TableCell>
      </StyledRow>
      {open && dataSource.map((data) => (
        <DataRow key={data.id} {...{ data, columnDef }} />
      ))}
    </>
  )
}
import { Auth } from "aws-amplify";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { FC, useState } from "react";
import { InferType, object, string } from "yup";
import { LoadingButton } from "../../../components/Buttons";
import { useUI } from "../../../hooks";
import { isError } from "../../../utility/type-guards";
import { AmplifyForgotPasswordResp, FormikSubmitFn } from "../../../utility/types";
import { ResetPasswordStage } from "../ResetPassword";

const resetPasswordRequestFormSchema = object({
  email: string().email("Please enter valid email").required("Email Required"),
});

type ResetPasswordRequestData = InferType<typeof resetPasswordRequestFormSchema>;

const initialValues: ResetPasswordRequestData = { email: '' };

const isSMSDelivery = (resp: AmplifyForgotPasswordResp) => {
  return resp.CodeDeliveryDetails?.DeliveryMedium === "SMS";
}

type ResetPasswordRequestProps = {
  setResetStage: React.Dispatch<React.SetStateAction<ResetPasswordStage>>,
  setEmail: React.Dispatch<React.SetStateAction<string>>,
}

/** 
 * Form for handling the Forced Password Update case of logging in 
 */
export const ResetPasswordRequestForm: FC<ResetPasswordRequestProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const ui = useUI();

  const handleSubmit: FormikSubmitFn<ResetPasswordRequestData> = async ({ email }, { setSubmitting }) => {
    try {
      setLoading(true);
      const resp = await Auth.forgotPassword(email) as AmplifyForgotPasswordResp;
      if (!isSMSDelivery(resp)) {
        throw new Error("Can't reset temporary password. Please login with temporary password, or contact support at investorcommunications@bipcapital.com");
      }
      
      props.setEmail(email);
      props.setResetStage(ResetPasswordStage.SetPassword);

    } catch (e) {
      isError(e) && ui.setError(e.message);
      setSubmitting(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={resetPasswordRequestFormSchema} onSubmit={(handleSubmit)}>
      <Form >
        <Field name="email" label="Email" component={TextField} fullWidth />
        <LoadingButton  type="submit" size="large" fullWidth loading={loading}>
          Reset Password
        </LoadingButton>
      </Form>
    </Formik>
  );
}
import React, { createContext, FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { GetGpUpdatesResp } from "../api/payloads";
import { createObjectMap } from "../utility/array.util";

export type GpUpdate = GetGpUpdatesResp[number];

export type GpUpdatesContextValue = {
  gpUpdates: GpUpdate[],
  setGpUpdates: React.Dispatch<React.SetStateAction<GpUpdate[]>>,
  gpUpdatesMap: Record<GpUpdate['id'], GpUpdate>
}

export const GpUpdatesContext = createContext(undefined as unknown as GpUpdatesContextValue);

export const GpUpdatesContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [gpUpdates, setGpUpdates] = useState<GpUpdatesContextValue['gpUpdates']>([]);
  const [gpUpdatesMap, setGpUpdatesMap] = useState<GpUpdatesContextValue['gpUpdatesMap']>({});

  const value = useMemo(() => ({ 
    gpUpdates,
    setGpUpdates,
    gpUpdatesMap
  }), [gpUpdates, setGpUpdates, gpUpdatesMap]);

  useEffect(() => {
    const map = createObjectMap(gpUpdates, 'id');
    setGpUpdatesMap(map);
  }, [gpUpdates])

  return (
    <GpUpdatesContext.Provider value={value}>
      {children}
    </GpUpdatesContext.Provider>
  )
}
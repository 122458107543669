import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Link as MuiLink,
  Paper,
  TableContainer,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ContentBackground } from "../../components/ContentBackground/ContentBackground";
import { ColumnDef, DataSourceTable } from "../../components/DataSourceTable";
import { TablePaginator } from "../../components/TablePaginator/TablePaginator";
import { Client } from "../../context/ClientsContext";
import { useClients, usePaginator } from "../../hooks";
import { toCurrency } from "../../utility/number.util";
import { maskRouteId } from "../../utility/route-mask.util";

const columnDef: ColumnDef<Client> = [
  {
    id: "name",
    label: "Client",
    displayFn: (v, row) => (
      <MuiLink component={Link} to={`/clients/${maskRouteId(row.id)}/overview`}>
        {v}
      </MuiLink>
    ),
    sortable: true,
    sortValueFn: (v: string | null) => v?.toLocaleLowerCase() ?? "",
  },
  {
    id: "number_of_investments",
    label: "Active Investments",
    displayFn: (v: number) => v.toString().padStart(2, "0"),
    sortable: true,
  },
  {
    id: "total_commitment",
    label: "Total Commitment",
    displayFn: (v) => toCurrency(v, false),
    sortable: true,
  },
  { id: "total_paid",
    label: "Amount Paid", 
    displayFn: (v) => toCurrency(v),
    sortable: true,
  },
  { id: "total_efv",
    label: "Fair Value",
    displayFn: (v) => toCurrency(v),
    sortable: true,
  },
];

export const ClientsPage: FC = (props) => {
  const { clients } = useClients();
  const [searchFilter, setSearchFilter] = useState("");
  const [filteredRows, setFilteredRows] = useState(clients);
  const navigate = useNavigate();

  useEffect(() => {
    //TODO: Look into some kind of debouncing strategy with a hook or something to improve performance
    const filteredRows = clients.filter((r) =>
      r.name.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase())
    );
    setFilteredRows(filteredRows);
  }, [searchFilter, clients]);

  const {
    currentPage,
    currentRowsPerPage,
    setCurrentRowsPerPage,
    paginatedRows,
    numberOfPages,
    handlePageChange,
  } = usePaginator(filteredRows, -1);

  const handleNewClientClick = () => {
    navigate("/clients/new-client");
  };

  return (
    <Container sx={{ py: 2 }}>
      <ContentBackground>
        <Toolbar sx={{ justifyContent: "space-between", py: 2 }}>
          <Typography variant="h5">My Clients</Typography>
          <Button
            color="info"
            onClick={handleNewClientClick}
            startIcon={
              <Box>
                <AddIcon sx={{ fontSize: 14 }} />
              </Box>
            }
            sx={{ py: 0 }}
          >
            Add New Client
          </Button>
        </Toolbar>
        <Box p={3} sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
            value={searchFilter}
            onChange={(event) => setSearchFilter(event.target.value)}
            placeholder="Search Clients"
            size="small"
            sx={{ alignSelf: "flex-end", pb: 2 }}
            helperText="" //Empty string to remove the helper space padding, since we've set our theme's default helperText value as " ";
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <DataSourceTable
              dataSource={paginatedRows}
              columnDef={columnDef}
              emptyMessage="No clients"
              initialOrderBy={"name"}
            />
          </TableContainer>
          <TablePaginator
            page={currentPage}
            count={numberOfPages}
            currentRowsPerPage={currentRowsPerPage}
            setCurrentRowsPerPage={setCurrentRowsPerPage}
            onChange={handlePageChange}
          />
        </Box>
      </ContentBackground>
    </Container>
  );
};

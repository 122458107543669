import { Box, MenuItem, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import { FC, useCallback, useEffect, useState } from "react";
import { apiClient } from "../../../../api/apiClient";
import { useClients, useInit } from "../../../../hooks";
import { useSubDocs } from "../../../../hooks/ContextHooks/useSubDocs";
import { AccountType } from "../../../../utility/constants";
import { RepresentationOfResidenceFormValues } from "./subDocsFormSchema";

export const RepresentationOfResidence: FC = () => {
  const {subDocsAccount} = useSubDocs();
  const {countries} = useClients();
  const { values, setFieldValue } = useFormikContext<RepresentationOfResidenceFormValues>();
  const {addressStates, setAddressStates} = useClients();
  const [ownerLabel, setOwnerLabel] = useState<string>("");

  const init = useCallback(async () => {
    if (addressStates == null) {
      const states = await apiClient.get("/customers/states");
      setAddressStates(states);
    }
  }, [addressStates, setAddressStates])

  useInit(init);

  useEffect(() => {
    let owner;
    owner = subDocsAccount && subDocsAccount.contact_owners.length >= 1 ? subDocsAccount.contact_owners[0]?.name : null
    switch(subDocsAccount?.account_type_id as AccountType) {
      case AccountType.IRA:
        setOwnerLabel("Beneficiary Name")
        break;
      case AccountType.Individual:
        setOwnerLabel("Account Owner")
        break;
      case AccountType.Entity:
        setOwnerLabel("Entity Name")
        owner = subDocsAccount?.account_name ?? null
        break;
      case AccountType.Joint:
        setOwnerLabel("Account Owner ")
        owner = subDocsAccount && subDocsAccount.contact_owners.length > 1 ? subDocsAccount.contact_owners.find(owner => owner.is_ssn_contact === true)?.name : null
        break;
      default:
        break;
    }
    setFieldValue("residenceAccountOwner", owner ?? "")
  },[setFieldValue, subDocsAccount])

  return (
    <>
    <Box sx={{py:2}}>
      <Typography>In order for the Company to verify the residence of the Investor and to obtain a written representation from each Investor as to the Investor’s legal residence, please complete the following:</Typography>
      <Typography sx={{mt:3, fontWeight: "bold"}} >Investor is a resident of:</Typography>
    </Box>
    <Box sx={{ display: 'flex', py: 2,}}>      
        <Field  
          component={TextField}
          required
          sx={{width: 'calc(33% - 8px)', mr: 2}}
          disabled
          value={values.residenceAccountOwner}
          label={ownerLabel}
          name="residenceAccountOwner"/>
        <Field
          formControl={{ sx:{width: 'calc(33% - 8px)', mr: 2}}}
          component={Select}
          label="Country"
          disabled
          name='residenceCountryId'
        >
          {countries.map(c => <MenuItem key={c.id} value={`${c.dial_code};${c.name}`}>{c.name}</MenuItem>)}
        </Field>
        <Field
          as='select' 
          name="residenceStateId" 
          label="State" 
          component={Select} 
          formControl={{ required: true, sx:{width: 'calc(33% - 8px)'}}}
          value={values.residenceStateId ?? ""}
          onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
        >
          {addressStates != null && addressStates.map(state => (
            <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
          ))}
        </Field>
    </Box>
    </>
  )
}
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Box } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Select, TextField } from "formik-mui";
import { InferType, object, string } from "yup";
import { apiClient } from "../../../../api/apiClient";
import { useUI } from "../../../../hooks";
import { FormikSubmitFn } from "../../../../utility/types";
import { CornerCloseButton, LoadingButton } from "../../../../components/Buttons";
import { Contact } from "../../../../api/payloads/customer.payload";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "100px",
    },
  },
};

const exchangeUserSchema = object({
  password: string().required("Password is required")
  .test('password', 'Password must be at least 8 characters long', (value: any) => {
    return value && value.length >= 8;
  })
  .test('uppercase', 'Password must contain at least one uppercase letter', (value: any) => {
    return /[A-Z]/.test(value );
  })
  .test('lowercase', 'Password must contain at least one lowercase letter', (value: any) => {
    return /[a-z]/.test(value);
  })
  .test('digit', 'Password must contain at least one digit', (value: any) => {
    return /\d/.test(value);
  })
  .test(
    'special',
    'Password must contain a special character (^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ ` = + - )',
    (value: any) => {
      return /[!@#$%^&*(),.?"':;{}|<>\\+_~`[\]\-=/]/.test(value);
    })
  ,
  phonenumberID: string().required('Phone Number is required')
});

type ExchangeData = InferType<typeof exchangeUserSchema>;

type Props = {
  setClose: any,
  member: Contact
}

export default function RegisterVisionUser({ setClose, member }: Props) {
  const { loading, setLoading, setError, setSuccess } = useUI()

  const initialValues: ExchangeData = {
    password: "",
    phonenumberID:""
  };
  
  const handleSubmit: FormikSubmitFn<ExchangeData> = async (values, { setSubmitting }) => {
    setLoading(true);
    const phone = member?.phone_numbers?.filter(p => p.id === Number(values.phonenumberID) )
    const phoneNumber = `+${phone[0]?.dial_code}${phone[0].number.replaceAll('-','')}` ;
    try {
      const payload = {
        first_name : member?.first_name,
        username: member?.email,
        password: values.password,
        email: member?.email,
        phone_number: phoneNumber,
        contact_id: member?.id,
        contact_number_id: Number(values.phonenumberID),
      }
      const response = await apiClient.post('/customers/investor-vision/register', {data: {...payload}})

      if(response){
        setSuccess("Registration Successful");
      }
      setClose()
      setSubmitting(false)
    } catch (e: any) {
      if (e.response.status === 409) {
        setError("Email already in use. Please contact support");
      } else {
        setError("Oops, something went wrong. Please try again later")
      }
      setLoading(false)
    } finally {
      setLoading(false)
    }
  };


  return (
    <Dialog open={true} PaperProps={{ sx: { minWidth: '550px' } }} maxWidth="sm" onClose={() => setClose()}>
      <DialogTitle>
        Vision User
        <CornerCloseButton onClick={() => setClose()}></CornerCloseButton>
      </DialogTitle>
      <Formik initialValues={initialValues} validationSchema={exchangeUserSchema} onSubmit={handleSubmit}>
        {({ isValid, dirty, errors }) => (
          <Form>
            <DialogContent style={{ paddingTop: '0.5rem', }}>
              <ErrorMessage name="error" />
              <Box sx={{my:1}}>
               <Field name="password" label="Password*" component={TextField} fullWidth/>

              </Box>
              <Field
                formControl={{ sx: { width: 1 } }}
                component={Select}
                label="Phone Number*"
                MenuProps={MenuProps}
                name="phonenumberID"
              >
                {member?.phone_numbers?.map(p => <MenuItem key={p.id} value={`${p?.id}`}>{p?.number}</MenuItem>)}
              </Field>
            </DialogContent>
            <DialogActions>
              <LoadingButton disabled={!isValid && dirty} color='info' type='submit' loading={loading}>Register</LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

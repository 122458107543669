import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { Notification } from '../../api/payloads/professional.payload';
import NotificationItem from './NotificationsItem';

type NotificationsListProps = {
  notificationsData: Notification[];
  maxHeight?: number; 
  onNotificationClick?: () => void; 
  emptyMessage?: string; 
};

const NotificationsList: React.FC<NotificationsListProps> = ({ notificationsData, maxHeight, onNotificationClick , emptyMessage = 'No Notifications'}) => (
  <Box sx={{ maxHeight: maxHeight || 'auto', overflowY: maxHeight ? 'auto' : 'visible', paddingRight: 1 }}>
    {notificationsData.length === 0 ? (
      <Typography variant="body2" color="text.secondary" align="center">
       {emptyMessage}
      </Typography>
    ) : (
      notificationsData.map((notification, index) => (
        <Box key={notification.id}>
          <NotificationItem notification={notification} onNotificationClick={onNotificationClick} />
          {index < notificationsData.length - 1 && <Divider sx={{ marginY: 1 }} />}
        </Box>
      ))
    )}
  </Box>
);

export default NotificationsList;

import { FC } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useProfessionals, useUI } from "../../../hooks";
import { Button, DialogActions, Grid, Typography } from "@mui/material";
import { ErrorContactEmail } from "../../../components/ErrorContactEmail/ErrorContactEmail";
import { apiClient } from "../../../api/apiClient";
import { PostNewProfessionalReq } from "../../../api/payloads";
import AdminAddProfContent from "./DialogContent/AdminAddProfContent";

const AdminAddProfDialog: FC<{onClose: (success?: boolean) => void;}> = ({ onClose }) => {
  const { professionalsList, countries, professionalTypes, advisory } = useProfessionals();
  const { setLoading, setError, setSuccess } = useUI();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "United States",
    typeId: 1,
    linkedProfessionals: [],
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().trim().required("First Name is required"),
    lastName: Yup.string().trim().required("Last Name is required"),
    email: Yup.string().trim().email("Invalid email address").required("Email is required"),
    phone: Yup.string().trim().required("Mobile Phone is required"),
    country: Yup.string().required("Country / Region is required"),
    typeId: Yup.string().required("Type of Professional is required"),
    linkedProfessionals: Yup.array().min(1, "At least one professional is required"),
  });

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      setLoading(true);
      if (!advisory) return;
      const selectedCountry = countries.find((country) => country.name === values.country);
      const dialCode = selectedCountry ? selectedCountry.dial_code : "+1";
      const payload: PostNewProfessionalReq = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone: values.phone,
        country_region: values.country,
        contact_number_types: "1",
        dial_code: dialCode,
        professional_type_id: +values.typeId,
        advisory_id: advisory.id,
        professionalsToLink: values.linkedProfessionals,
      };
      await apiClient.post("/professionals/", { data: payload });

      setLoading(false);
      setSuccess("Professional added successfully");
      onClose(true);
    } catch (error) {
      setError(
        <div>
          <Typography variant="h6">Something went wrong:</Typography>
          <Typography variant="body1">
            We were unable to add the selected professionals. Please contact <ErrorContactEmail /> for assistance.
          </Typography>
        </div>
      );
    }
    setLoading(false);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnMount>
      {({ isValid }) => (
        <Form>
          <AdminAddProfContent
            onClose={() => onClose(false)} 
            countries={countries}
            professionalTypes={professionalTypes}
            professionalsList={professionalsList}
          />
          <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button onClick={() => onClose(false)} color="inherit">
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button type="submit" color="info" disabled={!isValid}>
                  Create
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default AdminAddProfDialog;
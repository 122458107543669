import { Container, Toolbar, Typography } from "@mui/material";
import { FC } from "react";
import { ContentBackground } from "../../components/ContentBackground/ContentBackground";
import EmptyState from "../../components/EmptyState/EmptyState";
import { useOpportunities } from "../../hooks";
import { OpportunityCard } from "./OpportunityCard";

export const OpportunitiesPage: FC = (props) => {
  const {opportunities} = useOpportunities();
  
  return (
    <Container sx={{ py: 2 }}>
      <ContentBackground>
        <Toolbar sx={{justifyContent: "space-between", py: 2}}>
          <Typography variant="h5" >List of Opportunities</Typography>
        </Toolbar>
        {opportunities.length > 0 ?
         opportunities.map(opportunity => <OpportunityCard key={opportunity.id} opportunity={opportunity}/>)
        :
        <EmptyState message="No opportunities"/>
        }
      </ContentBackground>
    </Container>
  )
}
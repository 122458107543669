import { useFormikContext } from "formik";
import { FC, useMemo } from "react";
import { useClients, useProfessionals } from "../../../../../hooks";
import { FinancialAdvisorFormValues } from "../subDocsFormSchema";
import { SubDocsReviewInfoCard } from "../SubDocsReview";

export const FinancialAdvisorContactReview: FC = () => {
  const {addressStates, } = useClients();
  const { advisory, professionalsList } = useProfessionals();
  const { values } = useFormikContext<FinancialAdvisorFormValues>();

  const firmName = useMemo(() => {
    const currentFirm = advisory?.id?.toString() === values.firm.toString() ? advisory : null
    return currentFirm?.name ?? ""
  },[advisory, values.firm,])

  const advisorName = useMemo(() => {
    const currentAdvisor = professionalsList.find(advisor => +advisor.id === +values.advisorId);
    return currentAdvisor ? `${currentAdvisor.first_name} ${currentAdvisor.last_name}` : ""
  },[professionalsList, values.advisorId,])
  
  const stateName = useMemo(() => {
    const currentState = addressStates?.find(state => state.id === values.advisoryAddress?.stateId) ?? null;
    return currentState?.name ?? ""
  },[addressStates, values.advisoryAddress,])


  return (
    <>
      <SubDocsReviewInfoCard span={4} title="Firm" value={firmName} />
      <SubDocsReviewInfoCard span={8} title="Advisor Name" value={advisorName} />
      <SubDocsReviewInfoCard span={4} title="Street Address 1" value={values.advisoryAddress?.street1 ?? ""}/> 
      <SubDocsReviewInfoCard span={6} title="Street Address 2" value={values.advisoryAddress?.street2 ?? ""} />
      <SubDocsReviewInfoCard span={4} title="City" value={values.advisoryAddress?.city ?? ""} />
      <SubDocsReviewInfoCard span={2} title="State" value={stateName} />
      <SubDocsReviewInfoCard span={4} title="Zip" value={values.advisoryAddress?.zip ?? ""} />
      <SubDocsReviewInfoCard span={4} title="Email" value={values.advisorEmail?? ""} />
      <SubDocsReviewInfoCard title="Phone" value={values.advisorPhoneNumber ?? ""} />
    </>
  )
}
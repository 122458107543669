import { useTheme } from "@mui/material";
import { FC, useState } from "react";
import { useNavigate } from "react-router";
import { UnlinkedWidget} from "../../../../components/Widget/UnlinkedWidget";
import { useClientDetails } from "../../../../hooks";
import { LinkInvestmentInstructionsDialog } from "../Dialogs/LinkInvestmentInstructionsDialog";

// NOTE: This (like with the dialog component) and the Unlinked Account widget could be generalized into one or more components.
// For now settling on copy-pasta so that they can be modified independently and can't foresee a third 'mode' just yet

export const UnlinkedInvestmentsWidget: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { unlinkedInvestments } = useClientDetails();
  const [openLinkInvestmentsInstructionDialog, setOpenInvestmentsInstructionLinkDialog] = useState(false);

  return (
    <>
      <UnlinkedWidget
        title="Unlinked Investments"
        subtitle="without linked Instructions"
        count={unlinkedInvestments.length}
        gradient={`linear-gradient(90deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`}
        iconBgColor={theme.palette.error.main}
        iconColor={theme.palette.background.paper}
        hoverBgColor={theme.palette.primary.main}
        hoverIconColor={theme.palette.error.main}
        onClick={() => setOpenInvestmentsInstructionLinkDialog(true)}
      />
      {openLinkInvestmentsInstructionDialog &&
        <LinkInvestmentInstructionsDialog
          open={openLinkInvestmentsInstructionDialog}
          handleClose={() => setOpenInvestmentsInstructionLinkDialog(false)}
          handleNewInstructionClick={() => {
            setOpenInvestmentsInstructionLinkDialog(false);
            navigate("../instructions", { state: { showNewInstructionForm: true } });
          }}
        />
      }
    </>
  )
}
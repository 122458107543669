import { useParams } from 'react-router-dom';
import { unmaskRouteId } from '../utility/route-mask.util';
import { useMemo } from 'react';

type MaskedParams = Record<string, string>;

export function useMaskedParams() {
  const params = useParams();

  const unmaskedParams = useMemo(() => {
    const unmasked: MaskedParams = {};

    for (const [key, value] of Object.entries(params)) {
      unmasked[key] = unmaskRouteId(value!);
    }

    return unmasked;
  }, [params]);

  return unmaskedParams;
}
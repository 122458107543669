import { mixed, number, object, string } from 'yup';
import { invalidFileDataTest, largeFileTest, largeFileTestMessage } from '../../../../../utility/file.util';
import { AchInstructionModel, BrokerageInstructionModel, CheckInstructionModel, WireInstructionModel } from '../../../../../api/payloads/customer.payload';
import * as Yup from 'yup';

type AccountInvestmentsFormValues = {
  selected: any;
}
export type NewInstructionFormValues = InstructionDetailFormValues & AccountInvestmentsFormValues;

export type InstructionDetailFormValues = {
  instructionType: "" | number
  wireInstructions: WireInstructionModel & { verificationDocument: any},
  achInstructions: AchInstructionModel & {voidedCheck: any}
  checkInstructions: CheckInstructionModel
  brokerageInstructions: BrokerageInstructionModel & { verificationDocument: any}
}

export const newInstructionDetailFormSchema = object({
  instructionType: number().required("Please select an instruction type"),
  brokerageInstructions: object().shape({}).when('instructionType', {
    is: 4,
    then: schema => schema.shape({
      instructionName: string().required("Brokerage Instruction name is required"),
      custodianId: number().required("Custodian is required"),
      bankAccountName: string().required("Account name is required"),
      aba: string().required("Routing number is required").max(9, "Routing number should not exceed 9 digits"),
      accountNumber: string().required("Account number is required"),
      financialInstitutionName: string().required("Financial institution name is required"),
      financialCity: string().required("Financial Institution city is required"),
      financialStateId: number().required("Financial Institution State is required"),
      ffc: string().required("FFC is required"),
      ffcAccountNumber: string().required("FFC Account number is required"),
      verificationDocument: mixed()
        .required("Verification Document is required")
        .test('invalidFileData', "Invalid file data", invalidFileDataTest)
        .test('fileSize', largeFileTestMessage, largeFileTest)
    })
  }),
  wireInstructions: object().shape({}).when('instructionType', {
    is: 1,
    then: schema => schema.shape({
      instructionName: string().required("Wire name is required"),
      bankAccountName: string().required("Account name is required"),
      aba: string().required("Routing number is required").max(9, "Routing number should not exceed 9 digits"),
      accountNumber: string().required("Account number is required"),
      financialInstitutionName: string().required("Financial institution name is required"),
      streetAddress1: string().required("Financial Institution Address is required"),
      streetAddress2: string().optional(),
      city: string().required("City is required"),
      stateId: number().required("State is required"),
      zip: string().min(5, "Zip Code must be at least 5 digits").required("Required"),
      ffc: string().optional().nullable(),
      ffcAccountNumber: string().optional().nullable(),
      verificationDocument: mixed()
        .required("Verification Document is required")
        .test('invalidFileData', "Invalid file data", invalidFileDataTest)
        .test('fileSize', largeFileTestMessage, largeFileTest)
    })
  }),
  checkInstructions: object().shape({}).when('instructionType', {
    is: 2,
    then: schema => schema.shape({
      instructionName: string().required("Check name is required"),
      payeeName: string().required("Payee name is required"),
      existingAddressId: number().nullable(),
      streetAddress1: string().required("Street Address is required"),
      streetAddress2: string().optional(),
      city: string().required("City is required"),
      stateId: number().required("State is required"),
      zip: string().min(5, "Zip Code must be at least 5 digits").required("Required") 
    })
  }),
  achInstructions: object().shape({}).when('instructionType', {
    is: 3,
    then: schema => schema.shape({
      instructionName: string().required("ACH name is required"),
      bankAccountName: string().required("Account name is required"),
      aba: string().required("Routing number is required").max(9, "Routing number should not exceed 9 digits"),
      accountNumber: string().required("Account number is required"),
      ffc: string().optional().nullable(),
      accountType: number().required("Account type is required"),
      voidedCheck: mixed().required("Required")
        .test('invalid-file-type', "File type is not supported", value => {
          if (!(value instanceof File)) { return true; }
          return ['application/pdf', 'image/jpeg', 'image/tiff', 'image/gif', 'image/png'].includes(value.type);
        })
        .test('invalidFileData', "Invalid file data", invalidFileDataTest)
        .test('fileSize', largeFileTestMessage, largeFileTest),
      })
  }),
});

export const getNewInstructionAccountAndInvestmentFormSchema = (accountsMap: Record<string, any>) => {
  const hasNonCustodianAccounts = Object.values(accountsMap).some(account => account.custodian_id === null);
  return Yup.object().shape({
    selected: Yup.object()
      .test(
        'atLeastOne',
        hasNonCustodianAccounts ? 'Please select at least one account' : '',
        (selected) => {
          if (!hasNonCustodianAccounts) return true;
          return Object.values(selected).some(value => value);
        }
      )
      .test(
        'accountOwnersPresent',
        '',
        function (selected) {
          if (!hasNonCustodianAccounts) return true;
          const missingOwners = Object.keys(selected).filter(accountId => {
            const isSelected = selected[accountId];
            const accountExists = !!accountsMap[accountId];
            const hasNoOwners = !(accountsMap[accountId]?.contact_owners?.length > 0);
            return isSelected && accountExists && hasNoOwners;
          }).map(accountId => accountsMap[accountId].account_name);
      
          if (missingOwners.length > 0) {
            const formattedAccountNames = missingOwners.join(', ');
            const message = missingOwners.length === 1 ?
              `Account Owner for Account ${formattedAccountNames} is missing` :
              `Account Owners for Accounts ${formattedAccountNames} are missing`;
            return this.createError({ message });
          }
          return true;
        },
      )
  });
};

export const newInstructionReviewFormSchema = object().shape({
  ...newInstructionDetailFormSchema.fields,
  ...getNewInstructionAccountAndInvestmentFormSchema({}).fields,
});
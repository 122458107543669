import { Typography, Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { FC } from "react";
import { ElectronicDeliveryFormValues, SupplementalDataFormValues } from "../subDocsFormSchema";
import { SubDocsReviewInfoCard } from "../SubDocsReview";

export const ElectronicDeliveryReview: FC = () => {
  const { values } = 
  useFormikContext<ElectronicDeliveryFormValues>();

  return (
    <Grid item xs={12}>
      <Typography variant="h6" sx={{fontSize: 16, fontWeight: 'bold', mr: 0}}>The Company is seeking written consent from each Shareholder to use Electronic Delivery to deliver the Company Materials and Regulatory Documents to Shareholders. Accordingly, the Company seeks consent from you, as follows: </Typography>
      <ul>
        <li>
        The Investor hereby acknowledges that the Investor has read and understands this Notice and Consent for Electronic Delivery of Company Materials and Regulatory Documents (this 'Consent') and hereby consents to Electronic Delivery of all Company Materials and Regulatory Documents. 
        </li>
        <li>
        The Investor understands that the Investor may incur costs in connection with Electronic Delivery of Company Materials and Regulatory Documents, including costs associated with online time and printing documents.
        </li>
        <li>
        It is the Investor's affirmative obligation to notify the Company in writing if the Investor's email address provided to the Company changes.
        </li>
        <li>
        The Investor may revoke or restrict its consent to this Electronic Delivery of Company Materials and Regulatory Documents upon delivery of 30 days' prior written notice to the Company of such revocation or restriction. 
        </li>
        <li>
        The Investor agrees to notify the Company as promptly as possible if the Investor is unable to access a document link in an Electronic Delivery communication or does not receive an expected communication via Electronic Delivery.
        </li>
      </ul>
      <Typography>
        {values?.isElectronicDeliveryConfirmed === 1 ? "yes" : "no"}
      </Typography>
    </Grid>
  )
}
import { Dialog, DialogContent, DialogProps, DialogTitle, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { CornerCloseButton } from "../../../../components/Buttons";

export type DocumentListDialogProps = Omit<DialogProps, 'onClose'> & {
  handleClose: (result?: boolean) => void
};

export const AMLDocumentsListDialog: FC<DocumentListDialogProps> = (props) => {
  let {  maxWidth, fullWidth, handleClose,...rest } = props;

  return (
    
    <Dialog PaperProps={{ sx: { minWidth: '700px' } }} maxWidth={maxWidth} onClose={() => handleClose()} {...rest}>
      <DialogTitle>
        Supporting Documents
        <CornerCloseButton onClick={() => handleClose()}></CornerCloseButton>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '0.5rem' }}>
        <Grid spacing={2} container sx={{flex: 1}}>
          <Grid item xs={6}>
            <Typography variant='h6'>Corporations</Typography>
            <ul>
              <li>Certificate/Formation Documents</li>
              <li>Copy of By-laws</li>
              <li>Names of Board of Directors and/or Senior Executives</li>
              <li>Copy of Authorized Signatory List</li>
              <li>Names of Shareholders that own 25% or more of the corporation and signed organizational documents</li>
              <li>Copy of government-issued business license</li>
            </ul>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6'>Limited Liability Companies</Typography>
            <ul>
              <li>Signed Copy of the Governing Document</li>
              <li>Copy of the Certificate of Formation</li>
              <li>Names of members owning 25% or more of the equity interest and signed organizational documents</li>
            </ul>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6'>Pension/Profit-Sharing Plan</Typography>
            <ul>
              <li>Names of Beneficiaries: if # exceeds 10, obtain names of individuals making investment decisions</li>
              <li>Signed copy of Plan document and Trust Agreement</li>
              <li>Current authorized signatory list (if the signatory is an entity)</li>
            </ul>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6'>Estates</Typography>
            <ul>
              <li>Copy of Death Certificate</li>
              <li>Letters Testamentary or Letter of Administration</li>
              <li>Letter of Instructions from Executor</li>
              <li>Copy of the Will</li>
              <li>Proof of Identity of the Executor</li>
            </ul>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6'>Foundations/Endowments (or other Tax-Exempt Entities)</Typography>
            <ul>
              <li>Names of individuals making investment decisions</li>
              <li>Copy of signed organizational documents</li>
              <li>Current Authorized Signatory List</li>
            </ul>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6'>Partnerships of Limited Partnerships</Typography>
            <ul>
              <li>Names of General Partner(s) and Limited Partner(s)</li>
              <li>Copy of Certificate of Partnership or LP</li>
              <li>Copy of signed organizational documents for each entity that is a GP or LP</li>
            </ul>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6'>Trusts</Typography>
            <ul>
              <li>A copy of the title page, authorized individual page, and signature page or the Trust Agreement</li>
            </ul>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
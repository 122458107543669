import { Box, Button, FormControl, InputLabel, Link, MenuItem, Paper, Select, TableContainer, Typography } from "@mui/material";
import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { apiClient } from "../../../../api/apiClient";
import { GetCustomerDocumentsPayload } from "../../../../api/payloads/documents.payload";
import { ColumnDef, DataSourceBase, DataSourceTable } from "../../../../components/DataSourceTable";
import { ErrorContactEmail } from "../../../../components/ErrorContactEmail/ErrorContactEmail";
import { useInit, useMaskedParams, useUI } from "../../../../hooks";
import { groupBy } from "../../../../utility/array.util";
import { InvestmentCompanyStages } from "../../../../utility/constants";
import { formatDate } from "../../../../utility/date.util";
import { deepClone } from "../../../../utility/object.util";

export const ClientDocuments: FC = () => {
  const { clientId } = useMaskedParams();
  const [masterDataSource, setMasterDataSource] = useState<DataSourceBase[]>([]);
  const [dataSource, setDataSource] = useState<DataSourceBase[]>([]);
  const { setLoading, setError, setSuccess } = useUI();
  const [filters, setFilters] = useState({
    investmentId: 0,
    accountId: 0,
    documentTypeId: 0,
    yearTypeId: 0
  });

  const [filterConfigs, setFilterConfigs] = useState<
    Record<keyof typeof filters, { options: { id: number | string, name: string | number }[], label: string }>
  >();

  const init = useCallback(async () => {
    const docs = await apiClient.get("/documents/customers-documents/:p0", { routeParams: [clientId!] });
    const docsByCompany = groupBy(docs, d => d.investment_company_id!);
    const groupedByAccount = groupBy(docs, d => d.account_id);
    const groupedByType = groupBy(docs, d => d.document_type_id);
    const groupByYear = groupBy(docs, d => d.year);

    const dataSource = Object.entries(docsByCompany).map(([companyId, docs]) => ({
      id: companyId,
      name: docs[0].investment_company_name,
      secondary: docs[0].investment_company_stage_id === InvestmentCompanyStages.InvestmentClosed ? "closed" : null,
      children: docs,
    }));

    const accountList = Object.values(groupedByAccount).map(a => ({ id: a[0].account_id, name: a[0].account_name }));
    const docTypeList = Object.values(groupedByType).map(d => ({ id: d[0].document_type_id, name: d[0].document_type_name }));
    const yearTypeList = Object.values(groupByYear).filter(y => y[0].year !== 0).map(y => ({ id: y[0].year, name: y[0].year }));

    setFilterConfigs({
      investmentId: { options: dataSource, label: "Investments", },
      accountId: { options: accountList, label: "Accounts" },
      documentTypeId: { options: docTypeList, label: "Document Types" },
      yearTypeId: { options: yearTypeList, label: "Year" }
    })

    setMasterDataSource(dataSource);
  }, [clientId]);

  useInit(init, null);

  useEffect(() => {
    let dataSource = deepClone(masterDataSource);
    dataSource = filters.investmentId ? dataSource.filter(d => +d.id === filters.investmentId) : dataSource;

    if (filters.accountId || filters.documentTypeId || filters.yearTypeId) {
      for (const data of dataSource) {
        data.children = data.children!.filter((c: GetCustomerDocumentsPayload[number]) => {
          const isSelectedAccount = !filters.accountId || c.account_id === filters.accountId;
          const isSelectedDocumentType = !filters.documentTypeId || c.document_type_id === filters.documentTypeId;
          const isSelectedYearType = !filters.yearTypeId || c.year === filters.yearTypeId;
          return isSelectedAccount && isSelectedDocumentType && isSelectedYearType;
        })
      }
    }

    dataSource = dataSource.filter(d => d.children?.length);
    setDataSource(dataSource);
  }, [masterDataSource, filters])

  const handleFileClick = useCallback(async (e: MouseEvent<any>, docId: number) => {
    try {
      e.preventDefault();
      setLoading(true);
      const resp = await apiClient.get("/documents/:p0", { routeParams: [docId] });
      window.open(resp.url)
    } catch (error) {
      setError("Unable to download file");
    } finally {
      setLoading(false);
    }
  }, [setLoading, setError]);

  const columnDef: ColumnDef<GetCustomerDocumentsPayload[number]> = useMemo(() => {
    return [
      { id: "account_name", label: "Account" },
      { id: "year", label: "Year", displayFn: v => v || "---" },
      { id: "document_type_name", label: "Document Type" },
      { id: "file_name", label: "Document", displayFn: (v, row) => <Link color="primary" onClick={(e) => handleFileClick(e, row.id)} href="#">{v.replace(/['"]+/g, "")}</Link> },
      { id: "as_of_date", label: "As of", displayFn: v => formatDate(v, { dateStyle: 'short' })},
    ];
  }, [handleFileClick]);

  const handleDownload = async () => {
    try {
      setLoading(true)
      const childrenArray = [] as number[]
      for (const data of dataSource) {
        data?.children?.map((c: GetCustomerDocumentsPayload[number]) => childrenArray.push(c.id))
      }

      if (!childrenArray.length) return;

      await apiClient.post("/documents/bulk-download", { data: childrenArray })
      setSuccess(
        'Your documents are being prepared. ' + 
        'You will receive an email with the download link once they are ready. ' + 
        'For large downloads, please allow up to 15 minutes for the email to arrive');
    } catch (error) {
      setError(
        <div>
          <Typography variant="h6">Something went wrong:</Typography>
          <Typography variant="body1">
            We were unable to load docs.
            Please contact <ErrorContactEmail /> for assistance.
          </Typography>
        </div>
      )
    } finally {
      setLoading(false);
    }
  }


  return (
    <>
      {!!filterConfigs &&
        <Box display='flex' alignItems={'center'} gap={1} marginBottom={1} flexWrap='wrap'>
          {Object.entries(filterConfigs).map(([filterName, config]) => (
            <FormControl key={filterName} sx={{ flexBasis: 115, flexGrow: 1, maxWidth: 250}}>
              <InputLabel>{config.label}</InputLabel>
              <Select
                sx={{ backgroundColor: 'background.paper' }}
                size="small"
                value={filters[filterName as keyof typeof filterConfigs]}
                onChange={e => {
                  const newFilters = { ...filters };
                  newFilters[filterName as keyof typeof filterConfigs] = +e.target.value;
                  setFilters(newFilters);
                }}
                label={config.label}
              >
                <MenuItem value={0}>All</MenuItem>
                {config.options.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)}
              </Select>
            </FormControl>
          ))}
          <Button sx={{minWidth: 'fit-content'}} onClick={handleDownload} variant="contained">Download All</Button>
        </Box>
      }
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <DataSourceTable columnDef={columnDef} dataSource={dataSource} emptyMessage="No documents" />
      </TableContainer>
    </>
  )
}

import { AddCircle, Delete } from "@mui/icons-material";
import { Box, IconButton, MenuItem, Tooltip, Typography, FormHelperText } from "@mui/material";
import { Field, FieldArray, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import moment from "moment";
import { FC, useEffect, useState, useCallback } from "react";
import { Contact } from "../../../../api/payloads/customer.payload";
import { DatePickerField } from "../../../../components/FormFields/DatePickerField";
import { useSubDocs } from "../../../../hooks/ContextHooks/useSubDocs";
import { CustomerEntityTypes } from "../../../../utility/constants";
import  {SSNInput } from '../../../../utility/MaskComponent';
import { AMLVerificationFormValues, initialAuthorizedRepresentativeValue, InvestorQuestionnaireFormValues, SubDocsFormValues } from "./subDocsFormSchema";

export const AuthorizedRepresentative: FC = () => {
  const {subDocsClient, subDocsAccount} = useSubDocs();
  const [selectedMembers, setSelectedMembers] = useState(new Set())
  const { values, setFieldValue } = useFormikContext<InvestorQuestionnaireFormValues>();
  const amlVerificationStepValues = values as SubDocsFormValues as AMLVerificationFormValues
  const contacts = subDocsClient?.contacts
  const SingleMemberLLCOwnerId = subDocsAccount?.contact_owners[0]?.contact_id;
  const IndividualSingleMemberLLCSubscriber = subDocsAccount?.customer_entity_type_id === CustomerEntityTypes.IndividualSingleMemberLLC;

  const setAuthorizedRepresentativeData = useCallback(()=>{
    const SingleMemberLLCOwner: Contact[]  = subDocsClient?.contacts?.filter((member) => member?.id === SingleMemberLLCOwnerId) ?? []
    if (IndividualSingleMemberLLCSubscriber && SingleMemberLLCOwner?.length > 0) {
      setFieldValue(`authorizedRepresentatives[0].representativeContactId`, SingleMemberLLCOwnerId);
      setFieldValue(`authorizedRepresentatives[0].ssn`, SingleMemberLLCOwner[0]?.ssn ?? "");
      setFieldValue(`authorizedRepresentatives[0].dob`, SingleMemberLLCOwner[0]?.date_of_birth ? moment(SingleMemberLLCOwner[0]?.date_of_birth) : null);
    }
  },[IndividualSingleMemberLLCSubscriber,SingleMemberLLCOwnerId,subDocsClient?.contacts, setFieldValue])
 
  // TODO: Fix this hacky solution
  useEffect(()=>{
    setAuthorizedRepresentativeData()
  },[setAuthorizedRepresentativeData])
  
  
  useEffect(() => {
    const representativesMap = values.authorizedRepresentatives.map((member) => member.representativeContactId.toString()).filter((id) => id !== "")
    setSelectedMembers(new Set(representativesMap))
  },[setSelectedMembers,values.authorizedRepresentatives])

  const removeRepFromPhotoIds = (repContactId: number | '') => { 
    const ownerIdDocuments = amlVerificationStepValues.ownerIdDocuments;
    const accountOwnerIds = subDocsAccount!.contact_owners.map(owner => owner.contact_id);
    return ownerIdDocuments.filter(doc => accountOwnerIds.includes(doc.contactId) || doc.contactId !== repContactId);
  }

  const handleAuthorizedRepresentativeChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    const currentRepContactId = values.authorizedRepresentatives[index].representativeContactId;
    const value = +event.target.value;
    const contact = contacts?.find(contact => contact.id === value);
    const date = contact?.date_of_birth ? new Date(contact?.date_of_birth) : null;
    setFieldValue(`authorizedRepresentatives[${index}].representativeContactId`, value);
    setFieldValue(`authorizedRepresentatives[${index}].ssn`, contact?.ssn ?? "")
    setFieldValue(`authorizedRepresentatives[${index}].dob`, date ? moment(date) : null)

    // Make sure AML Verification step includes all non-owner authorized representatives 
    const updatedOwnerIdDocuments = removeRepFromPhotoIds(currentRepContactId);
    const isAuthorizedRepAnOwner = subDocsAccount!.contact_owners.some(owner => owner.contact_id === value);

    if (!isAuthorizedRepAnOwner) {
      updatedOwnerIdDocuments.push({
        contactId: value,
        name: `${contact?.first_name} ${contact?.last_name}`,
        expirationDate: contact?.doc_expiry_date ? moment(contact?.doc_expiry_date) : null,
        idImageFile: contact?.photo_id_file_name ? { name: contact?.photo_id_file_name } : null,
        imageId: contact?.document_id,
      })
    }
    setFieldValue('ownerIdDocuments', updatedOwnerIdDocuments);
  }


  const onDelete = (index: number) => {
    const repContactId = values.authorizedRepresentatives[index].representativeContactId;
    const updatedOwnerIdDocuments = removeRepFromPhotoIds(repContactId);
    const newAuthorizedRepresentatives = values.authorizedRepresentatives.filter((_, i) => i !== index);
    setFieldValue('ownerIdDocuments', updatedOwnerIdDocuments);
    setFieldValue('authorizedRepresentatives', newAuthorizedRepresentatives);
  }

  return (
    <Box sx={{ display: 'flex',flexDirection: 'column', py: 2,}}>
      <Typography variant="h6" >Authorized Representative Information</Typography>
      <Typography sx={{mb: 2}}>Please complete the below information for individuals that can sign on behalf of the entity.</Typography>
      <FormHelperText sx={{mb: 1.5}}>Authorized Representative Signing the Subscription Document</FormHelperText>
      <FieldArray name="authorizedRepresentatives">
        {({ push}) => (
          <Box sx={{position: 'relative', display:'flex', flexDirection: 'column', width: '100%'}}>
          {values.authorizedRepresentatives.map((_representative, index) => (
           <Box key={index}>
             {index === 1 && <FormHelperText sx={{mb: 1.5}}>Other Authorized Representatives</FormHelperText>}
            <Box sx={{display: 'flex', width: '100%'}}>
              <Field
                formControl={{required: true, sx:{width: "25%",minWidth: 112, mr: 2,}}}
                component={Select}
                label="Authorized Representative"
                name={`authorizedRepresentatives[${index}].representativeContactId`}
                onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleAuthorizedRepresentativeChange(event, index)}
                disabled={IndividualSingleMemberLLCSubscriber && index === 0}
              >
                {subDocsClient?.contacts.map((member) => <MenuItem disabled={selectedMembers.has(member.id.toString())} key={member.id} value={member.id}>{`${member.last_name}, ${member.first_name}`}</MenuItem>)}
              </Field>
              <Field name={`authorizedRepresentatives[${index}].representativeAddress`} required label="Address" component={TextField} sx={{width: '35%', mr: 2}} 
              />
              <Field name={`authorizedRepresentatives[${index}].ssn`}  disabled label="SSN or TIN" required component={SSNInput} sx={{width: '20%', mr: 2}}/>
              <DatePickerField disabled name={`authorizedRepresentatives[${index}].dob`} label="Date of Birth*" sx={{width: '20%',mr: 2}}/>
              <Box sx={{width: 60, pt: 1}}>
                {index !== 0 &&
                  <Tooltip title="Delete" >
                    <IconButton aria-label='delete' onClick={() => onDelete(index)} color='error'><Delete /></IconButton>
                  </Tooltip>}
              </Box>
            </Box>
           </Box>
          )
          )}
          {contacts != null && selectedMembers.size < contacts.length && values.authorizedRepresentatives.length === 1 && <FormHelperText>Other Authorized Representatives</FormHelperText>}
          {contacts != null && selectedMembers.size < contacts.length && values.authorizedRepresentatives.length < 3 &&
          <Box>
              <IconButton
                color='primary'
                onClick={() => push(initialAuthorizedRepresentativeValue)}
                sx={{mb: 2}}
                >
                <Tooltip title="Add Authorized Representative">
                  <AddCircle fontSize="large" />
                </Tooltip>
              </IconButton>
          </Box>}
          <FormHelperText>Can only add up to 3 Authorized Representatives</FormHelperText>
        </Box>
        )}
      </FieldArray>
    </Box>
  )
}
import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useMemo, useState } from "react";
import { Accreditation, Contact, Country, Custodian, EntityType, PhoneNumberType, PostCustomerEntityResp } from "../api/payloads/customer.payload";
import { BaseRecord } from "../utility/types";

export type NewClientContextValue = {
  accountTypeValues: BaseRecord[],
  accreditationValues: Accreditation[],
  countryCodeValues: Country[],
  custodians: Custodian[],
  customerID: number | null,
  entityTypeValues: EntityType[],
  iraTypes: BaseRecord[],
  newMembers: Contact[],
  newEntity: PostCustomerEntityResp | null,
  phoneTypeValues: PhoneNumberType[],
  setAccountTypeValues: Dispatch<SetStateAction<BaseRecord[]>>,
  setAccreditationValues: Dispatch<SetStateAction<Accreditation[]>>,
  setCountryCodeValues: Dispatch<SetStateAction<Country[]>>,
  setCustomerID: Dispatch<SetStateAction<number | null>>,
  setCustodians: Dispatch<SetStateAction<Custodian[]>>,
  setEntityTypeValues: Dispatch<SetStateAction<EntityType[]>>,
  setIraTypes: Dispatch<SetStateAction<BaseRecord[]>>,
  setNewMembers: Dispatch<SetStateAction<Contact[]>>,
  setNewEntity: Dispatch<SetStateAction<PostCustomerEntityResp | null>>,
  setPhoneTypeValues: Dispatch<SetStateAction<PhoneNumberType[]>>,
}

export const NewClientContext = createContext(undefined as unknown as NewClientContextValue);

export const NewClientContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [accreditationValues, setAccreditationValues] = useState<NewClientContextValue['accreditationValues']>([]);
  const [countryCodeValues, setCountryCodeValues] = useState<NewClientContextValue['countryCodeValues']>([]);
  const [entityTypeValues, setEntityTypeValues] = useState<NewClientContextValue['entityTypeValues']>([]);
  const [newMembers,setNewMembers] = useState<NewClientContextValue['newMembers']>([]);
  const [newEntity,setNewEntity] = useState<NewClientContextValue['newEntity'] | null>(null);
  const [phoneTypeValues, setPhoneTypeValues] = useState<NewClientContextValue['phoneTypeValues']>([]);
  const [accountTypeValues, setAccountTypeValues] = useState<NewClientContextValue['accountTypeValues']>([]);
  const [custodians,setCustodians] = useState<NewClientContextValue['custodians']>([]);
  const [customerID, setCustomerID] = useState<NewClientContextValue['customerID']>(null);
  const [iraTypes, setIraTypes] = useState<NewClientContextValue['iraTypes']>([]);

  const value = useMemo(() => ({
    accountTypeValues,
    accreditationValues,
    countryCodeValues,
    custodians,
    customerID,
    entityTypeValues,
    iraTypes,
    newMembers,
    newEntity,
    phoneTypeValues,
    setAccountTypeValues,
    setAccreditationValues,
    setCountryCodeValues,
    setCustodians,
    setCustomerID,
    setEntityTypeValues,
    setIraTypes,
    setNewMembers,
    setNewEntity,
    setPhoneTypeValues,
  }), [
      accountTypeValues,
      accreditationValues,
      countryCodeValues,
      custodians,
      customerID,
      entityTypeValues,
      iraTypes,
      newMembers,
      newEntity,
      phoneTypeValues,
    ]);

  return (
    <NewClientContext.Provider value={value}>
      {children}
    </NewClientContext.Provider>
  )
}
import moment from "moment";
import { array, boolean, date, mixed, number, object, string } from "yup";
import { AccountType, CustomerEntityTypes } from "../../../../utility/constants";
import { defaultCountryData } from "../../../../utility/misc.util";
import { invalidFileDataTest, largeFileTest, largeFileTestMessage } from "../../../../utility/file.util";
import { checkDuplicate } from "../../../../utility/debounce.util";

export type HouseholdFormValues = {
  householdName: string | null,
  streetAddress1: string | null,
  streetAddress2: string | null,
  city: string | null,
  stateId: number | string,
  zipCode: number | string
}

export type MemberValue = {
  firstName: string,
  nickname: string,
  middleName: string,
  lastName: string,
  dateOfBirth: moment.Moment | null,
  email: string,
  SSN: string,
  phoneNumber: string,
  countryCode: string,
  contactNumberType: string | number,
  idImageFile: any | null,
  expirationDate: moment.Moment | null,
  accreditation: number | string 
  }

export type MemberFormValues = {
  members: MemberValue[]
}

export type Professional = {
  professionalId: number,
  receivesComms: boolean
}

export type ProfessionalFormValues = {
  professionals: Professional[],
  primaryProfessionalId: number | undefined
}

export type EntityOwner = {
  id: number | string,
  percentage: number | string
}

export type EntityFormValues = {
  entityName: string,
  entityOwners: EntityOwner[],
  EIN: number | string,
  entityType: string | number,
  qualification: string,
  revocable: string,
  grantor: string,
  other_info?:string 
}

export type DocumentFormValues = {
  formationDocuments: any | null,
  governingDocuments: any | null,
  trustDocument: any | null,
}

export type AccountExtraValues = {
  accountNumber: string,
  beneficiary: number | "",
  clientAccountMember: number | "",
  clientAccountMembers: (number | "")[],
  custodian: string,
  entityAccount: number | "",
  ssnContactId: number | "",
  iraTypeId: number | "",
  isCustodied: boolean,
}

export type AccountBaseValues = {
  accountType: number,
  accountName: string,
}

export type NewClientFormValues = HouseholdFormValues & MemberFormValues & ProfessionalFormValues

export type EntityDocumentFormValues = EntityFormValues & DocumentFormValues

export type AccountFormValues = AccountBaseValues & AccountExtraValues

export const householdFormSchema = {
  householdName: string().required("Household Name is required"),
  streetAddress1: string().required("Street Address 1 is required"),
  streetAddress2: string(),
  city: string().required("City is required"),
  stateId: number().required("State is Required"),
  zipCode: number().required("Zip Code is required"),
}

export const memberFormSchema = {
  members: array().of(object({
    firstName: string().required("First Name is required")
    .test(
      'invalidCharacters',
      'Invalid characters in first name field',
      value => !invalidCharactersRegex.test(value ?? '')
    ),
    nickname: string().required("Preferred Name is required")
    .test(
      'invalidCharacters',
      'Invalid characters in preferred name field',
      value => !invalidCharactersRegex.test(value ?? '')
    ),
    middleName: string().nullable()
    .test(
      'invalidCharacters',
      'Invalid characters in middle name field',
      value => !invalidCharactersRegex.test(value ?? '')
    ),
    lastName: string().required("Last Name is required")
    .test(
      'invalidCharacters',
      'Invalid characters in last name field',
      value => !invalidCharactersRegex.test(value ?? '')
    ),
    dateOfBirth: date().typeError("Must be a valid date").max(new Date(), "Please pick a valid date").required("Date of Birth is required"),
    email: string().required("Email is required")
    .test('duplicateEmail', "Email already exists", function(value, context) {
      if (!value) {
        return true;
    }
      return checkDuplicate('email')(value, context);
  }),
    SSN: string().matches(/^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/, "Not a valid SSN").required("SSN is required")
    .test(
      'duplicateSSN',
      "SSN already exists", function(value, context) {
        if (!value) {
          return true;
      }
        return checkDuplicate('ssn')(value, context);
      }),
    phoneNumber: string().matches(/^[-\d]+$/g, "Not a valid phone number").required('Phone number is required'),
    contactNumberType: number().required('Phone number type is required'),
    countryCode: string().required("Country dialing code is required"),
    idImageFile: mixed()
      .nullable()
      .test('invalidFileData', "Invalid file data", invalidFileDataTest)
      .test('fileSize', largeFileTestMessage, largeFileTest),
      expirationDate: date().typeError("Must be a valid date").min(new Date(), "Please pick a date in the future").nullable()
      .when('idImageFile', {
        is: (idImageFile: any) => !!idImageFile,
        then: (schema) => schema.required("ID Expiration Date is required")
      }),
    accreditation: number().required("Accreditation is required")
  }))
}

export const professionalFormSchema = {
  professionals: array().of(object({
    professionalId: number().required(),
    receivesComms: boolean().required()
  })),
  primaryProfessionalId: number().required("Please select a primary contact."),
}
const invalidCharactersRegex = /[~`!@#$%^&*()=+{}[\]|\\:;“<,>.?/]/;

export const entityFormSchema = {
  entityName: string().required("Entity Name is required")
  .test(
    'invalidCharacters',
    'Invalid characters',
    value => !invalidCharactersRegex.test(value ?? '')
  ),
  entityOwners: array().of(object({
    id: number().integer().required("Owner is required"),
    percentage: number().required("Percentage is required").lessThan(101, "Ownership should total to no more than 100%").integer("Percentage must be an integer").required("Percentage is required").typeError("Percentage must be a number").notOneOf([0], "Percentage must be greater than 0")
  }))
  .test("total", "Ownership should total to 100%",  (value) => {
    let total = 0;
    value?.forEach((v:any) => {
      total += v.percentage
    })
    return total === 100
  }),
  EIN: number().integer("EIN must be an integer").required("EIN is required"),
  entityType: number().required("Entity Type is required"),
  qualification: string().required("Qualification is required"),
  revocable: string().when("entityType", {
    is: (val:number) => (val === CustomerEntityTypes.STrust),
    then: string().required('Required'),
  }).nullable(),
  grantor: string().when("entityType", {
    is: (val:number) => (val === CustomerEntityTypes.STrust),
    then: string().required('Required'),
  }).nullable(),
  other_info: string().when('entityType', {
    is: (val:number) => val === CustomerEntityTypes.OtherDescribe || val === CustomerEntityTypes.OtherEmployeeBenefitPlan ,
    then: string().required('Required'),
    otherwise: string().nullable(),
  }),
}

export const documentFormSchema = {
  formationDocuments: mixed()
    .nullable()
    .test('invalidFileData', "Invalid file data", invalidFileDataTest)
    .test('fileSize', largeFileTestMessage, largeFileTest),
  governingDocuments: mixed()
    .nullable()
    .test('invalidFileData', "Invalid file data", invalidFileDataTest)
    .test('fileSize', largeFileTestMessage, largeFileTest),
  trustDocument: mixed()
    .nullable()
    .test('invalidFileData', "Invalid file data", invalidFileDataTest)
    .test('fileSize', largeFileTestMessage, largeFileTest),
}

export const accountFormSchema = object({
  accountName: string().required("Account name is required")
  .test(
    'invalidCharacters',
    'Invalid characters',
    value => !invalidCharactersRegex.test(value ?? '')
  ),
  accountNumber: string().when(['accountType', 'isCustodied'], {
    is: (accountType: number, isCustodied: boolean) => accountType === AccountType.IRA || isCustodied,
    then: schema => schema.required('Required'),
  }),
  accountType: number().required("Account type is required"),
  iraTypeId: number().when('accountType', { 
    is: (val: AccountType) => val === AccountType.IRA,
    then: number().required('Required'),
  }),
  beneficiary: number().when('accountType', {
    is: (val: AccountType) => val === AccountType.IRA,
    then: schema => schema.required('Required'),
  }),
  clientAccountMember: number().when('accountType', {
    is: (val: AccountType) => val === AccountType.Individual,
    then: schema => schema.required('Required'),
  }),
  clientAccountMembers: array().when('accountType', {
    is: (val: AccountType) => val === AccountType.Joint,
    then: array()
    .required('Required')
    .test('notEmptyStrings', '', (value: any) => {
      if (Array.isArray(value)) {
        return value.every((member) =>{
          return member !== '' && member != null;});
      }
      return false;
    }),
  }).nullable(),
  custodian: string().when(['accountType', 'isCustodied'], {
    is: (accountType: AccountType, isCustodied: boolean) => accountType === AccountType.IRA || isCustodied,
    then: string().required('Custodian is required'),
    otherwise: string().nullable(),
  }),
  entityAccount: number().when('accountType', {
    is: (val: AccountType) => val === AccountType.Entity,
    then: schema => schema.required('Entity is required'),
  }),
  ssnContactId: number().when('accountType', {
    is: (val: AccountType) => val === AccountType.Joint,
    then: number().required('SSN is required'),
    otherwise: number().notRequired(),
  }),
  isCustodied: boolean().nullable(),
})

export const newClientFormSchema = object({
  ...householdFormSchema,
  ...memberFormSchema,
  ...professionalFormSchema,
});

export const entityDocumentFormSchema = object({
  ...entityFormSchema,
  ...documentFormSchema,
})

export const initialHouseholdValues = {
  householdName: "",
  streetAddress1: "",
  streetAddress2: "",
  city: "",
  stateId: "",
  zipCode: "",
}

export const initialAccountValues: AccountFormValues = {
  accountName: "",
  accountNumber: "",
  accountType: "" as unknown as number,
  beneficiary: "",
  clientAccountMember: "",
  clientAccountMembers: ["", ""],
  custodian: "",
  entityAccount: "",
  ssnContactId: "",
  iraTypeId: "",
  isCustodied: false,
}

export const initialMemberValue: MemberValue = {
  firstName: "",
  nickname: "",
  middleName: "",
  lastName: "",
  dateOfBirth: null,
  email: "",
  SSN: "",
  phoneNumber: "",
  countryCode: defaultCountryData,
  contactNumberType: 1,
  idImageFile: null,
  expirationDate: null,
  accreditation: "",
}

export const initialMemberValues: MemberFormValues = {members: [
  initialMemberValue,
]}

export const initialProfessionalValues: ProfessionalFormValues = {
  professionals: [],
  primaryProfessionalId: undefined,

}

export const initialEntityOwnerValue: EntityOwner = {
  id: "",
  percentage: "",
}

export const initialEntityValues = {
  entityName: "",
  entityOwners: [initialEntityOwnerValue],
  EIN: "",
  entityType: "",
  qualification: "",
  revocable: "",
  grantor: "",
  other_info:""
}

export const initialDocumentValues = {
  formationDocuments: null,
  governingDocuments: null,
  trustDocument: null,
}

import { Navigate, RouteObject } from "react-router-dom";
import { InvestmentCapitalCallDetail, InvestmentCapitalCalls, InvestmentDistributions, InvestmentDistributionsDetail, InvestmentInvestors } from "../../pages/Investments/InvestmentDetailPage/Tabs";
import { isNavBarRoute, NavBarRouteObject } from "../router.util";

//TODO: Lots of repetition here with path, label, and handle values. 
// Consider DRying it up by building this with by calling Array.prototype.map with a simplified array.
export const investmentDetailRoutes: (RouteObject | NavBarRouteObject)[] = [
  { index: true, element: <Navigate to="investors" /> },
  { path: "investors", element: <InvestmentInvestors />, handle: { tab: "investors" }, label: "Investors" },
  { path: "capital-calls", element: <InvestmentCapitalCalls />, handle: { tab: "capital-calls" }, label: "Capital Calls", },
  { path: "capital-calls/:capitalCallId", element: <InvestmentCapitalCallDetail />, handle: { hideTabs: true }},
  { path: "distributions", element: <InvestmentDistributions />, handle: { tab: "distributions" }, label: "Distributions" },
  { path: "distributions/:distributionId", element: <InvestmentDistributionsDetail />, handle: { hideTabs: true }},
];

export const investmentDetailNavLinks = investmentDetailRoutes.filter(isNavBarRoute); 
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Dialog,
  Paper,
  TableContainer,
  Typography,
} from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { apiClient } from "../../../../api/apiClient";
import { Account, DistributionInstruction } from "../../../../api/payloads/customer.payload";
import {
  ColumnDef,
  DataSourceTable,
} from "../../../../components/DataSourceTable";
import { ErrorContactEmail } from "../../../../components/ErrorContactEmail/ErrorContactEmail";
import { useClientDetails, useMaskedParams, useUI } from "../../../../hooks";
import { AccountType, DistributionInstructionStatus } from "../../../../utility/constants";
import { AddAccountDialog } from "../Dialogs/AddAccountDialog";
import { createObjectMap } from "../../../../utility/array.util";
import MuiLink from '@mui/material/Link';
import { InstructionDetailsModal } from "../../../../components/CustomModal/DistributionInstructionDetailModal";
import { deepClone } from "../../../../utility/object.util";


export const ClientAccounts: FC = () => {
  const { activeClient, setActiveClient } = useClientDetails();
  const { setLoading, setError } = useUI();
  const { clientId } = useMaskedParams();
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);
  const [selectedDistributionInstruction, setSelectedDistributionInstruction] = useState<DistributionInstruction | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const accountColumnDef = useMemo(() => {
    const clientInstructions = activeClient?.distributionInstructions ?? [];
    const distributionInstructionsMap = createObjectMap(clientInstructions, 'id');
    const columnDef: ColumnDef<Account & { owners: string }> = [
      {
        id: "owners",
        label: "Owners",
        displayFn: (_, r) =>
          r.account_type_id === AccountType.IRA
            ? r.custodian
            : r.contact_owners.map((r) => r.name)?.join(", ") || "Pending",
      },
      {
        id: "account_number",
        label: "Account Number",
        displayFn: (v, r) =>
          !!v && r.custodian_id !== null ? v : "---",
      },
      { id: "account_name", label: "Account Name" },
      { id: "account_type", label: "Account Type" },
      { id: "distribution_instruction_id", label: "Distribution Instruction",
        displayFn: (v, row) => {
          if (row.distribution_instruction_status === DistributionInstructionStatus.Pending) {
            return 'Instruction Not Set';
          }
          const instruction = distributionInstructionsMap[v];
          if (instruction && row.distribution_instruction_status === DistributionInstructionStatus.Approved) {
            return (
              <MuiLink
                component="span"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  // Clone and modify the instruction as needed
                  let clonedInstruction = deepClone(instruction);
                  if (row.account_type_id === AccountType.IRA) {
                    clonedInstruction.details.ffc_account_number = row.account_number ?? '';
                    clonedInstruction.details.ffc_to = '';
                  }
                  else if (row.account_type_id !== AccountType.IRA && row.custodian_id !== null) {
                    clonedInstruction.details = {
                      ...clonedInstruction.details,
                      ffc_account_number: row.account_number ?? '',
                      ffc_to: row.account_name ?? '',
                      instruction_address_1: '--',
                      instruction_address_2: '',
                      instruction_city: '',
                      instruction_state: '',
                      instruction_zip: ''
                    };
                  }
                  handleOpenModal(clonedInstruction);
                }}
              >
                {instruction.name}
              </MuiLink>
            );
          } else {
            return 'Instruction Not Set';
          }
        }
      },
    ];

    return columnDef;
  }, [activeClient])

  const accountDataSource: Account[] = useMemo(() => {
    return activeClient?.accounts ?? [];
  }, [activeClient]);

  const handleAddAccountClick = () => {
    setShowAddAccountModal(true);
  };

  const handleOpenModal = (instruction: DistributionInstruction) => {
    setSelectedDistributionInstruction(instruction)
    setModalOpen(true);
  }
  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedDistributionInstruction(null);
  };

  const refreshClientData = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await apiClient.get("/customers/:p0", {
        routeParams: [clientId!],
      });
      setActiveClient(resp);
    } catch (error) {
      setError(
        <div>
          <Typography variant="h6">Something went wrong:</Typography>
          <Typography variant="body1">
            We were unable to load your data after updating the professionals.
            Please contact <ErrorContactEmail /> for assistance.
          </Typography>
        </div>
      );
    } finally {
      setLoading(false);
    }
  }, [clientId, setError, setActiveClient, setLoading]);

  const closeAddAccountModal = async (success?: boolean) => {
    setShowAddAccountModal(false);
    if (!success) {
      return;
    }

    refreshClientData();
  };

  return (
    <Box component="section" mb={1}>
      <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
        <Typography variant="h5" mb={1}>
          Accounts
        </Typography>
        <Button
          color="info"
          onClick={handleAddAccountClick}
          startIcon={
            <Box>
              <AddIcon sx={{ fontSize: 14 }} />
            </Box>
          }
          sx={{ py: 0 }}
        >
          Add New Account
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <DataSourceTable
          columnDef={accountColumnDef}
          dataSource={accountDataSource}
          emptyMessage="No accounts"
        />
      </TableContainer>
      <Dialog
        open={showAddAccountModal}
        fullWidth
        maxWidth="md"
      >
        <AddAccountDialog
          onClose={closeAddAccountModal}
          clientId={+clientId!}
        />
      </Dialog>
      {selectedDistributionInstruction && (
        <InstructionDetailsModal 
          open={modalOpen} 
          onClose={handleCloseModal} 
          instruction={selectedDistributionInstruction}
          isCustodied={selectedDistributionInstruction.details.custodian_id !== null}
        />
      )}
    </Box>
  );
};

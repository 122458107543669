import React from 'react';
import MaskedInput from 'react-text-mask';
import { TextField } from '@mui/material';
import { FieldProps, useField } from 'formik';

const InputMasked = React.memo(
  React.forwardRef(({ mask, inputRef, ...other }: any, ref) => (
    <MaskedInput
      {...other}
      ref={(input) => {
        // Fixing ref console error...basically just need to make sure that we're passing input element to the ref
        if (ref) {
          if (typeof ref === 'function') {
            ref(input?.inputElement || null);
          } else if (typeof ref === 'object' && ref !== null) {
            ref.current = input?.inputElement || null;
          }
        }
      }}
      mask={mask}
      showMask={false}
      guide={false}
    />
  ))
);

interface Props extends FieldProps {
  label: string;
}

const createMaskedInputComponent = (ComponentName: string, mask: (string | RegExp)[]) => {
  const MaskedInputComponent = ({ field, ...other }: Props) => {
    const [_fieldProps, meta, _helpers] = useField(field.name);
    return (
      <TextField
        {...field}
        {...other}
        helperText={meta.touched && meta.error ? meta.error : ""}
        error={!!meta.touched && !!meta.error}
        InputProps={{
          inputComponent: InputMasked,
          inputProps: { mask, inputRef: _helpers.setValue }
        }}
        InputLabelProps={{ shrink: !!field.value }}
      />
    );
  };

  MaskedInputComponent.displayName = ComponentName;
  return MaskedInputComponent;
};

const ssnMask = [
  /\d/, /\d/, /\d/,
  '-', /\d/, /\d/,
  '-', /\d/, /\d/, /\d/, /\d/
];

const phoneMask = [
  /\d/, /\d/, /\d/,
  '-', /\d/, /\d/, /\d/,
  '-', /\d/, /\d/, /\d/, /\d/
];

const SSNInput = createMaskedInputComponent('SSNInput', ssnMask);
const PhoneInput = createMaskedInputComponent('PhoneInput', phoneMask);

export { SSNInput, PhoneInput };

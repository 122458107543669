/** 
 * First cut attempt at a deep cloning function a la lodash's cloneDeep, but utilizing 
 * ECMAScripts structuredClone API + generic typing. Think this will work for the 
 * most common use cases, but may require some enhancements overtime.
 */
export function deepClone<T extends Object>(obj: T) {
  return structuredClone(obj) as T;
}


export function areObjectsEquivalent(obj1: any, obj2: any): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  
  return true;
}
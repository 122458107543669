import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Button, Link, Paper, TableContainer, Toolbar, Tooltip, Typography } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { apiClient } from "../../../../api/apiClient";
import { ColumnDef, DataSourceBase, DataSourceTable } from "../../../../components/DataSourceTable";
import { ErrorContactEmail } from "../../../../components/ErrorContactEmail/ErrorContactEmail";
import { useInit, useUI, useMaskedParams } from "../../../../hooks";
import { groupBy } from "../../../../utility/array.util";
import { downloadBlob } from "../../../../utility/file.util";
import { toCurrency } from "../../../../utility/number.util";

type CheckListDef = {
  legal_name: string,
  commitment?: number,
  subscription_amount?: number,
}
const currentYear = new Date().getFullYear()

const displayCapitalFn = (v: number, row: any) => {
  const amount = row?.commitment ?? row?.subscription_amount;
  return (
    <Box component="span" color="primary.main">
      {`${toCurrency(amount)}`}
    </Box>
  );
};

const displayEntityName = (v: string, row: any) => {  
  const handleK1Download =async(e:any, documentId:number)=>{
    try {
      e.preventDefault()
      const resp = await apiClient.get("/documents/:p0", { routeParams: [documentId] });
      window.open(resp.url)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Box sx={{display:"flex"}}>    
      {row?.document_id ? 
      <>
       <CheckBoxIcon sx={{color:"primary.main"}}/>
       <Tooltip title="Download">
        <Link sx={{mt:0.4, ml:"2px"}} color="primary" onClick={(e)=> handleK1Download(e, row.document_id)} href="#">{v}</Link> 
       </Tooltip>
      </>:
      <>
       <CheckBoxOutlineBlankIcon sx={{color:"grey"}} />
       <Typography sx={{ml:"2px"}} component="span">{v}</Typography>
      </>
       }
    </Box>
  )
}

const k1checklistColumnDef: ColumnDef<CheckListDef> = [
  { id: 'legal_name', label: "Entities", displayFn: displayEntityName },
  { id: 'commitment', label: "Committed Amount", displayFn: displayCapitalFn, },
];

export const K1Checklist: FC = () => {
  const { clientId } = useMaskedParams();
  const [checkListState, setCheckListState] = useState<DataSourceBase[]>([])
  const [childrenData, setChildrenData] = useState<DataSourceBase[]>([])
  const { setLoading, setError, setSuccess } = useUI();

  const init = useCallback(async () => {
    const data = await apiClient.get('/documents/k1checklist/:p0' , {routeParams: [clientId!]});
    const { regDK1s, bdcK1s } = data;
    const accounts = [...regDK1s, ...bdcK1s]
    const docsByAccount = groupBy(accounts, f => f.account_id)
    const dataSource = Object.entries(docsByAccount).map(([accountId, docs]) => ({
      id: accountId,
      name: docs[0].account_name,
      children: docs
   }));
    setCheckListState(dataSource)
    setChildrenData(accounts)
  }, [clientId])

  useInit(init)

  const isDocAvailable = useMemo(() => {
    return childrenData?.some((c => !!c.document_id))
  }, [childrenData])
  
  const handleDownload = async () => {
    try {
      setLoading(true)
      const payload = childrenData?.filter((c => !!c.document_id )).map((c => c.document_id)) as number[]
      await apiClient.post('/documents/bulk-download', { data: payload })
      setSuccess(
        'Your documents are being prepared. ' + 
        'You will receive an email with the download link once they are ready. ' + 
        'For large downloads, please allow up to 15 minutes for the email to arrive'
      );
    } catch (error) {
      setError(
        <div>
          <Typography variant="h6">Something went wrong:</Typography>
          <Typography variant="body1">
            We were unable to load docs for K1 Checklist.
            Please contact <ErrorContactEmail /> for assistance.
          </Typography>
        </div>
      )
    } finally {
      setLoading(false);
    }
  }
  return (
    <>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box sx={{p:1}}>
          <Typography variant="h4" sx={{ paddingBottom: 3 }}>
            {currentYear-1} Schedule K-1 Forms
          </Typography>
          <Typography>
            Below are Schedule K-1 Tax Forms for the {currentYear-1} tax year. When a Schedule K-1 has
            been published and ready to be viewed/downloaded, you'll see a check mark in the box next to that
            investment account. Boxes without a checkmark are not yet available.
          </Typography>
        </Box>
      </Toolbar>
      <Box sx={{ mt: 5, display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={handleDownload} disabled={!isDocAvailable}>Download All</Button>
      </Box>
      {checkListState?.map((checklist, index) => (
        <Box key={index} component="section" mb={1}>
          <Typography variant="h5" mb={1} mt={5}>{checklist.name}</Typography>
          <TableContainer component={Paper} sx={{ 
              mb: 1,
              '& .col-header-legal_name, & .col-legal_name': {
                width: '75%'
              }
            }}>
            <DataSourceTable columnDef={k1checklistColumnDef} dataSource={checklist?.children!.map(((c) => ({...c, id: c.id})))} emptyMessage="No K1CheckList" />
          </TableContainer>
        </Box>
      ))}
    </>
  )
}
import { Box } from "@mui/material";
import { FC } from "react";
import SubDocsCheckBoxGroup from "./CustomFields/SubDocsCheckBoxGroup";

export const AccreditedInvestor: FC = () => {
  
  return (
    <Box sx={{ display: 'flex', py: 2,}}>
      <SubDocsCheckBoxGroup />
    </Box>
  )
}
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { ErrorMessage, useFormikContext } from "formik";
import { useEffect } from "react";
import { Accreditation } from '../../../../api/payloads/customer.payload';
import { useClients, useProfessionals } from '../../../../hooks';
import { useNewClient } from '../../../../hooks/ContextHooks/useNewClient';
import { NewClientFormValues } from './newClientFormSchema';

type InfoCardProps = {
  title: string,
  value: string
}

const InfoCard = ({title, value}:InfoCardProps) => {
  return (
  <Box sx={{p:2}}>
    <Typography 
    sx={{fontSize: 16, fontWeight: 'bold'}}
    >{`${title}:`}</Typography>
    <Typography sx={{color: 'info.contrastText'}}>{value}</Typography>
  </Box>
  )
}

type ReviewHouseHoldFormProps = {
  handleBack: () => void
}

export default function ReviewHouseholdForm({handleBack}: ReviewHouseHoldFormProps) {
  const {accreditationValues, phoneTypeValues,} = useNewClient();
  const {professionalsList} = useProfessionals();
  const { values, setErrors } = useFormikContext<NewClientFormValues>();
  const {addressStates} = useClients();
 
  const findState = (stateId: number | string):string => {
    if (addressStates != null && stateId != null) {
      for (let i = 0; i < addressStates?.length;i++ ) {
        const state = addressStates[i]
        if (state.id?.toString() === stateId) return state.name
      }
    }
    return ""
  }

  const maskSSN = (ssn: string) => {
    let r = ssn.replace(/\D/g,"");
    if (r.length > 9) {
        r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/,"***-**-$3");
        return r;
    }
    else if (r.length > 4) {
        r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/,"***-**-$3");
    }
    else if (r.length > 2) {
        r = r.replace(/^(\d\d\d)(\d{0,3})/,"**-$2");
    }
    else {
        r = r.replace(/^(\d*)/, "$1");
    }
    return r;
  }

  const displayCountry = (countryString: string):string => {
    const splitCountry = countryString.split(";")
    if (splitCountry.length === 2) {
      return splitCountry[1]
    } else {
      return ""
    }
  }

  const getNumberType = (id: number | string) => {
    for (let i = 0; i < phoneTypeValues.length; i++) {
      const phoneType = phoneTypeValues[i]
      if (phoneType?.id?.toString() === id?.toString()) {
        return phoneType.name
      }
    }
    return ""
  }
  
  return (
    <Box>
      <Box sx={{mb: 2}}>
        <ErrorMessage  name="error"/> 
        <Accordion defaultExpanded={true} sx={{mb: 2}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >Household Details
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <InfoCard title="Household Name" value={values.householdName ?? ""}></InfoCard>
              </Grid>
              <Grid item xs={2}>
                <InfoCard title="Street Address 1" value={values.streetAddress1 ?? ""}></InfoCard>
              </Grid>
              <Grid item xs={2}>
                  <InfoCard title="Street Address 2" value={values.streetAddress2 ?? ""}></InfoCard>
              </Grid>
              <Grid item xs={2}>
                  <InfoCard title="City" value={values.city ?? ""}></InfoCard>
              </Grid>
              <Grid item xs={2}>
                  <InfoCard title="State" value={findState(values.stateId)}></InfoCard>
              </Grid>
              <Grid item xs={2}>
                  <InfoCard title="Zip Code" value={values.zipCode?.toString() ?? ""}></InfoCard>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{mb: 2}}>
        <ErrorMessage  name="error"/> 
        {values.members && values.members.map((member, index) => (
        <Accordion defaultExpanded={true} key={index} sx={{mb: 2}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >{`Member ${index + 1}`}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <InfoCard title="First Name" value={member.firstName ?? ""}></InfoCard>
              </Grid>
              <Grid item xs={4}>
                <InfoCard title="Middle Name" value={member.middleName ?? ""}></InfoCard>
              </Grid>
              <Grid item xs={4}>
                  <InfoCard title="Last Name" value={member.lastName ?? ""}></InfoCard>
              </Grid>
              <Grid item xs={4}>
                  <InfoCard title="email" value={member.email ?? ""}></InfoCard>
              </Grid>
              <Grid item xs={4}>
                  <InfoCard title="Date of Birth" value={member.dateOfBirth?.format('MMMM Do, YYYY') ?? ""}></InfoCard>
              </Grid>
              <Grid item xs={4}>
                  <InfoCard title="SSN" value={maskSSN(member.SSN)}></InfoCard>
              </Grid>
              <Grid item xs={4}>
                  <InfoCard title="Phone Number" value={member.phoneNumber?.toString() ?? ""}></InfoCard>
              </Grid>
              <Grid item xs={4}>
                  <InfoCard title="Number Type" value={getNumberType(member.contactNumberType) ?? ""}></InfoCard>
              </Grid>
              <Grid item xs={4}>
                  <InfoCard title="Country Code" value={displayCountry(member.countryCode)}></InfoCard>
              </Grid>
              <Grid item xs={4}>
                  <InfoCard title="Accredidation" value={accreditationValues.find((accreditation) => accreditation.id?.toString() === member?.accreditation?.toString())?.name ?? ""}></InfoCard>
              </Grid>
              <Grid item xs={4}>
                  <InfoCard title="ID File" value={member?.idImageFile?.name ?? ""}></InfoCard>
              </Grid>
              <Grid item xs={4}>
                  <InfoCard title="ID Expiration Date" value={member.expirationDate?.format('MMMM Do, YYYY') ?? ""}></InfoCard>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>))}
        {values.professionals && <Accordion defaultExpanded={true} sx={{mb: 2}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >Professional Details
          </AccordionSummary>
          <AccordionDetails>
            {values.professionals.map((professional, index) => {
            const professionalData = professionalsList.find(p => p.id === professional.professionalId)
             return (
              <Grid key={index} container spacing={2}>
                <Grid item xs={4}>
                  <InfoCard title="Professional Name" value={professionalData ? `${professionalData.first_name} ${professionalData.last_name}` : ""}></InfoCard>
                </Grid>
                <Grid item xs={4}>
                  <InfoCard title="Primary Contact" value={professional.professionalId === values.primaryProfessionalId ? "Yes": "No"}></InfoCard>
                </Grid>
                <Grid item xs={4}>
                  <InfoCard title="Receives Communications" value={professional.receivesComms ? "Yes": "No"}></InfoCard>
                </Grid>
              </Grid>
              )}
            )}
          </AccordionDetails>
        </Accordion>}
      </Box>
    </Box>
  );
}
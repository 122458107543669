import { InvestingEntityType } from "./constants";

export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  return parts.length === 2 ? parts.pop()!.split(';').shift() : "";
}

export function isNullish(value: any): value is null | undefined {
  return (value === null || value === undefined);
}

export const stockImages = [
  "stock-image-1.jpg",
  "stock-image-2.jpg",
  "stock-image-3.jpg",
  "stock-image-4.jpg",
  "stock-image-5.jpg",
  "stock-image-6.jpg",
  "stock-image-7.jpg",
  "stock-image-8.jpg",
  "stock-image-9.jpg",
  "stock-image-10.jpg",
  "stock-image-11.jpg",
  "stock-image-12.jpg",
]

export const isProduction = process.env.REACT_APP_PRODUCTION === "true";

export const isDebug = process.env.REACT_APP_DEBUG === "true";

export const TOKEN_EXPIRED = "TOKEN_EXPIRED";

/** Hacky code necessary for phone numbers to display correctly in JFM */
export const defaultCountryData = '1;United States';

export function isBdc(investingEntityTypeId: number) {
  return [InvestingEntityType.BDCEquity, InvestingEntityType.BDCDebt].includes(investingEntityTypeId);
}
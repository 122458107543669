import { Breadcrumbs, Link as MuiLink, styled, Toolbar, Typography } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import { CapitalCall } from "../../../../../api/payloads";
import { formatDate } from "../../../../../utility/date.util";
import { toCurrency } from "../../../../../utility/number.util";

const HeaderTable = styled('table')(({theme}) => ({
  border: 'none',
  width: '25%',
  marginBottom: theme.spacing(1),
}));

type CapitalCallDetailHeaderProps = {
  capitalCall: CapitalCall,
}

export const CapitalCallDetailHeader: FC<CapitalCallDetailHeaderProps> = ({capitalCall}) => {
  return (
    <Toolbar sx={{ flexDirection: 'column', alignItems: 'flex-start', pt: 2 }}>
      <Breadcrumbs sx={{ pb: 1 }}>
        <MuiLink component={Link} to=".." relative="path">Capital Calls</MuiLink>
        <Typography>{capitalCall.title}</Typography>
      </Breadcrumbs>
      <Typography variant="h4" mb={1}>{capitalCall.title}</Typography>
      <HeaderTable>
        <tbody>
          <tr><td>Due Date</td><td>{formatDate(capitalCall.date)}</td></tr>
          <tr><td>Status</td><td>{capitalCall.status ? "Open" : "Closed"}</td></tr>
          <tr><td>Called Amount</td><td>{toCurrency(capitalCall.totalCalled)}</td></tr>
          <tr><td>Paid Amount</td><td>{toCurrency(capitalCall.totalPaid)}</td></tr>
        </tbody>
      </HeaderTable>
      <Typography>{capitalCall.description}</Typography>
    </Toolbar>

  )
}
import { Search } from "@mui/icons-material"
import { Box, Button, Checkbox, DialogActions, DialogContent, DialogTitle, InputAdornment, Radio, styled, TableContainer, TextField, Typography } from "@mui/material"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { apiClient } from "../../../../api/apiClient"
import { PostInterestedPartiesReq, Professional } from "../../../../api/payloads"
import { CustomerProfessional } from "../../../../api/payloads/customer.payload"
import { CornerCloseButton, LoadingButton } from "../../../../components/Buttons"
import { ColumnDef, DataSourceTable } from "../../../../components/DataSourceTable"
import { ErrorContactEmail } from "../../../../components/ErrorContactEmail/ErrorContactEmail"
import { useAuth, useClientDetails, useProfessionals, useUI } from "../../../../hooks"
import { ProfessionalRelationship, ProfessionalType } from "../../../../utility/constants"
import { getProfessionalTypeName } from "../../NewClientPage/Forms/ProfessionalsTable"

const TableCheckBox = styled(Checkbox)({padding: 0})
const TableRadio = styled(Radio)({padding: 0})

type AddProfessionalData = Professional & { isAddChecked: boolean, isPrimaryContact: boolean, isReceivesChecked: boolean }

type AddProfessionalDialogProps = {
  onClose: (result?: boolean) => void,
  clientId: number,
  isManageMode?: boolean,
  setProfessionalToDelete: React.Dispatch<React.SetStateAction<Pick<Professional, 'first_name' | 'last_name' | 'id'> | null>>
}

export const AddProfessionalDialog: FC<AddProfessionalDialogProps> = ({ 
  onClose, 
  clientId, 
  setProfessionalToDelete,
  isManageMode = false 
}) => {
  const { activeClient } = useClientDetails();
  const { user } = useAuth();
  const { setError } = useUI();
  const { professionalsList, professional } = useProfessionals();
  const [isLoading, setLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  
  const clientProfessionals = useMemo(() => {
    return activeClient?.professionals ?? [];
  }, [activeClient]);

  const filteredProfessionals = useMemo(() => {
    return professionalsList
      .filter(p => {
        const isClientProfessional = clientProfessionals.some(cp => cp.id === p.id);
        return isManageMode ? isClientProfessional : !isClientProfessional;
      })
      .sort((a, b) => a.first_name.localeCompare(b.first_name))
  }
    , [clientProfessionals, professionalsList, isManageMode]);

  const dataSource = useMemo<AddProfessionalData[]>(() => {
    return filteredProfessionals.map(p => ({
      ...p,
      isAddChecked: false,
      isPrimaryContact: false,
      isReceivesChecked: false,
    }));
  }, [filteredProfessionals]);

  const filteredDataSource = useMemo(() => {
    return dataSource.filter(d => `${d.first_name} ${d.last_name}`.toLocaleLowerCase().includes(searchFilter));
  }, [dataSource, searchFilter]);

  const [professionalsToAdd, setProfessionalsToAdd] = useState<Record<number, { receivesComm: boolean }>>(
    isManageMode ? getMangeModeFormData(clientProfessionals) : {}
  );

  /* 
    So sorry about this, but the last minute request to move the "Remove" feature to this modal may be contorting t too much to 
    comfortably support the dual "manage mode". Fow now I'll use a useEffect to reset the form state when active clients changes (i.e. "after using "Remove"),
    but in the future we should take the time to split the Management features into their own component.
  */
  useEffect(() => {
    if (!isManageMode) return;

    setProfessionalsToAdd(getMangeModeFormData(clientProfessionals));
  }, [clientProfessionals, isManageMode])
  

  const [primaryContact, setPrimaryContact] = useState<number | null>(
    !isManageMode
      ? null
      : clientProfessionals.find(p => p.is_primary)?.id ?? null
  );

  const handleCheckAdd = useCallback((row: AddProfessionalData, isChecked: boolean) => {
    if (isChecked) {
      setProfessionalsToAdd(current => ({ ...current, [row.id]: { receivesComm: false } }));
      return;
    }
    if (row.id === primaryContact) {
      setPrimaryContact(null)
    }
    setProfessionalsToAdd(current => {
      delete current[row.id];
      return { ...current };
    });
  }, [primaryContact]);

  const handleIsPrimaryChange = useCallback((row: AddProfessionalData, isChecked: boolean) => {
    if (isChecked) {
      setPrimaryContact(row.id);
      return;
    } else {
      setPrimaryContact(null)
    }
  }, []);

  const handleCheckReceivesComm = useCallback((row: AddProfessionalData, isChecked: boolean) => {
    setProfessionalsToAdd(current => {
      current[row.id].receivesComm = isChecked;
      return { ...current };
    })
  }, [])

  const handleAdd = async () => {
    try {
      setLoading(true);
      const payload: PostInterestedPartiesReq = Object.entries(professionalsToAdd).map(([id, value]) => ({
        professional_id: +id,
        advisory_id: professional!.advisory_id,
        customer_id: clientId,
        professional_relationship_id: ProfessionalRelationship.Advisor,
        receives_email_notices: value.receivesComm,
      }));

      await apiClient.post("/professionals/interested-parties", { data: payload });

      if (primaryContact) {
        await apiClient.patch(`/customers/:p0`, { routeParams: [clientId], data: { primary_advisor_id: primaryContact } });
      }
      onClose(true);
    } catch (error) {
      setError(
        <div>
          <Typography variant="h6">Something went wrong:</Typography>
          <Typography variant="body1">
            We were unable to add the selected professionals.
            Please contact <ErrorContactEmail /> for assistance.
          </Typography>
        </div>
      )
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async () => {
    try {
      setLoading(true);
      
      for (const [professionalId, value] of Object.entries(professionalsToAdd)) { 
        await apiClient.patch(`/professionals/interested-parties/customers/:p0/professional/:p1`, { 
          routeParams: [clientId, professionalId], data: { professional_id: +professionalId, receives_email_notices: value.receivesComm } });
        }

        await apiClient.patch(`/customers/:p0`, { routeParams: [clientId], data: { primary_advisor_id: primaryContact } });

      onClose(true);
    } catch (error) {
      setError(
        <div>
          <Typography variant="h6">Something went wrong:</Typography>
          <Typography variant="body1">
            We were unable to add the selected professionals.
            Please contact <ErrorContactEmail /> for assistance.
          </Typography>
        </div>
      )
    } finally {
      setLoading(false);
    }
  }

  const columnDef = useMemo(() => {
    const def: ColumnDef<AddProfessionalData & {remove: never}> = [
      { id: "first_name", label: "Name", sortable: true, displayFn: (_, r) => `${r.first_name} ${r.last_name}` },
      { id: "phone", label: "Phone", sortable: true },
      { id: "email", label: "Email", sortable: true },
      { id: "professional_type_id", label: "Type", sortable: true, displayFn: (_, r) => getProfessionalTypeName(r.professional_type_id as ProfessionalType) },
      ...(!isManageMode ? [{
        id: "isAddChecked",
        label: "Add",
        displayFn: (_, r) => (
          <TableCheckBox
            disabled={isLoading}
            checked={!!professionalsToAdd[r.id]}
            onChange={e => handleCheckAdd(r, e.target.checked)}
          />
        )
      } as ColumnDef<AddProfessionalData>[number]] : []),
      {
        id: "isPrimaryContact",
        label: "Primary Contact",
        displayFn: (_, r) => !isManageMode
          ? (
          <TableCheckBox
            disabled={!professionalsToAdd[r.id] || isLoading}
            checked={primaryContact === r.id && !!professionalsToAdd[r.id]}
            onChange={e => handleIsPrimaryChange(r, e.target.checked)}
          />
        )
        : (
          <TableRadio
            disabled={isLoading}
            checked={primaryContact === r.id && !!professionalsToAdd[r.id]}
            onChange={e => handleIsPrimaryChange(r, e.target.checked)}
          />
        )
      },
      {
        id: "isReceivesChecked",
        label: "Receives Communications",
        displayFn: (_, r) => (
          <TableCheckBox
            disabled={isLoading || !professionalsToAdd[r.id]}
            checked={!!professionalsToAdd[r.id]?.receivesComm}
            onChange={e => handleCheckReceivesComm(r, e.target.checked)}
          />
        )
      },
      ...(isManageMode ? [{
        id: "remove",
        label: "",
        displayFn: (_, r) => (
          <Button 
            color="error" 
            sx={{fontFamily: 'inherit'}} 
            size="small" disabled={r.id === user!.id || r.id === activeClient?.primaryAdvisorId} 
            onClick={() => setProfessionalToDelete(r)}
          >
            Remove
          </Button>
        )
      } as ColumnDef<AddProfessionalData & {remove: never}>[number]] : []),
    ];
    return def;
  }, [isManageMode, professionalsToAdd, handleCheckAdd, handleCheckReceivesComm, primaryContact, handleIsPrimaryChange, isLoading, user, activeClient, setProfessionalToDelete])

  const handleFilter = (searchFilter: string) => {
    setSearchFilter(searchFilter);
    // const filteredData = dataSource.filter(d => `${d.first_name} ${d.last_name}`.toLocaleLowerCase().includes(searchFilter));
    // setFilteredDataSource(filteredData);
  };

  return (
    <>
      <DialogTitle sx={{ pt: 5 }}>
        <Box display="flex" justifyContent={"space-between"} alignItems="center">
          <Typography variant="h5">{isManageMode ? "Manage Professionals" : "Add New Professional"}</Typography>
          <TextField
            size="small"
            helperText=""
            onChange={e => setSearchFilter(e.target.value)}
            value={searchFilter}
            placeholder="Search"
            InputProps={{
              startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
            }}
          />
        </Box>
        <CornerCloseButton onClick={() => onClose()}></CornerCloseButton>
      </DialogTitle>
      <DialogContent>
        <TableContainer>
          <DataSourceTable columnDef={columnDef} dataSource={filteredDataSource} />
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={!isManageMode ? handleAdd : handleSubmit}
          loading={isLoading}
          disabled={!Object.keys(professionalsToAdd).length}
          color="info" >
          {isManageMode ?  "Submit" : "Add"}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

function getMangeModeFormData(clientProfessionals: CustomerProfessional[]) {
  return clientProfessionals.reduce((result, current) => {
    result[current.id] = { receivesComm: current.receives_email_notices };
    return result;
  }, {} as Record<number, { receivesComm: boolean }>)
}
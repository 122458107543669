import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { Box, Button, FormHelperText, IconButton, Tooltip, Typography } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { SimpleFileUpload } from 'formik-mui';
import moment from 'moment';
import { MouseEvent, useCallback, useEffect } from 'react';
import { apiClient } from '../../../../../api/apiClient';
import { DatePickerField } from "../../../../../components/FormFields/DatePickerField";
import { LimitedLineTypography } from '../../../../../components/Typography/LimitedLineTypography';
import { useUI } from '../../../../../hooks';
import { isNullish } from '../../../../../utility/misc.util';
import { SubDocsFormValues } from '../subDocsFormSchema';

type Props = {
  label: string
  name: string
  index: number
  owner: string
}

export const IdUploadFields = ({ label, name, index, owner }: Props) => {
  const form = useFormikContext<SubDocsFormValues>();
  const [field, metaData] = useField(name);
  const {values, setFieldValue, setFieldTouched} = form 
  const fileValue = values.ownerIdDocuments[index]
  const file = fileValue.idImageFile
  const title = file ? file.name : label
  const { setLoading, setError } = useUI();
  
  const handleFileClick = useCallback(async (e: MouseEvent<any>) => {
    try {
      if (fileValue.imageId == null) {
        return
      }
      e.preventDefault();
      setLoading(true);
      const resp = await apiClient.get("/documents/:p0", { routeParams: [fileValue.imageId] });
      window.open(resp.url)
    } catch (error) {
      setError("Unable to download file");
    } finally {
      setLoading(false);
    }
  }, [setLoading, fileValue, setError]);

  useEffect(() => {
    setFieldTouched(`ownerIdDocuments[${index}].idImageFile`,true)
    if (fileValue.expirationDate && fileValue.expirationDate.isBefore(moment())){
      setFieldTouched(`ownerIdDocuments[${index}].expirationDate`,true)
    }
  }, [index,fileValue.expirationDate, setFieldTouched, fileValue.idImageFile])

  const handleFileDelete = () => {
    setFieldValue(`ownerIdDocuments[${index}].imageId`, null)
    setFieldValue(`ownerIdDocuments[${index}].idImageFile`, null)
  }

  return (
    <Box>
      <Typography>{owner}</Typography>
        <Button 
          endIcon={fileValue.imageId == null 
            ? <DriveFolderUploadIcon /> 
            : <CloudDownloadIcon/>
          } 
          variant={file ? "contained":"outlined"} 
          component="label"
          sx={{width: 'calc(33% - 8px)', mr: 2, height: 57}} 
          onClick={(e: MouseEvent) => handleFileClick(e)}
        >
          <LimitedLineTypography numLines={1}>{title}</LimitedLineTypography>
          <Box sx={{display: 'none'}} hidden={true}>
            <SimpleFileUpload 
              meta={{
                touched: true, 
                value: "test", 
                error: "null", 
                initialValue: "", 
                initialTouched: false 
              }}
              form={form} 
              field={field}
              accept="image/*,application/pdf" 
              label="Upload Photo ID"
            />
          </Box>
        </Button>
        {fileValue.imageId != null && <IconButton onClick={(_e) => handleFileDelete()} sx={{mr: 2}}>
          <DeleteIcon sx={{color: 'error.main'}} />
        </IconButton>}
        <Tooltip title={!isNullish(fileValue.imageId) ? "To change the expiration date, first upload a new ID" : ""}>
          <span>
            <DatePickerField disabled={!isNullish(fileValue.imageId)} name={`ownerIdDocuments[${index}].expirationDate`} label="ID Expiration Date" sx={{width: 'calc(33% - 8px)'}} minDate={moment().add(1, 'day')}/>
          </span>
        </Tooltip>
        {(metaData.error && metaData.touched) && <FormHelperText error>{metaData.error}</FormHelperText>}
    </Box>
  )
};
import {
  Box, Breadcrumbs, Container, Link as MuiLink, Paper, TableContainer,
  Toolbar, Typography
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { apiClient } from "../../../api/apiClient";
import { Transaction } from "../../../api/payloads";
import { ContentBackground } from "../../../components/ContentBackground/ContentBackground";
import { ColumnDef, DataSourceTable } from "../../../components/DataSourceTable";
import { useInit, useUI, useMaskedParams } from "../../../hooks";
import { formatDate } from "../../../utility/date.util";
import { toCurrency } from "../../../utility/number.util";
import { maskRouteId } from "../../../utility/route-mask.util";
import { getPathByLabel } from "../../../routes/router.util";
import { bdcOpportunityDetailNavLinks } from "../../../routes/child-routes/opportunity-detail.routes";

const transactionColumnDef: ColumnDef<Transaction> = [
  { id: 'posting_date', label: "Date",displayFn: v => formatDate(v) },
  { id: 'transaction_type_name', label: "Type" },
  { id: 'shares', label: "Shares", showTotal: true },
  { id: 'nav', label: "Price per Share", displayFn: v => toCurrency(v, true),},
  { id: 'gross_amount', label: "Amount", displayFn: v => toCurrency(v, true),},
  { id: 'subscription_amount', label: "Subscription Amount", displayFn: v => toCurrency(v, true),},
  { id: 'close', label: "Close"}
];

export const TransactionHistory: FC = () => {
  const {positionId, investmentId} = useMaskedParams()
  const [bdcInvestor, setBdcInvestor] = useState<string>()
  const [transactionHistoryRows, setTransactionHistoryRows] = useState<Transaction[]>([])
  const navigate = useNavigate()
  const {setError} = useUI()
  const firstTabPath = getPathByLabel(bdcOpportunityDetailNavLinks, "Positions") || bdcOpportunityDetailNavLinks[1].path;
  
  const init = useCallback(async () => {
    try {
      const res = await apiClient.get('/investors/bdc-positions/:p0/transactions', { routeParams: [positionId!] });
      setTransactionHistoryRows(res.transactions)
      setBdcInvestor(res.account_name)
    } catch (e) {
      setError("Oops, something went wrong. Please try again later")
    }
  }, [positionId,setError])

  useInit(init)

  return (
    <Container sx={{ py: 2 }}>
      <ContentBackground>
        <Toolbar sx={{ flexDirection: 'column', alignItems: 'flex-start', pt: 2, pb: 2 }}>
          <Breadcrumbs sx={{ pb: 1 }}>
            <MuiLink component={Link} to='/opportunities'>Opportunities</MuiLink>
            <MuiLink sx={{cursor:"pointer"}} onClick={()=> navigate(`/opportunities/${maskRouteId(investmentId)}/${firstTabPath}`, {state:{"tab":1}})}>Investors</MuiLink>
            <Typography>Transaction History</Typography>
          </Breadcrumbs>
          <Typography variant="h6" sx={{ pt: 5 }} >Transaction History</Typography>          
        </Toolbar>
        <Box p={3}>
          <Typography variant="h5" sx={{ pt:2, pb:2}}>{bdcInvestor}</Typography>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <DataSourceTable
              dataSource={transactionHistoryRows}
              columnDef={transactionColumnDef}
              emptyMessage="No Transaction History"
              initialOrderBy="amount"
            />
          </TableContainer>
        </Box>
      </ContentBackground>
    </Container>
  )
}
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { CornerCloseButton } from "../../../../components/Buttons";
import { useClientDetails, useUI } from "../../../../hooks";
import { apiClient } from "../../../../api/apiClient";
import { DistributionInstructionStatus } from "../../../../utility/constants";
import { isAxiosError } from "../../../../utility/type-guards";
import { ErrorToast } from "../../../../components/ErrorToast";

type LinkAccountInstructionsDialogProps = {
  handleClose: () => void;
  handleNewInstructionClick: () => void;
  open: boolean;
}

export const LinkAccountInstructionsDialog: FC<LinkAccountInstructionsDialogProps> = (props) => {
  const { activeClient, setActiveClient, unlinkedAccounts, accountsMap } = useClientDetails();
  const { setLoading, setError } = useUI();
  const [selected, setSelected] = useState<Record<number, { selected: boolean, instructionId?: number }>>(
    unlinkedAccounts.reduce((result, account) => ({ ...result, [account.id]: { selected: false, instructionId: undefined } }), {})
  );

  const clientInstructions = useMemo(() =>
    activeClient?.distributionInstructions.filter(i => !i.is_ira_instruction) ?? [],
    [activeClient?.distributionInstructions]
  );

  const isValid = useMemo(() => {
    const checkedRows = Object.values(selected).filter(row => row.selected);
    if (!checkedRows.length) {
      return false;
    }

    return checkedRows.every(row => row.instructionId !== undefined);
  }, [selected]);

  const handleSaveChanges = async () => {
    setLoading(true);
    const checkedRows = Object.entries(selected).filter(([_accountId, row]) => row.selected);
    let successfulUpdates = 0;

    for (const [accountId, row] of checkedRows) {
      try {
        await apiClient.post('/customers/accounts/:p0/distribution-instructions/:p1', {
          routeParams: [accountId, row.instructionId!],
          data: { status: DistributionInstructionStatus.Approved }
        });
        successfulUpdates++;
      } catch (e) {
        const header = `Error linking distribution instruction to account ${accountsMap[+accountId]?.account_name ?? `with ID ${accountId}`}`;

        if (isAxiosError(e)) {
          const isInternalError = e.response?.status === 500;
          const message = e.response?.data?.message ?? e.message;
          setError(<ErrorToast header={header} showContactEmail={isInternalError}>{message}</ErrorToast>)
        } else {
          setError(<ErrorToast header={header} showContactEmail>An unexpected error occurred.</ErrorToast>)
        }

        console.error(e);
        break;
      }
    }

    if (successfulUpdates) {
      const client = await apiClient.get("/customers/:p0", { routeParams: [activeClient!.id] });
      setActiveClient(client);
    }

    props.handleClose();
    setLoading(false);
  }

  return (
    <Dialog fullWidth maxWidth='md' open={props.open}>
      <CornerCloseButton onClick={() => props.handleClose()}></CornerCloseButton>
      <DialogTitle bgcolor='background.secondary'>
        <Box>Unlinked Accounts - {unlinkedAccounts.length}</Box>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography color='grey.600' variant='subtitle1' component='div'>Existing - Distribution Instruction</Typography>
          <Button onClick={props.handleNewInstructionClick} color='info'>+ New Instruction</Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid p={2} container
          component={Paper}
          variant="elevation"
          elevation={0}
          mb={1}
          bgcolor={(theme) => theme.palette.mode === 'dark' ? theme.palette.grey['600'] : theme.palette.grey['300']}
          fontWeight='500'
        >
          <Grid item xs={8}>Accounts</Grid>
          <Grid item xs={4}>Distribution Instructions</Grid>
        </Grid>
        {unlinkedAccounts.map((account) => (
          <Grid key={account.id}
            container p={1}
            borderBottom={theme => `1px solid ${theme.palette.divider}`}
            alignItems={'center'}
          >
            <Grid item xs={8}>
              <FormControlLabel
                label={account.account_name}
                control={(
                  <Checkbox
                    checked={selected[account.id].selected}
                    onChange={(e) => {
                      const current = selected[account.id];
                      const isChecked = e.target.checked;
                      setSelected({ ...selected, [account.id]: { selected: isChecked, instructionId: isChecked ? current.instructionId : undefined } })
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size='small' error={selected[account.id].selected && !selected[account.id].instructionId}>
                <InputLabel>Select Distribution Instruction</InputLabel>
                  <Select
                    label='Select Distribution Instruction'
                    value={selected[account.id].instructionId ?? ''}
                    onChange={e => {
                      const value = e.target.value as number | "";
                      setSelected({ ...selected, [account.id]: { selected: !!value, instructionId: value || undefined } })
                    }}
                  >
                    <MenuItem value="" disabled>{clientInstructions.length ? "Select Distribution Instruction" : "No instructions available"}</MenuItem>
                    {clientInstructions.map(instruction => (
                      <MenuItem key={instruction.id} value={instruction.id}>{instruction.name}</MenuItem>
                    ))}
                  </Select>
              </FormControl>
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={props.handleClose} sx={theme => ({color: theme.palette.text.primary})} variant="outlined">Cancel</Button>
        <Button onClick={handleSaveChanges} color="primary" disabled={!isValid}>Save Changes</Button>
      </DialogActions>
    </Dialog>
  )
}
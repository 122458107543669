
import { Auth } from 'aws-amplify';
import axiosStatic, { AxiosRequestConfig } from 'axios';
import { ApplicationSource, APPLICATION_SOURCE_ID_HEADER, APP_USERNAME_HEADER } from '../utility/constants';
import { TOKEN_EXPIRED } from '../utility/misc.util';
import { ApiRoute, DeleteTypeMap, GetRespPayloadTypeMap, PatchTypeMap, PostTypeMap, UrlMap } from './apiRoutes';

type ApiClientConfig<D = any> = AxiosRequestConfig<D> & { routeParams?: RouteParams };
type AxiosMethod = "get" | "post" | "put" | "patch" | "delete";
type RouteParams = (string | number)[];
type GetResponsePayload<T extends ApiRoute> = T extends keyof GetRespPayloadTypeMap ? GetRespPayloadTypeMap[T] : unknown;
type PostRequestPayload<T extends ApiRoute> = T extends keyof PostTypeMap ? PostTypeMap[T]['req'] : unknown;
type PostResponsePayload<T extends ApiRoute> = T extends keyof PostTypeMap ? PostTypeMap[T]['res'] : unknown;
type PatchRequestPayload<T extends ApiRoute> = T extends keyof PatchTypeMap ? PatchTypeMap[T]['req'] : unknown;
type PatchResponsePayload<T extends ApiRoute> = T extends keyof PatchTypeMap ? PatchTypeMap[T]['res'] : unknown;
type DeleteRequestPayload<T extends ApiRoute> = T extends keyof DeleteTypeMap ? DeleteTypeMap[T]['req'] : unknown;
type DeleteResponsePayload<T extends ApiRoute> = T extends keyof DeleteTypeMap ? DeleteTypeMap[T]['res'] : unknown;

class ApiClient {
  private axios = axiosStatic.create();


  /** Escape hatch in case we need to work with the axios API directly. */
  getInstance() { return this.axios; }

  setHeader(headerName: string, value: string) {
    this.axios.defaults.headers[headerName] = value;
  }

  get<T extends (keyof GetRespPayloadTypeMap & ApiRoute)>(route: T, config?: ApiClientConfig): Promise<GetResponsePayload<T>> {
    return this.send("get", route, config);
  }

  post<T extends (keyof PostTypeMap & ApiRoute)>(route: T, config?: ApiClientConfig<PostRequestPayload<T>>): Promise<PostResponsePayload<T>> {
    return this.send("post", route, config);
  }

  put(route: ApiRoute, config?: ApiClientConfig) {
    return this.send("put", route, config);
  }

  putFile(presignedUrl: string, file: File) {
    return this.axios.put(presignedUrl, file, {
      headers: { 'Content-Type': file.type }
    });
  }

  patch<T extends (keyof PatchTypeMap & ApiRoute)>(route: T, config?: ApiClientConfig<PatchRequestPayload<T>>): Promise<PatchResponsePayload<T>> {
    return this.send("patch", route, config);
  }

  delete<T extends (keyof DeleteTypeMap & ApiRoute)>(route: T, config?: ApiClientConfig<DeleteRequestPayload<T>>): Promise<DeleteResponsePayload<T>> {
    return this.send("delete", route, config);
  }

  /** 
   * Insert parameters into a parameterized route, where each param follows
   * the pattern `:p{index}
   * @example
   * const url = this.handleParameterizedRoute("/users/:p0/accounts/:p1", [123, 'xyz']) // "/users/123/accounts/xyz"
   * @todo May want to pop this out as an exported utility function
   */
  private handleParameterizedRoute(parameterizedRoute: string, params: RouteParams) {
    let route = parameterizedRoute;

    for (const i of Array.from(params.keys())) {
      route = route.replace(`:p${i}`, params[i].toString())
    }

    return route;
  }

  private async send<T extends ApiRoute>(method: AxiosMethod, route: T, config?: ApiClientConfig) {
    const session = await this.getSession();
    this.axios.defaults.headers.accessToken = session.getAccessToken().getJwtToken();
    
    // In staging/prod this will be automatically set/overwritten by the API Gateway, so this is really only needed for local development
    if (process.env.REACT_APP_LOCAL === "true") {
      this.axios.defaults.headers[APPLICATION_SOURCE_ID_HEADER] = ApplicationSource.AdvisorVision;
      this.axios.defaults.headers[APP_USERNAME_HEADER] = session.getAccessToken().decodePayload().username;
    }

    const baseURL = process.env.REACT_APP_BACKEND_URL || UrlMap[route] || "";
    const url = config?.routeParams ? this.handleParameterizedRoute(route, config.routeParams) : route;

    if (config?.data instanceof FormData) {
      const contentTypeHeader = { "Content-Type": "multipart/form-data" };
      config.headers = config.headers ? {...config.headers, ...contentTypeHeader} : contentTypeHeader;
    }

    const resp = await this.axios({ method, baseURL, url, ...config });
    return resp.data;
  }

  private async getSession() {
    try {
      return await Auth.currentSession();
    } catch (e) {
      const error = new Error("Your session has expired. Please log in again to continue using BIP Capital Advisor Vision");
      error.name = TOKEN_EXPIRED;
      throw error;
    }
  }
}


export const apiClient = new ApiClient();


import React from 'react';
import { Popover, Typography, Box, Button, Link, Fade } from '@mui/material';
import NotificationsList from './NotificationsList';
import { useNotifications } from '../../context/NotificationsContext';
import { useUI } from '../../hooks';

type NotificationsCardProps = {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onViewAll: () => void;
};

const NotificationsCard: React.FC<NotificationsCardProps> = ({ anchorEl, open, onClose, onViewAll }) => {
  const { notifications, markAsRead, clearNotifications } = useNotifications();
  const { setError } = useUI();

  const handleMarkAllAsRead = async () => {
    const result = await markAsRead();
    if (result instanceof Error) {
      console.error(result.message);
      setError(result.message);
    }
  };

  const handleClearAllNotifications = async () => {
    const result = await clearNotifications();
    if (result instanceof Error) {
      console.error(result.message);
      setError(result.message);
    } 
  };

  const unreadNotifications = notifications.filter(n => !n.isRead);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      TransitionComponent={Fade}
      transitionDuration={300}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: {
          width: 400,
          padding: 2,
          boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
          borderRadius: 2,
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="h6" fontWeight="bold">
          Notifications
        </Typography>
        <Link component="button" variant="body2" onClick={handleMarkAllAsRead}>
          Mark all as read
        </Link>
      </Box>

      <NotificationsList notificationsData={unreadNotifications} maxHeight={300} onNotificationClick={onClose} emptyMessage="No new notifications" />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
        <Button variant="outlined" size="small" onClick={onViewAll}>
          View all notifications
        </Button>
        <Button variant="text" size="small" color="error" onClick={handleClearAllNotifications}>
          Clear all notifications
        </Button>
      </Box>
    </Popover>
  );
};

export default NotificationsCard;

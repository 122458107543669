import { FC } from "react";
import { Auth } from "aws-amplify";
import { Box, Link as MuiLink} from "@mui/material"
import { Field, Form, Formik } from "formik"
import { TextField } from "formik-mui";
import { Link } from "react-router-dom";
import { InferType, object, string } from "yup";
import { LoginPageFormProps } from "../LoginPage";
import { LoadingButton } from "../../../components/Buttons";
import { AmplifyAuthResp, FormikSubmitFn } from "../../../utility/types";

const loginFormSchema = object({
  email: string().email("Not a valid email").required("Email is required"),
  password: string().required("Password is required"),
});

type LoginData = InferType<typeof loginFormSchema>;

const initialValues: LoginData = { email: '', password: '' };

/** 
 * Basic login form component. Successful responses may require 
 * further authentication stages, e.g MFA 
 */
export const LoginForm: FC<LoginPageFormProps> = ({ handleLoginStageSubmit: handleLoginResp, loading }) => {
  const handleSubmit: FormikSubmitFn<LoginData> = async (values, { setSubmitting }) => {
    const authFn = () => Auth.signIn(values.email, values.password) as Promise<AmplifyAuthResp>; 
    handleLoginResp(authFn, setSubmitting);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={loginFormSchema} onSubmit={handleSubmit}>
      <Form>
        <Field id='email' name="email" label="Email" component={TextField} fullWidth autoComplete='email'/>
        <Field id='password' name="password" label="Password" type="password" component={TextField} fullWidth autoComplete='current-password'/>
        <Box sx={{ width: "100%", display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
          <MuiLink component={Link} to="./reset">Forgot Password?</MuiLink>
        </Box>
        
        <LoadingButton type="submit" size="large" fullWidth loading={loading}>
          Login
        </LoadingButton>
      </Form>
    </Formik>
  )
}
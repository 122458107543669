import { AddCircle, Delete } from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import { FC, useMemo, useState } from "react";
import { object } from "yup";
import { apiClient } from "../../../../api/apiClient";
import { Accreditation, Country, PhoneNumberType, PostContactReq } from "../../../../api/payloads/customer.payload";
import { CornerCloseButton, LoadingButton } from "../../../../components/Buttons";
import { useInit, useUI } from "../../../../hooks";
import { createDocument } from "../../../../services/document.service";
import { DocumentTypes } from "../../../../utility/constants";
import { FormikSubmitFn } from "../../../../utility/types";
import MemberFormFields from "../../NewClientPage/Forms/MemberFormFields";
import { initialMemberValue, initialMemberValues, memberFormSchema, MemberFormValues, MemberValue } from "../../NewClientPage/Forms/newClientFormSchema";

type AddMemberDialogProps = {
  onClose: (result?: boolean) => void,
  clientId: number,
}

export const AddMemberDialog: FC<AddMemberDialogProps> = ({onClose, clientId}) => {
  const { setError, setSuccess } = useUI();
  const [isLoading, setLoading] = useState(false);
  const [accreditationValues, setAccreditationValues ] = useState<Accreditation[]>([])
  const [countryCodeValues, setCountryCodeValues ] = useState<Country[]>([])
  const [phoneTypeValues, setPhoneTypeValues ] = useState<PhoneNumberType[]>([])

  const MemberInitialValues = useMemo((): MemberFormValues => ({
    ...initialMemberValues
  }), [])

  useInit(async () => {
    const resp = await apiClient.get("/customers/new-client-field-values");
    setAccreditationValues(resp.accreditations);
    setCountryCodeValues(resp.countries);
    setPhoneTypeValues(resp.phoneNumberTypes);
  }, null)

  const handleMemberSubmit: FormikSubmitFn<MemberFormValues> = async (values) => {
    if (clientId == null)  {
      return;
    }
    setLoading(true);

    try {
      for (const m of values.members) {
        let documentId: number | undefined;

        if (m.idImageFile && m.expirationDate) { 
          const file = m.idImageFile as File;
          
          documentId = await createDocument(file, {
            fileName: file.name,
            customerId: +clientId,
            documentTypeId: DocumentTypes.Identification,
          });
        }

        const [dial_code, country_region] = m.countryCode.split(';');
        const createContactReq: PostContactReq = {
          customerId: clientId,
          member: {
            first_name: m.firstName,
            nickname: m.nickname,
            middle_name: m.middleName,
            last_name: m.lastName,
            date_of_birth: m.dateOfBirth!.toISOString(),
            email: m.email,
            ssn: m.SSN,
            accreditation: Number(m.accreditation)
          },
          phone: {
            number: m.phoneNumber,
            contact_number_types: +m.contactNumberType,
            dial_code,
            country_region
          }
        }
        const newContact = await apiClient.post("/customers/contacts", {data: createContactReq});

        if (documentId && m.expirationDate) {
          await apiClient.post('/documents/customers-documents/:p0/contact/:p1', {
            routeParams: [clientId, newContact.id],
            data: {
              expirationDate: m.expirationDate.toISOString(),
              documentId,
            },
          });
        }
      }

      setLoading(false);
      setSuccess("Successfully added member");
      onClose(true);
    } catch (e) {
      setError("Oops, something went wrong. Please try again later")
      setLoading(false)
      onClose(false)
    }
  };
  
  return (
    <>
      <DialogTitle sx={{pt: 5}}>
        <Box display="flex" justifyContent={"space-between"} alignItems="center">
          <Typography variant="h5">Add New Members</Typography>
        </Box>
        <CornerCloseButton onClick={() => onClose()}></CornerCloseButton>
      </DialogTitle>
        {phoneTypeValues.length > 0 && 
        <Formik 
          initialValues={MemberInitialValues} 
          validationSchema={object(memberFormSchema)} 
          onSubmit={handleMemberSubmit}
        >
          {({ isValid }) => (
          <Form>
            <DialogContent>
              <FieldArray name="members">
                {({ form, remove, push }) => (
                  <>
                    {(form.values.members as MemberValue[]).map((member, index) => (
                        <Accordion key={index} defaultExpanded={index === 0}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <Box sx={{ display: 'flex',alignItems: 'center', justifyContent: 'space-between', width: '100%', height: 40, mr: 2}}>
                              <Typography>{`Member ${index + 1}`}</Typography>
                            {index !== 0 &&
                            <Tooltip title="Delete" >
                              <IconButton aria-label='delete' onClick={() => remove(index)} color='error' ><Delete /></IconButton>
                            </Tooltip>}
                            </Box>
                          </AccordionSummary>
                        <AccordionDetails>
                          <MemberFormFields accreditationValues={accreditationValues} countryCodeValues={countryCodeValues} index={index} key={index} phoneTypeValues={phoneTypeValues} />
                        </AccordionDetails>
                      </Accordion>
                    ))}
                    <Tooltip title="Add Member">
                      <IconButton
                        sx={{ mt: 2 }}
                        color='primary'
                        aria-label='add member'
                        onClick={() => push(initialMemberValue)}
                      >
                        <AddCircle fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </FieldArray>
            </DialogContent>
            <DialogActions>
              <LoadingButton disabled={!isValid} color='info' type='submit' loading={isLoading}>Submit</LoadingButton>
            </DialogActions>
          </Form>
          )}
        </Formik>}
    </>
  )
}
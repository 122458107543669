import { useFormikContext } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import { VerboseDistributionInstruction } from "../../../../../api/payloads/customer.payload";
import { useClients } from "../../../../../hooks";
import { useSubDocs } from "../../../../../hooks/ContextHooks/useSubDocs";
import { DistributionInstructionType } from "../../../../../utility/constants";
import { DistributionInformationFormValues } from "../subDocsFormSchema";
import { SubDocsReviewInfoCard } from "../SubDocsReview";

export const DistributionInformationReview: FC = () => {
  const {distributionInstructionsData, custodians} = useSubDocs();
  const {addressStates, } = useClients();
  const { values } = useFormikContext<DistributionInformationFormValues>();
  const [selectedDistributionInstruction, setSelectedDistributionInstruction] = useState<VerboseDistributionInstruction | null>(null)

  useEffect(() => {
    const currentDistributionInstruction = distributionInstructionsData?.distributionInstructions?.find(instruction => instruction.id === values.distributionInstructionId)
    setSelectedDistributionInstruction(currentDistributionInstruction ?? null);
  }, [values, distributionInstructionsData])

  const getStateName = useCallback((stateId: number | "") => {
    const currentState = addressStates?.find(state => state.id ===  stateId) ?? null;
    return currentState?.name ?? ""
  },[addressStates,])

  const getAccountTypeName = useCallback((accountTypeId: number | "") => {
    const currentAccountType = distributionInstructionsData?.distributionInstructionAccountTypes?.find(type=> type.id ===  accountTypeId) ?? null;
    return currentAccountType?.name ?? ""
  },[distributionInstructionsData,])

  const renderAppropriateFields = () => {
    switch(selectedDistributionInstruction?.instruction_type_id) {
      case DistributionInstructionType.Wire:
        return (
          <>
            <SubDocsReviewInfoCard span={4} title="Name on Bank Account" value={values.wireInstructions.bankAccountName ?? ""} />
            <SubDocsReviewInfoCard span={4} title="Bank's Routing Number" value={values.wireInstructions.aba ?? ""} />
            <SubDocsReviewInfoCard span={4} title="Bank Account Number" value={values.wireInstructions.accountNumber ?? ""} />
            <SubDocsReviewInfoCard span={4} title="Financial Institution Name" value={values.wireInstructions.financialInstitutionName ?? ""} />
            <SubDocsReviewInfoCard span={4} title="Financial Institution Street Address" value={values.wireInstructions.financialStreetAddress1 ?? ""} />
            <SubDocsReviewInfoCard span={4} title="Financial Institution Street Address 2" value={values.wireInstructions.financialStreetAddress2 ?? ""} />
            <SubDocsReviewInfoCard span={4} title="Financial Institution City" value={values.wireInstructions.financialCity ?? ""} />
            <SubDocsReviewInfoCard span={4} title="Financial Institution State" value={getStateName(values.wireInstructions.financialStateId ?? "")} />
            <SubDocsReviewInfoCard span={4} title="Financial Institution Zip Code" value={values.wireInstructions.financialZip ?? ""} />
            <SubDocsReviewInfoCard span={4} title="Reference (For Further Credit)" value={values.wireInstructions.ffc ?? ""} />
            <SubDocsReviewInfoCard span={4} title="FFC to Account number" value={values.wireInstructions.ffcAccountNumber.toString() ?? ""} />
            <SubDocsReviewInfoCard span={4} title="Verification Document" value={values.wireInstructions.verificationDocument?.documentName ?? ""} />
          </>
        )
        case DistributionInstructionType.Check:
          return (
            <>
              <SubDocsReviewInfoCard span={4} title="Payee Name" value={values.checkInstructions.payeeName ?? ""} />
              <SubDocsReviewInfoCard span={4} title="Street Address" value={values.checkInstructions.streetAddress1 ?? ""} />
              <SubDocsReviewInfoCard span={4} title="Street Address 2" value={values.checkInstructions.streetAddress2 ?? ""} />
              <SubDocsReviewInfoCard span={4} title="City" value={values.checkInstructions.city ?? ""} />
              <SubDocsReviewInfoCard span={4} title="State" value={getStateName(values.checkInstructions.stateId)} />
              <SubDocsReviewInfoCard span={4} title="Zip Code" value={values.checkInstructions.city ?? ""} />
            </>
          )
          case DistributionInstructionType.ACH:
            return (
              <>
                <SubDocsReviewInfoCard span={4} title="Name on Bank Account" value={values.achInstructions.bankAccountName ?? ""} />
                <SubDocsReviewInfoCard span={4} title="Bank's Routing Number" value={values.achInstructions.aba ?? ""} />
                <SubDocsReviewInfoCard span={4} title="Bank Account Number" value={values.achInstructions.accountNumber ?? ""} />
                <SubDocsReviewInfoCard span={4} title="Account Type" value={getAccountTypeName(values.achInstructions.accountType)} />
                <SubDocsReviewInfoCard span={4} title="For Further Credit To" value={values.achInstructions.ffc.toString() ?? ""} />
                <SubDocsReviewInfoCard span={4} title="Voided Check" value={values.achInstructions.voidedCheck?.documentName ?? ""} />
              </>
            )
          case DistributionInstructionType.Brokerage:
            return (
              <>
                <SubDocsReviewInfoCard span={4} title="Custodian" value={values.brokerageInstructions.custodianId ? custodians.find(custodian => custodian.id === values.brokerageInstructions.custodianId)?.name ?? "" : ""} />
                <SubDocsReviewInfoCard span={4} title="Name on Bank Account" value={values.brokerageInstructions.bankAccountName ?? ""} />
                <SubDocsReviewInfoCard span={4} title="Bank's Routing Number" value={values.brokerageInstructions.aba ?? ""} />
                <SubDocsReviewInfoCard span={4} title="Bank Account Number" value={values.brokerageInstructions.accountNumber ?? ""} />
                <SubDocsReviewInfoCard span={4} title="Financial Institution Name" value={values.brokerageInstructions.financialInstitutionName ?? ""} />
                <SubDocsReviewInfoCard span={4} title="Financial Institution City" value={values.brokerageInstructions.financialCity ?? ""} />
                <SubDocsReviewInfoCard span={4} title="Financial Institution State" value={getStateName(values.brokerageInstructions.financialStateId)} />
                <SubDocsReviewInfoCard span={4} title="Reference (For Further Credit)" value={values.brokerageInstructions.ffc ?? ""} />
                <SubDocsReviewInfoCard span={4} title="FFC to Account number" value={values.brokerageInstructions.ffcAccountNumber.toString() ?? ""} />
                <SubDocsReviewInfoCard span={4} title="Verification Document" value={values.brokerageInstructions.verificationDocument?.documentName ?? ""} />
              </>
            )
      default:
        return null
    }
  }

  return (
    <>
      <SubDocsReviewInfoCard span={4} title="Distribution Instruction Friendly Name" value={selectedDistributionInstruction?.name ?? ""} />
      <SubDocsReviewInfoCard span={8} title="Distribution Instruction Type" value={selectedDistributionInstruction?.instruction_type ?? ""} />
      {renderAppropriateFields()}
    </>
  )
}
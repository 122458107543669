import { Box, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { FC } from "react";
import { ReviewStepFormValues } from "../subDocsFormSchema";

export const AdvisorAcknowledgementReview: FC = () => {
  const { values, setFieldValue } = useFormikContext<ReviewStepFormValues>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('advisorAcknowledgement', event.target.checked)
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: 16, fontWeight: 'bold', mr: 0}}>By submitting this Subscription Agreement to the Investor for signature, I acknowledge I have:
        </Typography>      
      </Grid>
      <ul>
        <li>
          discussed such Investor’s prospective purchase of Shares of the BDC with such Investor;
        </li>
        <li>
        advised such Investor of all pertinent facts with regard to the lack of liquidity and marketability of the Shares of the BDC, and apprised the Investor of the risks of an investment in the Shares of the BDC as described in the BDC’s confidential private placement memorandum (the “<u>Memorandum</u>”);
        </li>
        <li>
        delivered the Memorandum and related supplements, if any, to such Investor;
        </li>
        <li>
        reasonable grounds to believe that the Investor is purchasing the Shares of the BDC for his or her own account and that the purchase of such Shares is a suitable investment for such Investor and that such Investor is in a financial position to enable such Investor to realize the benefits of such an investment and to suffer any loss that may occur with respect thereto; and complied with and has followed all applicable policies and procedures under his or her firm’s existing Anti-Money Laundering Program and Customer Identification Program, including OFAC compliance.
        </li>
      </ul>
      <Box sx={{display: 'flex', p: 2, alignItems: 'flex-start'}}>
      <FormControlLabel
        control={
          <Checkbox sx={{py:1, mr:1}} checked={values.advisorAcknowledgement ?? false} onChange={handleChange} name={'advisorAcknowledgment'} />
        }
        label="I have read and abided by the Advisor Acknowledgment stated above."
      />
    </Box>
    </>
  )
}
import { useFormikContext } from "formik";
import { FC } from "react";
import { FundingStatusStrings, InvestorDescriptionStrings } from "../../../../../utility/constants";
import { PurchaseFundingInstructionFormValues } from "../subDocsFormSchema";
import { SubDocsReviewInfoCard } from "../SubDocsReview";

export const PurchaseFundingInstructionReview: FC = () => {
  const { values } = useFormikContext<PurchaseFundingInstructionFormValues>();

  return (
    <>
      <SubDocsReviewInfoCard span={4} title="Funding Status" value={values.isFunded === 1 ? FundingStatusStrings.alreadyFunded : FundingStatusStrings.toBeFunded} />
      {values.isFunded === 0 && values.fundingDate && <SubDocsReviewInfoCard span={4} title="Anticipated Funding Date" value={values.fundingDate.format('MMMM Do, YYYY') ?? ""} />}
      <SubDocsReviewInfoCard span={4} title="Funding Type" value={values.fundingType ?? ""} />
      <SubDocsReviewInfoCard span={12} title="Investor Description" value={values.isOfficer ? InvestorDescriptionStrings.isAnOfficer : InvestorDescriptionStrings.notAnOfficer} />
      {values.isOfficer === +true && <SubDocsReviewInfoCard span={4} title="Nature of Relationship" value={values.investorNatureOfRelationship ?? ""} />}
      {values.isOfficer === +true && <SubDocsReviewInfoCard span={4} title="Percent Owned" value={values.investorPercentOwned ? `${values.investorPercentOwned}%`: ""} />}
    </>
  )
}
import { Box, MenuItem, Pagination, PaginationProps, Select, styled, Typography } from "@mui/material";
import { FC} from "react";

type TablePaginatorProps = PaginationProps & {
  currentRowsPerPage: number,
  setCurrentRowsPerPage: any,
  rowsPerPageOptions?: number[],
}

const defaultRowsPerPageOptions = [5, 10, 25, 50, 100];

const PageSelectWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '0',
  '.MuiInputBase-root': {
    backgroundColor: theme.palette.background.paper
  }
}))

export const TablePaginator: FC<TablePaginatorProps> = ({
  rowsPerPageOptions,
  currentRowsPerPage,
  setCurrentRowsPerPage,
  ...rest
}) => { 
  
  const pageOptions = rowsPerPageOptions ?? defaultRowsPerPageOptions;

  return (
    <Box display="flex" justifyContent="center" alignItems="center" position="relative">
      <Pagination {...rest} />
      <PageSelectWrapper>
        <Typography component="span" mr={1}>No of Rows</Typography>
        <Select value={currentRowsPerPage} onChange={(event) => setCurrentRowsPerPage(+(event.target.value as string))} size="small">
          <MenuItem value={-1}>All</MenuItem>
          {pageOptions.map(p => (
            <MenuItem key={p} value={p}>{p}</MenuItem>
          ))}
        </Select>
      </PageSelectWrapper>
    </Box>
  )
}
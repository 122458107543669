import InfoIcon from '@mui/icons-material/Info';
import { Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSubDocs } from '../../../../../hooks/ContextHooks/useSubDocs';
import { SubDocsFormValues } from '../subDocsFormSchema';

type AnswerCardProps = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  checked: boolean,
  id: number,
  label: string,
  notes: string | null;
}

const AnswersCard = ({handleChange, checked, id, label, notes }: AnswerCardProps) => {
  return (
    <Box sx={{display: 'flex',flexDirection: 'row', my: ".2rem", p: 2, justifyContent: 'space-between', backgroundColor: 'background.paper'}}>
      <FormControlLabel
        control={
          <Checkbox sx={{py:1, mr:1}} checked={checked} onChange={handleChange} name={id.toString()} />
        }
        label={<Typography component='div'>{label}{notes && 
          <Box sx={{position: 'relative', display: 'inline-block'}}>
            <Tooltip 
            componentsProps={{tooltip: {sx: {p: 2, maxWidth: 400}}}}
            placement='right' title={<Typography component='div' sx={{fontSize: 16}}><div dangerouslySetInnerHTML={{ __html: notes }} /></Typography>}>
              <InfoIcon sx={{position: 'absolute', bottom: -7, left: 5, color: "secondary.main"}}/>
            </Tooltip>
           </Box>
          }</Typography>}
      />
    </Box>
  )
}


export default function SubDocsCheckBoxGroup() {
  const { accreditedInvestorQuestions } = useSubDocs();
  const { setFieldValue, values } = useFormikContext<SubDocsFormValues>();
  const [answerState, setAnswerState] = useState<Set<number>>(new Set(values.accreditedAnswers));

  useEffect(() => {
    setFieldValue('accreditedAnswers', Array.from(answerState))
  },[answerState, setFieldValue])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempSet = new Set(answerState)
    if (event.target.checked) {
      tempSet.add(Number(event.target.name))
      setAnswerState(tempSet)
    } else {
      tempSet.delete(Number(event.target.name))
      setAnswerState(tempSet)
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography component='div' sx={{ mb: 4 }}>
        In order to invest in this fund you have to be an Accredited investor. 
        An “accredited investor” is any person who comes within any of the following 
        categories, or who the Investment Adviser reasonably believes comes within any of 
        the following categories, at the time of the sale of the Shares to that person.  
        Please indicate which of the categories are applicable to you by checking the boxes 
        beside the applicable item or items (you may qualify under more than one category).
      </Typography>
      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          {accreditedInvestorQuestions.map(question => {
            return <AnswersCard key={question.id} label={question.question} id={question.envelope_tab_index} checked={answerState.has(question.envelope_tab_index) ?? false} handleChange={handleChange} notes={question.notes}/>
          })}
        </FormGroup>
        <FormHelperText>Must pick at least one</FormHelperText>
      </FormControl>
    </Box>
  );
}
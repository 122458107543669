import { Box, Grid, MenuItem, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { Select, TextField } from 'formik-mui';
import { useEffect } from 'react';
import { useClients, useUI } from '../../../../hooks';
import { useSubDocs } from '../../../../hooks/ContextHooks/useSubDocs';
import { DistributionInstructionType, DocumentTypes } from '../../../../utility/constants';
import { DownloadableDocumentField } from './CustomFields/DownloadableDocumentField';
import { SubDocsFormValues } from './subDocsFormSchema';
import { InfoCard } from './InvestorQuestionnaire';

type ParamProps = {
  fundingMethod: string;
  instructionFields: InstructionField[];
}

type InstructionField = {
  label: string;
  value: string | undefined;
};

export default function DistributionInformationFields() {
  const {distributionInstructionsData} = useSubDocs();
  const {setError} = useUI();
  const {custodians} = useSubDocs();
  const {addressStates} = useClients();
  const {values} = useFormikContext<SubDocsFormValues>();

  if (distributionInstructionsData == null) {
    setError("Oops, something went wrong. Please try again later")
    return null
  }

  if (values.distributionInstructionType === DistributionInstructionType.ACH) {
    return (
      <Box sx={{mt: 4, mb:2}}>
        <Box>
          <Field 
            disabled 
            name="achInstructions.instructionName" 
            required 
            label="Distribution Instruction Friendly Name" 
            component={TextField} 

            sx={{width: 'calc(50% - 8px)', mr: 2}}
          />
          <Field 
            disabled 
            name="achInstructions.bankAccountName" 
            required 

            label="Name on Bank Account" 
            component={TextField} 
            sx={{width: 'calc(50% - 8px)'}}
          />
        </Box>
        <Box>
          <Field disabled 
          name="achInstructions.aba"
          required
          label="Bank's Routing Number"
          component={TextField}
          sx={{width: 'calc(50% - 8px)', mr: 2}}
          />
          <Field 
          disabled
          name="achInstructions.accountNumber"
          required
          label="Bank Account Number"
          component={TextField}
          sx={{width: 'calc(50% - 8px)'}}/>
        </Box>
        <Box>
        <Field 
          disabled 
          name="achInstructions.accountType" 
          label="Account Type" 
          component={Select} 
          formControl={{required: true, sx:{width: 'calc(50% - 8px)', mr: 2}}}
          onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
        >
            {distributionInstructionsData.distributionInstructionAccountTypes.map(accountType => (
              <MenuItem key={accountType.id} value={accountType.id}>{accountType.name}</MenuItem>
            ))}
        </Field>
        <Field 
          disabled
          name="achInstructions.ffc"
          required
          label="For Further Credit To" 
          component={TextField}
          sx={{width: 'calc(50% - 8px)'}}
        />
        {values.achInstructions.voidedCheck && <DownloadableDocumentField disableDelete={true} entityDocumentType={DocumentTypes.VoidedCheck} name="achInstructions.voidedCheck" label="Voided Check" />}
        </Box>
      </Box>
    );
  } else if (values.distributionInstructionType === DistributionInstructionType.Check) {
    return (<Box sx={{mt: 4, mb:2}}>
      <Box>
        <Field disabled name="checkInstructions.instructionName" required label="Distribution Instruction Friendly Name" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
        <Field disabled name="checkInstructions.payeeName" required label="Payee Name" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
      </Box>
      <Box>
        <Field disabled name="checkInstructions.streetAddress1" required label="Street Address" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
        <Field disabled name="checkInstructions.streetAddress2" label="Street Address 2" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Field disabled name="checkInstructions.city" required label="City" component={TextField} sx={{width: 'calc(42% - 8px)', mr: 2}} />
        <Field 
          disabled 
          name="checkInstructions.stateId" 
          label="State" 
          component={Select} 
          formControl={{required: true}}
          sx={{ width: '200px',}}
          onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
          >
            {addressStates != null && addressStates.map(state => (
              <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
            ))}
        </Field>
        <Field disabled name="checkInstructions.zip" label="ZIP Code" required component={TextField} sx={{ml: 2,width: 'calc(42% - 8px)'}}/>
        </Box>
    </Box>)
  } else if (values.distributionInstructionType === DistributionInstructionType.Wire) {
    return (
      <Box sx={{mt: 4, mb:2}}>
        <Box>
          <Field disabled name="wireInstructions.instructionName" required label="Distribution Instruction Friendly Name" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
          <Field disabled name="wireInstructions.bankAccountName" required label="Name on Bank Account" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
        </Box>
        <Box>
          <Field disabled name="wireInstructions.aba" required label="Bank's Routing Number" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
          <Field disabled name="wireInstructions.accountNumber" required label="Bank Account Number" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
        </Box>
        <Box>
          <Field disabled name="wireInstructions.financialInstitutionName" required label="Financial Institution Name" component={TextField} fullWidth/>
        </Box>
        <Box>
          <Field disabled name="wireInstructions.financialStreetAddress1" required label="Financial Institution Address" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
          <Field disabled name="wireInstructions.financialStreetAddress2" label="Street Address 2" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Field disabled name="wireInstructions.financialCity" required label="City" component={TextField} sx={{width: 'calc(42% - 8px)', mr: 2}} />
          <Field disabled 
            name="wireInstructions.financialStateId" 
            label="State" 
            component={Select} 
            formControl={{required: true}}
            sx={{ width: '200px',}}
            onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
            >
              {addressStates != null && addressStates.map(state => (
                <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
              ))}
          </Field>
          <Field disabled name="wireInstructions.financialZip" label="ZIP Code" required component={TextField} sx={{ml: 2,width: 'calc(42% - 8px)'}}/>
        </Box>
          <Box>
            <Field disabled name="wireInstructions.ffc" label="Reference (For Further Credit)" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
            <Field disabled name="wireInstructions.ffcAccountNumber" label="FFC to Account Number" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
            {values.wireInstructions.verificationDocument && <DownloadableDocumentField disableDelete={true} entityDocumentType={DocumentTypes.WireVerificationDocument} name="wireInstructions.verificationDocument" label="Verification Document" />}
          </Box>
      </Box>
    );
  } else if (values.distributionInstructionType === DistributionInstructionType.Brokerage)  {
    return (
      <Box sx={{mt: 4, mb:2}}>
      <Box>
        <Field disabled name="brokerageInstructions.instructionName" required label="Distribution Instruction Friendly Name" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
        <Field 
          disabled
          name="brokerageInstructions.custodianId" 
          label="Custodian" 
          value={values.brokerageInstructions.custodianId}
          renderValue={(value: number | null) => {
            const custodian = custodians.find(custodian => custodian.id === value)
            return custodian?.name ?? ""
          }}
          component={Select} 
          formControl={{required: true, sx:{width: 'calc(50% - 8px)'}}}
          // onChange={handleCustodianSelection}
          onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
          >
            {custodians.map(custodian => (
              <MenuItem key={custodian.id} value={custodian.id}>{custodian.name}</MenuItem>
            ))}
        </Field>
      </Box>
      <Box>
        <Field disabled name="brokerageInstructions.bankAccountName" required label="Name on Bank Account" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
        <Field disabled name="brokerageInstructions.aba" required label="Bank's Routing Number" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
      </Box>
      <Box>
        <Field disabled name="brokerageInstructions.accountNumber" required label="Bank Account Number" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Field disabled name="brokerageInstructions.financialInstitutionName" required label="Financial Institution Name" component={TextField} sx={{width: 'calc(42% - 8px)', mr: 2}} />
        <Field disabled name="brokerageInstructions.financialCity" required label="City" component={TextField} sx={{width: 'calc(42% - 8px)', mr: 2}} />
        <Field 
          disabled
          name="brokerageInstructions.financialStateId" 
          label="State" 
          component={Select} 
          formControl={{required: true}}
          sx={{ width: '200px',}}
          onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
          >
            {addressStates != null && addressStates.map(state => (
              <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
            ))}
        </Field>
      </Box>
        <Box>
          <Field disabled name="brokerageInstructions.ffc" label="Reference (For Further Credit)" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
          <Field disabled name="brokerageInstructions.ffcAccountNumber" label="FFC to Account Number" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
          {values.brokerageInstructions.verificationDocument && <DownloadableDocumentField disableDelete={true} entityDocumentType={DocumentTypes.WireVerificationDocument} name="brokerageInstructions.verificationDocument" label="Verification Document" />}
        </Box>
    </Box>
    );
  } else {
    return null
  }
}

export const CustodialAccountInformation: React.FC<ParamProps> = ({ fundingMethod, instructionFields }) => {
  return (
    <Grid item xs={12}>
      <Typography sx={{ mb: 2 }} fontWeight="bold">
        For custodial-held accounts, cash distributions will be sent to the custodian.
      </Typography>
      <Box sx={{ display: 'flex', p: 2 }}>
        <Box sx={{ display: 'flex', width: 1, minWidth: 500 }}>
          <Grid container sx={{ flex: 1 }}>
            {instructionFields.map((field, index) => (
              <InfoCard key={index} title={field.label} value={field.value} />
            ))}
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { FC, MouseEvent, useCallback, useMemo, useState } from "react";
import { createObjectMap, sumElements } from "../../utility/array.util";
import EmptyState from "../EmptyState/EmptyState";
import { DataRow } from "./DataRows/DataRow";
import { ExpandableRow } from "./DataRows/ExpandableRow";
import { DataSourceTableProps } from "./DataSourceTable.types";

type Order = 'asc' | 'desc';

export const DataSourceTable: FC<DataSourceTableProps> = (props) => {
  const { columnDef, dataSource, emptyMessage, showTotals, initialOrderBy, tableBodyCellSize , rowState, onRowStateChange } = props;
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string | number | symbol | undefined>(initialOrderBy);
  const columnDefMap = useMemo(
    () => createObjectMap(columnDef, 'id'),
    [columnDef]
  );
  
  const descendingComparator = useCallback((a: any, b: any) => {
    const column = columnDefMap[orderBy as string];

    const getValue = (row: any) => column.sortValueFn ? column.sortValueFn(row[orderBy!], row) : row[orderBy!];

    if (getValue(a) > getValue(b)) {
      return -1;
    }
    if (getValue(a) < getValue(b)) {
      return 1;
    }
    return 0;
  }, [orderBy, columnDefMap])

  const sortedData = useMemo(() => {
    if (!orderBy || !columnDefMap[orderBy as string]) {
      return dataSource;
    }

    const isDescending = order === 'desc';
    
    return dataSource.sort(isDescending
      ? (a, b) => descendingComparator(a, b)
      : (a, b) => -descendingComparator(a, b)
    );
  }, [dataSource, order, orderBy, descendingComparator, columnDefMap])

  const handleRequestSort = (event: MouseEvent<unknown>, property: keyof typeof dataSource[number]) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const totalsRow = useMemo(() => {
    if (!showTotals) { return null };

    const [_first, ...rest] = columnDef;

    const totalsCells = rest.map(column => {
      const { totalFn, showTotal, id } = column;
      const total = showTotal ? totalFn ? totalFn(dataSource) : sumElements(dataSource, id) : "";
      const displayFn = column.totalDisplayFn ?? column.displayFn;
      const displayedTotal = displayFn && showTotal ? displayFn(total, dataSource) : total;
      return <TableCell className={`col-total-${column.id as any}`} component="th" key={id as string | number} sx={{ py: 1 }}>{displayedTotal}</TableCell>
    })

    return (
      <TableHead>
        <TableRow>
          <TableCell className="col-total-label" sx={{ py: 1 }}><Box component="span" fontWeight="bold">Total</Box></TableCell>
          {totalsCells}
        </TableRow>
      </TableHead>
    )
  }, [dataSource, showTotals, columnDef]);

  return (
      <>
        {dataSource.length > 0 ? 
        <Table>
          <TableHead>
            <TableRow>
                {columnDef.map(column => (
                  <TableCell className={`col-header-${column.id as any}`} component="th" key={column.id as any} sx={{ py: 1}}>
                      { column.sortable 
                        ? <Button variant='text' 
                            color='inherit'
                            aria-label={`sort by ${column.label}`} 
                            onClick={e => handleRequestSort(e, column.id)}
                            sx={(theme) =>({
                              '&:hover': {
                                '.MuiTableSortLabel-root:not(.Mui-active)': {
                                  color: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255,255,255,0.7)',
                                  '.MuiTableSortLabel-icon': { opacity: 0.5}
                              }
                            }})}
                          >
                            {column.label}
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : 'asc'}
                              tabIndex={-1}
                            />
                          </Button>
                        : column.label
                      }
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
            <TableBody>
              {sortedData.map(data => (
                !data.children
                  ? <DataRow key={data.id} {...{ data, columnDef }} cellSize={tableBodyCellSize} rowState={rowState?.[data.id]} onRowStateChange={(newState) => onRowStateChange?.(data.id, newState)} />
                  : <ExpandableRow key={data.id} dataSource={data.children} columnDef={columnDef} name={data.name!} secondary={data.secondary} />
              ))}
          </TableBody>
          {showTotals && totalsRow}
        </Table>
      : 
        <EmptyState message={emptyMessage}/>
      }
    </>
  )
}
import AddIcon from "@mui/icons-material/Add";
import BlockIcon from "@mui/icons-material/Block";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import lightBlue from "@mui/material/colors/lightBlue";
import { FC, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { apiClient } from "../../../../api/apiClient";
import { DistributionInstruction } from "../../../../api/payloads/customer.payload";
import { GetDistributionInstructionEnvelopeStatusResp } from "../../../../api/payloads/docusign.payload";
import { ConfirmationDialog } from "../../../../components/ConfirmationDialog/ConfirmationDialog";
import { InstructionDetailsModal } from "../../../../components/CustomModal/DistributionInstructionDetailModal";
import { ColumnDef, DataSourceTable } from "../../../../components/DataSourceTable";
import { useClientDetails, useUI } from "../../../../hooks";
import { groupBy } from "../../../../utility/array.util";
import { ApplicationSource, DistributionInstructionDocusignStatus } from "../../../../utility/constants";
import { isNullish } from "../../../../utility/misc.util";
import { ClientInstructionsStepper } from "../../NewClientPage/Forms/NewInstructions/ClientInstructionsStepper";
import { UnlinkedAccountsWidget } from "../components/UnlinkedAccountsWidget";
import { UnlinkedInvestmentsWidget } from "../components/UnlinkedInvestmentsWidget";
import { InstructionsTable } from "../InstructionsTable";
import moment from "moment";
import { RecipientStatus } from "../../../Opportunities/OpportunityDetailPage.tsx/InterestedList";

type StatusDialogProps = {
  open: boolean;
  onClose: () => void;
};

const unlinkedInstructionsColumnDef: ColumnDef<{ account_name: string, investment_company_name?: string[]}> = [
  { id: "account_name", label: "Accounts" },
  { id: "investment_company_name", label: "Investments",
    displayFn: (investments?: string[]) =>
      investments && investments.length > 0 
        ? investments.map((investment, i) => (
            <Box key={i} component="div">
              {investment}
            </Box>
          ))
        : 'No Investments on File'
  },
];

export const ClientInstructions: FC = () => {
  const { activeClient, setActiveClient, unlinkedInvestments } = useClientDetails();
  const [open, setOpen] = useState<{ [key: number]: boolean }>({});
  const [showStepperForm, setShowStepperForm] = useState(false);
  const [selectedInstructionId, setSelectedInstructionId] = useState<number | null>(null);
  const [openInstructionDetailModal, setOpenInstructionDetailModal] = useState(false);
  const setAddInstructionOpen = () => {setShowStepperForm(true)}
  const [envelopeStatusData, setEnvelopeStatusData] = useState<GetDistributionInstructionEnvelopeStatusResp | null>(null);
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const [showVoidConfirmation, setShowVoidConfirmation] = useState(false);
  const location = useLocation();
  const {setError, setSuccess} = useUI()

  const EnvelopeStatus = async (instructionId: number) => {
    try {
      const response = await apiClient.get(`/docusign/distribution-instruction/:p0/envelope-status`, { routeParams: [instructionId] });
      if (response && response.envelopeStatus) {
        return response;
      } else {
        setError("Invalid data format received");
        setShowStatusDialog(false);
        return null;
      }
    } catch (error) {
      console.error('Error fetching envelope status:', error);
      setError(error instanceof Error ? error.message : "An error occurred while fetching the envelope status.");
      return null;
    }
  };

  useEffect(() => {
    if (!isNullish(location.state?.showNewInstructionForm)) {
      setShowStepperForm(location.state.showNewInstructionForm);
    }
  }, [location.state])

  const handleClick = async (instructionId: number) => {
    setSelectedInstructionId(instructionId);
    const response = await EnvelopeStatus(instructionId);
    if (response && !response.envelopeStatus.error) {
      setEnvelopeStatusData(response); 
      setShowStatusDialog(true);
    } else {
      setError('No data available for this distribution instruction.');
      setShowStatusDialog(false);
    }
  };

  const handleOpenInstructionDetailModalModal = (id: number) => {
    setSelectedInstructionId(id);
    setOpenInstructionDetailModal(true);
  };  

  const handleCloseInstructionDetailModal = () => {
    setOpenInstructionDetailModal(false);
    setShowStatusDialog(false);
    setSelectedInstructionId(null);
  };
  
  const handleToggle = (id: number) => {
    setOpen(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const handleCancel = () => {
    setShowStepperForm(false);
  };

  const handleOpenVoidConfirmation = (instructionId: number) => {
    setSelectedInstructionId(instructionId);
    const instruction = activeClient?.pendingDistributionInstructions.find(inst => inst.id === instructionId);
    if (instruction) {
      setShowVoidConfirmation(true);
    }
  };

  const handleVoid = async (confirmed?: boolean) => {
    if (confirmed === true && selectedInstructionId != null && activeClient) {
      try {
        const voidReason: string = "Voided by Advisor";
        await apiClient.patch(`/docusign/distribution-instruction/:p0/void`, {
          routeParams: [selectedInstructionId],
          data: { voidReason },
        });
        setShowVoidConfirmation(false);
        const updatedList = activeClient.pendingDistributionInstructions.filter(instruction => instruction.id !== selectedInstructionId);
        setActiveClient({
          ...activeClient,
          pendingDistributionInstructions: updatedList,
        });
        setSuccess("Envelope voided successfully");
      } catch (error) {
        console.error('Error voiding the envelope:', error);
        setError(error instanceof Error ? error.message : "An error occurred while voiding the envelope.");
      }
    } else {
      setShowVoidConfirmation(false);
    }
  };

  const renderVoidButton = (isDisabled: boolean, color: string, onClick: () => void): JSX.Element => (
    <Button variant="outlined" size="small" startIcon={<BlockIcon />} onClick={onClick} disabled={isDisabled}
      sx={{ color: color, textTransform: "none", backgroundColor: grey[200],}}
      >
      Void
    </Button>
  );

  const actionsDisplayFn = (row: DistributionInstruction) => {
    const isDisabled: boolean = row?.envelope_id === null || row?.status === DistributionInstructionDocusignStatus.Signed;
    const buttonColor: string = isDisabled ? grey[400] : grey[600];
    const handleClick = () => handleOpenVoidConfirmation(row.id);
  
    return renderVoidButton(isDisabled, buttonColor, isDisabled ? () => {} : handleClick);
  };

  const pendingApprovalInstructionsColumnDef: ColumnDef<DistributionInstruction & { actions: never; status: never }> = [
      { id: "name", label: "Distribution Instruction Name", displayFn: (v, row) => (<Link onClick={() => handleOpenInstructionDetailModalModal(row.id)} sx={{textTransform: "none", color: lightBlue[700], cursor: "pointer"}}>{v}</Link>)},
      { id: "status", label: "Status", displayFn: (v, row) => displayStatus(row)},
      { id: "actions",label: "Action", displayFn: (_, row) => actionsDisplayFn(row)},
    ];

  const DocusignStatusChips = {
    AwaitingSignature: (onClick: () => void) => (
      <Chip color="attention" clickable={true} size="small" label="Awaiting Signature" variant="filled" onClick={onClick}/>
    ),
    Signed: (
      <Chip color="info" clickable={false} size="small" label="Signed" variant="outlined"/>
    ),
  };

  const displayStatus = (row: DistributionInstruction) => {
    const { id, status } = row;

    switch (status) {
      case DistributionInstructionDocusignStatus.AwaitingSignature:
        return (
          <>
            {DocusignStatusChips.AwaitingSignature(() => handleClick(id))}
          </>
        );
      case DistributionInstructionDocusignStatus.Signed:
        return DocusignStatusChips.Signed;
      default:
        break;
    }
  };

  const unlinkedInvestmentsByAccount = groupBy(unlinkedInvestments, investment => investment.account_id);

  const initialDataSource = Object.entries(unlinkedInvestmentsByAccount).map(([account_id, investments]) => {
    const investmentCompanies = investments.map(i => i.investment_name || "Unknown Investment");

    return {
      account_id: parseInt(account_id),
      account_name: investments[0].account_name || "Unknown Account",
      investment_company_name: investmentCompanies
    };
  });

  const unlinkedAccounts = activeClient?.accounts.filter(a => a.distribution_instructions_count === 0);

  const unlinkedInstructionsDataSource = [...initialDataSource];
  for (const account of unlinkedAccounts ?? []) {
    if (!unlinkedInstructionsDataSource.some(item => item.account_id === account.id)) {
      unlinkedInstructionsDataSource.push({
        account_id: account.id,
        account_name: account.account_name,
        investment_company_name: []
      });
    }
  }

  const UnlinkedAccountsAndInvestments = () => {
    return (
      <>
        <TableContainer component={Paper} sx={{ mb: 4, "& .col-header-account_name": { width: "75%" } }}>
          <DataSourceTable
            columnDef={unlinkedInstructionsColumnDef}
            dataSource={unlinkedInstructionsDataSource}
          />
        </TableContainer>
      </>
    )
  }

  const instructionsDataSource: DistributionInstruction[] = useMemo(() => {
    if (!activeClient) return [];

    return activeClient.distributionInstructions.filter(instruction =>
      !instruction.is_ira_instruction
    )
  }, [activeClient]);

  const pendingInstructionsDataSource = useMemo(
    () => (activeClient?.pendingDistributionInstructions ?? [] as DistributionInstruction[])
      .filter(instruction => instruction.application_source_id === ApplicationSource.AdvisorVision),
    [activeClient]
  );

  const hasPendingInstructions = pendingInstructionsDataSource.length > 0;

  const selectedInstruction = instructionsDataSource.find((inst: { id: number | null }) => inst.id === selectedInstructionId);
  const selectedPendingInstruction = pendingInstructionsDataSource.find((inst: { id: number | null }) => inst.id === selectedInstructionId);

  const PendingApproval = () => {
    return (
      <>
        <Typography variant="h5" sx={{ mb: 2 }}>Instructions Pending Approval</Typography>
          <TableContainer component={Paper}
            sx={{
                mb: 4,
                "& .col-header-name": { width: "50%" },
                "& .col-header-status": { width: "25%" },
                "& .col-header-actions": { width: "25%" },
              }}
            >
            <DataSourceTable
              columnDef={pendingApprovalInstructionsColumnDef}
              dataSource={pendingInstructionsDataSource}
            />
          </TableContainer>
      </>
    )
  }

  const StatusDialog = ({ open, onClose }: StatusDialogProps) => {
    const instructionStatus = envelopeStatusData && envelopeStatusData.envelopeStatus;
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Awaiting Signature Status</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sent On</TableCell>
                <TableCell>Recipients</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {instructionStatus && instructionStatus.recipients && instructionStatus.recipients.length > 0 ? (
                instructionStatus.recipients.map((recipient, index) => {
                  const sentDateTime = moment(recipient.sentDateTime).format('MM/DD/YYYY hh:mm:ss');
                  const statusDetails = recipient.status === RecipientStatus.Signed || recipient.status === RecipientStatus.Completed
                    ? `Signed, ${moment(recipient.signedDateTime).format('MM/DD/YYYY hh:mm:ss')}`
                    : 'Awaiting Signature';
                  return (
                    <TableRow key={index}>
                      <TableCell>{sentDateTime}</TableCell>
                      <TableCell>{recipient.signerName}</TableCell>
                      <TableCell>{statusDetails}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>No Data Available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box component="section">
      {showStepperForm ? (
        <ClientInstructionsStepper onCancel={handleCancel} />
      ) : (
        <>
          <Box sx={{ display: 'flex', gap: 2, mb: 4, alignItems: 'flex-end' }}>
            <UnlinkedAccountsWidget />
            <UnlinkedInvestmentsWidget />
            <Box sx={{ flexGrow: 1 }}></Box>
            <Button id="distributionInstructionButton"
              color="info"
              onClick={setAddInstructionOpen}
              startIcon={<AddIcon />}
              sx={{ height: '45px', alignSelf: 'flex-end' }}
            >
              Add New Instruction
            </Button>
          </Box>
          {hasPendingInstructions && <PendingApproval />}
          <InstructionsTable
            instructionsDataSource={instructionsDataSource}
            open={open}
            handleToggle={handleToggle}
            openModal={handleOpenInstructionDetailModalModal}
          />
          {openInstructionDetailModal && (selectedInstruction || selectedPendingInstruction) && (
            <InstructionDetailsModal
              open={openInstructionDetailModal}
              onClose={handleCloseInstructionDetailModal}
              instruction={selectedInstruction || selectedPendingInstruction as DistributionInstruction}
              isCustodied={false}
            />
          )}
          <StatusDialog
            open={showStatusDialog}
            onClose={handleCloseInstructionDetailModal}
          />
          <ConfirmationDialog
            open={showVoidConfirmation}
            handleClose={handleVoid}
            titleNode="Confirm Void Action"
            message={`You are about to void the DocuSign envelope which will send all signers a ‘Void Confirmation’ for ${selectedPendingInstruction?.name}. In addition, the Distribution Information entered will be lost.`}
            yesButtonLabel="Confirm"
            noButtonLabel="Cancel"
          />
        </>
      )}
    </Box>
  );
};
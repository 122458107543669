import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Dialog,
  Paper,
  TableContainer,
  Typography,
} from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { apiClient } from "../../../../api/apiClient";
import { GetCustomerResp } from "../../../../api/payloads/customer.payload";
import {
  ColumnDef,
  DataSourceTable,
} from "../../../../components/DataSourceTable";
import { ErrorContactEmail } from "../../../../components/ErrorContactEmail/ErrorContactEmail";
import { useClientDetails, useMaskedParams, useUI } from "../../../../hooks";
import { AddEntityDialog } from "../Dialogs/AddEntityDialog";
import { AddMemberDialog } from "../Dialogs/AddMemberDialog";
import { MembersTable } from "../MembersTable";

type CustomerEntity = GetCustomerResp["entities"][number];

const entityColumnDef: ColumnDef<CustomerEntity> = [
  { id: "name", label: "Name" },
  { id: "ein", label: "EIN" },
  { id: "entity_type", label: "Type" },
  { id: "accreditation", label: "Accreditation" }
];

export const ClientMembers: FC = () => {
  const { clientId } = useMaskedParams();
  const { setLoading, setError } = useUI();
  const { activeClient, setActiveClient } = useClientDetails();
  const [showAddMemberModal, setShowAddMemberModal] = useState<boolean>(false);
  const [showAddEntityModal, setShowAddEntityModal] = useState<boolean>(false);

  const entityDataSource: CustomerEntity[] = useMemo(() => {
    return activeClient?.entities ?? [];
  }, [activeClient]);

  const refreshClientData = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await apiClient.get("/customers/:p0", {
        routeParams: [clientId!],
      });
      setActiveClient(resp);
    } catch (error) {
      setError(
        <div>
          <Typography variant="h6">Something went wrong:</Typography>
          <Typography variant="body1">
            We were unable to load your data after updating the professionals.
            Please contact <ErrorContactEmail /> for assistance.
          </Typography>
        </div>
      );
    } finally {
      setLoading(false);
    }
  }, [clientId, setActiveClient, setError, setLoading]);

  const handleAddMemberClick = () => {
    setShowAddMemberModal(true);
  };

  const handleAddEntityClick = () => {
    setShowAddEntityModal(true);
  };

  const closeAddMemberModal = async (success?: boolean) => {
    setShowAddMemberModal(false);
    if (!success) {
      return;
    }

    refreshClientData();
  };

  const closeAddEntityModal = async (success?: boolean) => {
    setShowAddEntityModal(false);
    if (!success) {
      return;
    }

    refreshClientData();
  };

  return (
    <>
      <Box component="section" mb={3}>
        <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
          <Typography variant="h5" mb={1}>
            Members
          </Typography>
          <Button
              color="info"
              onClick={handleAddMemberClick}
              startIcon={
                <Box>
                  <AddIcon sx={{ fontSize: 14 }} />
                </Box>
              }
              sx={{ py: 0 }}
            >
              Add New Member
            </Button>
        </Box>
        <TableContainer component={Paper} sx={{ mb: 1 }}>
          <MembersTable
            dataSource={activeClient?.contacts ?? []}
            emptyMessage="No members"
          />
        </TableContainer>
      </Box>
      <Box component="section" mb={1}>
        <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
          <Typography variant="h5" mb={1}>
            Entities
          </Typography>
          <Button
              color="info"
              onClick={handleAddEntityClick}
              startIcon={
                <Box>
                  <AddIcon sx={{ fontSize: 14 }} />
                </Box>
              }
              sx={{ py: 0 }}
            >
              Add New Entity
          </Button>
        </Box>
        <TableContainer component={Paper} sx={{ mb: 1 }}>
          <DataSourceTable
            columnDef={entityColumnDef}
            dataSource={entityDataSource}
            emptyMessage="No entities"
          />
        </TableContainer>
        <Dialog
          open={showAddMemberModal}
          onClose={() => closeAddMemberModal()}
          fullWidth
          maxWidth="lg"
        >
          <AddMemberDialog
            onClose={closeAddMemberModal}
            clientId={+clientId!}
          />
        </Dialog>
        <Dialog
          open={showAddEntityModal}
          onClose={() => closeAddEntityModal()}
          fullWidth
          maxWidth="lg"
        >
          <AddEntityDialog
            onClose={closeAddEntityModal}
            clientId={+clientId!}
          />
        </Dialog>
      </Box>
    </>
  );
};

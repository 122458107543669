import { isJsDate } from "./type-guards";

/** 
 * Takes a string | Date parameter and narrows type/value to a javascript Date 
 */
export function handleDateOrString(date: string | Date) {
  return isJsDate(date) ? date : new Date(date);
}

/**
 * Format a date to a string according to given INTL.DateTimeFormat options
 * See "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat"
 */
export function formatDate(date?: Date | string | null, options: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' }) {
  if (!date) {
    return "";
  }
  try {
    date = handleDateOrString(date);
    const formatter = new Intl.DateTimeFormat("en-US", options);
    return formatter.format(date);

  } catch (error) {
    console.log(error);
    return ""
  }
}

export function getDateYear(date: Date | string, options: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' }) {
  if (!date) {
    return "";
  }
  try {
    date = handleDateOrString(date);
    return date.getFullYear()
  } catch (error) {
    console.log(error);
  }
}

/**
 * Returns a given date adjusted to midnight UTC. Useful for when
 * comparing Date values persisted without time info, since these may be  
 * returned from API as an ISO-8061 string set to midnight with a variable UTC offset
 * (e.g. daylight vs standard time)
 */
export function toUtcMidnight(date: string | Date = new Date()) {
  date = handleDateOrString(date);
  date.setHours(0, 0, 0, 0);
  const utcMidnight = new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));
  return utcMidnight;
}

/**
 * Get a Date object that is some given hours in the future from a given date
 * @note Moment is probably a better solution when doing Date arithmetic,
 * due to days/weeks/months having an variable number of hours. Use with caution
 */
export function getEndDateTime(date: Date | string, hours: number) {
  date = handleDateOrString(date);
  const endDateTime = new Date(date);
  endDateTime.setHours(date.getHours() + hours);
  return endDateTime;
}
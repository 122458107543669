import { Box, InputAdornment, Link as MuiLink, Paper, TextField, TableContainer, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Distribution } from "../../../../../api/payloads";
import { Link } from "react-router-dom";
import { ColumnDef, DataSourceTable } from "../../../../../components/DataSourceTable";
import { useInvestments } from "../../../../../hooks";
import { formatDate } from "../../../../../utility/date.util";
import { toCurrency } from "../../../../../utility/number.util";
import { Search } from "@mui/icons-material";
import { maskRouteId } from "../../../../../utility/route-mask.util";

const displayCurrency = (v: number | string | null) => v !== null ? toCurrency(v) : "---";


export const InvestmentDistributions: FC = () => {
  const { distributions } = useInvestments();
  const [searchFilter, setSearchFilter] = useState("");
  const [filteredRows, setFilteredRows] = useState(distributions || []);

  const distributionsColumnDef: ColumnDef<Distribution> = [
    { id: 'title', label: "Title", displayFn: (v, r) =>  <MuiLink component={Link} to={`./${maskRouteId(r.id)}`}>{v}</MuiLink> },
    { id: "date", label: "Date", displayFn: (v: string) => formatDate(v), },
    { id: "totalDistributed", label: "Amount", displayFn: displayCurrency, showTotal: true, },
  ];

  useEffect(() => {
    const filteredRows = distributions? distributions.filter(r => r.title.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase())) : [];
    setFilteredRows(filteredRows);
  }, [searchFilter, distributions])




  return (
    <Box component="section" mb={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant="h5">Distribution List</Typography>
          <TextField
            value={searchFilter}
            onChange={(event) => setSearchFilter(event.target.value)}
            placeholder="Search Investors"
            size="small"
            helperText=""
            InputProps={{
              startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
            }} />
        </Box>
      <TableContainer component={Paper}>
        <DataSourceTable columnDef={distributionsColumnDef} dataSource={filteredRows} emptyMessage="No distributions" showTotals/>
      </TableContainer>
    </Box>
  )
}
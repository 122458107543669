import { Box } from '@mui/material';
import { useFormikContext } from 'formik';
import { DocumentFileUploadField } from './CustomFields/DocumentFileUploadField';
import { DocumentFormValues } from './newClientFormSchema';

type Props = {
  isTrustSelected: boolean
}

export default function DocumentFields({isTrustSelected}:Props) {
  const {values} = useFormikContext<DocumentFormValues>();

  return (
      <Box sx={{mt: 4, mb:2}}>
        <DocumentFileUploadField fileValue={values.formationDocuments} accept=".pdf" name="formationDocuments" label="Formation Document" sx={{width: 'calc(33% - 8px)', mr: 2}}/>
        <DocumentFileUploadField fileValue={values.governingDocuments} accept=".pdf" name="governingDocuments" label="Governing Document" sx={{width: 'calc(33% - 8px)', mr: 2}}/>
        {isTrustSelected && <DocumentFileUploadField fileValue={values.trustDocument} accept=".pdf" name="trustDocument" label="Trust Document" sx={{width: 'calc(33% - 8px)'}}/>}
      </Box>
  );
}
import { AddCircle } from "@mui/icons-material";
import { Box, IconButton, Link as MuiLink, TextField, Tooltip, Typography } from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import { FC, MouseEvent, useMemo } from "react";
import { useSubDocs } from "../../../../hooks/ContextHooks/useSubDocs";
import useBoolean from "../../../../hooks/useBoolean";
import { AccountType, DocumentTypes } from "../../../../utility/constants";
import { isNullish } from "../../../../utility/misc.util";
import { AMLDocumentsListDialog } from "./AMLDocumentsListDialog";
import { AMLIdentificationFields } from "./AMLIdentificationFields";
import { DownloadableDocumentField } from "./CustomFields/DownloadableDocumentField";
import { SupportingDocumentField } from "./CustomFields/SupportingDocumentField";
import { AMLVerificationFormValues, initialSupportingDocument } from "./subDocsFormSchema";

const SourceOfFundsField = () => {
  const { values, handleChange } = useFormikContext<AMLVerificationFormValues>();

  return (
    <Box sx={{my: 2}}>
      <Typography variant="h6">Source of Funds</Typography>
      <Typography>
        BIP Capital maintains an Anti-Money Laundering (AML) compliance program that requires us to obtain an understanding where investors have obtained the money that they are using to make an investment in the fund.
        Accordingly, please list below the source of funds for this investment. Examples of sources include: wages, capital gains, sale of real estate, sale of investments, inheritance.
      </Typography>
      <TextField
        fullWidth
        id="sourceOfFunds"
        name="sourceOfFunds"
        label="Source of Funds"
        value={values.sourceOfFunds}
        onChange={handleChange}
        margin="normal"
      />
    </Box>
  );
};

export const AMLVerification: FC = () => {
  const {subDocsAccount} = useSubDocs();
  const { values } = useFormikContext<AMLVerificationFormValues>();
  const {value: openDocumentsList, setFalse: setDocumentsListClose, setTrue: setDocumentsListOpen } = useBoolean();

  const appropriateFields = useMemo(() => {
    const handleDocumentListClose = () => {
      setDocumentsListClose();
    }
  
    const handleDocumentClick = (e: MouseEvent) => {
      e.preventDefault()
      setDocumentsListOpen();
    }
    switch(subDocsAccount?.account_type_id as AccountType) {
      case AccountType.IRA:
      case AccountType.Individual:
      case AccountType.Joint:
        return <AMLIdentificationFields />
      case AccountType.Entity:
        return (
          <Box>
            <Box sx={{mb: 2}}>
              <Typography variant="h6" sx={{mb: 1}}>Documents:</Typography>
              <MuiLink
                  component="button"
                  sx={{color: 'primary.main'}}
                  onClick={handleDocumentClick}>
                    <Typography>
                      View Supporting Documents List
                    </Typography>
              </MuiLink>
              {openDocumentsList && <AMLDocumentsListDialog open={openDocumentsList} handleClose={handleDocumentListClose} maxWidth="md" />}
              <Typography >Please review any attached documents. Add any supporting documents.</Typography>
            </Box>
              {values.formationDocuments && <DownloadableDocumentField entityDocumentType={DocumentTypes.FormationDocuments} name="formationDocuments" label="Formation Document" />}
              {values.governingDocuments && <DownloadableDocumentField entityDocumentType={DocumentTypes.GoverningDocuments} name="governingDocuments" label="Governing Document" />}
              {values.trustDocuments && <DownloadableDocumentField entityDocumentType={DocumentTypes.TrustDocuments} name="trustDocuments" label="Trust Documents" />}
              <Typography sx={{mt: 2}}>Supporting Documents</Typography>
              <FieldArray name="supportingDocuments">
                {({ form, remove, push, replace }) => (
                  <Box sx={{position: 'relative', display:'flex', flexDirection: 'column', width: '100%'}}>
                    {values.supportingDocuments.map((document,index) => (
                        <SupportingDocumentField key={`${document?.supportingDocumentFile?.name ?? ""}-${index}`} index={index} remove={remove}/>
                    ))}
                    {(values.supportingDocuments.length === 0 || (values.supportingDocuments.length > 0 && (!isNullish( values.supportingDocuments[values.supportingDocuments.length - 1]?.supportingDocumentFile))) || !isNullish(values.supportingDocuments[values.supportingDocuments.length - 1]?.documentId)) && <Box>
                      <IconButton
                        color='primary'
                        onClick={() => push(initialSupportingDocument)}
                        sx={{mt: 2}}
                      >
                        <Tooltip title="Add Supporting Document">
                          <AddCircle fontSize="large" />
                        </Tooltip>
                      </IconButton>
                    </Box>}
                  </Box>
                )}
              </FieldArray>
              <AMLIdentificationFields />
          </Box>)
      default:
        return null;
    }
  },[setDocumentsListOpen, setDocumentsListClose,openDocumentsList,subDocsAccount, values])

  return (
    <Box sx={{ display: 'flex',flexDirection: 'column', py: 2,}}>
      <Box>
        {appropriateFields}
      </Box>
      <SourceOfFundsField />
    </Box>
  )
}
import { Box, Breadcrumbs, Link as MuiLink, styled, Tabs, Toolbar, Typography } from "@mui/material"
import { FC } from "react"
import { Link, useMatches } from "react-router-dom"
import { LinkTab } from "../../../components/LinkTab/LinkTab"
import { Investment } from "../../../context/InvestmentsContext"
import { investmentDetailNavLinks } from "../../../routes/child-routes/investment-detail.routes"
import { MatchWithHandle } from "../../../routes/router.util"
import { toCurrency } from "../../../utility/number.util"

type InvestmentDetailHeaderProps = {
  investment: Investment
}

export const InvestmentDetailHeader: FC<InvestmentDetailHeaderProps> = ({investment}) => {
  // const { investmentGeneralInfo } = useInvestments();
  const matches = useMatches() as MatchWithHandle[];
  const [routeTabValue] = matches.filter((m: MatchWithHandle) => m.handle?.tab);
  const activeTab = routeTabValue?.handle?.tab ?? "investors";

  return (
    <Toolbar sx={{ flexDirection: 'column', alignItems: 'flex-start', pt: 2 }}>
      <Breadcrumbs sx={{ pb: 1 }}>
        <MuiLink component={Link} to="/investments">Investments</MuiLink>
        <Typography>{investment.name ?? ""}</Typography>
      </Breadcrumbs>
      <Typography variant="h4">{investment.name ?? ""}</Typography>
      <Box sx={{ width: 300, display: 'flex', justifyContent: 'space-between' }}>
        <Typography>Total Client Commitment:</Typography>
        <Typography>{investment.total_commitment ? toCurrency(investment.total_commitment) : ""}</Typography>
      </Box>
      <Box sx={{ width: 300, display: 'flex', justifyContent: 'space-between' }}>
        <Typography>Total Client Investors:</Typography>
        <Typography>{investment.investors ?? ""}</Typography>
      </Box>
      <Typography>{investment.description ?? ""}</Typography>
      <Tabs value={activeTab} aria-label="nav tabs">
        {investmentDetailNavLinks.map(r => (
          <LinkTab label={r.label} to={r.path!} value={r.path!} key={r.label} />
        ))}
      </Tabs>
    </Toolbar>
  )
}

import { Search } from "@mui/icons-material";
import { Box, InputAdornment, Paper, TableContainer, TextField, Typography } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { Distribution, DistributionInvestor } from "../../../../../api/payloads";
import { ColumnDef, DataSourceTable } from "../../../../../components/DataSourceTable";
import { useInvestments, useMaskedParams } from "../../../../../hooks";
import { toCurrency } from "../../../../../utility/number.util";
import { DistributionDetailHeader } from "./DistributionDetailHeader";
import { Link as MuiLink } from "@mui/material";
import { InstructionDetailsModal } from "../../../../../components/CustomModal/DistributionInstructionDetailModal";
import { AccountType } from "../../../../../utility/constants";
import { deepClone } from "../../../../../utility/object.util";

const displayCurrency = (v: number) => toCurrency(v);


export const InvestmentDistributionsDetail: FC = () => {
  const { investmentId, distributionId } = useMaskedParams();
  const { investmentsMap, distributionsMap } = useInvestments();
  const [searchFilter, setSearchFilter] = useState("");
  const distribution = useMemo(() => distributionsMap[+distributionId!] ?? {}, [distributionId, distributionsMap]);
  const [filteredRows, setFilteredRows] = useState(distribution.distributionInvestors || []);
  const investment = investmentsMap[+investmentId!] ?? {};
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDistributionInstruction, setSelectedDistributionInstruction] = useState<DistributionInvestor | null>(null);

  const columnDef: ColumnDef<Distribution['distributionInvestors'][number]> = [
    { id: 'customer_name', label: "Client" },
    { id: 'account_name', label: "Account Name" },
    { id: 'amount', label: "Distributed", displayFn: displayCurrency, showTotal: true },
    { id: "details",label: "Distribution Instruction",
      displayFn: (v, row) => {
        if (row.details.distribution_instruction_name) {
          return (
            <MuiLink 
              component="span" 
              style={{ cursor: 'pointer' }} 
              onClick={() => {
                let instruction = row
                if(row.account_type_id === AccountType.IRA){
                  instruction = deepClone(instruction);
                  instruction.details.ffc_account_number = row.account_number ?? '';
                  instruction.details.ffc_to =  '';
                }
                handleOpenModal(instruction)}}
            >
              {row.details.distribution_instruction_name}
            </MuiLink>
          );
        }
        return 'Instruction Not Set';
      },
    },
  ];

  useEffect(() => {
    const filteredRows = distribution && distribution.distributionInvestors ? distribution.distributionInvestors.filter(r => r.customer_name.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase())) : [];
    setFilteredRows(filteredRows);
  }, [searchFilter, distribution])

  const handleOpenModal = (instruction: DistributionInvestor) => {
    setSelectedDistributionInstruction(instruction)
    setModalOpen(true);
};

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedDistributionInstruction(null);
  };

  return (
      <>
        <DistributionDetailHeader {...{ distribution, investment }} />
        <Box p={3}>
          <Box component="section" mb={1}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <Typography variant="h5">Distribution Summary</Typography>
              <TextField
                value={searchFilter}
                onChange={(event) => setSearchFilter(event.target.value)}
                placeholder="Search Investors"
                size="small"
                helperText=""
                InputProps={{
                  startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
                }} />
            </Box>
              <TableContainer component={Paper}>
                <DataSourceTable columnDef={columnDef} dataSource={filteredRows} emptyMessage="No distributions" showTotals />
              </TableContainer>
            </Box>
            {selectedDistributionInstruction && (
              <InstructionDetailsModal 
                open={modalOpen} 
                onClose={handleCloseModal} 
                instruction={selectedDistributionInstruction}
                isCustodied={false}
              />
            )}
        </Box>
      </>
  )
}
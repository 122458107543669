import { Box, FormControl, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, Typography, Link as MuiLink } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import moment from "moment";
import { FC, useEffect } from "react";
import { DatePickerField } from "../../../../components/FormFields/DatePickerField";
import { useSubDocs } from "../../../../hooks/ContextHooks/useSubDocs";
import { FundingStatusStrings, IRAFundingType, InvestorDescriptionStrings } from "../../../../utility/constants";
import { PurchaseFundingInstructionFormValues } from "./subDocsFormSchema";
import { toCurrency } from "../../../../utility/number.util";

type InfoCardProps = {
  title: string,
  value: string,
  subCopy?: string | null,
}

export const InfoCard = ({title,subCopy,value}:InfoCardProps) => {
  return (
    <Grid item xs={6} sx={{display: 'flex', height: 60, mb:2}}>
      <Typography sx={{fontSize: 16, fontWeight: 'bold', mr: 4}}>{`${title}:`}</Typography>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Typography sx={{color: 'info.contrastText'}}>{value}</Typography>
        <Typography sx={{fontSize: '.8rem', color: 'grey.600'}}>{subCopy}</Typography>
      </Box>
      <Box>
      </Box>
    </Grid>
  )
}

export const PurchaseFundingInstruction: FC = () => {
  const { subDocsAccount, subscriptionAmount} = useSubDocs();
  const { values, setFieldValue, getFieldProps, setFieldTouched} = useFormikContext<PurchaseFundingInstructionFormValues>();
  const fundingStatusProps =  getFieldProps('isFunded');
  const fundingTypeProps =  getFieldProps('fundingType');
  const investorDescriptionProps =  getFieldProps('isOfficer');
  
  useEffect(() => {
    if (values.fundingDate && values.fundingDate.isBefore(moment())){
      setFieldTouched('fundingDate',true)
    }
  }, [values.fundingDate, setFieldTouched])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', pl: 2}}>
      <InfoCard title="Subscription Amount" value={toCurrency(subscriptionAmount, true) ?? ""} />
      <Typography>Please select one of the following options:</Typography>
      <Box sx={{pl: 6, py: 2, display: 'flex', flexDirection: 'column'}}>
      <Typography sx={{fontSize: 16, fontWeight: 'bold', mb:2}}>Funding Status:</Typography>
        <FormControl>
          <RadioGroup
            {...fundingStatusProps}
            onChange={(_event, value) => {
              if (+value === 1) {
                setFieldValue('fundingDate', null)
                setFieldValue('fundingType', "");
              }
              setFieldValue('isFunded', +value) 
            }
          }
          >
            <Box sx={{pl:2, display: 'flex', flexDirection: 'column'}}>
              <FormControlLabel value={1} control={<Radio />} label={FundingStatusStrings.alreadyFunded} />
              <FormControlLabel value={0} control={<Radio />} label={FundingStatusStrings.toBeFunded} />
            </Box>
          </RadioGroup>
        </FormControl>
        <DatePickerField disabled={values.isFunded !== 0} size='small' name='fundingDate' label="Anticipated funding date" sx={{ml: 8,mt: 2,width: 'calc(33% - 8px)'}} minDate={moment().add(1, 'day')}/>
      </Box>
      <Typography>Please select one of the following options:</Typography>
      <Box sx={{pl: 6, pt: 2, pb: 4}}>
      <Typography sx={{fontSize: 16, fontWeight: 'bold', mb:2}}>Funding Type:</Typography>
        <FormControl disabled={values.isFunded === 1}>
          <RadioGroup
            {...fundingTypeProps}
            onChange={(event, value) => { 
              setFieldValue('fundingType', value) 
            }
          }
          >
            <Box sx={{pl:2, display: 'flex', flexDirection: 'column'}}>
              <FormControlLabel value={IRAFundingType.Wire.value} control={<Radio />} label={IRAFundingType.Wire.description} />
              <FormControlLabel value={IRAFundingType.Custodian.value} control={<Radio />} label={IRAFundingType.Custodian.description} />
              <Typography sx={{ml: 6, color: values.fundingType !== IRAFundingType.Custodian.value ? `grey.600` : 'default'}}>If you need to fund your Inspira Financial account, please fill out the <MuiLink target='_blank' href="https://wealthretirementforms.inspirafinancial.com/">IRA to IRA form.</MuiLink></Typography>
            </Box>
          </RadioGroup>
        </FormControl>
      </Box>
      <Typography>Please select and complete the best option that describes the investor:</Typography>
      <FormControl>
        <RadioGroup
          {...investorDescriptionProps}
          onChange={(event, value) => { 
            if (+value === 0) {
              setFieldValue('investorNatureOfRelationship', '');
              setFieldValue('investorPercentOwned', '');
            }

            setFieldValue('isOfficer', +value) 
          }
        }
        >
          <Box sx={{pl:2, display: 'flex', flexDirection: 'column'}}>
            <FormControlLabel value={0} control={<Radio />} label={<p>I represent that <b>I am not</b> an officer or director of the offering entity or any affiliates thereof, nor am I related to any officer or director of the offering entity or any affiliates thereof. I also represent that my ownership of this entity (combined with any family member or disqualified person) will be less than 50%.</p>} />
            <FormControlLabel value={1} control={<Radio />} label={<p>I represent and disclose that <b>I am</b> an officer or director of the offering entity or its affiliate, or that I am related to any officer or director of the offering entity or its affiliate. The nature of the relationship and the combined percentage of the entity owned by any account, any family member or disqualified person are as follows:</p>} />
          </Box>
        </RadioGroup>
      </FormControl>
      <Box sx={{display: 'flex', ml: 8}}>
        <Field disabled={!values.isOfficer} name="investorNatureOfRelationship" label="Nature of Relationship" component={TextField} size="small" sx={{width: 'calc(35% - 8px)', mr: 2}}/>
        <Field disabled={!values.isOfficer} name='investorPercentOwned' size="small" label="Percent Owned" component={TextField} sx={{width: 'calc(35% - 8px)', mr: 2}} 
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ outline: "none" }}>
                %
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  )
}
import { useFormikContext } from "formik";
import { FC, useMemo } from "react";
import { useClients } from "../../../../../hooks";
import { useSubDocs } from "../../../../../hooks/ContextHooks/useSubDocs";
import { RevocableStrings } from "../../../../../utility/constants";
import { SupplementalDataFormValues } from "../subDocsFormSchema";
import { SubDocsReviewInfoCard } from "../SubDocsReview";

export const SupplementalDataReview: FC = () => {
  const {addressStates } = useClients();
  const { values } = 
  useFormikContext<SupplementalDataFormValues>();
  const {subDocsAccount} = useSubDocs()

  const stateName = useMemo(() => {
    const currentState = addressStates?.find(state => +state.id === +values.stateOfIncorporation) ?? null;
    return currentState?.name ?? ""
  },[addressStates, values.stateOfIncorporation,])

  return (
    <>
      <SubDocsReviewInfoCard span={4} title={"Date of Incorporation"} value={values?.dateOfIncorporation?.format('MMMM Do, YYYY') ?? ""} />
      <SubDocsReviewInfoCard span={4} title="State of Incorporation" value={stateName} />
      <SubDocsReviewInfoCard span={12} title="Was Entity formed specifically for the purpose of investing in the Company?" value={!!values?.fundSpecificEntity ? "yes" : "no"} />
      {subDocsAccount?.trust === true && <SubDocsReviewInfoCard title="Type of Trust" value={values?.revocable ?? ""} />}
      {
        values?.revocable === RevocableStrings.Revocable && 
        <>
          <SubDocsReviewInfoCard title="Grantor(s)" value={values?.grantor ?? ""} />
        </>
      }
      {
        values?.revocable === RevocableStrings.Other && 
        <>
          <SubDocsReviewInfoCard title="Type of 'Other' Trust" value={values?.typeOfTrust ?? ""} />
          <SubDocsReviewInfoCard title="Grantor(s)" value={values?.grantor ?? ""} />
          <SubDocsReviewInfoCard title="Beneficiary(y)/(ies)" value={values?.beneficiary} />
          <SubDocsReviewInfoCard title="Trustee(s)" value={values?.trustees ?? ""} />
        </>
      }
    </>
  )
}
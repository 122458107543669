export function snakeToCamel(s: string) {
  return s.toLowerCase().replace(/[-_][a-z]/g, (group) => group.slice(-1).toUpperCase());
}

export function isString(s: any) {
  return typeof s === 'string';
}

export function camelCaseToSpaceSeparated(camelCaseString: string): string {
  return camelCaseString
    .replace(/([A-Z])/g, ' $1') // Add space before each uppercase letter
    .trim(); // Remove leading or trailing spaces
}
import { Box, Container, Toolbar, Typography } from "@mui/material";
import { FC } from 'react';
import { ContentBackground } from "../../components/ContentBackground/ContentBackground";
import { isProduction } from "../../utility/misc.util";

 export const TermsAndConditionsPage: FC = () => {
  return (
      <Container sx={{ py: 2 }}>
      <ContentBackground>
        <Toolbar
          sx={{ flexDirection: "column", alignItems: "flex-start", pt: 2 }}
        >
          <Typography variant="h3" sx={{color: "primary.main", fontFamily: "Times New Roman"}}>Advisor Vision – Disclosures</Typography>
        </Toolbar>
          <Box sx={{ p: 2, pt: 0 }}>
            <p>
              <Typography variant="h6">Terms of Use and Disclosures</Typography>
              <Typography variant="body2">Last updated May, 2023</Typography>
            </p>
            <p>
              <Typography variant="body1">
                <b>
                  These Terms of Use and Disclosures (“Terms & Disclosures”) are specific to the use of the Adviser Vision website (the “Platform”). Any use of the BIP Capital website (bipcapital.com) (the “Website”), the portions of the Website that are only accessible to investors, and the companion mobile application for investors is governed by the general Terms of Use and Disclosures available at the following link: </b> 
                  <a rel="noreferrer" target="_blank" href={isProduction ? "https://advisorvision.bipcapital.com/terms-and-conditions" : "/terms-and-conditions"}>advisorvision.bipcapital.com/terms-and-conditions</a>.
              </Typography>
            </p>
            <p>
              <Typography variant="body1">
                Before  accessing  or  using  (1)  the  Platform  or  (2)  the  information  and  tools available on the Platform, please carefully read each of the terms, conditions, and disclosures set forth in these Terms & Disclosures in their entirety. These Terms & Disclosures govern your access to and use of the Platform and provide important information regarding the Platform; the information contained on the Platform;  the  Platform’s  owner,  BIP  Capital,  LLC,  a  Delaware  limited  liability company  d/b/a  BIP  Capital  with  an  address  at  3575 Piedmont  Rd  NE,  Building  15,  Suite  730,  Atlanta,  GA  30305;  and  certain affiliates of BIP Capital (the “BIP Affiliates”).  
            </Typography>
          </p>
          <p>
            <Typography variant="body1">
              By accessing or using any part of the Platform or any tools available through the Platform, you are agreeing that: (1) you have read, understand, and consent to these Terms & Disclosures; (2) you are of legal age to form a binding contract with us; and (3) you have the authority to enter into these Terms & Disclosures personally or, if you are using the Platform on behalf of an entity, on behalf of the entity you have named as the user, and to bind that entity to these Terms & Disclosures. If you do not agree with these Terms & Disclosures, then you must immediately discontinue your access and use of the Platform.
            </Typography>
        </p>
        <p>
          <Typography variant="body1">
            Please  note  that  these  Terms  &  Disclosures  are  subject  to  change  by  BIP Capital in its sole discretion at any time. When changes are made, BIP Capital 
will make a new copy of the Terms & Disclosures available on this web page. BIP Capital will also update the “Last Updated” date at the top of these Terms &  Disclosures.  PLEASE  REGULARLY  CHECK  THIS  WEBPAGE  TO  VIEW THE THEN-CURRENT TERMS & DISCLOSURES.
          </Typography>
        </p>
        <p>
          <Typography variant="body1">
          The  Platform  is  only  accessible  to  registered  investment  advisers  that  have been approved by BIP Capital (each an “Authorized RIA”) and that have one or more advisory clients (“RIA Clients”) who are actual or prospective investors in one or more pooled investment vehicles that are managed by BIP Capital or a BIP Affiliate (each, a “Fund” and collectively, the “Funds”).
          </Typography>
        </p>
        <p>
          <Typography variant="body1">
          Authorized  RIAs  are  permitted  to  access  the  Platform  only  through  their designated investment adviser representatives (each a “Designated IAR”) that have created an account that is linked to the Authorized RIA in accordance with these Terms & Disclosures.
          </Typography>
        </p>
        <p>
          <Typography variant="body1">
          We may terminate, change, restrict, suspend, or discontinue any aspect of the Platform, or your access to the Platform, at any time without notice or liability to you, and any of your RIA Clients may terminate or change your access to the Platform,  relating  to  that  particular  RIA  Client  at  any  time  without  notice  or liability to you.
          </Typography>
        </p>
        <p>
          <Typography><b>Platform Access.</b></Typography>
          <ul>
            <li>
              <u>Authorized  Access</u>.  If  you  are  a  Designated  IAR,  in  order  to  access  the Platform, you will be required to create a user account (“User Account”). When  you  create  your  User  Account,  you  will  be  required  to  choose  a password,  which  will  be  required  to  use  and  access  your  User  Account. You  are  responsible  for  safeguarding  your  password  and  other  User Account information. You agree not to disclose your password to any third party, and you will notify BIP Capital immediately if your password is lost or stolen or if you suspect any unauthorized use of your User Account. You further agree that you will be solely responsible for any activities or actions under  your  User  Account,  whether  or  not  you  have  authorized  such activities or actions. You agree that the information you provide to us on account  registration  through  the  User  Account  will  be  true,  accurate, current,  and  complete.  You  acknowledge  and  agree  that  it  is  your responsibility, and not the responsibility of BIP Capital or any of its agents or  affiliates,  to  add  or  remove  authorized  users  with  respect  your  User Account.
            </li>
            <li>
              <u>License.</u> BIP Capital hereby grants you a non-exclusive, non-transferable, revocable, limited right to access and use the Platform in accordance with, and for so long as you are in compliance with, these Terms & Disclosures and any other agreements or other terms and conditions applicable to you.
            </li>
            <li>
              <u>Confidentiality.</u>The  information  contained  on  the  Platform  is  confidential and proprietary information of BIP Capital or other BIP Affiliates, and any reproduction  or  other  disclosure  of  such  information,  in  whole  or  in  part, without   the   prior   written   consent   of   BIP   Capital   is   prohibited. Notwithstanding  the  foregoing,  you  may,  without  the  permission  of  BIP Capital, disclose the information contained on the Platform to those of your RIA   Clients   to   which   the   information   pertains.   The   Platform   is   for convenience only and does not limit your ability to request information from BIP Capital by calling 404-479-5782.
            </li>
          </ul>
          <Typography><b>Use of Platform.</b> By  accepting  these  Terms  &  Disclosures,  you  agree  and acknowledge that you will not do any of the following: </Typography>
          <ul>
            <li>Treat any information on the Platform as investment advice;</li>
            <li>
             Provide information to BIP Capital or any BIP Affiliate, through the Platform, regarding  another  party  (including,  without  limitation,  any  of  your  RIA Clients) without such party’s authorization;
            </li>
             <li>
             Use data available through the Platform relating to any of your RIA Clients (“Client Data”) outside of the direct relationship between you and the RIA Client to whom the Client Data relates;
            </li>
             <li>
             Sell,  share,  or  otherwise  disclose  Client  Data  to  any  third  party,  or  use Client Data on behalf of any third party, unless strictly required for offering advisory services to that client;
            </li>
            
            <li>
             Use Client Data in a manner that is not disclosed to the RIA Client and/or in  any  manner  that  is  not  in  compliance  with  any  applicable  laws  and regulations;
            </li>
            <li>
             Use  the  Platform  in  any  capacity  or  for  any  purpose  other  than  as  a Designated IAR of an Authorized RIA;
            </li>
            <li>
             Circumvent, disable, or otherwise interfere with any security-related feature of the Platform or any feature that prevents or restricts use or copying of any content or information that has been uploaded to the Platform;
            </li>
            <li>
             Use  any  meta  tags  or  other  hidden  text  or  metadata  utilizing  any  of  BIP Capital’s,  or  any  BIP  Affiliate’s,  names,  trademarks,  URLs,  or  product names;
            </li>
            <li>
             Interfere with or disrupt (or attempt to interfere with or disrupt) the Platform or any of BIP Capital’s or any BIP Affiliate’s systems or networks or breach or  impair  or  circumvent  any  security  authentication  measures  protecting the Platform, BIP Capital, or any BIP Affiliate;
            </li>
            <li>
             Modify, translate, decipher, decompile, disassemble, reverse-engineer, or create derivative works from any of the software used by or in connection with  the  Platform,  or  attempt  to  perform,  or  assist  anyone  with  the performance of or attempt to perform, any of the foregoing actions;
            </li>
            <li>
              Violate any applicable law, regulation, or ordinance;
            </li>
            <li>
              Scrape or copy information from the Platform through any means (including crawlers,  browser  plugins  and  add-ons,  and  any  other  technology  or manual work);
            </li>
            <li>
              Use, launch, or permit to be used any automated system, including without limitation “robots,” “crawlers,” or “spiders”, in connection with the Platform;
            </li>
            <li>
              Violate  any  applicable  third-party  terms  or  agreement  when  using  the Platform;
            </li>
            <li>
              Monitor the availability, performance, or functionality of the Platform for any competitive purposes; or
            </li>
            <li>
              Use the Platform or any content thereon in any manner not permitted by these Terms & Disclosures.
            </li>
          </ul>
        </p>
        <p>
          <b>Subscription Documents.</b> For the convenience of Designated IARs and their applicable  RIA  Clients,  certain  features  of  the  Platform  will  allow  Designated IARs  to  pre-populate  subscription  documents  and  other  documents  that  will later  be  provided  to  RIA  Clients  for  execution.   As  a  condition  to  using  the Platform, you agree that you will take reasonable steps to verify the accuracy of all information that you pre-populate through the Platform on behalf of any RIA Client.
        </p>
        <p>
          <b>Title.</b> You  agree  that  BIP  Capital  or  BIP  Affiliates  own  and  hold  title  to  the Platform  and  all  information  thereon  and  all  subsequent  copies  thereof regardless  of  the  form  or  medium.  Furthermore,  title,  ownership  rights,  and intellectual  property  rights  in  the  Platform  and  the  information  thereon  will remain with BIP Capital and/or applicable BIP Affiliates. If you become aware of any misuse or abuse of the Platform or any content provided in connection with the Platform by any person, you agree to promptly report such misuse or abuse to BIP Capital.
        </p>
        <p>
          <b>Feedback.</b> BIP Capital will own any suggestions for improvements or changes, ideas, feedback, error identifications, or other information related to the Platform (“Feedback”). Without limiting the foregoing, BIP Capital and BIP Affiliates may use  Feedback  without  restriction  and  without  obligation  to  you.  Providing Feedback is voluntary and BIP Capital is under no obligation to post or use any Feedback.
        </p>
        <p>
          <b>International Use.</b> The Platform is controlled, operated, and administered by us from offices within the United States of America. We make no representation regarding use of the Platform outside of the United States. You represent and warrant  that  (i)  you  are  not  located  in  a  country  that  is  subject  to  a  U.S. Government embargo, or that has been designated by the U.S. Government as a  “terrorist  supporting”  country;  and  (ii)  you  are  not  listed  on  any  U.S. Government list of prohibited or restricted parties.
        </p>
        <p>
          <b>No Representation or Warranty.</b> You acknowledge and agree that your use of  the  Platform  is  at  your  own  risk.  THE  PLATFORM  IS  PROVIDED  “AS  IS” AND “AS AVAILABLE”, WITHOUT WARRANTIES OR GUARANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED. YOU ASSUME THE RISK OF ANY AND ALL DAMAGE OR LOSS FROM USE OF, OR INABILITY TO USE, THE PLATFORM. WE MAKE NO WARRANTY THAT THE PLATFORM WILL MEET YOUR    REQUIREMENTS.    IF    YOU    ARE    DISSATISFIED    WITH    THE PLATFORM,  YOUR  SOLE  REMEDY  IS  TO  DISCONTINUE  USING  THE PLATFORM. WITHOUT LIMITING THE FOREGOING, BIP CAPITAL HEREBY SPECIFICALLY  DISCLAIMS  ANY  REPRESENTATIONS  OR  WARRANTIES AS TO THE ACCURACY, PERFORMANCE, TIMELINESS, QUALITY, TRUTH, AVAILABILITY,    RELIABILITY,    UPTIME,    AND    SUITABILITY    OF    THE CONTENT.  YOU  ACKNOWLEDGE  AND  AGREE  THAT  WE  CANNOT  AND DO   NOT   GUARANTEE   AGAINST   VIRUSES,   WORMS,   OR   OTHER UNAUTHORIZED   USERS   OR   HACKERS   ATTEMPTING   TO   OBTAIN ACCESS TO THE PLATFORM.
        </p>
        <p>
          <b>Limitation  of  Liability.</b> IN  NO  EVENT  SHALL  BIP  CAPITAL  AND  BIP AFFILIATES,   AND   THEIR   LICENSORS,   BUSINESS   PARTNERS,   AND SERVICE   PROVIDERS,   BE   LIABLE   TO   YOU   FOR   ANY   MONETARY DAMAGES,   WHETHER   DIRECT,   INDIRECT,   SPECIAL,   INCIDENTAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THE PLATFORM AND/OR THESE TERMS & DISCLOSURES, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY,  AND  EVEN  IF  BIP  CAPITAL  HAS  BEEN  ADVISED  OF  THE POSSIBILITY OF SUCH DAMAGES.THE  LIMITATIONS,  EXCLUSIONS,  AND  DISCLAIMERS  SET  FORTH  IN THESE TERMS ARE THE BASIS FOR THE BARGAIN OF OUR PROVISION OF,  AND  YOUR  USE  OF,  THE  PLATFORM.  NOTWITHSTANDING  THE FOREGOING,   BIP   CAPITAL   AND   BIP   AFFILIATES   SHALL   HAVE   NO LIABILITY TO YOU ARISING FROM OR RELATED TO THE UNAUTHORIZED OR  ACCIDENTAL  DESTRUCTION,  LOSS,  ALTERATION  OF,  OR  ACCESS TO YOUR INFORMATION OR THE INVESTOR PORTAL.BIP CAPITAL WILL NOT BE LIABLE TO YOU FOR ANY LOSS RESULTING FROM  A  CAUSE  OVER  WHICH  BIP  CAPITAL  DOES  NOT  HAVE  DIRECT CONTROL. THIS INCLUDES FAILURE OF ELECTRONIC OR MECHANICAL EQUIPMENT  OR  COMMUNICATIONS  LINES  (INCLUDING  TELEPHONE, CABLE  AND  INTERNET),  UNAUTHORIZED  ACCESS,  VIRUSES,  THEFT, OPERATOR    ERRORS,    SEVERE    OR    EXTRAORDINARY    WEATHER (INCLUDING  FLOOD,  EARTHQUAKE,  OR  OTHER  ACT  OF  GOD),  FIRE, WAR,  INSURRECTION,  TERRORIST  ACT,  RIOT,  LABOR  DISPUTE  AND OTHER  LABOR  PROBLEMS,  ACCIDENT,  EMERGENCY  OR  ACTION  OF GOVERNMENT.
        </p>
        <p>
          <b>Indemnification.</b> You  agree  to  indemnify,  defend,  and  hold  BIP  Capital  and BIP  Affiliates,  and  their  officers,  directors,  employees,  licensors,  licensees, suppliers,  agents,  and  contractors,  harmless  from  and  against  any  loss, damage, liability, claim, demand, cost, and expense (including attorneys’ fees), brought by any third party in connection with, arising out of, or related to the use of the Platform and/or violation of these Terms & Disclosures by you or any of your   employees,   agents,   or   representatives.   All   rights   and   duties   of indemnification that are set forth herein shall survive termination of these Terms & Disclosures
        </p>
        <p>
          <b>Governing  Law/Jurisdiction/Claim  Limitation.</b> These  Terms  &  Disclosures are governed by the laws of the State of Georgia. Any action or suit related to these Terms & Disclosures must be brought in the state or federal courts sitting in Atlanta, Georgia, provided however, that we may seek equitable relief in any jurisdiction. Any cause of action brought against us must be commenced within one (1) year after the claim or cause of action arose. You and BIP Capital each may bring claims against the other only in your or its individual capacity, and not  as  a  plaintiff  or  class  member  in  any  purported  class  or  representative proceeding.
        </p>
        <p>
          <b>BIP Capital and BIP Capital Management Services.</b> BIP Capital was founded in January 2018 by Mark A. Buffington. Prior to founding BIP Capital, Mr. Buffington, along with   others,   managed   certain   investment   vehicles   through   BIP   Capital Management Services, LLC, a Delaware limited liability company (“BIP CMS”), and  other  affiliated  entities  that  collectively  operated  under  the  “BIP  Capital” name  (the  “Prior  BIP  Entities”).  Information  contained  on  the  Platform  that  is dated  prior  to  January  2018  relates  to  the  Prior  BIP  Entities  and  not  to  BIP Capital.  References  to  “BIP”, or  “BIP  Capital”  in  any  content  on  the  Platform  refer  to  activities  conducted  by  BIP Capital and other BIP Affiliates.
        </p>
        <p>
        BIP Capital and BIP CMS (collectively, the “Advisers” and each, an “Adviser”) are  each  registered  as  an  investment  adviser  with  the  U.S.  Securities  and Exchange  Commission  (the  “SEC”).  Registration  with  the  SEC  or  other  state securities authorities as an investment adviser does not imply a certain level of skill or training, and the information on the Platfrom has not been approved or verified by the SEC or by any state securities authority. Additional information about  each  Adviser,  including  each  Adviser’s  written  disclosure  statement (Form ADV Part 2A) discussing such Adviser’s business operations, services, and fees, is available from the applicable Adviser by written request or on the SEC’s website at <a target="_blank" rel="noreferrer" href="https://www.adviserinfo.sec.gov">www.adviserinfo.sec.gov</a>.
        </p>
        <p>
          <b>Third-Party Websites.</b> The Platform may include links to websites operated by third  parties,  including,  without  limitation,  links  to  the  websites  of  portfolio companies of certain Funds. You acknowledge and agree that these links are provided for convenience purposes only, it is impossible for BIP Capital and the BIP  Affiliates  to  monitor  such  websites  and  the  information  thereon,  and  you are  accessing  such  websites  and  information  at  your  own  risk.  Neither  BIP Capital  nor  any  BIP  Affiliate  makes  any  representation  or  warranty  regarding any  of  the  information  or  other  content  on  such  websites  or  any  products  or services that may be described or offered thereon.
        </p>
        <p>
          <b>Third-Party Websites.</b> The Platform may include links to websites operated by third  parties,  including,  without  limitation,  links  to  the  websites  of  portfolio companies of certain Funds. You acknowledge and agree that these links are provided for convenience purposes only, it is impossible for BIP Capital and the BIP  Affiliates  to  monitor  such  websites  and  the  information  thereon,  and  you are  accessing  such  websites  and  information  at  your  own  risk.  Neither  BIP Capital  nor  any  BIP  Affiliate  makes  any  representation  or  warranty  regarding any  of  the  information  or  other  content  on  such  websites  or  any  products  or services that may be described or offered thereon.
        </p>
        <p>
          <b>Performance Information.</b> Any performance results presented on the Platform are based on BIP Capital’ s or a BIP Affiliate’s estimation of the net asset value of the applicable Fund and are presented net of management fees, brokerage fees,    administrative    expenses,    other    Fund    operating    expenses    and organizational  expenses,  and  accrued  performance  allocations,  if  any.  BIP Capital’s  and  the  BIP  Affiliates’  estimations  of  net  asset  value  are  generally speculative and based on significant assumptions. Negative market or business conditions could compound this difficulty. Moreover, as discussed in more detail in  the  confidential  offering  documents  for  each  Fund,  the  Advisers  face substantial  conflicts  of  interest  with  respect  to  the  Funds  and  their  portfolio companies, and these conflicts of interest may impact the Advisers’ valuations of portfolio companies, the Funds, and your investments in the Funds. Further, past  performance  is  not  indicative  of  future  results  and  no  representation  is being made that any investment in a Fund will or is likely to achieve profits or losses  similar  to  those  achieved  in  the  past,  or  that  significant  losses  will  be avoided. You should not assume that any investment in any Fund is or will be profitable or that any investments made in the future will equal the performance of your investments identified in the Platform.
        </p>
        <p>
          <b>Portfolio  Company  Information.</b> Any  transactions  or  activities  described  on the  Platform  as  having  been  engaged  in  by  BIP  Capital  or  BIP  Affiliates  are included as representative transactions for illustrative purposes only, and are not presented as representative of all transactions engaged in by BIP Capital, BIP Affiliates or any principals thereof. As noted above, past performance is not indicative  of  future  results,  and  no  representation  is  being  made  that  any investment managed by BIP Capital or any BIP Affiliate will or is likely to achieve profits or losses similar to those achieved in the past, or that significant losses will  be  avoided.  One  should  not  assume  that  all  investments  in  any  portfolio companies identified on the Platform are or will be profitable or that any portfolio company   investments   made   in   the   future   by   any   Fund   will   equal   the performance  of  the  companies  identified  herein.  Any  quotations  provided  by personnel of portfolio companies may not be representative of the experience of  personnel  of  other  portfolio  companies,  and  are  not  a  guarantee  of  future performance   or   success.   Further,   certain   information   regarding   portfolio companies that is presented on the Platform has been prepared by unaffiliated third  parties,  and  neither  BIP  Capital  nor  any  BIP  Affiliate  makes  any representation   or   warranty   as   to   the   accuracy,   timeliness,   suitability, completeness, or relevance of such information, or has any obligation to verify the accuracy of such information.
        </p>
        <p>
          <b>No  Offer,  Solicitation  or  Advice.</b>  The  Platform  is  intended  solely  to  provide information regarding your RIA Clients’ current investments in the Funds and to facilitate you assisting your RIA Clients with completing subscription materials for  certain  Finds.  Material  presented  on  the  Platform  (including,  without limitation, any information regarding BIP Capital’s or any BIP Affiliate’s current and  historical  portfolio  companies  and  other  investments)  is  presented  for informational purposes only and is not intended to constitute, and should not be construed  as:  (i)  an  offer,  or  solicitation  of  an  offer,  to  purchase  or  sell  any security,  other  asset,  or  service;  (ii)  investment  advice  or  an  offer  to  provide such advice; or (iii) a basis for making any investment decision. No content on the  Platform  makes  any  effort  to  present  a  comprehensive  or  balanced description of BIP Capital or any BIP Affiliate, or the investment activities of BIP Capital or any BIP Affiliate. In any event, past performance is not a guarantee of future results, and it should not be assumed that results of any Fund or any portfolio  investment  will  be  achieved  for  other  investments.  Any  analysis  or discussion  of  investments,  sectors,  or  markets  generally  is  based  on  current information,  including  from  public  sources,  that  BIP  Capital  or  BIP  Affiliates consider reliable, but neither BIP Capital nor any BIP Affiliate represents that any  such  information  provided  is  accurate  or  complete,  and  it  should  not  be relied  on  as  such.  Any  views  and  opinions  expressed  in  any  content  on  the Platform are current at the time of publication and are subject to change.
        </p>
        <p>
          <b>Forward-Looking  Statements.</b> Certain  information  on  the  Platform  contains forward-looking  statements.  Such  forward-looking  statements  are  subject  to various risks and uncertainties and speak only as of the date on which they are made. Accordingly, there are or will be important factors that could cause actual outcomes or results to differ materially from those indicated in these statements. You can identify these forward-looking statements by the use of words such as “outlook,” “indicator,” “believe,” “expect,” “potential,” “continue,” “may,” “should,” “seek,”  “approximately,”  “predict,”  “anticipate,”  “optimistic,”  “intend,”  “plan,” “estimate,”  “aim,”  “will”  or  the  negative  version  of  these  words  or  similar expressions. Neither BIP Capital nor any BIP Affiliate undertakes any obligation to update or revise any information on the Platform, whether as a result of new information, future developments, or otherwise.
        </p>
        <p>
          <b>General Provisions.</b> If, for any reason, a court of competent jurisdiction finally determines any provision of these Terms & Disclosures to be unenforceable, such provision shall be enforced to the maximum extent permissible so as to give  the  intended  effect  thereof,  and  the  remainder  of  these  Terms  & Disclosures shall continue in full force and effect. Our failure to act with respect to a breach by you or others does not waive our right to act with respect to that breach or subsequent or similar breaches. No consent or waiver by BIP Capital will be effective unless in writing.
        </p>
          </Box>
      </ContentBackground>
    </Container>
  );
}
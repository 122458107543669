import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { Box, Button, FormHelperText, IconButton, Tooltip, Typography } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { SimpleFileUpload } from 'formik-mui';
import moment from 'moment';
import { MouseEvent, useCallback, useEffect } from 'react';
import { apiClient } from '../../../../../api/apiClient';
import { DatePickerField } from "../../../../../components/FormFields/DatePickerField";
import { LimitedLineTypography } from '../../../../../components/Typography/LimitedLineTypography';
import { useUI } from '../../../../../hooks';
import { isNullish } from '../../../../../utility/misc.util';
import { EditMemberFormValues } from './EditMemberDialog';

type Props = {
  label: string
}

export const EditMemberFormIdentificationFields = ({label} : Props) => {
  const form = useFormikContext<EditMemberFormValues>();
  const [field] = useField("idImageFile");
  const {values, setFieldValue, setFieldTouched} = form 
  const file = values.idImageFile
  const title = file ? file.name : label
  const { setLoading, setError } = useUI();

  const handleFileClick = useCallback(async (e: MouseEvent<any>) => {
    try {
      if (values.imageId == null) {
        return
      }
      e.preventDefault();
      setLoading(true);
      const resp = await apiClient.get("/documents/:p0", { routeParams: [values.imageId] });
      window.open(resp.url)
    } catch (error) {
      setError("Unable to download file");
    } finally {
      setLoading(false);
    }
  }, [setLoading, values, setError]);

  useEffect(() => {
    if (values.expirationDate && values.expirationDate.isBefore(moment())){
      setFieldTouched('expirationDate',true)
    }
  }, [values.expirationDate, setFieldTouched])

  const handleFileDelete = () => {
    setFieldValue('imageId', null)
    setFieldValue('idImageFile', null)
  }

  return (
    <Box sx={{display: 'flex'}}>
      <Box>
        <Button endIcon={values.imageId == null ? <DriveFolderUploadIcon /> : <CloudDownloadIcon />} variant={file ? "contained":"outlined"} component="label"sx={{width: 300, mr: 2, height: 57}} onClick={(e: MouseEvent) => handleFileClick(e)}>
          <LimitedLineTypography numLines={1}>{title}</LimitedLineTypography>
          <Box sx={{display: 'none'}} hidden={true}>
            <SimpleFileUpload 
              meta={{touched: true, value: "test", error: "null", initialValue: "", initialTouched: false }}
              form={form} 
              field={field}
              accept="image/*,application/pdf" label="Upload Photo ID"
            />
          </Box>
        </Button>
          {form.errors.idImageFile && <FormHelperText error>{form.errors.idImageFile}</FormHelperText>}
      </Box>
      <Box sx={{width: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {file && <IconButton onClick={(_e) => handleFileDelete()} sx={{mr: 2}}>
          <DeleteIcon />
        </IconButton>}
      </Box>
      <Tooltip title={!isNullish(values.imageId) ? "To change the expiration date, first upload a new ID" : ""}>
        <span>
          <Box>
            <DatePickerField disabled={!isNullish(values.imageId)} name='expirationDate' label="ID Expiration Date" sx={{width: 200}} minDate={moment().add(1, 'day')}/>
          </Box>
        </span>
      </Tooltip>
    </Box>
  )
};
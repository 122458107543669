import { Typography } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { Field, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import { useEffect } from "react";
import { Contact, Custodian } from "../../../../api/payloads/customer.payload";
import { CustodiansAccountType, IraType } from "../../../../utility/constants";
import { BaseRecord } from "../../../../utility/types";
import { AccountFormValues } from "./newClientFormSchema";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "100px",
    },
  },
};

type Props = {
  custodians: Custodian[],
  members: Contact[],
  iraTypes: BaseRecord[],
}

export default function IRAAccountFields({custodians, members, iraTypes}: Props) {
  const {values, setFieldValue, setFieldTouched, validateForm} = useFormikContext<AccountFormValues>();
  const {beneficiary, custodian, isCustodied, iraTypeId} = values;

  useEffect(() => {
    const currentCustodian = custodians.find(c => (c.id.toString() === custodian.toString()));
    const currentBeneficiary = members?.find(member => member.id === beneficiary);
    const currentIraType = iraTypes.find(t => t.id !== IraType.Traditional && t.id === iraTypeId);
    
    const accountName = currentBeneficiary ? [
      currentCustodian?.name,
      "FBO",
      currentBeneficiary?.first_name ?? "",
      currentBeneficiary?.last_name,
      currentIraType?.name ?? "",
      "IRA"
    ].filter(Boolean).join(' ') : "";
    
    setFieldValue('accountName', accountName).then(() => validateForm());
  }, [iraTypes, iraTypeId, members, beneficiary, custodian, custodians, isCustodied, setFieldValue, setFieldTouched, validateForm])

  
  
  return (
     <>
      <Field
        formControl={{ required: true, sx:{width: 'calc(50% - 8px)',minWidth: 112, mr: 2,mb: 2}}}
        component={Select}
        label="Custodian Name"
        MenuProps={MenuProps}
        renderValue={(custodianID: number) => {
          const currentCustodian = custodians.find((custodian) => (custodian.id.toString() === custodianID.toString()))
          return <Typography >{currentCustodian ? currentCustodian.name : ""}</Typography>
        }}
        name="custodian"
      >
        {custodians.filter(c => c.custodians_account_type_id === CustodiansAccountType.IRA).map((custodian) => <MenuItem key={custodian.id} value={custodian.id}>{custodian.name}</MenuItem>)}
      </Field>
      <Field
        formControl={{required: true, sx:{width: 'calc(50% - 8px)',minWidth: 112, mb:2,}}}
        component={Select}
        label="Beneficiary"
        MenuProps={MenuProps}
        renderValue={(memberID: number) => {
          const currentMember = members.find((member) => (member.id.toString() === memberID.toString()))
          return <Typography>{currentMember ? `${currentMember.first_name} ${currentMember.last_name}` : ""}</Typography>
        }}
        name="beneficiary"
        onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
      >
        {members.map((member) => <MenuItem key={member.id} value={member.id}><option value={member.id}>{`${member.last_name}, ${member.first_name}`}</option></MenuItem>)}
      </Field>
      <Field name="accountName" disabled required label="Account Name" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
      <Field name="accountNumber" required label="Account Number" component={TextField} sx={{width: 'calc(50% - 8px)',}}/>
    </>
  );
}
import { useFormikContext } from "formik";
import { FC, useEffect, useMemo, useState } from "react";
import { useClients } from "../../../../../hooks";
import { useSubDocs } from "../../../../../hooks/ContextHooks/useSubDocs";
import { AccountType } from "../../../../../utility/constants";
import { SubDocsReviewInfoCard } from "../SubDocsReview";
import { RepresentationOfResidenceFormValues } from "../subDocsFormSchema";

export const RepresentationOfResidenceReview: FC = () => {
  const { subDocsAccount} = useSubDocs();
  const {addressStates, } = useClients();
  const { setFieldValue, values } = 
  useFormikContext<RepresentationOfResidenceFormValues>();
  const [ownerLabel, setOwnerLabel] = useState<string>("");


  useEffect(() => {
    switch(subDocsAccount?.account_type_id as AccountType) {
      case AccountType.IRA:
        setOwnerLabel("Beneficiary Name")
        break;
      case AccountType.Individual:
        setOwnerLabel("Account Owner")
        break;
      case AccountType.Entity:
        setOwnerLabel("Entity Name")
        break;
      case AccountType.Joint:
        setOwnerLabel("Account Owner ")
        break;
      default:
        break;
    }
  },[setFieldValue, subDocsAccount])

  const stateName = useMemo(() => {
    const currentState = addressStates?.find(state => +state.id === +values.residenceStateId) ?? null;
    return currentState?.name ?? ""
  },[addressStates, values.residenceStateId,])

  const countryName = useMemo(() => {
    const [_code, country] = values.residenceCountryId?.split(";")
    return country ?? ""
  },[ values.residenceCountryId ])
  return (
    <>
      <SubDocsReviewInfoCard span={4} title={ownerLabel} value={values?.residenceAccountOwner} />
      <SubDocsReviewInfoCard title="Country" value={countryName} />
      <SubDocsReviewInfoCard title="State" value={stateName} />
    </>
  )
}
import { FC, PropsWithChildren, createContext, useEffect, useMemo, useState } from "react";
import { Account, BdcPosition, GetCustomerResp, Investment } from "../api/payloads/customer.payload";
import { createObjectMap } from "../utility/array.util";
import { AccountType } from "../utility/constants";

export type ClientDetailsContextValue = {
  activeClient?: GetCustomerResp,
  setActiveClient: React.Dispatch<React.SetStateAction<GetCustomerResp | undefined>>,
  unlinkedAccounts: Account[],
  unlinkedInvestments: InvestmentOrBdcPosition[],
  accountsMap: Record<number, Account>,
}

export const ClientDetailsContext = createContext(undefined as unknown as ClientDetailsContextValue);
type InvestmentOrBdcPosition = Investment | BdcPosition;

export const ClientDetailsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeClient, setActiveClient] = useState<ClientDetailsContextValue['activeClient']>();
  const [unlinkedAccounts, setUnlinkedAccounts] = useState<ClientDetailsContextValue['unlinkedAccounts']>([]);
  const [unlinkedInvestments, setUnlinkedInvestments] = useState<ClientDetailsContextValue['unlinkedInvestments']>([]);

  useEffect(() => {
    const unlinkedAccounts = activeClient?.accounts?.filter(a => a.distribution_instructions_count === 0) ?? [];
    const investments: InvestmentOrBdcPosition[] = [
      ...(activeClient?.investments ?? []),
      ...(activeClient?.bdcInvestments ?? [])
    ];
    const unlinkedInvestments = investments.filter(i => {
      const hasInstruction = !!i.distribution_instruction_id;
      const isIra = i.account_type_id === AccountType.IRA
      return !isIra && !hasInstruction;
    });
    
    setUnlinkedAccounts(unlinkedAccounts);
    setUnlinkedInvestments(unlinkedInvestments);
  }, [activeClient]);

  /* Index client accounts by id */
  const accountsMap = useMemo(() => {
    if (activeClient?.accounts) {
      return createObjectMap(activeClient.accounts, 'id');
    }
    return {}
  }, [activeClient?.accounts]);

  const value = useMemo(() => ({ 
    activeClient,
    setActiveClient,
    unlinkedAccounts,
    unlinkedInvestments,
    accountsMap,
  }), [activeClient, unlinkedAccounts, unlinkedInvestments, accountsMap]);

  return (
    <ClientDetailsContext.Provider value={value}>
      {children}
    </ClientDetailsContext.Provider>
  )
}
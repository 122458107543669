import React from 'react';
import { Badge, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../../context/NotificationsContext';
import { Notification } from '../../api/payloads/professional.payload';
import moment from 'moment';
import { useOpportunities, useUI } from '../../hooks';
import { NotificationActionType } from '../../utility/constants';

type NotificationItemProps = {
  notification: Notification;
  onNotificationClick?: () => void; 
};

const NotificationItem: React.FC<NotificationItemProps> = ({ notification ,  onNotificationClick}) => {
  const navigate = useNavigate();
  const { markAsRead } = useNotifications();
  const { opportunitiesMap } = useOpportunities();
  const { setError } = useUI();

  const handleClick = () => {
    const indicatingInterestTypes = [
      NotificationActionType.IndicatedInterestInOpportunity,
      NotificationActionType.IndicatedInterestInBDCOpportunity,
    ];
    let rowIdToHighlight: number | undefined;

    if (indicatingInterestTypes.includes(notification.actionType) && notification.linkId) {
      const opportunity = opportunitiesMap[notification.linkId];

      if (opportunity) {
        rowIdToHighlight = notification.metadata?.investorId || notification.metadata?.prospectId || notification.metadata?.transactionId;
        navigate(notification.link!, { state: { highlightedRowId: rowIdToHighlight } });
        markAsRead([notification.id]);
        if (onNotificationClick) onNotificationClick();
      } else {
        setError('This opportunity is no longer fundraising.');
      }
    } else {
      if (notification.link) {
        navigate(notification.link);
        markAsRead([notification.id]);
        if (onNotificationClick) {
          onNotificationClick();
        }
      } else {
        markAsRead([notification.id]);
      }
    }
  };
  const timeSinceCreated = moment(notification.created).fromNow();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingY: 1.5,
        paddingX: 2,
        cursor: notification.link ? 'pointer' : 'default',
        backgroundColor: notification.isRead ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
        borderRadius: 1,
        '&:hover': {
          backgroundColor: notification.isRead ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.05)',
        },
      }}
      onClick={handleClick}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Typography variant="caption" color="text.secondary">
            {moment(notification.created).format('MMMM Do YYYY, h:mm a')}
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="caption" color="text.secondary">
          {timeSinceCreated}
          </Typography>
          {!notification.isRead && (
            <Badge
              color="success"
              variant="dot"
              sx={{ marginLeft: 1 }}
            />
          )}
        </Box>
      </Box>

      <Typography variant="subtitle2" fontWeight="bold" sx={{ marginTop: 0.5 }}>
        {notification.title}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ marginTop: 0.5 }}>
        {notification.metadata?.description || ''}
      </Typography>
    </Box>
  );
};

export default NotificationItem;


import { Card, CardContent, Container, Link as MuiLink, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

type Props = {
  description: string,
  icon: ReactNode,
  path: string,
  title: string,
  onClick?: () => void
}

export const SettingsCard = ({ description, icon, path, title, onClick }: Props) => {

  const content = (
    <Card sx={{ p: 4 }} variant="elevation" elevation={1}>
      <CardContent sx={{
        '& .MuiTextField-root': { mb: 1 },
        '& button[type="submit"].MuiButton-root': { fontWeight: 'bold' },
        height: 150,
      }}>
        {icon}
        <Typography variant="h5" mb={1}><b>{title}</b></Typography>
        <Typography color={grey[600]}>{description}</Typography>
      </CardContent>
    </Card>
  );

  return (
    <Container component="main" maxWidth="xs">
      {onClick ? (
        <div onClick={onClick} style={{ textDecoration: 'none', cursor: 'pointer' }}>
          {content}
        </div>
      ) : (
        <MuiLink component={Link} to={path} sx={{ textDecoration: 'none' }}>
          {content}
        </MuiLink>
      )}
    </Container>
  );
}
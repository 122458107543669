import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Button, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { yellow } from "@mui/material/colors";
import { Form, Formik, useFormikContext } from "formik";
import { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { apiClient } from "../../../../../api/apiClient";
import { BdcPosition, Investment, PostNewCustomerDistributionInstructionReq } from "../../../../../api/payloads/customer.payload";
import { LoadingButton } from "../../../../../components/Buttons";
import { ErrorToast } from "../../../../../components/ErrorToast";
import { useAuth, useClientDetails, useMaskedParams, useUI } from "../../../../../hooks";
import { createDocument } from "../../../../../services/document.service";
import { groupBy } from "../../../../../utility/array.util";
import { DistributionInstructionType, DocumentTypes } from "../../../../../utility/constants";
import { isAxiosError, isBdcInvestment } from "../../../../../utility/type-guards";
import { FormikSubmitFn } from "../../../../../utility/types";
import { AssociatedAccountsAndInvestments } from "./AssociateAccountsAndInvestments/AssociateAccountsAndInvestments";
import { DistributionInstructionDetailsForm } from "./DistributionInstructionDetails/DistributionInstructionDetails";
import { DistributionInstructionReview } from "./DistributionInstructionReview/DistributionInstructionReview";
import { getNewInstructionAccountAndInvestmentFormSchema, newInstructionDetailFormSchema, NewInstructionFormValues, newInstructionReviewFormSchema } from "./newInstructionValidationSchema";

// TODO: Organize this component and children by moving share types/utils and such to a dedicated file
type TStep = {
  label: string;
  component: React.ReactElement;
  optional?: boolean;
  validationSchema: Yup.BaseSchema;
};

type NextButtonProps = {
  isSubmitting: boolean;
  isValid: boolean;
};

export type InvestmentsByAccountDataSource = {
  [accountId: string]: (Investment | BdcPosition)[];
};

export function getInvestmentKey(investment: Investment | BdcPosition) { 
  return `${investment.account_id}-${investment.investment_company_id}`;
}

export type SelectionState = {[key: string]: boolean;};

export type AccountNameMapping = {[accountId: string]: string};

export type InvestmentNameMapping = Record<string, BdcPosition | Investment>;

export const ClientInstructionsStepper: FC<{ onCancel: () => void }> = ({ onCancel }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [instructionType, setInstructionType] = useState<DistributionInstructionType | "">("");
  const { setError, setLoading, setSuccess } = useUI();
  const [selectedAccountsAndInvestments, setSelectAccountsAndInvestments] = useState<SelectionState>({});
  const [isContactInfoMissing, setIsContactInfoMissing] = useState(false);
  const { clientId } = useMaskedParams();
  const { user } = useAuth();
  const { activeClient, setActiveClient, accountsMap } = useClientDetails();
  const navigate = useNavigate();

  const newInstructionAccountAndInvestmentFormSchema = useMemo(() => {
    return getNewInstructionAccountAndInvestmentFormSchema(accountsMap);
  } , [accountsMap]);

  const linkableInvestmentsByAccount: InvestmentsByAccountDataSource = useMemo(() => {
    const linkableAccounts = activeClient?.accounts.filter(a => a.custodian_id === null) ?? [];

    const linkableRegDInvestments = activeClient?.investments.filter(regD => linkableAccounts.some(acc => acc.id === regD.account_id));
    const linkableBdcInvestments = activeClient?.bdcInvestments.filter(bdc => linkableAccounts.some(acc => acc.id === bdc.account_id));
    const regDInvestmentsByAccount = groupBy(linkableRegDInvestments ?? [], inv => inv.account_id);
    const bdcInvestmentsByAccount = groupBy(linkableBdcInvestments ?? [], inv => inv.account_id);
    
    return linkableAccounts.reduce((result, account) => {
      const bdcInvestments = bdcInvestmentsByAccount[account.id] ?? [];
      const regDInvestments =regDInvestmentsByAccount[account.id] ?? [];
      return {
        ...result,
        [account.id]: [...bdcInvestments, ...regDInvestments]
      }
    }, {});
  }, [activeClient]);

  const investmentsMap = useMemo(() => {
    const map: Record<string, Investment | BdcPosition> = {};
    const investments = [...(activeClient?.investments ?? []), ...(activeClient?.bdcInvestments ?? [])];
    for (const investment of investments) {
      map[getInvestmentKey(investment)] = investment;
    }
    return map;
  }, [activeClient?.investments, activeClient?.bdcInvestments]);

  const handleBack = () => setActiveStep((prev) => prev - 1);

  const steps: TStep[] = useMemo(() => [
    {
      label: "Distribution Instruction Details",
      component: (
        <DistributionInstructionDetailsForm
          instructionType={instructionType}
          setInstructionType={setInstructionType}
        />
      ),
      validationSchema: newInstructionDetailFormSchema,
    },
    {
      label: "Associated Accounts And Investments",
      component: (
        <AssociatedAccountsAndInvestments
          linkableInvestmentsByAccount={linkableInvestmentsByAccount}
          selected={selectedAccountsAndInvestments}
          setSelected={setSelectAccountsAndInvestments}
          activeStep={activeStep}
        />
      ),
      validationSchema: newInstructionAccountAndInvestmentFormSchema,
    },
    {
      label: "Distribution Instruction Review",
      component: (
        <DistributionInstructionReview
          investmentNameMapping={investmentsMap}
          setIsContactInfoMissing={setIsContactInfoMissing}
        />
      ),
      validationSchema: newInstructionReviewFormSchema,
    },
  ], [investmentsMap, linkableInvestmentsByAccount, selectedAccountsAndInvestments, activeStep, instructionType, newInstructionAccountAndInvestmentFormSchema]);

  const handleSubmit: FormikSubmitFn<NewInstructionFormValues> = async ( values ) => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prev) => prev + 1);
      return;
    }
    if (!values.instructionType || !clientId || !user) {
      setError("Oops, something went wrong. Please try again later");
      return;
    }
    
    setLoading(true);
    /** At time of writing this can include a selected account without selected investments. Only possible to select when account does not have any listed investments. May be a bug */
    const selectedInvestmentsByAccount: PostNewCustomerDistributionInstructionReq['investmentsByAccount'] = {};

    try {
      // Parse the selection data into a format that the API expects
      for (const [key, isSelected] of Object.entries(selectedAccountsAndInvestments)) { 
        if (!isSelected) {
          continue;
        }
  
        const parts = key.split("-");
        const accountId = parts[0];
        const isInvestmentSelection = !!parts[1];
        
        if (!selectedInvestmentsByAccount[+accountId]) {
          selectedInvestmentsByAccount[+accountId] = { investorIds: [], bdcPositionIds: [] };
        }
  
        if (isInvestmentSelection) {
          const investment = investmentsMap[key];
          if (isBdcInvestment(investment)) {
            selectedInvestmentsByAccount[+accountId].bdcPositionIds.push(investment.id);
          } else {
            selectedInvestmentsByAccount[+accountId].investorIds.push(investment.id);
          }
        } 
      }

      const createDistributionInstructionData: PostNewCustomerDistributionInstructionReq = {
        instructionType: values.instructionType,
        investmentsByAccount: selectedInvestmentsByAccount,
      };

      if (values.instructionType === DistributionInstructionType.Wire) {
        createDistributionInstructionData.wireInstructions = {
          instructionName: values.wireInstructions.instructionName,
          bankAccountName: values.wireInstructions.bankAccountName,
          aba: values.wireInstructions.aba,
          accountNumber: values.wireInstructions.accountNumber,
          financialInstitutionName: values.wireInstructions.financialInstitutionName,
          financialCity: values.wireInstructions.financialCity,
          financialStateId: values.wireInstructions.financialStateId,
          financialZip: values.wireInstructions.financialZip,
          financialStreetAddress1: values.wireInstructions.financialStreetAddress1,
          financialStreetAddress2: values.wireInstructions.financialStreetAddress2,
          streetAddress1: values.wireInstructions.streetAddress1,
          streetAddress2: values.wireInstructions.streetAddress2,
          city: values.wireInstructions.city,
          stateId: values.wireInstructions.stateId,
          zip: values.wireInstructions.zip,
          ffc: values.wireInstructions.ffc,
          ffcAccountNumber: values.wireInstructions.ffcAccountNumber,
          existingAddressId: values.wireInstructions.existingAddressId,
        };
      } else if (values.instructionType === DistributionInstructionType.ACH) {
        createDistributionInstructionData.achInstructions = {
          instructionName: values.achInstructions.instructionName,
          bankAccountName: values.achInstructions.bankAccountName,
          aba: values.achInstructions.aba,
          accountNumber: values.achInstructions.accountNumber,
          accountType: values.achInstructions.accountType,
          ffc: values.achInstructions.ffc,
        };
      } else if (values.instructionType === DistributionInstructionType.Check) {
        createDistributionInstructionData.checkInstructions = {
          instructionName: values.checkInstructions.instructionName,
          payeeName: values.checkInstructions.payeeName,
          existingAddressId: values.checkInstructions.existingAddressId,
          streetAddress1: values.checkInstructions.streetAddress1,
          streetAddress2: values.checkInstructions.streetAddress2,
          city: values.checkInstructions.city,
          stateId: values.checkInstructions.stateId,
          zip: values.checkInstructions.zip,
        };
      } else if (values.instructionType === DistributionInstructionType.Brokerage) {
        createDistributionInstructionData.brokerageInstructions = {
          instructionName: values.brokerageInstructions.instructionName,
          custodianId: values.brokerageInstructions.custodianId,
          bankAccountName: values.brokerageInstructions.bankAccountName,
          aba: values.brokerageInstructions.aba,
          accountNumber: values.brokerageInstructions.accountNumber,
          financialInstitutionName: values.brokerageInstructions.financialInstitutionName,
          financialCity: values.brokerageInstructions.financialCity,
          financialStateId: values.brokerageInstructions.financialStateId,
          ffc: values.brokerageInstructions.ffc,
          ffcAccountNumber: values.brokerageInstructions.ffcAccountNumber,
        };
      }

      let documentId = await handleDocumentUpload(+clientId, values);

      const { distributionInstructionId } = await apiClient.post("/customers/:p0/distribution-instructions", {
        data: createDistributionInstructionData,
        routeParams: [clientId],
      });

      if (documentId) {
        await apiClient.post("/documents/customers-documents/:p0/distribution-instructions/:p1",{
          routeParams: [clientId, distributionInstructionId],
          data: { documentId },
        });
      }
      
      if (!distributionInstructionId) {
        throw new Error();
      }
      
      const docusignResponse = await apiClient.post('/docusign/distribution-instruction', {
        data: { distributionInstructionId }
      });

      if (!docusignResponse?.envelopeId) {
        throw new Error("Error creating the DocuSign envelope. Please contact support");
      } else {
          setSuccess("Sent successfully");
      }

      const client = await apiClient.get("/customers/:p0", { routeParams: [activeClient!.id] });
      setActiveClient(client);
      navigate('.', { state: { showNewInstructionForm: false } })
    } catch (e) {
      let message = 'Encountered error while creating Distribution Instruction';
      let isInternalError = false;

      if (e instanceof Error && e.message.length > 0) {
        message = e.message;
        if (isAxiosError(e)) {
          message = e.response?.data?.message ?? e.message;
          isInternalError = e.response?.status === 500;
        }
      }
      setError(<ErrorToast showContactEmail={isInternalError}>{message}</ErrorToast>)
    } finally {
      setLoading(false);
    }
  };

  const NextButton: FC<NextButtonProps> = ({ isSubmitting, isValid }) => {
    const { values } = useFormikContext<NewInstructionFormValues>();
    const isNextButtonDisabled = !values.instructionType || (activeStep === 1 && Object.keys(values.selected).length === 0);
    return (
      <LoadingButton
        loading={isSubmitting}
        disabled={!isValid || isSubmitting || isNextButtonDisabled || isContactInfoMissing}
        type="submit"
      >
        {activeStep === steps.length - 1 ? "Send To Client" : "Next"}
      </LoadingButton>
    );
  };

  const initialValues: NewInstructionFormValues = {
    instructionType: "",
    wireInstructions: {
      instructionName: "",
      bankAccountName: "",
      aba: "",
      accountNumber: "",
      financialInstitutionName: "",
      streetAddress1: "",
      streetAddress2: "",
      financialStreetAddress1: "",
      financialStreetAddress2: "",
      financialCity: "",
      financialStateId: "",
      financialZip: "",
      city: "",
      stateId: "",
      zip: "",
      ffc: "",
      ffcAccountNumber: "",
      verificationDocument: null,
      existingAddressId: null,
    },
    achInstructions: {
      instructionName: "",
      bankAccountName: "",
      aba: "",
      accountNumber: "",
      accountType: "",
      ffc: "",
      voidedCheck: null,
    },
    checkInstructions: {
      instructionName: "",
      payeeName: "",
      existingAddressId: null,
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      stateId: "",
      zip: "",
    },
    brokerageInstructions: {
      instructionName: "",
      custodianId: "",
      bankAccountName: "",
      aba: "",
      accountNumber: "",
      financialInstitutionName: "",
      financialCity: "",
      financialStateId: "",
      ffc: "",
      ffcAccountNumber: "",
      verificationDocument: null,
    },
    selected: {},
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h6" sx={{ mb: 4 }}>
        Add New Distribution Instruction
      </Typography>
      <Stepper activeStep={activeStep} connector={<ChevronRightIcon />}>
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <Typography sx={{ mt: 2, mb: 1 }}>
          All steps completed - you're finished
        </Typography>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={steps[activeStep].validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, isSubmitting }) => (
            <Form>
              {/* <TestObserver /> */}
              {steps[activeStep].component}
              <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
                {activeStep === 0 ? (
                  <Button
                    sx={{ backgroundColor: 'info.main', mr:2 }}
                    variant="outlined"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    sx={{ backgroundColor: 'info.main', color: "black", mr:2 }}
                    disabled={activeStep === 0}
                    variant="outlined"
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                )}
                <NextButton isSubmitting={isSubmitting} isValid={isValid}/>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

function handleDocumentUpload(clientId: number, values: NewInstructionFormValues) {
  const isRequiresDocument = [
    DistributionInstructionType.Wire, 
    DistributionInstructionType.ACH, 
    DistributionInstructionType.Brokerage
  ].includes(values.instructionType as number);
  
  if (!isRequiresDocument) { 
    return;
  }

  const file =
    values.instructionType === DistributionInstructionType.ACH ? values.achInstructions.voidedCheck :
      values.instructionType === DistributionInstructionType.Wire ? values.wireInstructions.verificationDocument :
        values.brokerageInstructions.verificationDocument;

  if (!(file instanceof File)) {
    throw new Error("Could not resolve uploaded file.");
  }

  const documentTypeId = values.instructionType === DistributionInstructionType.ACH ? DocumentTypes.VoidedCheck : DocumentTypes.WireVerificationDocument;

  return createDocument(file, {
    fileName: file.name,
    customerId: clientId,
    documentTypeId,
  });
}

// const TestObserver = () => {
//   const { errors, values } = useFormikContext();
//   console.log("errors", errors, "values", values);
//   return null;
// };

import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { Box, Button, FormHelperText, IconButton } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { SimpleFileUpload } from 'formik-mui';
import { MouseEvent, useCallback, useMemo } from 'react';
import { LimitedLineTypography } from '../../../../../components/Typography/LimitedLineTypography';
import { useSubDocs } from '../../../../../hooks/ContextHooks/useSubDocs';
import { isNullish } from '../../../../../utility/misc.util';
import { SubDocsFormValues } from '../subDocsFormSchema';
import { useUI } from '../../../../../hooks';
import { apiClient } from '../../../../../api/apiClient';

type Props = {
  index: number,
  remove: (index: number) => void
}

export const SupportingDocumentField = ({ index, remove  }: Props) => {
  const form = useFormikContext<SubDocsFormValues>();
  const {attachmentsToBeDetached, setAttachmentsToBeDetached} = useSubDocs();
  const { setLoading, setError } = useUI();

  const [field, metaData] = useField(`supportingDocuments[${index}].supportingDocumentFile`)
  const {values} = form
  const documentValue = useMemo(() => (values.supportingDocuments[index]),[values,index])

  const handleFileClick = useCallback(async (e: MouseEvent<any>) => {
    try {
      if (documentValue == null || documentValue.documentId == null) {
        return
      }
      e.preventDefault();
      setLoading(true);
      const resp = await apiClient.get("/documents/:p0", { routeParams: [documentValue.documentId] });
      window.open(resp.url)
    } catch (error) {
      setError("Unable to download file");
    } finally {
      setLoading(false);
    }
  }, [setLoading, documentValue, setError]);

  const handleFileDelete = () => {
    if (!isNullish(documentValue) && !isNullish(documentValue.documentId)) {
      setAttachmentsToBeDetached([...attachmentsToBeDetached, documentValue.documentId])
    }
    remove(index)
  }
  return (
    <Box sx={{mb: 2}}>
        <Button 
          endIcon={documentValue?.documentId == null ? <DriveFolderUploadIcon /> : <CloudDownloadIcon />} 
          variant={documentValue ? "contained":"outlined"} 
          component="label"
          sx={{width: 'calc(33% - 8px)', mr: 2, height: 57}} 
          onClick={(e: MouseEvent) => handleFileClick(e)}
        >
          <LimitedLineTypography numLines={1}>{documentValue?.documentName ?? documentValue?.supportingDocumentFile?.name ?? ""}</LimitedLineTypography>
          <Box sx={{display: 'none'}} hidden={true}>
            <SimpleFileUpload
              meta={{touched: true, value: "", error: "null", initialValue: 
              "", initialTouched: false }}
              form={form}
              field={field}
              accept=".pdf" label="Upload Photo ID"
            />
          </Box>
        </Button>
        <IconButton onClick={(_e) => handleFileDelete()} sx={{mr: 2}}>
          <DeleteIcon />
        </IconButton>
        {metaData.error && <FormHelperText error>{metaData.error}</FormHelperText>}
    </Box>
  )
};
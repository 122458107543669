import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { Box, Button, FormHelperText, IconButton, SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import { useField, useFormikContext } from "formik";
import { SimpleFileUpload } from "formik-mui";
import { LimitedLineTypography } from "../../../../../components/Typography/LimitedLineTypography";
import { MemberFormValues } from "../newClientFormSchema";
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
  accept: string
  index: number,
  label: string
  name: string
  sx?: SxProps<Theme>
}

export const IDFileUploadField = ({ ...props }: Props) => {
  const form = useFormikContext<MemberFormValues>();
  const { values, setFieldValue } = form
  const [field, metaData] = useField(props.name);
  const file = values.members[props.index].idImageFile
  const title = file ? file.name : props.label
  const [inputKey, setInputKey] = useState(Date.now());

  const handleClear = () => {
    setFieldValue(props.name, null);
    setFieldValue(`members[${props.index}].expirationDate`, null);
    setInputKey(Date.now());
  }

  return (
      <>
        <Button endIcon={<DriveFolderUploadIcon />} variant={file ? "contained":"outlined"} component="label"  sx={{...props.sx, height: '56px'}} >
          <LimitedLineTypography numLines={1}>{title}</LimitedLineTypography>
          <Box sx={{display: 'none'}} hidden={true}>
            <SimpleFileUpload 
              key={inputKey}
              meta={{
                touched: true, 
                value: form.values.members[props.index].idImageFile, 
                error: "null", 
                initialValue: "", 
                initialTouched: false 
              }} 
              form={form} 
              field={field} 
              accept={props.accept} 
              label="Upload Photo ID"
            />
          </Box>
        </Button>
        {file && (<IconButton onClick={handleClear} sx={{...props.sx, height: '56px', width: 'auto'}}><DeleteIcon sx={{color:'error.main'}} /></IconButton>)}
        {metaData.error && <FormHelperText error>{metaData.error}</FormHelperText>}
      </>
  );
};
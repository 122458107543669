import { Breadcrumbs, Link as MuiLink, styled, Toolbar, Typography } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Distribution } from "../../../../../api/payloads";
import { formatDate } from "../../../../../utility/date.util";
import { toCurrency } from "../../../../../utility/number.util";

const HeaderTable = styled('table')(({theme}) => ({
  border: 'none',
  marginBottom: theme.spacing(1),
}));

const TitleCell = styled('td')(({theme}) => ({
  width: 200,
  display: 'flex',
  alignItems: 'start',
}))

const DataCell = styled('td')(({theme}) => ({
  width: 500,
}))

type DistributionDetailHeaderProps = {
  distribution: Distribution,
}

export const DistributionDetailHeader: FC<DistributionDetailHeaderProps> = ({distribution}) => {
  return (
    <Toolbar sx={{ flexDirection: 'column', alignItems: 'flex-start', pt: 2 }}>
      <Breadcrumbs sx={{ pb: 1 }}>
        <MuiLink component={Link} to=".." relative="path">Distributions</MuiLink>
        <Typography>Distribution Details</Typography>
      </Breadcrumbs>
      <Typography variant="h4" mb={1}>{distribution.title}</Typography>
      <HeaderTable>
        <tbody>
          <tr><TitleCell>Distribution Date</TitleCell><DataCell>{formatDate(distribution.date)}</DataCell></tr>
          <tr><TitleCell>Total Distribution</TitleCell><DataCell>{toCurrency(distribution.totalDistributed)}</DataCell></tr>
          <tr><TitleCell>Description</TitleCell><DataCell>{distribution.transactionDescription}</DataCell></tr>
        </tbody>
      </HeaderTable>
    </Toolbar>

  )
}
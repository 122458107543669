import { ThemeOptions } from "@mui/material";

const MENU_ITEM_HEIGHT = 48;
const MENU_ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: MENU_ITEM_HEIGHT * 4.5 + MENU_ITEM_PADDING_TOP,
    },
  },
};

/** 
 * Defines common theme config options that may be used to compose our custom MUI themes
 */
export const baseThemeOptions: ThemeOptions = {
  palette: {
    primary: {main: '#1c252c'},
    secondary: {main: '#395871'},
    info: { main: '#ffc744' },
    tertiary: {main: '#f9e4b4'},
    error: {main: '#be1f0a', contrastText: "#FFFFFF"},
    success: {main: '#3d7c6d', contrastText: "#FFFFFF"},
    progress: { main: '#f9e4b4' , contrastText: "#000000"},
    attention: { main: '#ec9d93', contrastText: "#000000"},
    complete: { main: '#b8d9d1', contrastText: "#000000"}, 
  },
  typography: {
    fontFamily: 'Roboto',
    button: {
      textTransform: 'unset'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: { 
        colorDefault: ({theme}) => ({
          backgroundColor: theme.palette.background.paper
        })
      }
    },
    MuiChip: {
      styleOverrides: {
        colorInfo: ({theme}) => ({
          fontWeight: 500,
          color: theme.palette.error.contrastText,
          borderColor: theme.palette.grey[500],
          backgroundColor: theme.palette.grey[500],
        }),
        colorError:  ({theme}) => ({
          fontWeight: 500,
          color: theme.palette.error.contrastText,
          borderColor: theme.palette.error.main,
          backgroundColor: theme.palette.error.main,
        }),
        colorSuccess: ({theme}) => ({
          fontWeight: 500,
          color: theme.palette.success.contrastText,
          borderColor: theme.palette.success.main,
          backgroundColor: theme.palette.success.main,
        }),
      },

    },
    MuiPaper: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        rounded: ({theme}) => ({
          borderRadius: theme.spacing(1),
        })
      }
    },
    MuiTextField: {
      defaultProps: {
        helperText: " ", //Reserve space for the helper text box so it doesn't change field height when error appears,
        color: "secondary"
      }
    },
    MuiSelect: {
      defaultProps: {
        color: 'secondary',
        MenuProps 
      },
      styleOverrides: {
        outlined: {
          'option': {
            backgroundColor: 'inherit',
          }
        }
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({theme}) => ({
          backgroundColor: theme.palette.background.secondary,
        })
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: ({theme}) => ({
          color: theme.palette.primary.main,
          fontSize: 20,
        }),
      }
    },
    MuiButton: {
      defaultProps: {
        variant: "contained"
      },
      styleOverrides: {
        outlined: {
          borderColor: 'rgba(194, 197, 200, 0.4)',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          },
        }
      }
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {horizontal: 'right', vertical: 'top'}
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: ({theme}) => ({
          backgroundColor: theme.palette.background.secondary,
        })
      }
    },
    MuiPagination: {
      defaultProps: { shape: "rounded" }
    },
    MuiLink: {
      defaultProps: { color: "secondary" }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({theme}) => ({
          '&.Mui-focused': {
            color: theme.palette.secondary.main,
          }
        }),
      }
    },
  }
}
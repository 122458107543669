/** Paginates an array for the given page number and page size */
export function paginate<T = any>(arr: T[], pageSize: number, pageNumber: number) {
  const result = arr.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  return result;
}

type Predicate<T> = (element: T) => boolean;

/**
 * Partition an array of elements into two arrays that pass/fail a given condition, 
 * or into two arrays that pass a first condition and second optional condition
 * @param arr The array to partition
 * @param predicateA A function invoked on each element that returns truthy/falsey
 * @param predicateB (Optional) Second function if splitting array into arrays that pass each predicate respectively
 * @returns A tuple of partitioned arrays
 */
export function partition<T>(arr: T[], predicateA: Predicate<T>, predicateB?: Predicate<T>) {
  const [partitionA, partitionB] = [[] as T[], [] as T[]];

  for (const e of arr) {
    if (predicateA(e)) {
      partitionA.push(e);
    } else if (!predicateB || predicateB(e)) {
      partitionB.push(e)
    }
  }
  return [partitionA, partitionB];
}

/**
 * Group an array of elements by a key returned by a provided function
 * @param arr The array to group into an object 
 * @param groupingFn Function that returns a value to group similar array elements by
 * @returns An object where each key is a value returned by the grouping function
 */
export function groupBy<T, K extends (string | number)>(arr: T[], groupingFn: (item: T) => K) {
  return arr.reduce((result, current) => {
    const key = groupingFn(current);
    const hasAddedKey = !!result[key];
    hasAddedKey ? result[key].push(current) : (result[key] = [current]);
    return result;
  }, {} as Record<K, T[]>)
}

/**
 * Merge two grouped lists into a single grouped list
 * @param groupedA 
 * @param groupedB 
 * @returns 
 */
export function mergeGroupedLists<T, U>(groupedA: Record<string | number, T[]>, groupedB: Record<string | number, U[]>): Record<string, (T | U)[]> {
  const merged: Record<string, (T | U)[]> = {...groupedA}
  
  for (const key in groupedB) {
    if (merged[key]) {
      merged[key] = merged[key].concat(groupedB[key]);
    } else {
      merged[key] = groupedB[key];
    }
  }

  return merged;
}

/**
 * Sum elements of an array
 * @param arr 
 * @param key (optional) Element's property to sum
 * @todo Consider replacing key with a function
 * @returns Sum of array elements or property of array elements
 */
export function sumElements<T>(arr: T[], key?: keyof T): number {
  const sum = arr.reduce((acc, prev) => (key ? prev[key] as number ?? 0 : prev as number) + acc, 0);
  return sum;
}

/** 
 * Create object map where each key is some possible value of an array element's given field,
 * and each value is an an array element having that value. If provided optional valueFn,
 * then returned map's values are the whatever value is returned by the function
 * 
 * @todo: Really need to write doc comment examples, but I'm running out of time. 
 */
export function createObjectMap<T>(arr: T[], key: keyof T): Record<string | number, T>;
export function createObjectMap<T, R>(arr: T[], key: keyof T, valueFn: (element: T) => R): Record<string | number, R>;
export function createObjectMap<T, R>(arr: T[], key: keyof T, valueFn?: (element: T) => R): Record<string | number, R> {
  const map = {} as any;
  for (const element of arr) {
    map[element[key]] = valueFn ? valueFn(element) : element;
  }
  return map;
}
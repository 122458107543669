import { Button, styled } from "@mui/material";

export const MoreInfoButton = styled(Button)(({theme}) => ({
  backgroundColor: theme.palette.info.main,
  color: theme.palette.info.contrastText,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.info.dark,
    boxShadow: 'none'
  },
}));
import { createContext, useState, useContext, useCallback, useMemo } from 'react';
import { Notification } from '../api/payloads/professional.payload';
import { apiClient } from '../api/apiClient';
import { useAuth } from '../hooks/ContextHooks/useAuth';

export type NotificationsContextValue = {
  notifications: Notification[];
  setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>;
  markAsRead: (ids?: number[]) => Promise<void | Error>;
  clearNotifications: () => Promise<void | Error>;
};

export const NotificationsContext = createContext(undefined as unknown as NotificationsContextValue);

export const NotificationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { user } = useAuth();

  const markAsRead = useCallback(async (ids?: number[]) => {
    try {
      if (ids && ids.length > 0) {
        setNotifications(prev => prev.map(n => (ids.includes(n.id) ? { ...n, isRead: true } : n)));
      } else {
        setNotifications(prev => prev.map(n => ({ ...n, isRead: true })));
      }

      if (!user) return;
      
      await apiClient.patch('/professionals/:p0/notifications/mark-as-read', {
        routeParams: [user.id],
        data: { ids },
      });
    } catch (error) {
      return new Error('Failed to mark notifications as read.');
    }
  }, [ user]);

  const clearNotifications = useCallback(async () => {
    try {
      setNotifications([]);
      await apiClient.patch('/professionals/:p0/notifications/clear', {
        routeParams: [user!.id],
      });
    } catch (error) {
      return new Error('Failed to clear notifications.');
    }
  }, [user]);

  const value = useMemo(() => ({
    notifications,
    setNotifications,
    markAsRead,
    clearNotifications
  }), [notifications, setNotifications, markAsRead, clearNotifications]);

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }
  return context;
};

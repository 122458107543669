import { Backdrop, CircularProgress } from "@mui/material";
import { FC } from "react";
import { useUI } from "../../hooks";

export const GlobalLoader: FC = () => {
  const { loading } = useUI();

  return (
    <Backdrop open={loading} sx={{zIndex: 9999}}>
      <CircularProgress id='global-loader' size={60}/>
    </Backdrop>)
}
import React, { createContext, FC, PropsWithChildren, SetStateAction, useEffect, useMemo, useState } from "react";
import { GetInterestedPartiesResp } from "../api/payloads";
import { Country, Custodian, GetCustomerResp, GetDistributionInstructionsByAccountResp, GetStateResp, PhoneNumberType, State } from "../api/payloads/customer.payload";
import { createObjectMap } from "../utility/array.util";

export type Client = GetInterestedPartiesResp[number];

export type ClientsContextValue = {
  addressStates: GetStateResp | null,
  clients: Client[],
  setClients: React.Dispatch<React.SetStateAction<Client[]>>,
  clientsMap: Record<Client['id'], Client>,
  setAddressStates: React.Dispatch<React.SetStateAction<State[] | null>>,
  countries: Country[],
  setCountries:  React.Dispatch<React.SetStateAction<Country[]>>,
  phoneTypes: PhoneNumberType[],
  setPhoneTypes: React.Dispatch<React.SetStateAction<PhoneNumberType[]>>,
  custodians: Custodian[],
  setCustodians: React.Dispatch<React.SetStateAction<Custodian[]>>,
}

export const ClientsContext = createContext(undefined as unknown as ClientsContextValue);

/** WIP context for global UI states such as errors and loaders */
export const ClientsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [addressStates, setAddressStates] = useState<ClientsContextValue['addressStates']>(null)
  const [clients, setClients] = useState<ClientsContextValue['clients']>([]);
  const [clientsMap, setClientsMap] = useState<ClientsContextValue['clientsMap']>({});
  const [countries, setCountries] = useState<Country[]>([]);
  const [phoneTypes, setPhoneTypes] = useState<PhoneNumberType[]>([]);
  const [custodians, setCustodians] = useState<ClientsContextValue['custodians']>([]);

  const value = useMemo(() => ({ 
    addressStates,
    clients, 
    setClients,
    clientsMap,
    setAddressStates,
    phoneTypes,
    setPhoneTypes,
    countries,
    setCountries,
    custodians,
    setCustodians,
  }), [addressStates, clients, clientsMap, phoneTypes, countries, custodians]);

  /* Index client records by id */
  useEffect(() => {
    const map = createObjectMap(clients, 'id');
    setClientsMap(map);
  }, [clients])

  return (
    <ClientsContext.Provider value={value}>
      {children}
    </ClientsContext.Provider>
  )
}
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FieldArray } from "formik";
import { IdUploadFields } from "./CustomFields/IdUploadFields";
import { OwnerIdDocument } from "./subDocsFormSchema";

export const AMLIdentificationFields = () => {
  
  return (
    <Box sx={{my: 2}}>
      <Box sx={{mb: 2}}>
        <Typography variant="h6" sx={{mb: 1}}>Identification:</Typography>
        <Typography >Please review or upload the missing documents required to verify the investor(s) identity:</Typography>
      </Box>
      <FieldArray name="ownerIdDocuments">
        {({ form }) => (
          <Box>
            {(form.values.ownerIdDocuments as OwnerIdDocument[]).map((owner: OwnerIdDocument, index: number) => (
              <IdUploadFields key={index} label="Identification" name={`ownerIdDocuments[${index}].idImageFile`} index={index} owner={owner.name}/>
            ))}
          </Box>
        )}
    </FieldArray>
    </Box>
  )
};
import { East } from "@mui/icons-material";
import { Box, Button, Link as MuiLink, Paper, TableContainer, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { BdcPosition, CashFlow, GetCustomerResp, Investment } from "../../../../api/payloads/customer.payload";
import { ColumnDef, DataSourceTable } from "../../../../components/DataSourceTable";
import { TableTooltip } from "../../../../components/TableTooltip/Tabletooltip";
import { useClientDetails } from "../../../../hooks/ContextHooks/useClientDetails";
import { EntityStage } from "../../../../utility/constants";
import { formatDate } from "../../../../utility/date.util";
import { toCurrency, toPercent } from "../../../../utility/number.util";
import { maskRouteId } from "../../../../utility/route-mask.util";
import { UnlinkedAccountsWidget } from "../components/UnlinkedAccountsWidget";
import { UnlinkedInvestmentsWidget } from "../components/UnlinkedInvestmentsWidget";
import { MembersTable } from "../MembersTable";
import moment from "moment";

const displayDecimal = (v?: number) => v?.toFixed(2) ?? "---";
const displayPercent = (v?: number | string) => v ? toPercent(v, 2) : "---";
const displayCurrency = (v: number | string | null) => v !== null ? toCurrency(v, true) : "---";
const clickableRoute = (v: string , row:any) => <MuiLink component={Link} to={`/investments/${maskRouteId(row.investment_company_id)}/capital-calls/${maskRouteId(row.cash_flow_id)}`}>{v}</MuiLink>
const clickableDistRoute = (v: string , row:any) => <MuiLink component={Link} to={`/investments/${maskRouteId(row.investment_company_id)}/distributions/${maskRouteId(row.cash_flow_id)}`}>{v}</MuiLink>
const displayFairValue = (v: number | null, row: any) => {
  return (v !== null) ? (
    <TableTooltip arrow title={
      <Box>
        <Typography variant="subtitle2">
          As of {moment(row.value_as_of_date).format('MM/DD/YY')}
        </Typography>
      </Box>
    }>
      <Box component="span" color="secondary.main">{toCurrency(v)}</Box>
    </TableTooltip>
  ) : "Pending";
};


const CapitalCallColumnDef: ColumnDef<CashFlow> = [
  { id: 'title', label: 'Capital Call Name', displayFn: clickableRoute },
  { id: 'amount', label: 'Amount', displayFn: displayCurrency },
  { id: 'amount_paid', label: 'Amount Paid', displayFn: displayCurrency },
  { id: 'date', label: 'Due Date', displayFn: v => formatDate(v) },
  {
    id: 'is_paid',
    label: 'Status',
    displayFn: (data) => (
      <Box component="span" color={data ? 'success.main' : "error.main"}>{data ? 'Paid' : 'Not Paid'}</Box>
    ),
  }
];

const DistributionsColumnDef: ColumnDef<CashFlow> = [
  { id: 'title', label: 'Distribution Name', displayFn: clickableDistRoute  },
  { id: 'amount', label: 'Amount', displayFn: displayCurrency },
  { id: 'date', label: 'Due Date', displayFn: v => formatDate(v) },
];

const getInvestmentsColumnDef = (isActive: boolean, activeClient: GetCustomerResp) => {
  const investmentsColumnDef: ColumnDef<Investment> = [
    { id: "account_name",
      label: "Account Name",
      sortable: true,
      sortValueFn: (v: string | null) => v?.toLocaleLowerCase() ?? ""
    },
    { id: "investment_name", 
      label: "Investment Name", 
      displayFn: (v, row) => isActive ? <MuiLink component={Link} to={`/investments/${maskRouteId(row.investment_company_id)}/investors`}>{v}</MuiLink> : v, 
      sortable: true,
      sortValueFn: (v: string | null) => v?.toLocaleLowerCase() ?? ""
    },
    { id: "commitment", 
      label: "Commitment", 
      displayFn: displayCurrency, 
      showTotal: true, 
      sortable: true,
    },
    {
      id: "called_amount",
      label: "Called Amount",
      showTotal: true,
      displayFn: (data, row) => (
        (data && data !== 0) ?
          <TableTooltip arrow title={(
            <>
              <Typography variant="h6" px='0.5rem' py='0.25rem' fontWeight="500">Called Amount</Typography>
              <TableContainer > 
                <DataSourceTable columnDef={CapitalCallColumnDef} dataSource={row.capital_calls ?? []} />
              </TableContainer>
            </>
          )
          }>
            <Box component="span" color="secondary.main">{displayCurrency(data)}</Box>
          </TableTooltip>
          : "---"
      ),
      sortable: true,
      totalDisplayFn: displayCurrency,
    },
    {
      id: "distributed_amount",
      label: "Distributed Amount",
      displayFn: (data, row) => (
        (data && data !== 0) ?
          <TableTooltip arrow title={(
            <>
              <Typography variant="h6" px='0.5rem' py='0.25rem' fontWeight="500">Distributions</Typography>
              <TableContainer>
                <DataSourceTable columnDef={DistributionsColumnDef} dataSource={row.distributions ?? []} />
              </TableContainer>
            </>
          )
          }>
            <Box component="span" color="secondary.main">{displayCurrency(data)}</Box>
          </TableTooltip>
          : "---"
      ),
      showTotal: true,
      sortable: true,
      totalDisplayFn: displayCurrency,
    },
    ...(isActive ? [{ id: "fair_value", label: "Fair Value", displayFn: displayFairValue, showTotal: true, sortable: true, totalDisplayFn: displayCurrency }] : [] as any),
    { id: "dpi", label: "DPI", displayFn: displayDecimal, showTotal: isActive, totalFn: () => activeClient?.totalDPI,
    sortable: true,
    },
    { id: "tvpi", label: "TVPI", displayFn: displayDecimal,showTotal: isActive, totalFn: () => activeClient?.totalTVPI,
    sortable: true
    },
    { id: "irr", label: "IRR", displayFn: displayPercent, showTotal: isActive, totalFn: () => activeClient?.totalIRR,
    sortable: true
    },
  ];
  return investmentsColumnDef;
} 

export const ClientOverview: FC = () => {
  const { activeClient } = useClientDetails();
  const [showActive, setShowActive] = useState(true);

  const BdcInvestmentsColumnDef: ColumnDef<BdcPosition> = useMemo(() =>[
    { id: 'account_name', label: 'Account Name' },
    { 
      id: 'investment_name',
      label: 'Investment Name',
      displayFn: (v, row) => showActive && row.investment_company_stage_id === EntityStage.FundRaising
        ? <MuiLink component={Link} to={`/opportunities/${maskRouteId(row.investment_company_id)}/positions`}>{v}</MuiLink>
        : v,
    },
    { id: 'total_subscriptions', label: 'Subscription Amount', displayFn: displayCurrency },
    { id: 'shares', label: 'Shares', displayFn: (v: number) => v.toLocaleString() },
    { id: 'price_per_share', label: 'Price Per Share', displayFn: displayCurrency },
    { id: 'nav', label: 'NAV', displayFn: displayCurrency },
  ], [showActive]);

  const bdcInvestmentsDataSource = useMemo(() => {
    return showActive 
      ? activeClient?.bdcInvestments ?? []
      : activeClient?.inactiveBdcInvestments ?? [];
  }, [activeClient, showActive]);

  const investmentsDataSource = useMemo(() => {
    return showActive 
      ? activeClient?.investments ?? [] 
      : activeClient?.inactiveInvestments ?? [];
  }, [activeClient, showActive]);

  const investmentsColumnDef = useMemo(() => {
    return getInvestmentsColumnDef(showActive, activeClient!)
  }, [showActive, activeClient])

  const membersDataSource = useMemo(() => {
    return activeClient?.contacts?.slice(0, 2) ?? [];
  }, [activeClient?.contacts]);

  return (
    <>
      <Box sx={{ display: 'flex', gap: 2, mb: 4, alignItems: 'flex-end' }}>
        <UnlinkedAccountsWidget />
        <UnlinkedInvestmentsWidget />
      </Box>
      <Box component="section" mb={2}>
        <Typography variant="h5" mb={1}>Members</Typography>
        <TableContainer component={Paper} sx={{ mb: 1 }}>
          <MembersTable dataSource={membersDataSource ?? []} />
        </TableContainer>
        <Box display="flex" justifyContent="flex-end">
          {!!activeClient && activeClient!.contacts?.length > 2 &&
            <Button variant="outlined" endIcon={<East />} component={Link} to="../members">
              View All
            </Button>
          }
        </Box>
      </Box>

      <Box component="section" sx={{mb: 3}}>
        <Box mb={1}>
          <ToggleButtonGroup exclusive value={showActive} onChange={(_, v) => v !== null && setShowActive(v)}>
            <ToggleButton value={true}>Active</ToggleButton>
            <ToggleButton value={false}>Inactive</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Typography variant="h5" mb={1}>Traditional Reg D Investments</Typography>
        <TableContainer component={Paper}>
          <DataSourceTable columnDef={investmentsColumnDef} dataSource={investmentsDataSource} emptyMessage="No investments" showTotals initialOrderBy="account_name"/>
        </TableContainer>
        <Typography variant='caption'>
          <strong>Disclosure:</strong> The information above is presented on an aggregate basis as of the dates reflected for each Investment.
        </Typography>
      </Box>
     {bdcInvestmentsDataSource.length > 0 && <Box component="section" sx={{mb: 1}}>
        <Typography variant="h5" mb={1}>BDC Investments</Typography>
        <TableContainer component={Paper}>
          <DataSourceTable columnDef={BdcInvestmentsColumnDef} dataSource={bdcInvestmentsDataSource} emptyMessage="No BDC investments" showTotals initialOrderBy="account_name"/>
        </TableContainer>
      </Box>}
    </>
  )
}
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { BdcPosition, DistributionInstruction, Investment } from "../../../../api/payloads/customer.payload";
import { CornerCloseButton } from "../../../../components/Buttons";
import { useClientDetails, useUI } from "../../../../hooks";
import { apiClient } from "../../../../api/apiClient";
import { isAxiosError, isBdcInvestment } from "../../../../utility/type-guards";
import { DistributionInstructionStatus } from "../../../../utility/constants";
import { ErrorToast } from "../../../../components/ErrorToast";

/*
  Note: This and the LinkAccountInstructions modal could be generalized into a single component as they have
  quite similar layouts, but have opted to keep them separate for now as it remains to be seen if they will diverge.
  If we find ourselves making a third modal with the same layout, we should consider refactoring + generalizing.
*/

type LinkInvestmentInstructionsDialogProps = {
  handleClose: () => void;
  handleNewInstructionClick: () => void;
  open: boolean;
}

type SelectionModel = Record<string, { 
  selected: boolean,
  record: Investment | BdcPosition,
  instructionId?: number, 
}>;

/** Return a unique key for a RegD or BDC investment composed from Account ID + Investment Company ID. */
function getKey(investment: Investment | BdcPosition) { 
  return `${investment.account_id}-${investment.investment_company_id}`;
}

export const LinkInvestmentInstructionsDialog: FC<LinkInvestmentInstructionsDialogProps> = (props) => {
  const { activeClient, setActiveClient, unlinkedInvestments } = useClientDetails();
  const { setLoading, setError } = useUI();
   
  const [selected, setSelected] = useState<SelectionModel>(
    unlinkedInvestments.reduce((result, investment) => {
      const key = getKey(investment);
      return { ...result, [key]: { record: investment, selected: false, instructionId: undefined} }
    }, {} as SelectionModel)
  );

  /** 
   * Object map of an unlinked investment to Distribution Instructions linked to the account,
   * where both instruction and the Account-Instruction Link are approved.
   * Excludes IRA instructions.
   */
  const investmentInstructionSelectionLists = useMemo(() => (
    unlinkedInvestments.reduce((result, investment) => {
      const key = getKey(investment);
      const instructionList = activeClient?.distributionInstructions.filter(instruction =>
        !instruction.is_ira_instruction && instruction.accounts.some(account => account.id === investment.account_id)
      );
      return { ...result, [key]: instructionList }
    }, {} as Record<string, DistributionInstruction[] | undefined>)
  ), [unlinkedInvestments, activeClient?.distributionInstructions]);

  const isValid = useMemo(() => {
    const checkedRows = Object.values(selected).filter(row => row.selected);
    if (!checkedRows.length) {
      return false;
    }

    return checkedRows.every(row => row.instructionId !== undefined);
  }, [selected]);

  const handleSaveChanges = async () => {
    setLoading(true);
    const checkedRows = Object.values(selected).filter((row) => row.selected);
    let successfulUpdates = 0;

    for (const row of checkedRows) {
      try {
        const routeParams = [row.record.id, row.instructionId!];
        const data = { status: DistributionInstructionStatus.Approved };
        if (isBdcInvestment(row.record)) {
          await apiClient.post('/investors/bdc-positions/:p0/distribution-instructions/:p1', {routeParams, data})
        } else {
          await apiClient.post('/investors/:p0/distribution-instructions/:p1', {routeParams, data});
        }
        successfulUpdates++;
      } catch (e) {
        const header = 'Error linking distribution instruction to investment';

        if (isAxiosError(e)) {
          const isInternalError = e.response?.status === 500;
          const message = e.response?.data?.message ?? e.message;
          setError(<ErrorToast header={header} showContactEmail={isInternalError}>{message}</ErrorToast>)
        } else {
          setError(<ErrorToast header={header} showContactEmail>An unexpected error occurred.</ErrorToast>)
        }
        console.error(e);
        break;
      }
    }

    if (successfulUpdates) {
      const client = await apiClient.get("/customers/:p0", { routeParams: [activeClient!.id] });
      setActiveClient(client);
    }

    props.handleClose();
    setLoading(false);
  }

  return (
    <Dialog fullWidth maxWidth='md' open={props.open}>
      <CornerCloseButton onClick={() => props.handleClose()}></CornerCloseButton>
      <DialogTitle bgcolor='background.secondary'>
        <Box>Unlinked Investments - {unlinkedInvestments.length}</Box>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography color={theme => theme.palette.grey[700]} variant='subtitle1' component='div'>Existing - Distribution Instruction</Typography>
          <Button onClick={props.handleNewInstructionClick} color='info'>+ New Instruction</Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid p={2} container
          component={Paper}
          variant="elevation"
          elevation={0}
          mb={1}
          bgcolor={(theme) => theme.palette.mode === 'dark' ? theme.palette.grey['600'] : theme.palette.grey['300']}
          fontWeight='500'
        >
          <Grid item xs={8}>Investments</Grid>
          <Grid item xs={4}>Distribution Instructions</Grid>
        </Grid>
        {unlinkedInvestments.map((investment) => (
          <Grid key={`${investment.account_id}-${investment.investment_company_id}`}
            container p={1}
            borderBottom={theme => `1px solid ${theme.palette.divider}`}
            alignItems={'center'}
          >
            <Grid item xs={8}>
              <FormControlLabel
                label={(
                  <Box>
                    <Typography fontWeight='500' component='div'>{investment.investment_name}</Typography>
                    <Typography>
                      Account: <Typography component='span' color='grey.700'>{investment.account_name}</Typography>
                    </Typography>
                  </Box>
                )}
                control={(
                  <Checkbox
                    checked={selected[getKey(investment)].selected}
                    onChange={(e) => {
                      const key = getKey(investment);
                      const current = selected[key];
                      const isChecked = e.target.checked;
                      setSelected({ ...selected, [key]: { ...current, selected: isChecked, instructionId: isChecked ? current.instructionId : undefined } })
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}> 
              <FormControl fullWidth size='small' error={selected[getKey(investment)].selected && !selected[getKey(investment)].instructionId}>
                <InputLabel>Select Distribution Instruction</InputLabel>
                  <Select
                    label='Select Distribution Instruction'
                    value={selected[getKey(investment)].instructionId ?? ''}
                    onChange={e => {
                      const key = getKey(investment)
                      const current = selected[key];
                      const value = e.target.value as number | "";
                      setSelected({ ...selected, [key]: { ...current, selected: !!value, instructionId: value || undefined } })
                    }}
                    >
                    <MenuItem value="" disabled>{investmentInstructionSelectionLists[getKey(investment)]?.length ? "Select Distribution Instruction" : "No Distributions on file"}</MenuItem>
                    {investmentInstructionSelectionLists[getKey(investment)]!.map(instruction => (
                      <MenuItem key={instruction.id} value={instruction.id}>{instruction.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={props.handleClose} sx={theme => ({color: theme.palette.text.primary})} variant="outlined">Cancel</Button>
        <Button onClick={handleSaveChanges} color="primary" disabled={!isValid}>Save Changes</Button>
      </DialogActions>
    </Dialog>
  )
}
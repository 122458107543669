import { Add, CancelOutlined, Edit } from "@mui/icons-material";
import { Box, Button, Dialog, Paper, TableContainer, Typography } from "@mui/material";
import { FC, ReactNode, useCallback, useState } from "react";
import { apiClient } from "../../../../api/apiClient";
import { Professional } from "../../../../api/payloads";
import { CustomerProfessional } from "../../../../api/payloads/customer.payload";
import { BinaryValueCell } from "../../../../components/BinaryValueCell";
import { ConfirmationDialog } from "../../../../components/ConfirmationDialog/ConfirmationDialog";
import { ColumnDef, DataSourceTable } from "../../../../components/DataSourceTable";
import { ErrorContactEmail } from "../../../../components/ErrorContactEmail/ErrorContactEmail";
import { useClientDetails, useMaskedParams, useUI } from "../../../../hooks";
import { ProfessionalType } from "../../../../utility/constants";
import { getProfessionalTypeName } from "../../NewClientPage/Forms/ProfessionalsTable";
import { AddProfessionalDialog } from "../Dialogs/AddProfessionalDialog";

type CustomerProfessionalDataSource = (CustomerProfessional & {delete: ReactNode})

const professionalsColumnDef: ColumnDef<CustomerProfessionalDataSource> = [
  { id: "advisory", label: "Firm"},
  //TODO: For clarity may want to concat first/last names when making the data source and just call this key column key 'name'
  { id: "first_name", label: "Name", displayFn: (_, r) => `${r.first_name} ${r.last_name}`, sortable: true},
  { id: "relationship", label: "Relationship"},
  { id: "phone", label: "Phone"},
  { id: "email", label: "Email"},
  { id: "professional_type_id", label: "Type", sortable: true, displayFn: (_, r) => getProfessionalTypeName(r.professional_type_id as ProfessionalType)},
  { id: 'is_primary', label: "Primary Contact", displayFn: v => <BinaryValueCell value={v}/>},
  { id: 'receives_email_notices', label: "Receives Email Notices", displayFn: v => <BinaryValueCell value={v}/> },
];

export const ClientProfessionals: FC = () => {
  const { clientId } = useMaskedParams();
  const { activeClient, setActiveClient } = useClientDetails();
  const { setLoading, setError, setSuccess } = useUI();
  const [showAddProfessionalModal, setShowAddProfessionalModal] = useState({show: false, isManageMode: false});
  const [professionalToDelete, setProfessionalToDelete] = useState<Pick<Professional, 'id' | 'first_name' | 'last_name'> | null>(null);

  const refreshClientData = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await apiClient.get("/customers/:p0", {routeParams: [clientId!]});
      setActiveClient(resp);
    } catch (error) {
      setError(
        <div>
          <Typography variant="h6">Something went wrong:</Typography>
          <Typography variant="body1">
            We were unable to load your data after updating the professionals.
            Please contact <ErrorContactEmail/> for assistance.
          </Typography>
        </div>
      )
    } finally {
      setLoading(false);
    }
  }, [clientId, setActiveClient, setError, setLoading])

  const closeConfirmationDialog = (success?: boolean) => {
    if (success) {
      deleteProfessional(professionalToDelete!.id);
    }

    setProfessionalToDelete(null);
  }

  const deleteProfessional = useCallback(async (professionalId: number) => {
    try {
      setLoading(true);
      await apiClient.delete('/professionals/interested-parties', {data: {professionalId, customerId: +clientId!}})
      setSuccess("Professional removed successfully");
      refreshClientData();
    } catch (error) {
      setLoading(false);
      setError(
        <div>
          <Typography variant="h6">Something went wrong:</Typography>
          <Typography variant="body1">
            We were unable to remove the professional. 
            Please contact <ErrorContactEmail/> for assistance.
          </Typography>
        </div>
      )
    }
  }, [clientId, refreshClientData, setLoading, setError, setSuccess])

  const handleAddProfessional = useCallback(() => {
    setShowAddProfessionalModal({show: true, isManageMode: false});
  }, []);

  const handleManageProfessional = useCallback(() => {
    setShowAddProfessionalModal({show: true, isManageMode: true});
  }, []);

  const closeAddProfessionalModal = async (success?: boolean) => {
    setShowAddProfessionalModal(v => ({...v, show: false}));
    if (!success) {
      return;
    }
    refreshClientData();
  };

  return (
    <Box component="section">
      <Box sx={{display: 'flex',justifyContent: "space-between", py: 2}}>
        <Typography variant="h5" mb={1}>Professionals</Typography>
        <Box display='flex' gap={2}>
          <Button color="info" onClick={handleAddProfessional} startIcon={<Add/>}>Add</Button>
          <Button color="info" onClick={handleManageProfessional} startIcon={<Edit/>}>Manage</Button>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <DataSourceTable 
          initialOrderBy={"first_name"}
          columnDef={professionalsColumnDef} 
          dataSource={activeClient?.professionals ?? []} 
          emptyMessage="No professionals"
        />
      </TableContainer>

      <Dialog
        open={showAddProfessionalModal.show}
        fullWidth
        maxWidth="lg"
      >
        <AddProfessionalDialog
          onClose={closeAddProfessionalModal} 
          clientId={+clientId!}
          isManageMode={showAddProfessionalModal.isManageMode}
          setProfessionalToDelete={setProfessionalToDelete}
        />
      </Dialog>
      <ConfirmationDialog 
        maxWidth='xs'
        open={!!professionalToDelete} 
        handleClose={closeConfirmationDialog}
        yesButtonLabel="Remove"
        noButtonLabel="Cancel"
        message={
          `You are about to remove ${professionalToDelete?.first_name} ${professionalToDelete?.last_name} 
          from this client's household professionals list.`
        }
        titleNode={
          <Box display="flex" flexDirection='column' justifyContent='space-between' alignItems='center'>
            <CancelOutlined color='error' sx={{fontSize: "4rem", mb: 3}}/>
            <Typography variant="h4" fontWeight='bold'>Are you sure?</Typography>
          </Box>
        }
        sx={{
          '& .MuiButton-root.MuiButton-containedPrimary': {
            backgroundColor: 'error.main',
            '&:hover': {
              backgroundColor: 'error.dark',
            },
          },
        }}
        />
    </Box>
  )
}
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

const UnstyledTableTooltip = ({ className, ...props }: TooltipProps) => (
  <Tooltip placement="bottom-end" {...props} classes={{ popper: className }} />
);

export const TableTooltip = styled(UnstyledTableTooltip)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 11px 24px rgb(0 0 0 / 33%)',
    borderRadius: '10px',
    color: theme.palette.text.primary,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.secondary,
    fontSize: 16,
    '&::before': {
      border: '1px solid rgb(0 0 0 / 10%)',
    }
  },
  '& .MuiTableContainer-root': {
    maxHeight: '150px',
    overflowY: 'scroll',
  },
  '& .MuiTableCell-root': {
    padding: '0.8rem 1rem',
    border: 'none',
    '&.MuiTableCell-head': {
      fontFamily: 'inherit', fontWeight: '500'
    },
    fontSize: 16
  },
  '& .MuiTableHead-root': {
    backgroundColor: 'inherit'
  },
}));
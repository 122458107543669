import { Box, MenuItem } from '@mui/material';
import { Field } from 'formik';
import { Select, TextField } from 'formik-mui';
import { useUI } from '../../../../../hooks';
import { useSubDocs } from '../../../../../hooks/ContextHooks/useSubDocs';
import { DocumentFileUploadField } from '../../../../Clients/NewClientPage/Forms/CustomFields/DocumentFileUploadField';

export default function ACHInstructionFields() {
  const {distributionInstructionsData} = useSubDocs();
  const {setError} = useUI();

  if (distributionInstructionsData == null) {
    setError("Oops, something went wrong. Please try again later")
    return null
  } 

  return (
    <Box sx={{mt: 4, mb:2}}>
      <Box>
        <Field name="achInstructions.instructionName" required label="Distribution Instruction Friendly Name" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
        <Field name="achInstructions.bankAccountName" required label="Name on Bank Account" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
      </Box>
      <Box>
        <Field name="achInstructions.aba" required label="Bank's Routing Number" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
        <Field name="achInstructions.accountNumber" required label="Bank Account Number" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
      </Box>
      <Box>
      <Field 
          name="achInstructions.accountType" 
          label="Account Type" 
          component={Select} 
          formControl={{required: true, sx:{width: 'calc(50% - 8px)', mr: 2}}}
          onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
          >
            {distributionInstructionsData.distributionInstructionAccountTypes.map(accountType => (
              <MenuItem key={accountType.id} value={accountType.id}>{accountType.name}</MenuItem>
            ))}
        </Field>
        <Field name="achInstructions.ffc" label="For Further Credit To" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
        <DocumentFileUploadField
        accept="image/png,image/gif,image/jpeg,image/tiff,application/pdf"
        label="Attach Voided Check"
        name="achInstructions.voidedCheck"
        sx={{width: 'calc(33% - 8px)', mr: 2}}
        />
      </Box>
    </Box>
  );
}
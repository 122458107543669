import { createTheme } from '@mui/material/styles';
import { baseThemeOptions } from './_base.theme';

export const lightTheme = createTheme({
  typography: baseThemeOptions.typography,
  components: baseThemeOptions.components,
  palette: {
    ...baseThemeOptions.palette,
    background: {default: "#f7f9f4", secondary: "#fbfbfc"},
  },
});
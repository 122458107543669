import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { LoadingButton } from "../../../../components/Buttons";
import { ButtonProps, IconButton } from "@mui/material";
import { apiClient } from "../../../../api/apiClient";
import { grey, common } from "@mui/material/colors";

interface DocumentDownloadButtonProps extends ButtonProps {
  documentId: number;
  icon?: ReactNode;
  onDownload?: () => void;
}

export const DocumentDownloadButton: FC<DocumentDownloadButtonProps> = ({documentId, icon, disabled, onDownload, ...props}) => {
  const [loading, setLoading] = useState(false);
  
  const handleDownload = useCallback(async () => {
    if (disabled) return;

    setLoading(true);
    try {
      const { url } = await apiClient.get('/documents/:p0', { routeParams: [documentId] });
      window.open(url);
      onDownload?.();
    } catch (error) {
      console.error("Failed to download document:", error);
    } finally {
      setLoading(false);
    }
  }, [documentId, disabled, onDownload]);

  const buttonStyle = useMemo(() => ({
    width: '2rem',
    height: '2rem',
    color: disabled ? grey[400] : common.black
  }), [disabled]);

  if (icon) {
    return (
      <IconButton
        style={buttonStyle}
        onClick={handleDownload}
        disabled={disabled}
      >
        {icon}
      </IconButton>
    );
  }
  
  return (
    <LoadingButton {...props} loading={loading} onClick={handleDownload}>
      {props.children}
    </LoadingButton>
  );
}
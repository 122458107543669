import { TableCell, TableCellProps, TableRow } from "@mui/material"
import { ColumnDef, DataSourceBase, RowState } from "../DataSourceTable.types"
import { ReactElement, useEffect, useRef } from "react"

type DataRowProps<T extends DataSourceBase> = { 
  columnDef: ColumnDef<T>, 
  data: T,
  cellSize?: TableCellProps['size']
  rowState?: Partial<RowState[string]>;
  onRowStateChange?: (newState: Partial<RowState[string]>) => void;
}

export const DataRow = <T extends DataSourceBase>({ columnDef, data, cellSize, rowState, onRowStateChange }: DataRowProps<T>): ReactElement => {
  const rowRef = useRef<HTMLTableRowElement>(null);

  const handleRowClick = () => {
    if (rowState?.isHighlighted && onRowStateChange) {
      onRowStateChange({ isHighlighted: false });
    } 
  };

  useEffect(() => {
    if (rowState?.isHighlighted && rowRef.current) {
      rowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [rowState?.isHighlighted]);

  return (
    <TableRow 
      ref={rowRef}
      key={data.id} 
      onClick={handleRowClick}
      sx={{
        backgroundColor: rowState?.isHighlighted ? '#FFF3CD' : 'inherit',
        '&:hover': { backgroundColor: rowState?.isHighlighted ? '#FFE8A1' : '#F5F5F5' },
        transition: 'background-color 0.3s ease',
      }}
    >
      {columnDef.map(column => (
        <TableCell
          key={column.id as any}
          className={`col-${column.id as any}`}
          size={cellSize}
        >
          {column.displayFn ? column.displayFn(data[column.id], data) : data[column.id]}
        </TableCell>
      ))}
    </TableRow>
  )
}
import { InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { NumberFormatValues, NumericFormat } from "react-number-format";

/** @deprecated  */
export const handleValueChange = (name: string, setFieldValue: (arg0: string, arg1: number | undefined) => void) => (val: NumberFormatValues) => {
  setFieldValue(name, val.floatValue);
}

const CurrencyFieldText = ({ ...props }) => {
  const [displayValue, setDisplayValue] = useState<number | string | null >();
  return (
    <NumericFormat
      customInput={TextField}
      variant="outlined"
      thousandSeparator={true}
      value={displayValue}
      decimalScale={2}
      onValueChange={(vals: NumberFormatValues) => {
        return setDisplayValue(vals.formattedValue)}}
      InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ outline: "none" }}>
              $
            </InputAdornment>
          ),
        }}
      {...props}
    />
  );
};

export default CurrencyFieldText;

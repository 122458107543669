import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { SubDocsFormValues } from '../subDocsFormSchema';

type Props = {
  label: string
  name: string
}

export const SubDocsRadioGroup = ({ label, name, }: Props) => {
  const {setFieldValue, getFieldProps} = useFormikContext<SubDocsFormValues>();
  const fieldProps = getFieldProps(name);
  return (
    <>
      <Typography >{label}</Typography>
      <FormControl>
        <RadioGroup
          row
          {...fieldProps}
          onChange={(_event, value) => { 
            setFieldValue(name, +value) 
          }
        }
        >
          <FormControlLabel value={1} control={<Radio />} label="Yes" />
          <FormControlLabel value={0} control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
    </>
  )
};
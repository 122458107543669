import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Select, TextField, Switch } from "formik-mui";
import { InferType, object, string, boolean, number } from "yup";
import { apiClient } from "../../../../api/apiClient";
import { useUI } from "../../../../hooks";
import { FormikSubmitFn } from "../../../../utility/types";
import {
  CornerCloseButton,
  LoadingButton,
} from "../../../../components/Buttons";
import {
  CognitoUserStatus,
  Contact,
  ModifyCognito,
} from "../../../../api/payloads/customer.payload";
import { useState } from "react";
import { ConfirmationDialog } from "../../../../components/ConfirmationDialog/ConfirmationDialog";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "100px",
    },
  },
};

const exchangeUserSchema = object({
  password: string(),
  phonenumberID: number().required("Phone Number is required"),
  email: string().required("Phone Number is required"),
  updatePassword: boolean(),
  updateEmail: boolean(),
});

type ExchangeData = InferType<typeof exchangeUserSchema>;

type Props = {
  setClose: any;
  member: Contact;
};

export default function EditVisionUser({ setClose, member }: Props) {
  const { loading, setLoading, setError, setSuccess } = useUI();
  const [showConfimationDialog, setShowConfirmationDialog] = useState(false);

  const initialValues: ExchangeData = {
    password: "",
    email: member?.email || "",
    phonenumberID: member?.exchange_user?.contact_number_id || 0,
    updatePassword: false,
    updateEmail: false,
  };
  const handleSubmit: FormikSubmitFn<ExchangeData> = async (
    values,
    { setErrors, setSubmitting }
  ) => {
    setLoading(true);
    try {
      const { email, password, phonenumberID, updateEmail, updatePassword } =
        values;
      const cognitoId = member.exchange_user!.cognito_id;

      const phoneNumberChanged = phonenumberID !== initialValues.phonenumberID;
      const foundPhone = member?.phone_numbers?.filter(
        (p: any) => p.id === Number(phonenumberID)
      );

      const dialCodePhoneNumber = `+${
        foundPhone[0]?.dial_code
      }${foundPhone[0]?.number.replaceAll("-", "")}`;

      const editCognitoPayload: ModifyCognito = {
        cognitoId,
        phoneNumber: dialCodePhoneNumber,
        contact_number_id: phonenumberID,
        email,
        password,
        isUpdatePhone: !!phoneNumberChanged,
        isUpdateEmail: !!updateEmail,
        isUpdatePassword: !!updatePassword,
      };
      const resp = await apiClient.post("/customers/investor-vision/modify", {
        data: editCognitoPayload,
      });
      if (resp) {
        setSuccess("Vision User Updated");
      }
      setClose();
    } catch (e) {
      setError("Oops, something went wrong. Please try again later");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const closeConfirmationDialog = async (success?: boolean) => {
    setShowConfirmationDialog(false);
    if (!success) {
      return;
    }
    try {
      setLoading(true);
      const statusPayload: CognitoUserStatus = {
        username: member.exchange_user!.cognito_id,
        deleted: !member.exchange_user!.deleted,
        exUserId: member.exchange_user!.id,
      };
      await apiClient.post("/customers/investor-vision/user/status", {
        data: statusPayload,
      });
      setClose();
      member?.exchange_user?.deleted
        ? setSuccess("User Enabled")
        : setSuccess("User Disabled");
    } catch (error) {
      setError("Oops, something went wrong. Please try again later");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={true}
        PaperProps={{ sx: { minWidth: "550px" } }}
        maxWidth="sm"
        onClose={() => setClose()}
      >
        <DialogTitle>
          Edit Vision User
          <CornerCloseButton onClick={() => setClose()}></CornerCloseButton>
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={exchangeUserSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, dirty, values }) => (
            <Form>
              <DialogContent style={{ paddingTop: "0.5rem" }}>
                <ErrorMessage name="error" />
                <Box sx={{ my: 1, display: "flex", alignItems: "baseline" }}>
                  <Field
                    name="password"
                    label="New Password"
                    component={TextField}
                    fullWidth
                    disabled={!values.updatePassword}
                  />
                  <Field
                    component={Switch}
                    id="updatePassword"
                    type="checkbox"
                    name="updatePassword"
                  />
                </Box>
                <Box sx={{ my: 1, display: "flex", alignItems: "baseline" }}>
                  <Field
                    name="email"
                    label="Email*"
                    component={TextField}
                    fullWidth
                    disabled={!values.updateEmail}
                  />
                  <Field
                    component={Switch}
                    id="updateEmail"
                    type="checkbox"
                    name="updateEmail"
                  />
                </Box>
                <Field
                  formControl={{ sx: { width: 1 } }}
                  component={Select}
                  label="Phone Number*"
                  MenuProps={MenuProps}
                  name="phonenumberID"
                  id="phonenumberID"
                >
                  {member?.phone_numbers?.map((p: any) => (
                    <MenuItem key={p.id} value={`${p?.id}`}>
                      {p?.number}
                    </MenuItem>
                  ))}
                </Field>
              </DialogContent>

              <DialogActions sx={{flexDirection: 'column'}}>
                <Typography variant="body2" 
                  display='block'
                  fontWeight='500'
                >
                  Household member will be notified of changes.</Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}
                >
                    <LoadingButton
                      disabled={!isValid && dirty}
                      color="info"
                      type="submit"
                      loading={loading}
                    >
                      Update
                    </LoadingButton>
                </Box>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      <ConfirmationDialog
        maxWidth="md"
        open={showConfimationDialog}
        handleClose={closeConfirmationDialog}
        yesButtonLabel="Yes"
        noButtonLabel="No"
        message={`This action will  ${
          member?.exchange_user?.deleted ? "Enable" : "Disable"
        } User `}
      />
    </>
  );
}
